import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {determineFontSize} from './BookPage.service';
import useStyles from './BookPage.styles';

type props = {
  book?: {
    pages?: {content?: string; pagePhotoURL?: string}[];
  };
  pageNumber: number;
};

const BookPage: FC<props> = ({book, pageNumber}: props) => {
  const maxPageLength = book?.pages
    ?.map(page => page?.content?.length)
    .sort()
    .at(-1);

  const {fontSize, fontWeight} = determineFontSize(maxPageLength);

  const pages = book?.pages || [];
  const page = pages[pageNumber];
  const classes = useStyles({
    contentFontWeight: fontWeight,
    contentFontSize: fontSize,
    pagePhotoUrl: page?.pagePhotoURL || '',
  });

  return (
    <Box className={classes.page}>
      <Box className={classes.imageContainer}>
        <Box className={classes.image}></Box>
      </Box>
      <Box className={classes.contentContainer}>
        <Typography className={classes.content}>{page?.content}</Typography>
      </Box>
    </Box>
  );
};
export default BookPage;

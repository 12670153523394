import createStyles from '@mui/styles/createStyles';
import {makeStyles} from '@mui/styles';
import {fullHorizontalBreakpoint} from './BreakPoints';

const bodyBreakpoint = '@media (min-width: 640px)';
const bodyMaxWidthBreakpoint = '@media (min-width: 700px)';

export default makeStyles(theme => {
  return createStyles({
    body: {
      margin: '0 auto',
      padding: '0 16px',
      marginBottom: theme.spacing(4),
      textAlign: 'left',
      [bodyBreakpoint]: {
        padding: '0 24px',
      },
      [bodyMaxWidthBreakpoint]: {
        maxWidth: '740px',
      },
    },
    downloadPrompt: (props: {isDigital: boolean}) => ({
      [fullHorizontalBreakpoint]: {
        display: props.isDigital ? 'flex' : '',
        width: '100%',
      },
    }),
    title: {
      marginBottom: theme.spacing(2),
    },
  });
});

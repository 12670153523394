import React from 'react';
import {Container, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import elloSad from 'shared/assets/lottie/elloSad.json';
import Lottie from 'react-lottie-player';
import styles from './RemoveChildSuccess.styles';
import {FlowLayout} from 'customerPortal/components/Flow';
import Button from 'shared/components/common/Button';
import {useHistory} from 'react-router-dom';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';

const RemoveChildSuccess = ({classes}: WithStyles<typeof styles>) => {
  const history = useHistory();
  return (
    <FlowLayout
      title="Profile removed"
      cta={
        <Button
          className={classes.button}
          onClick={() => history.push(PRIVATE_ROUTES.ACCOUNT_SUB_DETAILS.path)}>
          Done
        </Button>
      }>
      <Container maxWidth="md" className={classes.content}>
        <Lottie loop animationData={elloSad} play className={classes.lottie} />
        <Typography className={classes.text}>
          We're sad to see your child leave, <br />
          and looking forward to hearing their awesome reading again soon.
        </Typography>
      </Container>
    </FlowLayout>
  );
};

export default withStyles(styles)(RemoveChildSuccess);

import React from 'react';
import LinkContainer from './ReferralLink.link-container';
import {ReferralLinkService} from './ReferralLink.service';
import Box from '@mui/material/Box';

type Props = {
  linkHeader?: string;
  linkBody?: string;
  baseURL?: string;
  title?: string;
};

const CopyReferralLink = ({baseURL, linkHeader, linkBody, title}: Props) => {
  const referralLink = ReferralLinkService.referralLink(baseURL);

  return (
    <Box>
      {referralLink && (
        <LinkContainer
          header={linkHeader}
          body={linkBody}
          referralLink={referralLink}
        />
      )}
    </Box>
  );
};

export default CopyReferralLink;

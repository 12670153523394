import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  FormHelperText,
} from '@mui/material';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';

export interface FormSelectItem {
  value: any;
  label: string;
}

export interface FormSelectProps {
  label: string;
  id: string;
  value: string;
  items: FormSelectItem[];
  errorMessage?: string;
  containerClass?: string;
  onChange: (c: any) => void;
}

const FormSelect = ({
  label,
  id,
  value,
  items,
  errorMessage,
  containerClass,
  onChange,
}: FormSelectProps) => {
  const shouldUseNativeSelect = useMobileLayout();

  return (
    <FormControl
      variant="outlined"
      error={!!errorMessage}
      className={containerClass}>
      <InputLabel id={id}>{label}</InputLabel>
      <Select
        MenuProps={{
          transitionDuration: 0,
        }}
        data-testid={id}
        labelId={id}
        id={id}
        native={shouldUseNativeSelect}
        value={value}
        onChange={onChange}
        label={label}>
        {shouldUseNativeSelect ? (
          <>
            <option hidden aria-label="None" value="" />
            {items.map((item: FormSelectItem) => (
              <option value={item.value} key={item.value}>
                {item.label}
              </option>
            ))}
          </>
        ) : (
          items.map((item: FormSelectItem) => (
            <MenuItem value={item.value} key={item.value}>
              {item.label}
            </MenuItem>
          ))
        )}
      </Select>
      {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
    </FormControl>
  );
};

export default FormSelect;

import makeStyles from '@mui/styles/makeStyles';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

export default makeStyles(theme => ({
  tileImage: {
    // Mobile Layout
    height: 50,
    width: 50,
    padding: 5,
    objectFit: 'scale-down',
    marginLeft: 50,
    marginRight: 20,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Desktop Layout
      padding: 0,
      margin: 0,
      height: 55,
      width: 'auto',
      marginBottom: 5,
      objectFit: 'cover',
    },
    textAlign: 'center',
  },
  tileImageSelectUpdated: {
    height: 30,
    width: 30,
    padding: 0,
    marginLeft: 0,
    marginRight: '12px',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      margin: 0,
      marginBottom: 5,
    },
  },

  largeTileImage: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Desktop Layout
      margin: 0,
      marginBottom: 10,
      height: 200,
      width: 200,
    },
  },
  tile: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    // Mobile layout
    flexDirection: 'row',
    justifyContent: 'left',
    borderRadius: 10,
    border: '3px solid white',
    textAlign: 'center',

    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      border: '6px solid white',
      // Desktop layout
      flexDirection: 'column',
      height: 175,
      justifyContent: 'center',
      borderRadius: 30,
      '&:hover': {
        background: '#fafafa',
      },
      textAlign: 'center',
    },
  },
  largeTile: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    // Mobile layout
    flexDirection: 'row',
    justifyContent: 'left',
    borderRadius: 10,
    border: '3px solid white',
    textAlign: 'left',
    background: '#f0f0f0',

    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      border: '6px solid white',
      // Desktop layout
      flexDirection: 'column',
      height: 275,
      maxWidth: 275,
      justifyContent: 'center',
      borderRadius: 30,
      '&:hover': {
        background: '#e6e6e6',
      },
      textAlign: 'center',
    },
  },

  tileWithoutImage: {
    background: '#f0f0f0',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Hover on Desktop
      '&:hover': {
        background: '#e6e6e6',
      },
    },
  },
  tileWithoutImageOrCheckbox: {
    justifyContent: 'center',
  },
  tileSelectUpdated: {
    padding: '16px 24px 14px 24px',
    borderRadius: 8,
    textAlign: 'left',
    border: `1px solid ${palette.gray300}`,
    background: palette.gray,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Desktop layout
      textAlign: 'center',
      borderRadius: 30,
    },
  },
  largeTileSelectUpdated: {
    padding: '16px 24px 14px 24px',
    borderRadius: 8,
    border: `1px solid ${palette.gray300}`,
    background: palette.gray,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Desktop layout
      borderRadius: 30,
    },
  },
  tileSelected: {
    color: palette.black,
    background: palette.turquoise100, // rgb(207, 250, 250)
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Hover on Desktop
      '&:hover': {
        background: `linear-gradient(to top, rgba(207,250,250,1.0) 0%, rgba(207,250,250,0.4) 100%)`,
      },
    },
  },
  tileTitle: {
    padding: 20,
    fontSize: 17,
  },
  // selectUpdated - tileTitle overriden styles
  tileTitleSelectUpdated: {
    padding: 0,
  },
  tileTitleImg: {
    marginTop: 5,
    fontWeight: 600,
    fontSize: 15,
    textAlign: 'left',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      fontSize: 13,
      textAlign: 'center',
    },
  },
  tileTitleLarge: {
    padding: 10,
    fontSize: 15,
    fontWeight: 600,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      padding: 10,
      marginTop: -20, // Hack hack -- go into the padding of the image a bit because of how they're formatted
    },
  },
  checkbox: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      // Positioning on desktop
      opacity: 0.8,
      position: 'absolute',
      top: 5,
      right: 5,
    },
  },
  otherContainer: {
    padding: 20,
    width: '100%',
  },
}));

/**
 * Navigation buttons for the onboarding flow.
 * Handle mobile and desktop layouting.
 *
 * Props:
 * - onNext: next button handler
 * - onBack: back button handler
 * - onFinish: finish/submit button handler
 * - hideNextButton: optional to hide the next button if we want to render a different CTA
 *   or input isn't ready yet.
 * - disableNextButton: render next button as disabled (renders hidden too on mobile)
 * - nextButtonTitle: optional next button title. Only visible on desktop.
 * - variant: one of 'step', 'submit' or 'finish'
 */

import React from 'react';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CheckIcon from '@mui/icons-material/Check';
import Fab from '@mui/material/Fab';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Zoom from '@mui/material/Zoom';
import clsx from 'clsx';
import {useWindowSize} from 'shared/utils/layoutHooks';
import {useLocale, useStrings, Locales} from 'shared/utils/localization';
import {resolveQueryString} from 'shared/utils/stringUtils/stringUtils';
import useStyles from './OnboardingNavigator.styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

function OnboardingNavigator({
  onNext,
  onFinish,
  hideNextButton,
  disableNextButton,
  inputFocusOffset,
  nextButtonTitle,
  variant = 'step',
}) {
  const classes = useStyles();
  const [windowWidth, windowHeight] = useWindowSize();
  const strings = useStrings();
  const {locale} = useLocale();

  // For rendering checkmark on mobile
  const isNextButtonConfirm =
    resolveQueryString(nextButtonTitle, Locales.enUS) &&
    ['confirm', 'submit', 'unlock', 'pay', 'go', 'save', 'update'].includes(
      resolveQueryString(nextButtonTitle, Locales.enUS).toLowerCase(),
    );

  // Handle localization
  nextButtonTitle = resolveQueryString(nextButtonTitle, Locales.enUS)
    ? resolveQueryString(nextButtonTitle, locale)
    : strings.next;

  // Deal with Mobile Safari Keyboard covering next button insanity
  const iOSFocusOffset = window.navigator.userAgent.match(/iPhone|iPod/i)
    ? inputFocusOffset
    : null;

  const isDesktop = windowWidth > TABLET_BREAKPOINT;

  if (variant === 'submit' || variant === 'finish')
    return (
      <Box component="div" className={classes.buttonsFinish}>
        <Button
          variant="contained"
          color={variant === 'submit' ? 'primary' : undefined}
          disableElevation
          onClick={onFinish}
          disabled={disableNextButton}
          className={clsx(
            classes.button,
            variant === 'submit' ? classes.submitButton : classes.finishButton,
          )}>
          {variant === 'submit' ? 'Submit' : 'Finish'}
        </Button>
      </Box>
    );

  if (isDesktop) {
    // On Desktop, we use contained Next and Back buttons
    return (
      <div>
        <Box component="div" className={classes.buttons}>
          {!hideNextButton && (
            <Button
              variant="contained"
              color="primary"
              disableElevation
              disabled={disableNextButton}
              onClick={() => onNext()}
              className={clsx(classes.button, classes.nextButton)}>
              {nextButtonTitle}
            </Button>
          )}
        </Box>
      </div>
    );
  }

  // On mobile, we use FABs
  return (
    <>
      {/* Add extra padding to the bottom of scrollable screen estate to make sure
          FAB doesn't cover content at the bottom. */}
      <div className={classes.fabSpacer} />

      <Zoom in={!hideNextButton}>
        <Fab
          disabled={disableNextButton}
          color="primary"
          aria-label="next"
          className={classes.fabNext}
          style={
            // Pull buttons up above keyboard to avoid covering input
            iOSFocusOffset
              ? {
                  bottom: windowHeight - iOSFocusOffset - 100,
                  transition: 'bottom 0.5s',
                }
              : {}
          }
          // Using onMouseDown rather than onClick to make sure we overwrite blur ordering
          // See https://stackoverflow.com/questions/17769005
          onMouseDown={() =>
            // If there's an input on focus, give it time to handle its onBlur event, since
            // moving to the next page takes it off screen.
            inputFocusOffset ? setTimeout(onNext, 10) : onNext()
          }>
          {isNextButtonConfirm ? <CheckIcon /> : <ArrowForwardIcon />}
        </Fab>
      </Zoom>
    </>
  );
}

export default React.memo(OnboardingNavigator);

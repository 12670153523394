import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {useMutation} from '@apollo/client';

import * as palette from 'shared/styles/palette';
import useMobileLayout from '../../../../utils/useMobileLayout';
import {CUSTOMER_UPDATE_PROFILE_MUTATION} from './EditProfileScreen.query';
import {updateProfileWithMutation} from './EditProfileScreen.service';
import CircularProgress from '@mui/material/CircularProgress';

type props = {
  id?: string;
  name?: string;
  refetchProfile?: () => void;
};
const EditProfileName = ({id, name, refetchProfile}: props) => {
  const [editingName, setEditingName] = useState(false);
  const [profileName, setProfileName] = useState(name);
  const [customerUpdateProfileMutation, {loading}] = useMutation(
    CUSTOMER_UPDATE_PROFILE_MUTATION,
  );
  const updateProfile = updateProfileWithMutation(
    customerUpdateProfileMutation,
  );
  const isMobile = useMobileLayout();
  const updateProfileName = (e: any) => {
    setProfileName(e.target.value);
  };

  return (
    <Box
      sx={{
        marginTop: '2rem',
      }}>
      {editingName && id ? (
        <Box>
          <TextField
            value={profileName}
            onChange={updateProfileName}
            label="Name"
            InputProps={{
              style: {
                width: isMobile ? '100%' : '400px',
              },
            }}
            style={{display: 'block'}}
            variant="outlined"
          />
          <Button
            variant="contained"
            disabled={loading}
            style={{
              minWidth: '200px',
              textTransform: 'none',
              height: '45px',
              margin: '1.5rem 0',
              backgroundColor: loading ? palette.gray500 : palette.turquoise900,
              color: palette.white,
              borderRadius: '2rem',
            }}
            onClick={async () => {
              await updateProfile(id, {name: profileName});
              refetchProfile && refetchProfile();
              setEditingName(false);
            }}>
            Save
            {loading && (
              <CircularProgress
                size={20}
                style={{
                  marginLeft: '1rem',
                }}
                color="inherit"
              />
            )}
          </Button>
        </Box>
      ) : (
        <Box>
          <Typography
            style={{color: palette.gray700, fontWeight: 500, fontSize: '14px'}}>
            Name
          </Typography>
          <Box
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}>
            <Typography
              style={{
                color: palette.gray900,
                fontWeight: 600,
                fontSize: '16px',
              }}>
              {name}
            </Typography>
            <Button
              onClick={() => {
                setEditingName(true);
              }}
              style={{
                textTransform: 'none',
                color: palette.turquoise900,
                fontWeight: 700,
                fontSize: '16px',
                maxHeight: '3rem',
              }}>
              Edit
            </Button>
          </Box>
        </Box>
      )}

      <Divider />
    </Box>
  );
};

export default EditProfileName;

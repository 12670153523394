import React, {useState} from 'react';
import Box from '@mui/material/Box';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Lottie from 'react-lottie-player';
import {useQuery} from '@apollo/react-hooks';
import Typography from '@mui/material/Typography';

import elloSuccess from 'shared/assets/lottie/elloSuccess.json';
import AddProfileScreenActions from './AddProfileScreen.actions';
import styles from './AddProfileScreen.styles';
import {MobileBanner, DesktopBanner} from '../../../../components/Banner';
import useMobileLayout from '../../../../utils/useMobileLayout';
import BackButton from '../../../../components/Banner/BackButton/BackButton';
import addChildFlow from 'shared/assets/addChildFlow.png';
import {
  AddProfileStep,
  addProfileErrorMessage,
  previousAddProfileStep,
} from './AddProfileScreen.service';
import robot from 'shared/assets/robot.svg';
import AddProfileScreenForm from './AddProfileScreen.form.details';
import {ProfileCharacter} from '../EditProfile/EditProfileScreen.service';
import {addedProfileErrorType} from '../ProfilesScreen.types';
import {CUSTOMER_PROFILES} from '../ProfilesScreen.query';
import {Profile} from '../../../../../shared/types/profile';
import AddProfileCharacter from './AddProfileScreen.character';

const AddProfileScreen = ({classes}: WithStyles<typeof styles>) => {
  const {data} = useQuery<{profiles: Profile[]}>(CUSTOMER_PROFILES, {
    fetchPolicy: 'network-only',
    onError: error => console.error('Customer profiles', error),
  });

  const customerProfiles = data?.profiles;

  const isMobileView = useMobileLayout();
  const [currentAddProfileStep, setCurrentAddProfileStep] = useState(
    AddProfileStep.AddDetails,
  );

  const backHandler = () => {
    setCurrentAddProfileStep(previousAddProfileStep(currentAddProfileStep));
  };

  const [profileBeingAdded, setProfileBeingAdded] = useState({
    name: '',
    grade: '',
    readingAbility: '',
    profileCharacter: ProfileCharacter.Bull,
  });

  const [error, setError] = useState<addedProfileErrorType>({
    nameError: {
      required: false,
      duplicate: false,
    },
    gradeError: false,
    readingAbilityError: false,
  });

  const [addProfileError, setAddProfileError] = useState<string>('');

  const isSelectingCharacter =
    currentAddProfileStep === AddProfileStep.SelectProfileCharacter;
  const isAddingProfileDetails =
    currentAddProfileStep === AddProfileStep.AddDetails;
  const successFullyAddedProfile =
    currentAddProfileStep === AddProfileStep.SuccessfullyAddedProfile;
  const errorAddingProfile =
    currentAddProfileStep === AddProfileStep.ErrorWhenAddingProfile;
  const isFullScreen = successFullyAddedProfile || errorAddingProfile;
  const backButtonClickHandler =
    isSelectingCharacter || errorAddingProfile ? backHandler : undefined;

  return (
    <Box>
      {isMobileView ? (
        <MobileBanner
          showBackButton={!successFullyAddedProfile}
          onClickHandler={backButtonClickHandler}
        />
      ) : (
        <DesktopBanner />
      )}
      <Box className={classes.container}>
        {!isMobileView && !successFullyAddedProfile && (
          <BackButton onClickHandler={backButtonClickHandler} />
        )}
        <Box className={classes.formContainer}>
          {isAddingProfileDetails && (
            <AddProfileScreenForm
              profileBeingAdded={profileBeingAdded}
              setProfileBeingAdded={setProfileBeingAdded}
              error={error}
              setError={setError}
            />
          )}

          {isSelectingCharacter && (
            <AddProfileCharacter
              selectedProfileCharacter={profileBeingAdded.profileCharacter}
              setProfileBeingAdded={setProfileBeingAdded}
            />
          )}

          {successFullyAddedProfile && (
            <Box className={classes.successScreenContainer}>
              <Typography className={classes.successScreenHeader}>
                {profileBeingAdded.name} was successfully added to your account
              </Typography>

              <Lottie
                loop
                animationData={elloSuccess}
                play
                className={classes.successScreenAnimation}
              />
            </Box>
          )}

          {errorAddingProfile && (
            <Box className={classes.errorContainer}>
              <Typography className={classes.errorHeader}>
                Problem Adding A Profile
              </Typography>
              <img src={robot} alt="" />
              <Typography variant="subtitle1" className={classes.errorSubtitle}>
                {addProfileErrorMessage(addProfileError)}
              </Typography>
            </Box>
          )}

          <AddProfileScreenActions
            profileBeingAdded={profileBeingAdded}
            customerProfiles={customerProfiles}
            currentAddProfileStep={currentAddProfileStep}
            setCurrentAddProfileStep={setCurrentAddProfileStep}
            setError={setError}
            setAddProfileError={setAddProfileError}
          />
        </Box>

        <Box
          className={classes.addChildImgContainer}
          style={{
            display: isFullScreen ? 'none' : undefined,
          }}>
          <img
            src={addChildFlow}
            alt=""
            width="100%"
            style={{
              aspectRatio: 1,
            }}
          />
        </Box>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(AddProfileScreen);

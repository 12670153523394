import React from 'react';
import Typography from '@mui/material/Typography';
import useStyles from './ReferralLink.styles';

type Props = {
  text?: string;
};

const BodyText = ({text}: Props) => {
  const classes = useStyles();

  return (
    <Typography my="20px" className={classes.bodyText}>
      {text || (
        <>
          Invite friends & family and you’ll both receive{' '}
          <span className={classes.linkHighlightText}>one free month</span> of
          Ello.
        </>
      )}
    </Typography>
  );
};

export default BodyText;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {blue500} from 'shared/styles/palette';
import Alert from '../../../../shared/components/common/Alert/Alert';
import {useCategories} from '../Library.hooks';
import LibraryService from '../Library.service';
import {CategoryName, isGradeLevelCategory} from '../Library.types';
import CategoryItems from './CategoryItems';

const categoryListBreakPoint = '@media (min-width: 1410px)';

type Props = {
  grade?: string;
};

const CategoriesPreview: FC<Props> = ({grade}: Props) => {
  const categoryName = LibraryService.getCategoryNameForGrade(grade);
  const name = isGradeLevelCategory(categoryName)
    ? CategoryName.popularBookSets
    : categoryName;

  const {data, error} = useCategories(
    {first: 2},
    {names: [name], includeReadingLists: true},
  );

  const [category] = data || [];

  return (
    <Box mt={6}>
      {category && (
        <Box
          sx={{
            maxWidth: '1360px',
            [categoryListBreakPoint]: {
              margin: '0 auto',
            },
          }}>
          <Typography
            sx={{
              mt: 8,
              fontSize: '1.625rem',
              fontWeight: 700,
              color: blue500,
            }}>
            Teacher Favorites
          </Typography>
          <CategoryItems
            subcategories={category.subcategories}
            readingLists={category.readingLists}
          />
        </Box>
      )}
      {error && (
        <Alert
          error={`Unable to retrieve books. Please check your internet connection and refresh the page.`}
          autoHide={false}
        />
      )}
    </Box>
  );
};

export default CategoriesPreview;

import {useQuery} from '@apollo/client';
import {QUERY_PROFILE_BOOKS} from './ProfileBooks.query';

type profileBook = {
  id: string;
  author: string;
  title: string;
  coverPhotoURL: string;
};

const digitalBookType = 'digital';
const personalisedBookType = 'personalised';

export function useProfileBooks(profileId: string) {
  const {data, error, loading} = useQuery<{
    profileBooks: profileBook[];
  }>(QUERY_PROFILE_BOOKS, {
    variables: {
      profileId: profileId,
      bookTypes: [digitalBookType, personalisedBookType],
    },
  });

  const profileBooks = sortProfileBooks(data?.profileBooks);

  return {profileBooks, error, loading};
}

function sortProfileBooks(profileBooks?: profileBook[]) {
  const sorted = [...(profileBooks || [])];

  if (sorted) {
    sorted.sort((a, b) => {
      if (a.id < b.id) {
        return 1;
      } else if (a.id > b.id) {
        return -1;
      } else {
        return 0;
      }
    });
  }

  return sorted;
}

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import useStyles from './Icon.styles';

type props = {
  icon: {alt: string; device: string; src: string; store: string};
};

const AppStorePromptIcon: FC<props> = ({icon}) => {
  const {alt, device, src, store} = icon;
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      <Box className={classes.iconContainer}>
        <img src={src} alt={alt} className={classes.icon} />
      </Box>
      <Box className={classes.textContainer}>
        <Typography className={classes.device}>{device}</Typography>
        <Typography className={classes.store}>{store}</Typography>
      </Box>
    </Box>
  );
};

export default AppStorePromptIcon;

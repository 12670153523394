import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {ImageQuestion} from './Image.types';

type Props = {
  question: ImageQuestion;
};

const defaultCutoff = 768;
const ResponsiveImage: FC<Props> = ({question}: Props) => {
  const cutoff = question.cutoff?.match(/\d+/)?.[0] || '';
  const cutoffInt = isNaN(parseInt(cutoff)) ? defaultCutoff : parseInt(cutoff);

  return (
    <Box>
      <Box component="picture">
        <Box
          component="source"
          media={`(max-width: ${cutoffInt - 1}px)`}
          srcSet={`${question.image}`}
        />
        <Box
          component="source"
          media={`(min-width: ${cutoffInt}px)`}
          srcSet={`${question.wideImage}`}
        />
        <Box
          component="img"
          sx={{
            maxWidth: '100%',
            maxHeight: '300px',
            ...(question.imageWidth ? {width: question.imageWidth} : {}),
            ...(question.maxWidth ? {maxWidth: question.maxWidth} : {}),
            ...(question.maxHeight ? {maxHeight: question.maxHeight} : {}),
            [`@media (min-width: ${cutoffInt}px)`]: {
              ...(question.wideMaxHeight
                ? {maxHeight: question.wideMaxHeight}
                : {}),
            },
          }}
          src={question.image}
          alt="Onboarding"
        />
      </Box>
    </Box>
  );
};

export default ResponsiveImage;

import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {blue500} from '../../../../shared/styles/palette';
import {Category as CategoryType} from '../Library.types';
import CategoryItems from './CategoryItems';

const categoryBreakPoint = '@media (min-width: 992px)';
const largeScreenBreakPoint = '@media (min-width: 1540px)';

type Props = {
  category: CategoryType;
};

const Category: FC<Props> = ({category}: Props) => {
  return (
    <Box>
      <Box
        sx={{
          mt: 6,
          [categoryBreakPoint]: {
            width: 'calc(100% - 80px)',
            margin: '32px auto',
          },
          [largeScreenBreakPoint]: {
            width: '100%',
          },
        }}>
        <Typography
          sx={{
            fontSize: '1.375rem',
            fontWeight: 700,
            color: blue500,
          }}>
          {category.name}
        </Typography>
      </Box>
      <Box>
        <CategoryItems
          subcategories={category.subcategories}
          readingLists={category.readingLists}
        />
      </Box>
    </Box>
  );
};

export default Category;

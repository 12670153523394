import React, {useState} from 'react';
import {Alert, Box, Snackbar, SnackbarCloseReason} from '@mui/material';
import ConfirmationScreen from './ConfirmationScreen';
import OfferScreen from './OfferScreen';
import {useExtendTrial} from '../../CancellationForm.service';

type Props = {
  onClose: () => void;
  onConfirm: () => void;
  setHideBackButton: (hide: boolean) => void;
};

const ExtendTrial: React.FC<Props> = ({
  onConfirm,
  onClose,
  setHideBackButton,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const {extendTrial, loading, errorMessage} = useExtendTrial(
    setHideBackButton,
    setShowConfirmation,
    setSnackbarOpen,
  );

  const handleCloseSnackbar = (
    _: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box height="100%">
      {showConfirmation ? (
        <ConfirmationScreen onClose={onClose} />
      ) : (
        <OfferScreen
          onAccept={extendTrial}
          onAcceptLoading={loading}
          onCancel={onConfirm}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default ExtendTrial;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const useStyles = (theme: Theme) =>
  createStyles({
    tile: {
      position: 'relative',
      display: 'flex',
      alignItems: 'center',
      cursor: 'pointer',
      // Mobile layout
      flexDirection: 'row',
      justifyContent: 'center',
      borderRadius: 10,
      border: '3px solid white',
      textAlign: 'left',
      background: palette.turquoise50,

      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        border: '6px solid white',
        // Desktop layout
        flexDirection: 'column',
        maxWidth: 275,
        justifyContent: 'center',
        borderRadius: 30,
        '&:hover': {
          background: '#e6e6e6',
        },
        textAlign: 'center',
      },
    },

    tileSelected: {
      color: palette.black,
      background: palette.turquoise100, // rgb(207, 250, 250)
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        // Hover on Desktop
        '&:hover': {
          background: `linear-gradient(to top, rgba(207,250,250,1.0) 0%, rgba(207,250,250,0.4) 100%)`,
        },
      },
    },
    tileTitle: {
      padding: 20,
      fontSize: 16,
      textAlign: 'center',
    },
  });

export default useStyles;

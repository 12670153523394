/*
 * A panel to select between different product options, each associated with a stripe price ID.
 * The selected product is stored in the URL query string.
 *
 * props.choices is an array of objects with the following properties:
 * - title: Product title
 * - priceId: Stripe price ID
 * - body: Product description (markdown)
 * - pill: Product pill text
 * - pillColor: Product pill color
 * - price: Product price (string label, including currency symbol)
 * - saving: Saving text (string label, including currency symbol)
 */

import Box from '@mui/material/Box';
import React from 'react';
import MoneyBackBadge from 'shared/components/common/MoneyBackBadge/MoneyBackBadge';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {useWindowSize} from 'shared/utils/layoutHooks';
import {StringParam, useQueryParam} from 'use-query-params';
import Choices from './Choices/Choices';
import ProductSavings from './ProductSavings';
import useStyles from './ProductSelectionPanel.styles';

function ProductSelectionPanel(props) {
  const {choices, onNext, onValidationChanged, useUpdated, useUpgrade} = props;
  const [selectedProduct, setSelectedProduct] = useQueryParam(
    'priceId',
    StringParam,
  );
  const classes = useStyles();

  const [windowWidth] = useWindowSize();
  const isTablet = windowWidth > TABLET_BREAKPOINT;

  const currentChoice = selectedProduct
    ? choices.find(choice => choice.priceId === selectedProduct)
    : undefined;

  const showProductSavings =
    !!selectedProduct && !!currentChoice?.price && !useUpdated;

  return (
    <>
      <Choices
        choices={choices}
        onNext={onNext}
        onValidationChanged={onValidationChanged}
        selectedProduct={selectedProduct}
        setSelectedProduct={setSelectedProduct}
        useUpdated={useUpdated || false}
        useUpgrade={useUpgrade || false}
      />

      {!isTablet && !useUpgrade && (
        <Box className={classes.mobileMoneyBackBox}>
          <MoneyBackBadge />
        </Box>
      )}
      <Box
        display="flex"
        alignItems="center"
        flexWrap={'wrap'}
        justifyContent={isTablet ? 'space-between' : 'center'}
        className={classes.priceContainer}>
        {isTablet && !useUpgrade && (
          <Box className={classes.center}>
            <MoneyBackBadge />
          </Box>
        )}
        {/* Three labels next to each other: "You pay: [Amount in blue] (Save [Amount in turqouoise]) */}
        {showProductSavings && (
          <ProductSavings
            currentChoice={currentChoice}
            selectedProduct={selectedProduct}
          />
        )}
      </Box>
    </>
  );
}

export default ProductSelectionPanel;

import {LevelSentence} from './AdjustReadingLevel.types';

export const levelSentences: LevelSentence[] = [
  {index: 0, level: 'A1', sentence: 'The cat ran to the rat.'},
  {index: 1, level: 'A2', sentence: 'The dog can hop to the log.'},
  {index: 2, level: 'A3', sentence: 'Did Jan and Tim go in the van?'},
  {index: 3, level: 'A4', sentence: 'He says the big fox is on the rug.'},
  {
    index: 4,
    level: 'A5',
    sentence: 'The hen can sit on the six eggs and nap there.',
  },
  {
    index: 5,
    level: 'B1',
    sentence: 'Dad does not want so much dip on his chip.',
  },
  {
    index: 6,
    level: 'B2',
    sentence: 'Did you see that ship? Chad says to his pal Josh.',
  },
  {
    index: 7,
    level: 'B3',
    sentence: 'Beth had a lot of mud on her, so Mom put her in the bath.',
  },
  {
    index: 8,
    level: 'B4',
    sentence: 'You can go to see your pals when you are done with your math.',
  },
  {
    index: 9,
    level: 'C1',
    sentence: 'Jan quit her job at the lab and went off in her big red van.',
  },
  {
    index: 10,
    level: 'C2',
    sentence: 'Look at how the duck rushes off and quacks when it sees a fox.',
  },
  {
    index: 11,
    level: 'C3',
    sentence:
      'Spot the hen lives in a red shack down by the bushes with her flock.',
  },
  {
    index: 12,
    level: 'D1',
    sentence: 'Rolf had a quiz and a test today, which felt like a lot to him.',
  },
  {
    index: 13,
    level: 'D2',
    sentence: 'Gran will work on the dent in her truck and try to fix it fast.',
  },
  {
    index: 14,
    level: 'E1',
    sentence: 'Trish and Ben lost their pet frog on their busy ranch again.',
  },
  {
    index: 15,
    level: 'E2',
    sentence:
      'I try to squish my bags into the back of the truck, but it does not fit.',
  },
  {
    index: 16,
    level: 'E3',
    sentence:
      'My friend Jill goes to chess club today, and she said I can come, too.',
  },
  {
    index: 17,
    level: 'F1',
    sentence: 'Every kid can bake a cake with help from their mom or dad.',
  },
  {
    index: 18,
    level: 'F2',
    sentence:
      'When it gets hot out, Steve the snake likes to doze in the brown grass.',
  },
  {
    index: 19,
    level: 'G1',
    sentence:
      'Duke was a good dog a lot of the time, but one time he chased the cat.',
  },
  {
    index: 20,
    level: 'G2',
    sentence:
      'She sang my best and yelled at the top of my lungs, but the new tune was a bust.',
  },
  {
    index: 21,
    level: 'G3',
    sentence:
      "I went to get some water, but the tap was filled with gunk and didn't work.",
  },
  {
    index: 22,
    level: 'H1',
    sentence:
      "My kitten drank her milk too fast, so I was sure that's how she got the hiccups.",
  },
  {
    index: 23,
    level: 'H2',
    sentence:
      'I was trying to get a ticket to the baking contest to see my friend Hannah compete in it.',
  },
  {
    index: 24,
    level: 'H3',
    sentence:
      'My Gran asked Becky and I if we would like some freshly made candy because she knows we love it.',
  },
  {
    index: 25,
    level: 'I1',
    sentence:
      'Before lunch, Andy had traded his sandwich with a kid named Brandon for a bag of lemon candy.',
  },
  {
    index: 26,
    level: 'I2',
    sentence:
      'My brother Jayden sprinted inside just because a bee buzzed by while we were playing.',
  },
  {
    index: 27,
    level: 'I3',
    sentence:
      'When the moon shone over the sea, the whole beach was lit by its pale, pretty glow.',
  },
  {
    index: 28,
    level: 'J1',
    sentence:
      'My new jacket had a zipper with a pocket on its arm, which is where I kept my money.',
  },
  {
    index: 29,
    level: 'J2',
    sentence:
      'As the boat floated on the dark, foggy waters, the sailors inside made up tales to pass the time.',
  },
  {
    index: 30,
    level: 'J3',
    sentence:
      'The swan honked to warn the other birds about the sneaky otter trying to steal eggs out of their nests.',
  },
  {
    index: 31,
    level: 'K1',
    sentence:
      'My sister was trying to stop me from buying the most costly bike in the store, but my mind was already made up.',
  },
  {
    index: 32,
    level: 'K2',
    sentence:
      'As my dad started cooking dinner on the grill, a deer wandered into our campsite and grabbed a mouthful of sagebrush.',
  },
  {
    index: 33,
    level: 'K3',
    sentence:
      "It is a fun tradition to create heart-shaped cards out of paper for your friends on Valentine's Day.",
  },
  {
    index: 34,
    level: 'L1',
    sentence:
      'The girl carefully picked a wildflower and took it back to her mother, who was setting up a picnic.',
  },
  {
    index: 35,
    level: 'L2',
    sentence:
      'It was true that we had spoiled the surprise, but Dad said the statue we made him was still fantastic.',
  },
  {
    index: 36,
    level: 'L3',
    sentence:
      'Although it was unclear how all the tests and exams had gone missing, this was clearly a disaster for the whole school.',
  },
  {
    index: 37,
    level: 'M1',
    sentence:
      'I grabbed my shoes, snatched a carrot from the fridge, yelled a quick goodbye to my mom, and was out the door in a minute.',
  },
  {
    index: 38,
    level: 'M2',
    sentence:
      'Now that there was a jewel robber on the loose, few people were brave enough to go out at night during a crime wave.',
  },
  {
    index: 39,
    level: 'M3',
    sentence:
      'The hotel owner put a puzzle on the table so that the guests would have something fun to do after dinner.',
  },
  {
    index: 40,
    level: 'N1',
    sentence:
      'The astronauts left their houses at dawn on Wednesday to get ready for the rocket launch later that week.',
  },
  {
    index: 41,
    level: 'N2',
    sentence:
      'By the end of Kindergarten, I knew everything from the alphabet to how the Earth orbits the sun.',
  },
  {
    index: 42,
    level: 'N3',
    sentence:
      'As Mr. Roberts drove down the road, he noticed a sign that read, "Lambs for Sale at Appletree Farm."',
  },
  {
    index: 43,
    level: 'N4',
    sentence:
      'When Mary won the award for "best pitcher" on her softball team, she was amazed and delighted.',
  },
  {
    index: 44,
    level: 'O1',
    sentence:
      "Recovering from hip surgery wasn't easy, but with support from his family and his doctors, my grandpa did a great job.",
  },
  {
    index: 45,
    level: 'O2',
    sentence:
      "I think I'm the luckiest because my older brother has two babies, so I already have nieces even though I'm only twelve.",
  },
  {
    index: 46,
    level: 'O3',
    sentence:
      'My mother said we could use all the bruised peaches and plums to make a delicious cold fruit soup for our guests.',
  },
  {
    index: 47,
    level: 'P1',
    sentence:
      "There is a myth that an explorer hid a magical crystal somewhere in a nearby mountain cave, but I don't believe it.",
  },
  {
    index: 48,
    level: 'P2',
    sentence:
      'I watched in amazement as my talented friend came to the front of the stage and played his trumpet solo with passion and boldness.',
  },
  {
    index: 49,
    level: 'P3',
    sentence:
      'As the temperature in the jungle increased, the monkey thought he should try to find a shady spot in a tree to wait out the heat until he continued his adventure.',
  },
];

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import useStyles from './ProductSelectionPanel.styles';

const ProductSavings = ({selectedProduct, currentChoice}) => {
  const classes = useStyles();
  return (
    <Box display="flex" alignItems="center" mt={1} className={classes.center}>
      <Typography variant="body2">
        <span className={classes.priceLabel}> You pay:</span>
        <span className={classes.price}>
          {selectedProduct && currentChoice.price}
        </span>
        {currentChoice && currentChoice.saving && (
          <span className={clsx(classes.price, classes.priceSaving)}>
            {`(${currentChoice.saving})`}
          </span>
        )}
      </Typography>
    </Box>
  );
};

export default ProductSavings;

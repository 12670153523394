import React from 'react';
import RequestReceivedTitle from './AdjustReadingLevel.request-received.title';
import {ReactComponent as RequestReceivedImage} from 'shared/assets/levelAdjustmentRequestReceived.svg';
import Box from '@mui/material/Box';
import RequestReceivedBodyText from './AdjustReadingLevel.request-recieved.body-text';
import RequestReceivedButton from './AdjustReadingLevel.request-received.button';

const RequestReceivedScreen = () => {
  return (
    <Box
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        marginTop: '2rem',
        textAlign: 'center',
      }}>
      <RequestReceivedTitle />
      <RequestReceivedImage />
      <RequestReceivedBodyText />
      <RequestReceivedButton />
    </Box>
  );
};

export default RequestReceivedScreen;

import gql from 'graphql-tag';

const BOOK_FIELDS = `
  id
  title
  author
  coverPhotoURL
  reviewDescription
  starRatingSource
  starRating
`;

export const BOOKS_QUERY = gql`
  query ExampleBooks($level: String!) {
    exampleBooks(level: $level) {
      ${BOOK_FIELDS}
    }
  }
`;

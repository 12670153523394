import Box from '@mui/material/Box';
import React from 'react';
import {FC} from 'react';

const BookFinishVideo: FC = () => {
  return (
    <Box sx={{mb: '24px'}}>
      <video
        autoPlay
        loop
        muted
        poster="https://assets-global.website-files.com/652e0352ad50feae8734edac/65642b22aff76e6b4c8c094e_Hero_D_video_comp-poster-00001.jpg"
        style={{width: '100%', height: 'auto'}}>
        <source
          src="https://assets-global.website-files.com/652e0352ad50feae8734edac/65642b22aff76e6b4c8c094e_Hero_D_video_comp-transcode.mp4"
          type="video/mp4"
        />
        <source
          src="https://assets-global.website-files.com/652e0352ad50feae8734edac/65642b22aff76e6b4c8c094e_Hero_D_video_comp-transcode.webm"
          type="video/webm"
        />
        Your browser does not support the video tag.
      </video>
    </Box>
  );
};

export default BookFinishVideo;

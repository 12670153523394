import React from 'react';
import {Typography, Grid, Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Section from 'customerPortal/components/Section';
import Button, {ButtonType} from 'shared/components/common/Button';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import styles from './DeleteAccountConfirmation.styles';
import clsx from 'clsx';

interface Props extends WithStyles<typeof styles> {
  onConfirm: () => void;
  onBack?: () => void;
  email: string;
}

const DeleteAccountConfirmation = ({
  classes,
  onConfirm,
  onBack = () => {},
}: Props) => {
  const isMobile = useMobileLayout();

  const confirmationText =
    'By proceeding with account deletion, all your data will be permanently erased. ' +
    'You will lose access to your account, and any active subscriptions will be canceled. ' +
    'This action is irreversible. Are you sure you want to continue?';
  const gridSize = isMobile ? 12 : 6;

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          {isMobile && <Section title="Cancellation" />}
          <Typography variant={'h6'} align={'center'} className={classes.title}>
            {confirmationText}
          </Typography>
        </Box>
        <Box>
          <Grid container spacing={1}>
            <Grid item xs={gridSize}>
              <Button
                className={clsx(classes.button, classes.backButton)}
                variant={ButtonType.Secondary}
                onClick={onBack}>
                Cancel
              </Button>
            </Grid>
            <Grid item xs={gridSize}>
              <Button className={classes.button} onClick={onConfirm}>
                Confirm Delete
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(DeleteAccountConfirmation);

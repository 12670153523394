import Box from '@mui/material/Box';
import React, {FC} from 'react';
import DefaultImage from './DefaultImage';
import {ImageQuestion} from './Image.types';
import ResponsiveImage from './ResponsiveImage';

type Props = {
  question: ImageQuestion;
};

const Image: FC<Props> = ({question}: Props) => {
  const responsiveImage = !!question.wideImage;
  return (
    <Box>
      {!responsiveImage && <DefaultImage question={question} />}
      {responsiveImage && <ResponsiveImage question={question} />}
    </Box>
  );
};

export default Image;

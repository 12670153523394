import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import {markupComponents} from './Choices.service';

type Props = {
  image: string;
  title: string;
};

const Header: FC<Props> = ({image, title}) => {
  return (
    <Box display="flex" width="100%">
      <Box sx={{width: 'calc(100% - 100px)', mr: 2}}>
        <Box sx={{maxWidth: '140px'}}>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              fontSize: '1.875rem',
              color: palette.blue500,
              textAlign: 'left',
            }}>
            {markupComponents(title)}
          </Typography>
        </Box>
      </Box>
      <Box sx={{width: '40%', marginTop: '-16px', maxWidth: '120px'}}>
        <img src={image} alt={title} width="100%" />
      </Box>
    </Box>
  );
};

export default Header;

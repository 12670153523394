import React from 'react';
import {Typography, Link} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import noBooks from 'shared/assets/noBooks.svg';
import {useNoBooksStyles} from './PurchaseList.styles';
import {supportEmail, supportPhoneNumber} from '../../constants/constants';

const NoOrdersAvailable = ({classes}: WithStyles<typeof useNoBooksStyles>) => {
  return (
    <div className={classes.container}>
      <img src={noBooks} alt="" />
      <Typography component="body" className={classes.title}>
        Would you like a monthly shipment with 5 books compatible with Ello?
        Please contact us at{' '}
        <Link href="mailto:support@ello.com">{supportEmail}</Link> or{' '}
        <Link href="tel:+4152148119">{supportPhoneNumber}</Link> to upgrade your
        subscription.
      </Typography>
    </div>
  );
};

export default withStyles(useNoBooksStyles)(NoOrdersAvailable);

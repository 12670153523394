import Box from '@mui/material/Box';
import React, {FC, useState} from 'react';
import {StringParam, useQueryParam} from 'use-query-params';
import ExternalNavigation from '../ExternalNavigation/ExternalNavigation';
import Books from './Book/Books';
import CategoriesPreview from './Category/CategoriesPreview';
import Filter from './Filter/Filter';
import {BookFilter, Pagination} from './Library.types';
import LibraryBanner from './LibraryBanner';

const Library: FC = () => {
  const [grade, setGrade] = useQueryParam('grade', StringParam);
  const [filter, setFilter] = useState<BookFilter>({
    grade,
    excludeFullText: true,
  });
  const [pagination, setPagination] = useState<Pagination>({first: 24});

  return (
    <Box>
      <ExternalNavigation />
      <Box mt={12}></Box>
      <LibraryBanner />
      <Filter
        grade={grade}
        setGrade={setGrade}
        setFilter={setFilter}
        setPagination={setPagination}
      />
      <CategoriesPreview grade={grade} />
      <Books
        pagination={pagination}
        filter={filter}
        setPagination={setPagination}
      />
    </Box>
  );
};

export default Library;

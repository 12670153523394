import React from 'react';
import {useHistory} from 'react-router-dom';

import Button from '@mui/material/Button';
import {PRIVATE_ROUTES} from '../../../../../shared/components/Route/routes';

const RequestReceivedButton = () => {
  const history = useHistory();

  const onClick = () => {
    history.push(PRIVATE_ROUTES.ACCOUNT_PROFILES.path);
  };

  return (
    <Button
      variant="contained"
      style={{
        marginTop: '2rem',
        padding: '1rem 5rem',
        borderRadius: '50px',
        fontWeight: 800,
        fontSize: '1rem',
      }}
      onClick={onClick}>
      Done
    </Button>
  );
};

export default RequestReceivedButton;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const useStyles = (theme: Theme) =>
  createStyles({
    button: {
      marginTop: theme.spacing(1.5),
      marginLeft: -theme.spacing(1.5),
    },
  });

export default useStyles;

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import {useHistory} from 'react-router-dom';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import {ReactComponent as SettingsIcon} from 'shared/assets/icons/settings.svg';
import {ReactComponent as IconChevron} from 'shared/assets/icons/iconChevron.svg';
import {EDIT_PROFILE_BASE_PATH} from '../../../../shared/components/Route/routes';
import {profileImageByName} from './EditProfile/EditProfileScreen.service';
import styles from './ProfilesScreen.styles';
import useMobileLayout from '../../../utils/useMobileLayout';
interface props extends WithStyles<typeof styles> {
  name: string;
  profileCharacter?: string;
  id: string;
}

const ProfileName = ({classes, name, id, profileCharacter}: props) => {
  const history = useHistory();
  const isMobile = useMobileLayout();
  return (
    <Box className={classes.profileIconNameContainer}>
      <Box className={classes.profileNameContainer}>
        <img
          src={profileImageByName(profileCharacter)}
          alt=""
          className={classes.profileCharacter}
        />
        <Typography variant="h5" className={classes.profileName}>
          {name}
        </Typography>
      </Box>

      <IconButton
        className={classes.settingsIcon}
        onClick={() => history.push(`${EDIT_PROFILE_BASE_PATH}/${id}`)}>
        {isMobile ? <IconChevron /> : <SettingsIcon />}
      </IconButton>
    </Box>
  );
};

export default withStyles(styles)(ProfileName);

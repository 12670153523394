import React from 'react';
import * as palette from '../../styles/palette';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ReactComponent as CopiedIcon} from '../../assets/copied-icon.svg';

const CopiedText = () => {
  return (
    <Box sx={{display: 'flex', alignItems: 'center'}}>
      <CopiedIcon style={{marginRight: '12px', color: palette.teal500}} />
      <Typography
        style={{
          color: palette.teal500,
          textTransform: 'uppercase',
          fontWeight: 800,
          fontSize: '16px',
        }}>
        Copied
      </Typography>
    </Box>
  );
};

export default CopiedText;

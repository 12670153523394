import React from 'react';
import Box from '@mui/material/Box';
import QRCodeContainer from './ReferralLink.qr-code-container';

type Props = {
  title?: string;
  qrCodeHeader?: string;
  qrCodeBody?: string;
};

const DownloadInstructions = ({title, qrCodeHeader, qrCodeBody}: Props) => {
  return (
    <Box>
      <QRCodeContainer header={qrCodeHeader} body={qrCodeBody} />
    </Box>
  );
};

export default DownloadInstructions;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {gray700} from '../../../shared/styles/palette';
import BackButton from '../Banner/BackButton/BackButton';
import {horizontalBreakPoint} from './BookPage.styles';

type Props = {
  bookTitle?: string;
  pageNumber: number;
  pageLength?: number;
  goBack?: () => void;
  onFinish?: () => void;
};

const BookHeaderRow: FC<Props> = ({
  bookTitle,
  pageNumber,
  pageLength,
  goBack,
  onFinish,
}: Props) => {
  const isLastPage = pageLength && pageNumber === pageLength - 1;
  const showFinishButton = isLastPage && onFinish;

  return (
    <Box
      sx={{
        display: 'flex',
        margin: '0 auto',
        maxWidth: 1536,
        [horizontalBreakPoint]: {
          padding: '0 12px',
        },
      }}>
      <Box
        sx={{
          mt: '-2px',
        }}>
        <BackButton onClickHandler={goBack} />
      </Box>
      <Typography
        component="h1"
        variant="h6"
        sx={{
          fontWeight: 'bold',
          marginBottom: '16px',
          mt: '22px',
          marginLeft: '4px',
          fontSize: '1rem',
        }}>
        {bookTitle}
      </Typography>
      {!showFinishButton && (
        <Typography
          variant="body2"
          sx={{
            color: gray700,
            mt: 3,
            marginLeft: 'auto',
          }}>
          {`Page ${pageNumber + 1}/${pageLength}`}
        </Typography>
      )}
      {showFinishButton && (
        <Box sx={{mt: 2, ml: 'auto'}}>
          <Button
            onClick={onFinish}
            sx={{
              textTransform: 'none',
            }}>
            <Typography>Finish</Typography>
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BookHeaderRow;

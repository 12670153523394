import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, {FC, useState} from 'react';
import copyIcon from '../../../assets/copy-icon.svg';
import useStyles from './Link.styles';

type props = {
  link?: string;
};

const defaultLink = 'https://ello.click/app';

const Link: FC<props> = ({link}) => {
  const classes = useStyles();
  const [copied, setCopied] = useState(false);

  const actualLink = link || defaultLink;
  const displayLink = actualLink.replace('https://', '').replace('http://', '');

  const copyToClipboard = async () => {
    if (!navigator?.clipboard) {
      return;
    }

    await navigator.clipboard.writeText(actualLink);
    setCopied(true);
    setTimeout(() => setCopied(false), 1000);
  };

  return (
    <Box className={classes.linkTextContainer}>
      <Box className={classes.linkTab}>
        <Box className={classes.linkContainer}>
          <Typography className={classes.linkTypography}>
            <a href={actualLink} className={classes.link}>
              {displayLink}
            </a>
          </Typography>
        </Box>
        <Divider orientation="vertical" className={classes.linkDivider} />
        <Box className={classes.linkButton}>
          {!copied && (
            <Button className={classes.copyButton} onClick={copyToClipboard}>
              <img src={copyIcon} alt="Copy Icon" />
              <Typography variant="button" className={classes.copyButtonText}>
                Copy Link
              </Typography>
            </Button>
          )}
          {copied && (
            <Typography className={classes.copied}>Copied!</Typography>
          )}
        </Box>
      </Box>
      <Box>
        <Typography className={classes.openLinkText}>
          or open this link in the browser on your child's tablet
        </Typography>
      </Box>
    </Box>
  );
};

export default Link;

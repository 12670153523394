import {useLazyQuery} from '@apollo/react-hooks';
import {useFeature} from '@growthbook/growthbook-react';
import {CircularProgress, Container, Snackbar} from '@mui/material';
import Alert from '@mui/material/Alert';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {Bar} from 'customerPortal/components/Banner';
import firebase from 'firebase/app';
import React, {SyntheticEvent, useEffect, useState} from 'react';
import {useHistory} from 'react-router-dom';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import {useAuth} from 'shared/utils/providers/authProvider';
import {LoginPhoneNumber, LoginVerificationScreen} from '.';
import AlternativeLoginMethodButton from './CustomerLoginScreen.alt-login-button';
import LoginEmail from './CustomerLoginScreen.email-login';
import {AUTHENTICATE_USER} from './CustomerLoginScreen.query';
import styles from './CustomerLoginScreen.styles';
import {LoginStep} from './constants';

interface props extends WithStyles<typeof styles> {
  initialLoginStep?: LoginStep;
}
const CustomerLoginScreen = ({initialLoginStep, classes}: props) => {
  const history = useHistory();
  const [error, setError] = useState('');
  const [currentStep, setCurrentStep] = useState(
    initialLoginStep || LoginStep.PhoneStep,
  );
  const [isLoading, setIsLoading] = useState(true);
  const auth = useAuth();
  const isEmailLoginStep = currentStep === LoginStep.EmailStep;
  const isPhoneLoginStep = currentStep === LoginStep.PhoneStep;
  const isVerifyStep = currentStep === LoginStep.VerifyStep;
  const isLoginStep = isPhoneLoginStep || isEmailLoginStep;
  const canSwitchSignInMethod =
    useFeature('customer-portal-sign-in-with-email').on && isLoginStep;

  const [queryAuthentication] = useLazyQuery(AUTHENTICATE_USER, {
    fetchPolicy: 'no-cache',
    onError: error => console.error('Query authentification::', error),
  });

  useEffect(() => {
    const reCaptchaVerifier = new firebase.auth.RecaptchaVerifier(
      'recaptcha-container',
      {
        size: 'invisible',
      },
    );
    window.recaptchaVerifier = reCaptchaVerifier;
    reCaptchaVerifier.render();
  }, []);

  useEffect(() => {
    const elloAccessTokenRegex = /ello_access_token=([^;]+)/;
    const elloAccessTokenInCookie = elloAccessTokenRegex.exec(document.cookie);

    if (!elloAccessTokenInCookie) {
      setIsLoading(false);
      return;
    }

    const accessToken = elloAccessTokenInCookie[1];

    authenticateUser(accessToken);

    // Should only run on load
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSuccessLogin = (loginMethod: string, user?: any) => {
    if (user) {
      window.analytics?.identify(user.uid, {
        name: user.name,
        email: user.email,
        phone: user.phoneNumber,
      });
    }
    window.analytics?.track('Ello LoggedIn', {
      loginMethod,
    });
    setIsLoading(false);
    auth.setIsLoading(true);
    history.push(PRIVATE_ROUTES.ACCOUNT.path);
  };

  const authenticateUser = (accessToken: string) => {
    queryAuthentication({
      variables: {
        token: accessToken,
      },
    })
      .then(({data}) => {
        firebase
          .auth()
          .signInWithCustomToken(data.authenticateUser.customToken)
          .then(data => {
            onSuccessLogin('cookie', data?.user);
          })
          .catch(error => {
            setIsLoading(false);
            console.error('ERROR LOGGING IN WITH TOKEN', error);
          });
      })
      .catch(error => {
        setIsLoading(false);
        console.error(error);
      });
  };

  const handleErrorClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setError('');
  };

  const login = (code: string) => {
    setIsLoading(true);
    if (!window.confirmationResult) {
      console.error('ConfirmationResult is undefined');
      setIsLoading(false);
      return;
    }

    window.confirmationResult
      .confirm(code)
      .then(data => {
        onSuccessLogin('phoneNumber', data?.user);
      })
      .catch(error => {
        console.error('Customer Login Screen::', error);
        setError(
          'Error confirming verification code. Please make sure you have entered the correct verification code.',
        );
        setCurrentStep(LoginStep.VerifyStep);
        setIsLoading(false);
      });
  };

  return (
    <>
      <Bar isPublic />
      <div id={'recaptcha-container'} />
      <Container component="main" maxWidth="xs" className={classes.container}>
        {isLoading && <CircularProgress color="primary" />}

        {!isLoading && (
          <>
            {isVerifyStep && <LoginVerificationScreen onComplete={login} />}
            {isPhoneLoginStep && (
              <LoginPhoneNumber
                setError={setError}
                setIsLoading={setIsLoading}
                setCurrentStep={setCurrentStep}
              />
            )}
            {isEmailLoginStep && (
              <LoginEmail
                setError={setError}
                setIsLoading={setIsLoading}
                onSuccessLogin={onSuccessLogin}
              />
            )}

            {canSwitchSignInMethod && (
              <AlternativeLoginMethodButton
                currentStep={currentStep}
                setCurrentStep={setCurrentStep}
              />
            )}
          </>
        )}
      </Container>
      <Snackbar
        open={!!error}
        onClose={handleErrorClose}
        autoHideDuration={3000}>
        <Alert onClose={handleErrorClose} severity="error">
          {error}
        </Alert>
      </Snackbar>
    </>
  );
};

export default withStyles(styles)(CustomerLoginScreen);

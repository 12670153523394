import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {Book} from './Book.type';
import {desktopBreakpoint} from './BreakPoints';

type Props = {
  book?: Book;
};

const CoverPhoto: FC<Props> = ({book}: Props) => {
  return (
    <Box
      mt={1}
      sx={{
        width: '280px',
        mx: 'auto',
        [desktopBreakpoint]: {
          width: '100%',
          mx: 0,
        },
      }}>
      <img
        src={book?.coverPhotoURL}
        alt={`${book?.title} cover`}
        width="100%"
      />
    </Box>
  );
};

export default CoverPhoto;

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import {
  CTAVariant,
  isCTAVariantContained,
  stringToCTAVariant,
} from '../Product.types';
import {markupComponents} from './Choices.service';

type Props = {
  ctaFinePrint: string;
  ctaText: string;
  ctaVariant: CTAVariant;
  onSelected: () => void;
};

const CTA: FC<Props> = ({ctaFinePrint, ctaText, ctaVariant, onSelected}) => {
  const variant = stringToCTAVariant(ctaVariant);

  const ctaTextStyles: {fontWeight: number; color?: string} = {
    fontWeight: 700,
  };
  if (!isCTAVariantContained(variant)) {
    ctaTextStyles.color = palette.turquoise900;
  }

  return (
    <Box mt={'auto'} position="absolute" bottom="120px" width="100%">
      <Button
        fullWidth
        disableElevation
        onClick={onSelected}
        variant={variant}
        sx={{
          py: 1,
          borderRadius: 6,
          marginTop: 'auto',
          borderColor: palette.turquoise900,
          border: `1px solid ${palette.turquoise900} !important`,
          backgroundColor: isCTAVariantContained(variant)
            ? palette.turquoise900
            : '',
        }}>
        <Typography variant="button" sx={ctaTextStyles}>
          {ctaText}
        </Typography>
      </Button>
      <Typography
        mt={2}
        variant="caption"
        sx={{fontWeight: 700, color: palette.turquoise900}}>
        {markupComponents(ctaFinePrint)}
      </Typography>
    </Box>
  );
};

export default CTA;

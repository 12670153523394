import Box from '@mui/material/Box';
import React, {FC} from 'react';
import ProfileBooks from './ProfileBooks';

type props = {
  profiles?: {id: string; name: string}[];
};

const ProfileBooksList: FC<props> = ({profiles}: props) => {
  return (
    <Box>
      {profiles?.map((profile: {id: string; name: string}) => (
        <ProfileBooks key={profile.id} profile={profile} />
      ))}
    </Box>
  );
};

export default ProfileBooksList;

import React from 'react';
import {
  Dialog,
  DialogContent,
  DialogActions,
  Typography,
  Button,
  IconButton,
  Box,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import {gray900} from 'shared/styles/palette';

type Props = {
  open: boolean;
  onClose: () => void;
};

export const RenewalConfirmationDialog: React.FC<Props> = ({open, onClose}) => {
  return (
    <Dialog
      onClose={onClose}
      open={open}
      PaperProps={{
        sx: {
          borderRadius: '16px',
          position: 'relative',
        },
      }}>
      <Box sx={{display: 'flex', justifyContent: 'end', p: '16px 24px 0'}}>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{pb: 0, width: '48px'}}>
          <CloseIcon />
        </IconButton>
      </Box>
      <DialogContent>
        <Typography variant="body1" textAlign="center" color={gray900}>
          Looks like your account requires a manual adjustment. An Ello team
          member will reach out to help you re-subscribe via text and get you
          back to reading adventures!
        </Typography>
      </DialogContent>
      <DialogActions sx={{justifyContent: 'center', p: '0 24px 24px'}}>
        <Button onClick={onClose} sx={{fontSize: '16px', fontWeight: '800'}}>
          OK
        </Button>
      </DialogActions>
    </Dialog>
  );
};

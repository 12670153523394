import createStyles from '@mui/styles/createStyles';
import * as palette from 'shared/styles/palette';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    chip: {
      marginLeft: theme.spacing(1),
    },
    almostDueDateChip: {
      borderColor: palette.orange500,
      color: palette.orange500,
      fontWeight: 700,
    },
    dueDateChip: {
      borderColor: palette.turquoise900,
      color: palette.turquoise900,
      fontWeight: 700,
    },
  });

export default styles;

/*
 * A graphical slider form component.
 */

import React from 'react';
import ButtonGroup from '@mui/material/ButtonGroup';
import Button from '@mui/material/Button';
import {useLocale, Locales} from 'shared/utils/localization';
import {useQueryParam, StringParam} from 'use-query-params';
import useStyles from './LanguagePicker.styles';

function LanguagePicker({
  choices = [Locales.enUS, Locales.esES, Locales.zhCN],
}) {
  const {locale, setLocale} = useLocale();
  const [, setQueryLanguage] = useQueryParam('lg', StringParam);

  const classes = useStyles();

  return (
    <ButtonGroup
      className={classes.root}
      color="primary"
      aria-label="outlined secondary button group">
      {choices.map((choice, index) => (
        <Button
          className={classes.languageButton}
          key={index}
          variant={locale === choice ? 'contained' : 'outlined'}
          disableElevation
          onClick={() => {
            setLocale(choice);
            setQueryLanguage(choice);
          }}>
          {choice === Locales.enUS
            ? 'English'
            : choice === Locales.esES
            ? 'Español'
            : choice === Locales.zhCN
            ? '中文'
            : ''}
        </Button>
      ))}
    </ButtonGroup>
  );
}

export default React.memo(LanguagePicker);

import {Grid} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {isRevenueCat, isStripe} from '../CancellationForm.service';
import {CancellationConfirmedBody} from './CancellationConfirmed.body';
import {CancellationConfirmedRevenueCatActions} from './CancellationConfirmed.revenueCatActions';
import {CancellationConfirmedStripeActions} from './CancellationConfirmed.stripeActions';
import styles from './CancellationConfirmed.styles';
import {BillingSource} from 'shared/types/customer';
import {supportEmail} from '../../../constants/constants';

const content = {
  [BillingSource.Stripe]: {
    cancellationText:
      "We're sorry to see you go and hope you enjoyed using Ello",
    subtext: `If you wish to re-subscribe in the future, please reach out to ${supportEmail}`,
  },
  [BillingSource.ReveneuCat]: {
    cancellationText:
      "You must go to your Device's Subscriptions Settings to complete your cancellation. Click on the link below.",
    subtext:
      'Please follow steps in the link below to cancel your subscription',
  },
};
interface Props extends WithStyles<typeof styles> {
  billingSource?: string;
  hideDesktopBackButton?: boolean;
  onConfirm: () => Promise<void>;
  onBack?: () => void;
}

const CancellationConfirmed = ({
  billingSource,
  classes,
  hideDesktopBackButton = false,
  onConfirm,
  onBack = () => {},
}: Props) => {
  const {cancellationText, subtext} = content[billingSource as BillingSource];

  return (
    <>
      <Grid container className={classes.content}>
        <CancellationConfirmedBody
          cancellationText={cancellationText}
          subtext={subtext}
        />
        {isStripe(billingSource || '') && (
          <CancellationConfirmedStripeActions
            hideDesktopBackButton={hideDesktopBackButton}
            onConfirm={onConfirm}
            onBack={onBack}
          />
        )}
        {isRevenueCat(billingSource || '') && (
          <CancellationConfirmedRevenueCatActions
            hideDesktopBackButton={hideDesktopBackButton}
            onConfirm={onConfirm}
            onBack={onBack}
          />
        )}
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationConfirmed);

import gql from 'graphql-tag';

export const QUERY_REMOVE_CHILD_PREVIEW = gql`
  query Profile($profileId: ID!) {
    profile(profileId: $profileId) {
      id
      name
    }
    profileRemovalSubscriptionPreview(profileId: $profileId) {
      nextCycleDate
      name
      description
      title
      price
      currentSubscription {
        name
        description
      }
    }
  }
`;

export const MUTATE_REMOVE_CHILD = gql`
  mutation Mutation($profileId: ID!) {
    deactivateProfile(profileId: $profileId) {
      id
    }
  }
`;

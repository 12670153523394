import {Grid} from '@mui/material';
import React from 'react';
import BackButton from '../BackButton/BackButton';
import Bar from '../Bar/Bar';

type props = {
  showBackButton?: boolean;
  onClickHandler?: () => void;
};

const MobileBanner = ({showBackButton = true, onClickHandler}: props) => {
  return (
    <Bar
      action={
        <Grid container item xs>
          {showBackButton && <BackButton onClickHandler={onClickHandler} />}
        </Grid>
      }
    />
  );
};

export default MobileBanner;

import React from 'react';

import TextField from '@mui/material/TextField';
import styles from './ResetPasswordScreen.styles';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Button, {ButtonType} from '../../../shared/components/common/Button';
import BasicForm from './ResetPasswordScreen.form';
interface props extends WithStyles<typeof styles> {
  resetPassword: (email: string) => void;
  email: string;
  setEmail: (email: string) => void;
  customMessage?: string;
  customTitle?: string;
}

const EmailTextField = ({
  email,
  customMessage,
  customTitle,
  setEmail,
  resetPassword,
  classes,
}: props) => {
  const onResetPasswordHandler = (event: any) => {
    event.preventDefault();
    resetPassword(email);
  };

  const onEmailChange = (event: any) => {
    setEmail(event.target.value);
  };

  return (
    <BasicForm
      customMessage={customMessage}
      titleText={customTitle || 'Reset Password'}
      subtitleText={
        customMessage || 'We can email you a link to create a new password.'
      }>
      <form
        className={classes.form}
        noValidate
        onSubmit={onResetPasswordHandler}>
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          onChange={onEmailChange}
          value={email}
          id="email"
          label="Email"
          name="email"
          type="email"
          autoComplete="email"
          autoFocus
        />
        <Button
          type="submit"
          variant={ButtonType.Primary}
          onClick={onResetPasswordHandler}
          className={classes.submitButton}>
          Submit
        </Button>
      </form>
    </BasicForm>
  );
};

export default withStyles(styles)(EmailTextField);

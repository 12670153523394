import Box from '@mui/material/Box';
import React, {FC} from 'react';
import ScrollCircle from './ScrollCircle';

const screenWidthModifier = 1.25;

type Props = {
  pages: number;
  screenWidth: number;
  scroll: number;
};

const ScrollPosition: FC<Props> = ({pages, screenWidth, scroll}: Props) => {
  const scrollIndex = Math.round(scroll / (screenWidth / screenWidthModifier));
  const currentIndex = scrollIndex < pages ? scrollIndex : pages - 1;
  const circles = [];
  for (let index = 0; index < pages; index++) {
    circles.push(
      <ScrollCircle key={index} index={index} currentIndex={currentIndex} />,
    );
  }

  return (
    <Box>
      <Box sx={{mt: 4, display: 'flex', justifyContent: 'center'}}>
        {circles}
      </Box>
    </Box>
  );
};

export default ScrollPosition;

import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import useStyles from './ProfileSelector.styles';

type props = {
  profileId?: string;
  profiles?: {id: string; name: string}[];
};

const ProfileSelector: FC<props> = ({profileId, profiles}: props) => {
  const classes = useStyles();
  const history = useHistory();

  const selectProfile = (profileId: string) => {
    history.push(`/account/reader/${profileId}`);
  };

  return (
    <Tabs
      value={profileId}
      className={classes.tabHeader}
      TabIndicatorProps={{
        style: {
          backgroundColor: '#335C6E',
        },
      }}
      onChange={(event, value) => selectProfile(value)}>
      {profiles?.map((profile: {id: string; name: string}) => (
        <Tab
          key={profile.id}
          label={profile.name}
          className={classes.tab}
          value={profile.id}
        />
      ))}
    </Tabs>
  );
};

export default ProfileSelector;

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {BookFilter, Pagination, gradeMap} from '../Library.types';
import FilterGrades from './FilterGrades';
import FilterSelection from './FilterSelection';

const filterBreakPoint = '@media (min-width: 780px)';

type Props = {
  grade?: string;
  setGrade: (grade?: string) => void;
  setFilter: (fn: (filter: BookFilter) => BookFilter) => void;
  setPagination: (fn: (pagination: Pagination) => Pagination) => void;
};

const Filter: FC<Props> = ({
  grade,
  setGrade,
  setFilter,
  setPagination,
}: Props) => {
  const options = Object.entries(gradeMap).map(([key, value]) => ({
    label: value,
    value: key,
  }));

  const handleGradeChange = (grade: string) => {
    if (grade === 'all') {
      setGrade(undefined);
      setFilter((filter: BookFilter) => ({...filter, grade: undefined}));
      setPagination(pagination => ({...pagination, cursor: ''}));
      return;
    }

    setGrade(grade);
    setFilter((filter: BookFilter) => ({...filter, grade}));
    setPagination(pagination => ({...pagination, cursor: ''}));
  };

  return (
    <Box>
      <Box
        sx={{
          display: 'block',
          [filterBreakPoint]: {
            display: 'none',
          },
        }}>
        <FilterSelection
          grade={grade}
          options={options}
          handleGradeChange={handleGradeChange}
        />
      </Box>
      <Box
        sx={{
          display: 'none',
          [filterBreakPoint]: {
            display: 'block',
          },
        }}>
        <FilterGrades
          grade={grade}
          options={options}
          handleGradeChange={handleGradeChange}
        />
      </Box>
    </Box>
  );
};

export default Filter;

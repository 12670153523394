import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {blue500} from '../../../../shared/styles/palette';
import BooksList from '../Book/BooksList';
import GoBackButton from '../GoBackButton';
import {useReadingList} from '../Library.hooks';

const readingListTitleBreakPoint = '@media (min-width: 768px)';

type Props = {
  backText: string;
  readingListId: string;
  goBack: () => void;
};

const ReadingList: FC<Props> = ({backText, goBack, readingListId}: Props) => {
  const {readingList} = useReadingList(readingListId);

  return (
    <Box mt={12} mb={4}>
      <GoBackButton backText={backText} goBack={goBack} />
      <Typography
        sx={{
          fontSize: '1.375rem',
          fontWeight: 700,
          color: blue500,
          mt: 4,
          [readingListTitleBreakPoint]: {fontSize: '1.625rem'},
        }}>
        {readingList.name}
      </Typography>
      <Box mt={2}>
        <BooksList books={readingList.books || []} maxWidth="none" />
      </Box>
    </Box>
  );
};

export default ReadingList;

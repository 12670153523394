import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import Alert from '../../../shared/components/common/Alert/Alert';
import WebReader from '../../components/WebReader/WebReader';
import {useProfiles} from './WebReaderScreen.service';
import useStyles from './WebReaderScreen.styles';

const WebReaderScreen: FC = () => {
  const classes = useStyles();

  const {profiles, error} = useProfiles();

  return (
    <Box>
      <Typography component="h1" variant="h5" className={classes.title}>
        Books
      </Typography>
      {profiles && (
        <Box>
          <WebReader profiles={profiles} />
        </Box>
      )}
      {error && (
        <Alert
          error={
            'Unable to retrieve your profiles. Please check your internet connection and refresh the page.'
          }
          autoHide={false}
        />
      )}
    </Box>
  );
};

export default WebReaderScreen;

import React from 'react';
import {Profile} from 'shared/types/profile';
import {possessive} from 'shared/utils/stringUtils/stringUtils';
import {AddChildFlow} from '../types';

type Props = Pick<AddChildFlow, 'formValues' | 'profiles'>;

const conjugate = (names: string[]): string => {
  if (names.length === 1) {
    return names.join('');
  }

  if (names.length === 2) {
    return names.join(' and ');
  }

  const firstBit = names.slice(0, -1).join(', ');
  return firstBit.concat(`, and ${names[names.length - 1]}`);
};

export const BundlePlanLevelMatch = ({formValues}: Props) => {
  return (
    <div>
      <p>
        Based on the information you provided, the suggeted reading level
        indicates {formValues.name} can share the same books included in your
        current Ello subscription.
      </p>
      <p>
        If you would like an additional bundle at a different reading level,
        click '<b>Add Bundle</b>', or click '<b>Share Bundle</b>' to proceed
        with adding a profile to the existing bundle level. Your children will
        share a set of books.
      </p>
    </div>
  );
};

export const ProfilePlanLevelMatch = ({profiles, formValues}: Props) => {
  const matchingReaders = profiles.reduce((res: string[], value: Profile) => {
    if (value.readingLevel === formValues.readingLevel && value.name) {
      res.push(value.name);
      return res;
    }

    return res;
  }, []);

  return (
    <>
      <p>
        We determined that {possessive(formValues.name)} reading level is the
        same as {conjugate(matchingReaders)} which means that they can share
        their monthly books under selected update option.
      </p>
      <p>Click Continue to proceed.</p>
    </>
  );
};

export const ProfilePlanLevelMismatch = ({formValues}: Props) => {
  const childName = formValues.name;
  return (
    <>
      <p>
        Based on your child's information, the current reading level(s) on your
        Ello subscription may be unsuitable for {childName}.
      </p>
      <p>
        Consider adding a personal bundle to better match{' '}
        {possessive(childName)} reading level, or click '<b>Continue</b>' if you
        would like to create a profile to share books at an existing level
        anyways.
      </p>{' '}
    </>
  );
};

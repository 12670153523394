import moment from 'moment';

export const toUSDateFormat = (date: number): string => {
  return moment(date).format('M/D/YY');
};

/**
 * Returns a date formatted like Apr 27 2022
 */
export const toFriendlyDate = (date: number, includeYear = true): string => {
  return moment(date).format(`MMM D${includeYear ? ', YYYY' : ''}`);
};

/**
 * Returns a date in format YYYY-MM-DD.
 * Use as the value of `datetime` attribute in a <time> component
 * for maximum accessibility of dates.
 */
export const toA11yDate = (date: number): string => {
  return moment(date).format('YYYY-MM-DD');
};

interface FormattedDate {
  us: string;
  friendly: (s?: boolean) => string;
  a11y: string;
}
export const getFormattedDate = (date: number): FormattedDate => {
  return {
    us: toUSDateFormat(date),
    friendly: shouldIncludeYear => toFriendlyDate(date, shouldIncludeYear),
    a11y: toA11yDate(date),
  };
};

export const isWithin5Days = (date: number) => {
  const fiveDaysBeforeDeadline = moment(date).subtract(5, 'days');
  return moment().isBetween(fiveDaysBeforeDeadline, date, 'days', '[]');
};

export const isDateInPast = (date: number) => {
  return moment().diff(date, 'days') > 0;
};

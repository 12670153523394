import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import visaLogo from 'shared/assets/paymentIcons/visa.svg';
import masterCardLogo from 'shared/assets/paymentIcons/masterCard.svg';
import americanExpressLogo from 'shared/assets/paymentIcons/americanExpress.svg';
import iconCreditCard from 'shared/assets/icons/iconCreditCard.svg';
import {PaymentData} from 'shared/types/stripeSubscription';
import styles from './CreditCard.styles';

const getCreditCardLogo = (brand: string | undefined): string => {
  switch (brand) {
    case 'visa':
      return visaLogo;
    case 'mastercard':
      return masterCardLogo;
    case 'amex':
      return americanExpressLogo;
    default:
      return iconCreditCard;
  }
};

interface Props extends WithStyles<typeof styles> {
  card: PaymentData['card'];
}

const CreditCard = ({classes, card}: Props) => {
  const brand = card?.brand;
  const last4 = card?.last4;
  const badge = getCreditCardLogo(brand);

  if (!last4) {
    return null;
  }

  return (
    <span className={classes.container}>
      <img src={badge} alt="" width={40} className={classes.img} />
      x-{last4}
    </span>
  );
};

export default withStyles(styles)(CreditCard);

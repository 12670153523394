import makeStyles from '@mui/styles/makeStyles';
import {horizontalBreakPoint} from './BookPage.styles';

export default makeStyles(theme => ({
  navigate: {
    position: 'fixed',
    left: 0,
    top: '50vh',
    width: '100%',
    [horizontalBreakPoint]: {
      top: '50vh',
    },
  },
  navigateBefore: {
    position: 'absolute',
    left: 0,
    padding: 0,
    marginLeft: '4px',
  },
  navigateNext: {
    position: 'absolute',
    right: 0,
    padding: 0,
    marginRight: '4px',
  },
}));

import {gql} from '@apollo/client';

export const validatePinMutation = gql`
  mutation validatePin($pin: String!) {
    validatePin(pin: $pin)
  }
`;

export const validateTokenMutation = gql`
  query validateToken {
    validateToken {
      success
    }
  }
`;

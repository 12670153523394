import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import ReactCodeInput from 'react-verification-code-input';
import {PRIVATE_ROUTES} from '../../../shared/components/Route/routes';
import {gray700} from '../../../shared/styles/palette';
import {Bar} from '../../components/Banner';
import {useValidatePin} from './AccountManagerScreen.hooks';
import useStyles from './AccountManagerScreen.styles';

export const AccountManagerScreen: FC = () => {
  const classes = useStyles();

  const history = useHistory();
  const {validatePin} = useValidatePin();
  const handleValidatePin = async (pin: string) => {
    await validatePin(pin);
    history.push(PRIVATE_ROUTES.ACCOUNT.path);
  };

  return (
    <Box>
      <Bar isPublic />
      <Container
        component="main"
        maxWidth="xs"
        sx={{
          position: 'absolute',
          textAlign: 'center',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          py: 0,
          px: 3,
        }}>
        <Typography
          component="h1"
          variant="h5"
          sx={{
            fontWeight: 700,
            marginBottom: 2,
          }}>
          Account Manager Pin
        </Typography>
        <Typography
          paragraph
          sx={{
            fontWeight: 400,
            marginBottom: 5,
            color: gray700,
          }}>
          Please enter the account manager pin provided. Reach out if you need a
          new pin.
        </Typography>

        <ReactCodeInput
          className={classes.verificationInput}
          onComplete={handleValidatePin}
          fieldWidth={55}
          fieldHeight={55}
        />
      </Container>
    </Box>
  );
};

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {BORDER_RADIUS} from 'shared/styles/breakpoints';

const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      boxShadow: '0 4px 16px rgba(0, 0, 0, 0.11)',
      borderRadius: `${BORDER_RADIUS}px`,
      padding: `${theme.spacing(3)} ${theme.spacing(4)}`,
    },
  });

export default useStyles;

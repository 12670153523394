import React from 'react';
import Box from '@mui/material/Box';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import ProfileName from './ProfileScreen.profile-card.name';
import ProfileReadingLevel from './ProfileScreen.profile-card.reading-level';
import styles from './ProfilesScreen.styles';

interface props extends WithStyles<typeof styles> {
  name: string;
  readingLevel?: string;
  profileCharacter?: string;
  id: string;
  requestedLevelAdjustmentAt?: Date | string;
}

const ProfileCard = ({
  classes,
  name,
  readingLevel,
  id,
  profileCharacter,
  requestedLevelAdjustmentAt,
}: props) => {
  return (
    <Box className={classes.profileCardContainer}>
      <ProfileName name={name} id={id} profileCharacter={profileCharacter} />

      <ProfileReadingLevel
        readingLevel={readingLevel}
        profileId={id}
        requestedLevelAdjustmentAt={requestedLevelAdjustmentAt}
      />
    </Box>
  );
};

export default withStyles(styles)(ProfileCard);

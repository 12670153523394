import Typography from '@mui/material/Typography';
import React from 'react';

const RequestReceivedTitle = () => {
  return (
    <Typography
      variant="h6"
      mt={4}
      style={{
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      }}>
      We received your request!
    </Typography>
  );
};

export default RequestReceivedTitle;

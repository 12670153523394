import gql from 'graphql-tag';

export const STRIPE_PORTAL_QUERY = gql`
  {
    createStripePortalSession {
      url
    }
  }
`;

export const IS_VALID_PHONE_QUERY = gql`
  query ValidatePhoneUser($phoneNumber: String!) {
    validatePhoneUser(phoneNumber: $phoneNumber)
  }
`;

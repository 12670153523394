import {ReactComponent as IconAdditionalProfile} from 'shared/assets/icons/iconAdditionalProfile.svg';
import {ReactComponent as IconAdditionalBooks} from 'shared/assets/icons/iconAdditionalBooks.svg';
import {FunctionComponent, SVGProps} from 'react';
import {Plan} from '../types';

export interface PlanOption {
  title: string;
  value: Plan;
  price?: string;
  description: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
}

export const PLANS: PlanOption[] = [
  {
    title: 'Add a profile',
    value: Plan.Profile,
    price: 'FREE',
    description: `Added child will get their own Ello app profile,
      but will share books with your current reader.
      A good fit for families with children at the same reading level.`,
    Icon: IconAdditionalProfile,
  },
  {
    title: `Add a profile + book bundle at a different level`,
    value: Plan.Bundle,
    description: `Added child will get their own Ello app profile and their
      own set of books at a different level than your current reader. A good
      fit for families with children of different ages and reading abilities.`,
    Icon: IconAdditionalBooks,
  },
];

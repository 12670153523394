function canShareLink(): boolean {
  return !!navigator?.share;
}

async function copyLinkAndShare(link: string, text?: string): Promise<boolean> {
  try {
    if (!canShareLink()) {
      return false;
    }

    await navigator.share({
      title: 'Read with Ello',
      text: text || 'Check out this cool new reading app I found!',
      url: link,
    });
    return true;
  } catch (e) {
    console.error('failed to copy link to share', e);
    return false;
  }
}

async function copyLinkToClipboard(link: string): Promise<boolean> {
  try {
    if (!navigator?.clipboard) {
      return false;
    }

    await navigator.clipboard.writeText(link);
    return true;
  } catch (e) {
    console.error('failed to copy to clipboard', e);
    return false;
  }
}

export const CopyComponentService = {
  canShareLink,
  copyLinkAndShare,
  copyLinkToClipboard,
};

import makeStyles from '@mui/styles/makeStyles';
import {gray300, white} from '../../../styles/palette';

export default makeStyles(theme => ({
  qrcode: {
    backgroundColor: white,
    padding: '8px',
    margin: '24px auto',
    maxWidth: '180px',
    borderRadius: '16px',
    border: `2px solid ${gray300}`,
  },
}));

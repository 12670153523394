import React from 'react';
import Box from '@mui/material/Box';
import useStyles from './ReferralLink.styles';
import QRCode from './ReferralLink.qr-code-container.qrcode';
import QRCodeContainerHeader from './ReferralLink.qr-code-container.header';
import * as palette from '../../styles/palette';

type Props = {
  header?: string;
  body?: string;
};

const QRCodeContainer = ({header, body}: Props) => {
  const classes = useStyles();

  return (
    <Box
      my="16px"
      className={classes.qrCodeContainer}
      sx={{
        backgroundColor: palette.blue200,
      }}>
      <Box className={classes.iconContainer}>
        <QRCode />
      </Box>

      <Box className={classes.textContainer}>
        <QRCodeContainerHeader header={header} body={body} />
      </Box>
    </Box>
  );
};

export default QRCodeContainer;

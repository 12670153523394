import {useMutation} from '@apollo/client';
import {assignRewardBoksMutation} from './ShareableLink.queries';
import {ShareableLinkService} from './ShareableLink.service';

export function useAssignRewardBooks(): {
  assignRewardBooks: (shareMethod: string) => Promise<void>;
} {
  const [assignRewardBooks] = useMutation(assignRewardBoksMutation);

  return {
    assignRewardBooks: async (shareMethod: string) => {
      return ShareableLinkService.assignRewardBooks(
        assignRewardBooks,
        shareMethod,
      );
    },
  };
}

export enum LoginStep {
  PhoneStep = 'login_phone',
  VerifyStep = 'login_verify',
  EmailStep = 'login_email',
}

export enum LoginErrorMessage {
  UnregisteredNumber = 'Invalid phone number. Please make sure that the number you entered is correct.',
  InvalidNumber = 'The number you have entered is not a valid US number.',
  ApiError = 'Unable to validate phone number. Please text us at (415) 214-8119 for help.',
  InvalidEmailOrPassword = 'You have entered an invalid email or password',
  EmptyEmail = 'Please enter your email',
  EmptyPassword = 'Please enter your password',
  InvalidEmail = 'Please enter a valid email',
}

export enum PasswordResetErrorMessage {
  EmptyPassword = 'Please enter your new password',
  InvalidLink = 'The link entered is invalid. Redirecting you to request a new link to reset your password...',
  PasswordsDontMatch = 'Passwords do not match',
}

import {collectQueryParams} from 'shared/utils/queryParams';
import dompurify from 'dompurify';

// Allows filler syntax {{filler}}, {{filler|caps}}, {{filler|uncaps}}, {{filler|allcaps}}
export const PLACEHOLDER_REGEXP =
  /{{[A-z0-9]*(\|(un|all)?caps|(\|expandArray))?}}/g;

const PRONOUNS = ['subjective', 'objective', 'possessive'];
const PRONOUN_SHORTHANDS = ['he', 'him', 'his'];

/**
 * Replaces {{foo}} in any number of input strings with the query parameter
 * foo (if available).
 *
 * Replaces {{subjective}}, {{objective}}, and {{possessive}} with the pronouns
 * based on the `pronouns` query parameter.
 *
 * Alternatively, the male shorthands {{he}}, {{him}}, {{his}} can be used.
 * (We use these because they're easy to remember and are distinct for
 * the three choices unlike she / her / her.)
 *
 * @param {Array any} args: input strings (may be object if localized)
 * @param {String} locale: locale such as en-US, es-ES, ...
 * @returns the processed output strings
 */
export function resolveQueryStrings(args, locale) {
  let queryParams;

  const result = args.map(obj => {
    if (!obj) return obj; // Let null/undefined pass through

    // Handle localization: localized strings are objects like
    // {'en-US': 'Hello', 'es-ES': 'hola'}
    const str = typeof obj === 'string' ? obj : obj[locale];

    // Missing translation
    if (!str) return str;

    return str.replace(PLACEHOLDER_REGEXP, match => {
      // Lazily load query params on first match. If we get through
      // without matches, we don't need them.
      if (!queryParams) queryParams = collectQueryParams();

      const placeholder = match.substring(2, match.length - 2).split('|')[0];

      // See if placeholder is a pronoun in either PRONOUNS or PRONOUN_SHORTHANDS
      let pronounIndex = PRONOUNS.indexOf(placeholder.toLowerCase());
      if (pronounIndex === -1) {
        pronounIndex = PRONOUN_SHORTHANDS.indexOf(placeholder.toLowerCase());
      }
      if (pronounIndex !== -1) {
        // Add capitalization instructions so we can conveniently write {{He}} and
        // have it resolve to "She" and so on
        if (placeholder.toUpperCase() === placeholder) match += '|allcaps';
        else if (placeholder[0].toUpperCase() === placeholder[0])
          match += '|caps';
        else match += '|uncaps';
      }

      let filler;
      if (pronounIndex > -1) {
        // Pull from pronouns in query params
        if (queryParams.pronouns)
          filler = queryParams.pronouns.split('_')[pronounIndex] || '';
      } else {
        // Pull from all the variables in query params
        filler = queryParams[placeholder] || '';
      }

      // Handle _ separated arrays
      if (match.includes('|expandArray') && filler?.includes('_')) {
        const chunks = filler.split('_');
        if (chunks.length === 1) filler = chunks[0];
        else if (chunks.length === 2) filler = `${chunks[0]} and ${chunks[1]}`;
        else
          filler = `${chunks.slice(0, chunks.length - 1).join(', ')}, and ${
            chunks[chunks.length - 1]
          }`;
      }

      // Handle capitalization instruction
      if (filler)
        if (match.includes('|caps')) {
          // Capitalize first letter
          filler =
            filler.length > 1
              ? filler.charAt(0).toUpperCase() + filler.slice(1)
              : filler.toUpperCase();
        } else if (match.includes('|uncaps')) {
          // Uncapitalize everything
          filler = filler.toLowerCase();
        } else if (match.includes('|allcaps')) {
          // Capitalize everything
          filler = filler.toUpperCase();
        }

      return dompurify.sanitize(filler);
    });
  });

  return result;
}

/**
 * Wrapper for a single one.
 */
export function resolveQueryString(arg, locale) {
  return resolveQueryStrings([arg], locale)[0];
}

export function possessive(name) {
  const APOSTROPHE_CHAR = "'";
  if (name === '') {
    return name;
  }
  var lastChar = name.slice(-1);
  var endOfWord =
    lastChar.toLowerCase() === 's' ? APOSTROPHE_CHAR : `${APOSTROPHE_CHAR}s`;
  return `${name}${endOfWord}`;
}

import DiscountOffer from './DiscountOffer/DiscountOffer';
import ElloAccess from './ElloAccess/ElloAccess';
import LevelAdjustment from './LevelAdjustment/LevelAdjustment';
import ExtendTrial from './ExtendTrial/ExtendTrial';
import {BillingSource} from 'shared/types/customer';

type CustomizedFlowProps = {
  onConfirm: () => void;
  onClose: () => void;
  setHideBackButton: (hide: boolean) => void;
};

interface CustomizedFlowsMapping {
  [key: string]: {
    [key: string]: {
      component: React.ComponentType<CustomizedFlowProps>;
      flow: string;
      billingSource?: string;
    };
  };
}

export const customizedFlowsMapping: CustomizedFlowsMapping = {
  'no-time': {
    'too-short': {
      component: ExtendTrial,
      flow: "Didn't have enough time to use Ello | Trial period too short",
      billingSource: BillingSource.Stripe,
    },
    around: {
      component: DiscountOffer,
      flow: "Didn't have enough time to use Ello | Didn’t get around to it",
      billingSource: BillingSource.Stripe,
    },
  },
  'too-expensive': {
    'cant-afford': {
      component: ElloAccess,
      flow: 'Too expensive | I can’t afford it',
    },
  },
  'child-didnt-like': {
    'child-didnt-want': {
      component: LevelAdjustment,
      flow: 'My child did not like it | My child didn’t want to read with Ello',
    },
    'child-didnt-like-books': {
      component: LevelAdjustment,
      flow: 'My child did not like it | My child didn’t like the books',
    },
    other: {
      component: LevelAdjustment,
      flow: 'My child did not like it | Other',
    },
  },
};

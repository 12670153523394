import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {turquoise500} from 'shared/styles/palette';

const useStyles = makeStyles((theme: Theme) => ({
  text: {
    textAlign: 'center',
    fontSize: '1.25rem',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1rem',
    },
    '& a': {
      textDecoration: 'none',
      color: turquoise500,
    },
  },
}));

export default useStyles;

import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, {FC} from 'react';

const scrollNavigationBreakPoint = '@media (min-width: 992px)';
const largeScreenBreakPoint = '@media (min-width: 1540px)';

type Props = {
  handleBack: () => void;
  handleNext: () => void;
};

const ScrollNavigation: FC<Props> = ({handleBack, handleNext}: Props) => {
  return (
    <Box>
      <Box
        sx={{
          display: 'none',
          [scrollNavigationBreakPoint]: {display: 'block'},
        }}>
        <Box sx={{width: '100%', position: 'relative'}}>
          <IconButton
            onClick={handleBack}
            size="large"
            sx={{
              top: 132,
              left: -16,
              position: 'absolute',
              fontSize: '1.25rem',
              [largeScreenBreakPoint]: {left: -48},
            }}>
            <ArrowBackIosIcon fontSize="inherit" />
          </IconButton>
          <IconButton
            onClick={handleNext}
            size="large"
            sx={{
              top: 132,
              right: -16,
              position: 'absolute',
              fontSize: '1.25rem',
              [largeScreenBreakPoint]: {right: -48},
            }}>
            <ArrowForwardIosIcon fontSize="inherit" />
          </IconButton>
        </Box>
      </Box>
    </Box>
  );
};

export default ScrollNavigation;

import React from 'react';

import Title from './AdjustReadingLevel.request-adjustment.title';
import {List, ListItem, Typography} from '@mui/material';

type Props = {
  profileName?: string;
};

const RequestAdjustmentConfirmSelectionScreen = ({profileName}: Props) => {
  return (
    <>
      <Title text="Confirm level adjustment" />
      <Typography
        variant="body1"
        mt={3}
        style={{
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        }}>
        By clicking Continue, you confirm:
        <br />
        <List sx={{listStyleType: 'disc', pl: 3}}>
          <ListItem sx={{display: 'list-item', mt: 2}}>
            {profileName}'s book library will be changed to books from their new
            level
          </ListItem>

          <ListItem sx={{display: 'list-item', mt: 2}}>
            Progress on any books in the current level will be reset
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default RequestAdjustmentConfirmSelectionScreen;

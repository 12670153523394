import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {desktopBreakpoint} from '../BreakPoints';
import {Destination} from './SocialMedia.types';
import {getSocialMediaIcons} from './SocialMediaIcons';
import SocialMediaLink from './SocialMediaLink';

type Props = {
  destination: Destination;
  link: string;
};

const SocialMediaLinks: FC<Props> = ({link, destination}: Props) => {
  const icons = getSocialMediaIcons(link, destination);
  const shareText =
    destination === Destination.homepage
      ? 'Share this library with friends!'
      : 'Share this book with friends!';

  return (
    <Box
      mt={1}
      sx={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
        [desktopBreakpoint]: {
          textAlign: 'left',
        },
      }}>
      <Box>
        <Typography sx={{fontWeight: '700'}}>{shareText}</Typography>
      </Box>
      <Box
        sx={{
          display: 'flex',
          gap: 2,
          mt: '12px',
          mx: 'auto',
          [desktopBreakpoint]: {
            mx: 0,
          },
        }}>
        {icons.map((icon, index) => (
          <SocialMediaLink key={index} icon={icon} />
        ))}
      </Box>
    </Box>
  );
};

export default SocialMediaLinks;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    section: {
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      alignItems: 'baseline',
      marginBottom: theme.spacing(1),
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${palette.gray300}`,
    },
    title: {
      fontSize: '1.625rem',
    },
  });

export const actionButtonStyles = (theme: Theme) =>
  createStyles({
    button: {
      fontSize: '1rem',
      color: palette.turquoise700,
      fontWeight: 'bold',
      marginRight: -theme.spacing(1.3),
      padding: theme.spacing(1),
    },
    danger: {
      color: palette.orange500,
    },
  });

export default styles;

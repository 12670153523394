import React from 'react';
import cN from 'classnames';
import {CircularProgress, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles, {actionButtonStyles} from './Section.styles';
import Alert from 'shared/components/common/Alert/Alert';
import Button, {ButtonType} from 'shared/components/common/Button';

interface Props extends WithStyles<typeof styles> {
  title?: string | React.ReactNode;
  titleSection?: React.ReactNode;
  subtitle?: React.ReactNode;
  children?: React.ReactNode;
  containerClass?: string;
  error?: string;
  loading?: boolean;
}

const Section = ({
  classes,
  title,
  titleSection,
  subtitle,
  children = undefined,
  containerClass,
  error,
  loading = false,
}: Props) => {
  if (error) {
    return <Alert error={error} autoHide={false} />;
  }

  if (loading) {
    return <CircularProgress style={{margin: '50px auto', display: 'block'}} />;
  }

  return (
    <section>
      <div className={cN(classes.section, containerClass)}>
        {titleSection ? (
          titleSection
        ) : (
          <Typography component="h1" variant="h5" className={classes.title}>
            {title}
          </Typography>
        )}

        {subtitle}
      </div>

      {children}
    </section>
  );
};

export default withStyles(styles)(Section);

interface SectionActionButtonProps
  extends WithStyles<typeof actionButtonStyles> {
  onClick: () => void;
  label: string;
  isLoading?: boolean;
  danger?: boolean;
}
export const SectionActionButton = withStyles(actionButtonStyles)(
  ({
    classes,
    onClick,
    label,
    isLoading = false,
    danger = false,
  }: SectionActionButtonProps) => {
    const className = danger
      ? `${classes.button} ${classes.danger}`
      : classes.button;
    return (
      <Button
        onClick={onClick}
        variant={ButtonType.Inline}
        className={className}
        isLoading={isLoading}>
        {label}
      </Button>
    );
  },
);

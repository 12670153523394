/*
 * Universal Ello Login Page.
 */

import React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {Link} from 'react-router-dom';
import Grid from '@mui/material/Grid';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import Container from '@mui/material/Container';
import Snackbar from '@mui/material/Snackbar';
import Grow from '@mui/material/Grow';
import Alert from '@mui/material/Alert';
import Cookies from 'js-cookie';
import {withQueryParams, BooleanParam, StringParam} from 'use-query-params';
import firebase from 'firebase/app';
import CircularProgress from '@mui/material/CircularProgress';
import 'firebase/auth';
import 'shared/utils/connectFirebase';
import styles from './AdminLoginScreen.styles';
import PasswordVisibilityButton from '../../../customerPortal/components/PasswordVisibilityButton/PasswordVisibilityButton';
const queryParams = {failed: BooleanParam, redirect: StringParam};

class AdminLoginScreen extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      errorVisible: false,
      isLoading: true,
      isReady: false, // once loading animation has finished (yeah I'm pedantic)
      showPassword: false,
    };

    this.emailField = React.createRef();
    this.passwordField = React.createRef();
  }

  componentDidMount() {
    window.analytics.page('Ello Login');

    firebase.auth().onAuthStateChanged(user => {
      if (user) {
        // If we got redirected because of a failed login, log the user back out and display an error message
        // then clear the query field to let them try again
        if (this.props.query.failed) {
          this.setState({error: 'Not authorized!', errorVisible: true});
          this.props.setQuery({failed: false});
          firebase.auth().signOut();
          this.setState({isLoading: false});
        } else {
          this.redirectUser(user);
        }
      } else {
        this.setState({isLoading: false});
      }
    });
  }

  redirectUser = user => {
    user
      .getIdToken()
      .catch(error => {
        this.setState({error: error.message, errorVisible: true});
      })
      .then(token => {
        // Set access token in our cookies
        Cookies.set('ello_access_token', token);

        // Redirect to ello-private
        window.location.href = this.props.query.redirect || '/auth';
      });
  };

  logIn = () => {
    this.setState({isLoading: true});

    const email = this.emailField.current.value;
    const password = this.passwordField.current.value;

    // Perform firebase auth
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .catch(error => {
        this.setState({
          error: error.message,
          errorVisible: true,
          isLoading: false,
        });
      })
      .then(result => {
        // Triggers onAuthStateChanged() set in componentDidMount
      });
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  togglePasswordVisibility = () => {
    this.setState({showPassword: !this.state.showPassword});
  };

  render() {
    const {classes} = this.props;

    return (
      <Container
        component="main"
        className={classes.loginContainer}
        maxWidth="xs">
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
        <Typography component="h1" variant="h4">
          Ello
        </Typography>

        <Grow in={!this.state.isLoading} unmountOnExit>
          <form className={classes.form} noValidate onSubmit={this.logIn}>
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              id="email"
              label="Email"
              name="email"
              type="email"
              autoComplete="email"
              autoFocus
              inputRef={this.emailField}
            />
            <TextField
              variant="outlined"
              margin="normal"
              fullWidth
              name="password"
              label="Password"
              type={this.state.showPassword ? 'text' : 'password'}
              id="password"
              autoComplete="current-password"
              inputRef={this.passwordField}
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityButton
                    showPassword={this.state.showPassword}
                    onClick={this.togglePasswordVisibility}
                  />
                ),
              }}
            />
            <Button
              type="submit"
              variant="outlined"
              onClick={ev => {
                // Make sure page doesn't get refreshed
                ev.preventDefault();
                this.logIn();
              }}
              className={classes.submitButton}>
              Sign In
            </Button>
            <Grid container>
              <Grid item xs>
                <Typography
                  component={Link}
                  to={'/reset-password'}
                  className={classes.forgotPassword}
                  variant="body2">
                  Forgot Password
                </Typography>
              </Grid>
            </Grid>
          </form>
        </Grow>

        {this.state.isLoading && (
          <CircularProgress
            color="primary"
            className={classes.loadingIndicator}
          />
        )}
        <Snackbar
          open={this.state.errorVisible}
          onClose={this.handleErrorClose}
          autoHideDuration={8000}>
          <Alert onClose={this.handleErrorClose} severity="error">
            {this.state.error}
          </Alert>
        </Snackbar>
      </Container>
    );
  }
}

export default withStyles(styles)(
  withQueryParams(queryParams, AdminLoginScreen),
);

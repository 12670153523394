import React from 'react';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './CustomerLoginScreen.styles';
import Button from 'shared/components/common/Button';

interface Props extends WithStyles<typeof styles> {
  onSubmit: (e: any) => void;
  children: React.ReactNode | string;
}

const CustomerLoginScreenForm = ({classes, children, onSubmit}: Props) => {
  return (
    <>
      <Typography component="h1" variant="h5" className={classes.title}>
        Customer Portal Log In
      </Typography>
      <Typography paragraph className={classes.subtitle}>
        Log in to manage your subscription, adjust your account details, and
        more.
      </Typography>
      <form noValidate onSubmit={onSubmit} data-testid="loginForm">
        {children}
        <Button className={classes.button} type="submit">
          Next
        </Button>
      </form>
    </>
  );
};

export default withStyles(styles)(CustomerLoginScreenForm);

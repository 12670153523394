import React from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Dialog from '@mui/material/Dialog';
import MuiDialogTitle from '@mui/material/DialogTitle';
import MuiDialogContent from '@mui/material/DialogContent';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './Modal.styles';

interface DialogTitleProps extends WithStyles<typeof styles> {
  onClose: () => void;
}
const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const {classes, onClose} = props;
  return (
    <MuiDialogTitle>
      {onClose ? (
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={onClose}
          size="large">
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

interface ModalProps extends WithStyles<typeof styles> {
  handleClose: () => void;
  isOpen: boolean;
  children: React.ReactNode;
}
const Modal = ({classes, children, handleClose, isOpen}: ModalProps) => {
  return (
    <Dialog
      PaperProps={{
        elevation: 0,
        className: classes.dialog,
      }}
      onClose={handleClose}
      open={isOpen}>
      <DialogTitle onClose={handleClose} />
      <MuiDialogContent>{children}</MuiDialogContent>
    </Dialog>
  );
};

export default withStyles(styles)(Modal);

/**
 * Helper function to get a complete set of query params.
 */
export function collectQueryParams() {
  const iterable = new URLSearchParams(window.location.search).entries();
  const params = {};

  for (const pair of iterable) {
    const key = pair[0];
    let value = pair[1];

    // Handle email specifically to replace spaces with '+'
    if (key === 'email') {
      value = value.replace(/ /g, '+'); // Replace spaces with '+' for email
    }

    params[key] = value;
  }

  return params;
}

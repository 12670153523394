import {Box} from '@mui/material';
import React from 'react';
import ImageSection from '../Sections/ImageSection';
import BodySection from '../Sections/BodySection';
import ActionsSection from '../Sections/ActionsSection';
import {ReactComponent as FreeMonth} from 'shared/assets/Free-Month.svg';
import ContainerWrapper from '../Sections/ContainerWrapper';

type Props = {
  onAccept: () => void;
  onAcceptLoading: boolean;
  onCancel: () => void;
};

const OfferScreen: React.FC<Props> = ({
  onAccept,
  onCancel,
  onAcceptLoading,
}) => {
  return (
    <ContainerWrapper>
      <Box>
        <Box mb="40px">
          <ImageSection>
            <FreeMonth />
          </ImageSection>
        </Box>
        <BodySection
          title="Get a month of Ello on us!"
          description="We’d love for you to try Ello before deciding to leave us! Get a free
         month of Ello"
        />
      </Box>
      <ActionsSection
        acceptLabel="Yes! Accept 1 month free"
        cancelLabel="Continue Cancellation"
        onAccept={onAccept}
        onAcceptLoading={onAcceptLoading}
        onCancel={onCancel}
      />
    </ContainerWrapper>
  );
};

export default OfferScreen;

import {CircularProgress} from '@mui/material';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';
import Alert from 'shared/components/common/Alert/Alert';
import {Purchase, PurchasesFilter} from 'shared/types/purchases';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import HorizontalPurchaseList from './HorizontalPurchaseList';
import NoOrdersAvailable from './NoOrdersAvailable';
import VerticalPurchaseList from './VerticalPurchaseList';

interface PurchaseListProps {
  items?: Purchase[];
  error?: string;
  isLoading: boolean;
  filter?: PurchasesFilter;
  productType?: string;
}
const PurchaseList = ({
  items,
  error,
  isLoading,
  filter,
  productType,
}: PurchaseListProps) => {
  const isMobile = useMobileLayout();

  if (error) {
    return <Alert error={error} autoHide={false} />;
  }

  if (isLoading) {
    return <CircularProgress style={{margin: '50px auto', display: 'block'}} />;
  }

  const allPurchasesHaveNullStatus = items?.every(
    ({status}) => !!status === false,
  );

  if (
    !items?.length ||
    (allPurchasesHaveNullStatus && filter?.showCurrentPurchases)
  ) {
    return <NoOrdersAvailable />;
  }

  return isMobile ? (
    <VerticalPurchaseList
      items={items}
      filter={filter}
      productType={productType}
    />
  ) : (
    <HorizontalPurchaseList
      items={items}
      filter={filter}
      productType={productType}
    />
  );
};

export default PurchaseList;

import React from 'react';
import Box from '@mui/material/Box';

type Props = {
  image: any;
};

const ImageContainer = ({image}: Props) => {
  return (
    <Box
      sx={{
        mt: '2rem',
      }}>
      <img
        src={image}
        alt="Onboarding Share"
        style={{
          maxWidth: '300px',
          maxHeight: '100%',
        }}
      />
    </Box>
  );
};

export default ImageContainer;

import React from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  text?: string;
};

const Title = ({text}: Props) => {
  return (
    <Typography
      variant="h6"
      mt={4}
      style={{
        fontWeight: 700,
        fontSize: '1.25rem',
        lineHeight: '1.5rem',
      }}>
      {text}
    </Typography>
  );
};

export default Title;

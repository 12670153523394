import React, {useState} from 'react';
import OfferScreen from './OfferScreen';
import ConfirmationScreen from './ConfirmationScreen';
import {Alert, Box, Snackbar, SnackbarCloseReason} from '@mui/material';
import {useOfferDiscount} from '../../CancellationForm.service';

type Props = {
  onConfirm: () => void;
  onClose: () => void;
  setHideBackButton: (hide: boolean) => void;
};

const DiscountOffer: React.FC<Props> = ({
  setHideBackButton,
  onConfirm,
  onClose,
}) => {
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);

  const {offerDiscount, loading, errorMessage} = useOfferDiscount(
    setHideBackButton,
    setShowConfirmation,
    setSnackbarOpen,
  );

  const handleCloseSnackbar = (
    _: React.SyntheticEvent | Event,
    reason?: SnackbarCloseReason,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  return (
    <Box height="100%">
      {showConfirmation ? (
        <ConfirmationScreen onClose={onClose} />
      ) : (
        <OfferScreen
          onAccept={offerDiscount}
          onAcceptLoading={loading}
          onCancel={onConfirm}
        />
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={3000}
        onClose={handleCloseSnackbar}>
        <Alert onClose={handleCloseSnackbar} severity="error">
          {errorMessage}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default DiscountOffer;

import {useQuery} from '@apollo/client';
import {Pagination} from '../WebReader/Book.type';
import {
  categoriesQuery,
  categoryQuery,
  externalBooksQuery,
  readingListQuery,
  readingListsByCategoriesQuery,
} from './Library.queries';
import {
  BookFilter,
  Category,
  CategoryFilter,
  ReadingList,
} from './Library.types';

export function useExternalBooks<T>(
  pagination: Pagination,
  filter?: BookFilter,
): {
  data: T[];
  loading: boolean;
  error: Error;
  pageInfo: {cursor: string; page: number; totalPages: number};
} {
  const {data, error, loading} = useQuery(externalBooksQuery, {
    fetchPolicy: 'cache-first',
    variables: {pagination, filter},
  });

  if (!data) {
    const books: T[] = [];

    return {
      data: books,
      pageInfo: {cursor: '', page: 0, totalPages: 0},
      error: error as Error,
      loading,
    };
  }

  const books = data.externalBooks.books as T[];
  const pageInfo = data.externalBooks.pageInfo;

  return {data: books, pageInfo, error: error as Error, loading};
}

export function useCategories(
  pagination: Pagination,
  filter: CategoryFilter,
): {data: Category[]; loading: boolean; error: Error} {
  const {data, error, loading} = useQuery(categoriesQuery, {
    fetchPolicy: 'cache-first',
    variables: {pagination, filter},
  });

  if (!data) {
    return {data: [], error: error as Error, loading};
  }

  const categories = data.categories.categories as Category[];
  return {data: categories, error: error as Error, loading};
}

export function useCategory(categoryId: string): {
  data: Category;
  loading: boolean;
  error: Error;
} {
  const {data, error, loading} = useQuery(categoryQuery, {
    fetchPolicy: 'cache-first',
    variables: {id: categoryId},
    skip: !categoryId,
  });

  if (!data) {
    return {data: {} as Category, error: error as Error, loading};
  }

  const category = data.category as Category;
  return {data: category, error: error as Error, loading};
}

export function useReadingListsByCategories(categoryIds: string[]): {
  readingLists: ReadingList[];
  loading: boolean;
  error: Error;
} {
  const {data, error, loading} = useQuery(readingListsByCategoriesQuery, {
    fetchPolicy: 'cache-first',
    variables: {categoryIds},
    skip: !categoryIds?.length,
  });

  if (!data) {
    return {readingLists: [], error: error as Error, loading};
  }

  const readingLists = data.readingListsByCategories
    .readingLists as ReadingList[];

  return {readingLists, error: error as Error, loading};
}

export function useReadingList(id?: string): {
  readingList: ReadingList;
  loading: boolean;
  error: Error;
} {
  const {data, error, loading} = useQuery(readingListQuery, {
    fetchPolicy: 'cache-first',
    variables: {id},
    skip: !id,
  });

  if (!data) {
    return {readingList: {} as ReadingList, error: error as Error, loading};
  }

  const readingList = data.readingList as ReadingList;

  return {readingList, error: error as Error, loading};
}

import Box from '@mui/material/Box';
import React, {FC, useEffect, useRef} from 'react';
import {Category, ReadingList} from '../Library.types';
import CategoryItemList from './CategoryItemList';
import ScrollNavigation from './ScrollNavigation';
import ScrollPosition from './ScrollPosition';

const categoryItemsListBreakPoint = '@media (min-width: 991px)';
const largeScreenBreakPoint = '@media (min-width: 1540px)';
const maxCardWidth = 300;

type Props = {
  subcategories?: Category[];
  readingLists?: ReadingList[];
};

const CategoryItems: FC<Props> = ({subcategories, readingLists}: Props) => {
  const [scroll, setScroll] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const scrollRef = useRef<HTMLDivElement>(null);

  const count = subcategories?.length || readingLists?.length || 0;
  const pages = Math.ceil((count * maxCardWidth) / screenWidth);
  const showNavigation = pages > 1;

  const handleBack = () => {
    const isFirstPage = scroll === 0;
    if (isFirstPage) {
      return;
    }

    if (scrollRef.current) {
      scrollRef.current.scrollLeft -= screenWidth;
    }
  };
  const handleNext = () => {
    const isLastPage = scroll + screenWidth >= pages * screenWidth;
    if (isLastPage) {
      return;
    }

    if (scrollRef.current) {
      scrollRef.current.scrollLeft += screenWidth;
    }
  };

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [setScreenWidth]);

  useEffect(() => {
    const handleScroll = () => {
      if (scrollRef.current) {
        setScroll(scrollRef.current.scrollLeft);
      }
    };

    const scrollRefCurrent = scrollRef.current;

    if (scrollRefCurrent) {
      scrollRefCurrent.addEventListener('scroll', handleScroll);
    }

    return () => {
      if (scrollRefCurrent) {
        scrollRefCurrent.removeEventListener('scroll', handleScroll);
      }
    };
  }, [scrollRef, setScroll]);

  return (
    <Box>
      {showNavigation && (
        <ScrollNavigation handleBack={handleBack} handleNext={handleNext} />
      )}
      <Box
        sx={{
          [categoryItemsListBreakPoint]: {
            width: 'calc(100% - 48px)',
            margin: '0 auto',
          },
          [largeScreenBreakPoint]: {
            width: '100%',
          },
        }}>
        <CategoryItemList
          scrollRef={scrollRef}
          subcategories={subcategories}
          readingLists={readingLists}
        />
      </Box>
      {showNavigation && (
        <ScrollPosition
          pages={pages}
          screenWidth={screenWidth}
          scroll={scroll}
        />
      )}
    </Box>
  );
};

export default CategoryItems;

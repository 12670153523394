import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const useStyles = (theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(1),
      minHeight: '70vh',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        paddingTop: theme.spacing(4),
        minHeight: 'auto',
      },
    },
    topContainer: {
      flex: 1,
      flexDirection: 'column',
      justifyContent: 'center',
    },
    title: {
      padding: theme.spacing(2),
    },
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    button: {
      width: '100%',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        width: 'auto',
        paddingLeft: theme.spacing(10),
        paddingRight: theme.spacing(10),
      },
    },
    buttonContainer: {
      paddingLeft: theme.spacing(1),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    leftContainer: {
      flex: 1,
      alignItems: 'center',
    },
    rightContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
  });

export default useStyles;

import CircleIcon from '@mui/icons-material/Circle';
import CircleOutlinedIcon from '@mui/icons-material/CircleOutlined';
import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {turquoise900} from '../../../../shared/styles/palette';

type Props = {
  index: number;
  currentIndex: number;
};

const ScrollCircle: FC<Props> = ({index, currentIndex}: Props) => {
  return (
    <Box>
      {currentIndex === index && (
        <CircleIcon
          sx={{fontSize: '0.65rem', color: turquoise900, mr: '12px'}}
        />
      )}
      {currentIndex !== index && (
        <CircleOutlinedIcon
          sx={{fontSize: '0.65rem', color: turquoise900, mr: '12px'}}
        />
      )}
    </Box>
  );
};

export default ScrollCircle;

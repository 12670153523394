import React from 'react';
import cN from 'classnames';
import {Link as RouterLink} from 'react-router-dom';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from 'shared/components/common/Button/Button.styles';
import {ButtonType} from 'shared/components/common/Button';

/**
 * Duplicate of react router type def
 * because LinkProps type cannot be imported
 * for some reason
 */
interface Location {
  pathname?: string;
  search?: string;
  state?: unknown;
  hash?: string;
  key?: string;
}
interface Props extends WithStyles<typeof styles> {
  to: string | Location;
  className?: string;
  children: React.ReactNode | string;
  variant?: ButtonType;
}

const Link = ({
  classes,
  variant = ButtonType.Primary,
  className,
  ...rest
}: Props) => {
  return (
    <RouterLink
      className={cN(classes.base, classes[variant], className)}
      {...rest}
    />
  );
};

export default withStyles(styles)(Link);

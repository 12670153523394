import React from 'react';
import {AppBar, Container} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {useActionBarStyles} from './FlowLayout.styles';

interface Props extends WithStyles<typeof useActionBarStyles> {
  ctaContainerSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  children: React.ReactNode;
}

const ActionBar = ({classes, children, ctaContainerSize}: Props) => {
  const isMobile = useMobileLayout();

  return (
    <>
      {isMobile ? (
        <AppBar elevation={2} position="fixed" className={classes.actionBar}>
          {children}
        </AppBar>
      ) : (
        <Container
          disableGutters
          maxWidth={ctaContainerSize}
          className={classes.buttonContainer}>
          <>{children}</>
        </Container>
      )}
    </>
  );
};

export default withStyles(useActionBarStyles)(ActionBar);

/*
 * A graphical form picker component with two modes: select one (type="select")
 * and select multiple (type="multiple").
 *
 * For type "select" single, allows for passing along a "goToStep" in the onNext handler
 * to support conditional step jumps in the onboarding flow through the choices.
 */

import React, {useState} from 'react';
import Grid from '@mui/material/Grid';
import {
  useQueryParam,
  DelimitedArrayParam,
  StringParam,
} from 'use-query-params';
import {Typography} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import ButtonBase from '@mui/material/ButtonBase'; // For ripple effect
import TextField from '@mui/material/TextField';
import {useMemo} from 'react';
import {useLocale, Locales, useStrings} from 'shared/utils/localization';
import {resolveQueryStrings} from 'shared/utils/stringUtils/stringUtils';
import clsx from 'clsx';
import useStyles from './FormPicker.styles';

function FormPicker({
  type,
  field,
  choices,
  renderOther,
  onValidationChanged,
  onNext,
  largeTile,
  selectUpdated,
}) {
  const classes = useStyles();
  const {locale} = useLocale();
  const strings = useStrings();
  // For type "multiple", `selection` is an array of Strings. Initially empty.
  // For type "select", `selection` is a single string field with the choice title.
  const ParamType = type === 'select' ? StringParam : DelimitedArrayParam;

  // Setup hook for query params
  const [selection, setSelection] = useQueryParam(field, ParamType);
  // Setup hook for `other` multiple-type formpicker selection input value
  let otherSelectionItemValue = '';
  if (renderOther) {
    // Array containing `otherSelection()` entry
    const otherSelectionItem = selection?.filter(item =>
      item.includes('otherSelection('),
    )[0];
    // Substring inside `otherSelection()`
    otherSelectionItemValue = otherSelectionItem?.substring(
      otherSelectionItem.indexOf('(') + 1,
      otherSelectionItem.lastIndexOf(')'),
    );
  }
  const [otherInput, setOtherInput] = useState(otherSelectionItemValue);

  // Fill {{placeholder}} blanks and pass in locale
  const resolvedTitles = useMemo(
    () =>
      resolveQueryStrings(
        choices.map(c => c.title),
        locale,
      ),
    [choices, locale],
  );
  const englishTitles = useMemo(
    () =>
      resolveQueryStrings(
        choices.map(c => c.title),
        Locales.enUS,
      ),
    [choices],
  );

  return (
    <Grid container justifyContent="center" gap={selectUpdated && 1}>
      {choices.map((option, index) => (
        <Grid
          data-testid={`${englishTitles[index]} Grid`}
          item
          key={englishTitles[index]}
          component={type === 'select' ? ButtonBase : undefined}
          xs={12}
          sm={selectUpdated ? 4 : 6}
          md={largeTile ? 6 : 3}
          lg={largeTile ? 6 : 3}
          className={clsx(
            largeTile ? classes.largeTile : classes.tile,
            selectUpdated &&
              (largeTile
                ? classes.largeTileSelectUpdated
                : classes.tileSelectUpdated),
            selection &&
              ((type === 'select' && selection === englishTitles[index]) ||
                (type === 'multiple' &&
                  selection.includes(englishTitles[index]))) &&
              classes.tileSelected,
            !option.img && classes.tileWithoutImage,
            !option.img &&
              type !== 'multiple' &&
              classes.tileWithoutImageOrCheckbox,
          )}
          onClick={event => {
            // Select single mode
            if (type === 'select') {
              setSelection(englishTitles[index]);
              onValidationChanged(true);
              onNext(option.goToStep);
            }
            // Select multiple mode
            else {
              const otherPrimarySelection = selection?.filter(
                item => item !== englishTitles[index],
              );
              if (selection && selection.includes(englishTitles[index])) {
                // If it's already there, remove it
                setSelection(otherPrimarySelection);
                // If no other selections are selected, invalidate
                if (otherPrimarySelection.length === 0) {
                  onValidationChanged(false);
                }
              } else {
                // Else, add it
                setSelection((selection || []).concat([englishTitles[index]]));
                onValidationChanged(true);
              }
            }
          }}>
          {type === 'multiple' && (
            <Checkbox
              data-testid={`${englishTitles[index]} Checkbox`}
              color="primary"
              className={classes.checkbox}
              checked={!!selection && selection.includes(englishTitles[index])}
              disabled={true}
            />
          )}
          {option.img ? (
            <>
              <img
                src={option.img}
                alt={resolvedTitles[index]}
                className={clsx(
                  classes.tileImage,
                  selectUpdated && classes.tileImageSelectUpdated,
                  largeTile && classes.largeTileImage,
                )}
              />
              <Typography
                component="h4"
                className={clsx(
                  classes.tileTitleImg,
                  largeTile && classes.tileTitleLarge,
                )}>
                {resolvedTitles[index]}
              </Typography>
            </>
          ) : (
            <Typography
              component="h4"
              className={clsx(
                classes.tileTitle,
                selectUpdated && classes.tileTitleSelectUpdated,
                largeTile && classes.tileTitleLarge,
              )}>
              {resolvedTitles[index]}
            </Typography>
          )}
        </Grid>
      ))}
      {/* Render `Other:` Grid */}
      {renderOther && (
        <Grid
          data-testid="Other Grid"
          item
          key={choices.length}
          xs={12}
          sm={6}
          md={3}
          lg={3}
          className={clsx(
            classes.tile,
            otherInput && classes.tileSelected,
            classes.tileWithoutImage,
          )}>
          <Checkbox
            data-testid="Other Checkbox"
            color="primary"
            className={classes.checkbox}
            checked={!!otherInput}
            disabled={true}
          />
          <div className={classes.otherContainer}>
            <TextField
              type="text"
              fullWidth
              label={`${strings.other}:`}
              defaultValue={otherInput}
              multiline={true}
              onChange={({target: {value}}) => {
                const primarySelection = selection
                  ? selection.filter(item => !item.includes('otherSelection('))
                  : [];
                const otherSelection = selection
                  ? selection.filter(item => item.includes('otherSelection('))
                  : [];

                // Update input state
                setOtherInput(value);

                // 1) Previously highlighted (w/ input)
                if (otherSelection.length > 0) {
                  // 1a) input still present
                  if (value.length > 0) {
                    // Update query param with updated `other` selection item
                    const newSelection = primarySelection;
                    newSelection.push(`otherSelection(${value})`);
                    setSelection(newSelection);
                  }
                  // 1b) input now absent
                  else {
                    // Update query param without `other` selection item
                    setSelection(primarySelection);
                    // If no primary selections are selected, invalidate
                    if (primarySelection.length === 0) {
                      onValidationChanged(false);
                    }
                  }
                }
                // 2) Previously unhighlighted (w/o input)
                else {
                  // Add `other` value to queryParam
                  setSelection(
                    (selection || []).concat([`otherSelection(${value})`]),
                  );
                  // Validate
                  onValidationChanged(true);
                }
              }}
            />
          </div>
        </Grid>
      )}
    </Grid>
  );
}

export default FormPicker;

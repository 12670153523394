import {collectQueryParams} from '../../utils/queryParams';
import {useMutation} from '@apollo/client';
import {TRACK_REFERRAL_LINK_CLICK} from './ReferralLink.queries';

async function copyLinkToClipboard(link: string): Promise<boolean> {
  try {
    if (!navigator?.clipboard) {
      return false;
    }

    await navigator.clipboard.writeText(link);
    return true;
  } catch (e) {
    console.error('failed to copy to clipboard', e);
    return false;
  }
}

function referralLink(baseUrl?: string): string | undefined {
  const params = collectQueryParams() as {referralCode?: string};
  if (!params.referralCode) {
    return;
  }

  const base = baseUrl || 'https://ello.com/r';

  return `${base}/${params.referralCode}`;
}

const useTrackReferralLinkClick = () => {
  const [trackReferralLinkClick] = useMutation(TRACK_REFERRAL_LINK_CLICK);

  const trackReferral = async (referralCode: string) => {
    return trackReferralLinkClick({
      variables: {referralCode},
    });
  };

  return {trackReferral};
};

export const ReferralLinkService = {
  copyLinkToClipboard,
  referralLink,
  useTrackReferralLinkClick,
};

const readingLevels = {
  BELOW: 'BELOW',
  A: 'A',
  B: 'B',
  C: 'C',
  D: 'D',
  E: 'E',
  F: 'F',
  G: 'G',
  H: 'H',
  I: 'I',
  J: 'J',
  K: 'K',
  L: 'L',
  M: 'M',
  ABOVE: 'ABOVE',
};

const GradeComparisonStates = {
  VeryBelow: 'Far behind grade level',
  Below: 'A little behind grade level',
  AtGradeLevel: 'As expected for grade level',
  Ahead: 'A little ahead of grade level',
  VeryAhead: 'Far ahead of grade level',
};

/**
 * Sets the reading level for first grade users
 * based on the month when the user was giving
 * input
 * @param readinglevellist - list of the reading levels
 *           expected for each of the different cases
 * @param month - the number of the month when the user
 *           gave input
 */
const setFirstGradeLevel = (readinglevellist, month) => {
  switch (month) {
    case 8:
      return readinglevellist[0];
    case 9:
    case 10:
      return readinglevellist[1];
    case 11:
      return readinglevellist[2];
    case 0:
    case 1:
      return readinglevellist[3];
    case 2:
      return readinglevellist[4];
    case 3:
    case 4:
    case 5:
    case 6:
    case 7:
      return readinglevellist[5];
    default:
      break;
  }
};
/**
 * Sets the reading level for second grade users
 * based on the month when the user was giving
 * input
 * @param readinglevellist - list of the reading levels
 *           expected for each of the different cases
 * @param month - the number of the month when the user
 *           gave input
 */
const setSecondGradeLevel = (readinglevellist, month) => {
  switch (month) {
    case 8:
    case 9:
      return readinglevellist[0];
    case 10:
    case 11:
    case 0:
      return readinglevellist[1];
    case 1:
    case 2:
    case 3:
    case 4:
      return readinglevellist[2];
    case 5:
    case 6:
    case 7:
      return readinglevellist[3];
    default:
      break;
  }
};
/**
 * Gets level to start assessment based on grade, time of the month and grade comparison
 */
export const calculateLevel = (grade, gradeComparison, month) => {
  switch (grade) {
    case 'Younger than Pre-K':
    case 'Before Pre-K':
    case 'Too young for school':
    case 'Just born':
    case 'Not in school':
    case 'Pre-K':
      switch (gradeComparison) {
        case GradeComparisonStates.VeryBelow:
          return readingLevels.A;
        case GradeComparisonStates.Below:
          return readingLevels.A;
        case GradeComparisonStates.AtGradeLevel:
          return readingLevels.A;
        case GradeComparisonStates.Ahead:
          return readingLevels.A;
        case GradeComparisonStates.VeryAhead:
          return readingLevels.B;
        default:
          break;
      }
      break;
    case 'Kindergarten':
    case 'K':
      switch (gradeComparison) {
        case GradeComparisonStates.VeryBelow:
        case GradeComparisonStates.Below:
          return readingLevels.A;
        case GradeComparisonStates.AtGradeLevel:
          switch (month) {
            case 8:
            case 9:
            case 10:
            case 11:
            case 0:
              return readingLevels.A;
            case 1:
            case 2:
            case 3:
            case 4:
            case 5:
            case 6:
            case 7:
              return readingLevels.B;
            default:
              break;
          }
          break;
        case GradeComparisonStates.Ahead:
          return readingLevels.B;
        case GradeComparisonStates.VeryAhead:
          return readingLevels.C;
        default:
          break;
      }
      break;
    case '1st grade':
    case '1':
      switch (gradeComparison) {
        case GradeComparisonStates.VeryBelow:
          return setFirstGradeLevel(
            [
              readingLevels.A,
              readingLevels.C,
              readingLevels.D,
              readingLevels.E,
              readingLevels.F,
              readingLevels.G,
            ],
            month,
          );
        case GradeComparisonStates.Below:
          return setFirstGradeLevel(
            [
              readingLevels.B,
              readingLevels.D,
              readingLevels.E,
              readingLevels.F,
              readingLevels.G,
              readingLevels.H,
            ],
            month,
          );
        case GradeComparisonStates.AtGradeLevel:
          return setFirstGradeLevel(
            [
              readingLevels.C,
              readingLevels.E,
              readingLevels.F,
              readingLevels.G,
              readingLevels.H,
              readingLevels.I,
            ],
            month,
          );
        case GradeComparisonStates.Ahead:
          return setFirstGradeLevel(
            [
              readingLevels.D,
              readingLevels.E,
              readingLevels.G,
              readingLevels.H,
              readingLevels.I,
              readingLevels.J,
            ],
            month,
          );
        case GradeComparisonStates.VeryAhead:
          return setFirstGradeLevel(
            [
              readingLevels.E,
              readingLevels.F,
              readingLevels.G,
              readingLevels.I,
              readingLevels.J,
              readingLevels.K,
            ],
            month,
          );
        default:
          break;
      }
      break;
    case '2nd grade':
    case '2':
      switch (gradeComparison) {
        case GradeComparisonStates.VeryBelow:
          return setSecondGradeLevel(
            [
              readingLevels.H,
              readingLevels.I,
              readingLevels.J,
              readingLevels.K,
            ],
            month,
          );
        case GradeComparisonStates.Below:
          return setSecondGradeLevel(
            [
              readingLevels.I,
              readingLevels.J,
              readingLevels.K,
              readingLevels.L,
            ],
            month,
          );
        case GradeComparisonStates.AtGradeLevel:
          return setSecondGradeLevel(
            [
              readingLevels.J,
              readingLevels.K,
              readingLevels.L,
              readingLevels.M,
            ],
            month,
          );
        case GradeComparisonStates.Ahead:
          return setSecondGradeLevel(
            [
              readingLevels.K,
              readingLevels.L,
              readingLevels.L,
              readingLevels.M,
            ],
            month,
          );
        case GradeComparisonStates.VeryAhead:
          return setSecondGradeLevel(
            [
              readingLevels.L,
              readingLevels.L,
              readingLevels.M,
              readingLevels.M,
            ],
            month,
          );
        default:
          break;
      }
      break;
    case '3rd grade':
    case '3':
      switch (gradeComparison) {
        case GradeComparisonStates.VeryBelow:
          return readingLevels.K;
        case GradeComparisonStates.Below:
          return readingLevels.L;
        case GradeComparisonStates.AtGradeLevel:
          return readingLevels.M;
        case GradeComparisonStates.Ahead:
          return readingLevels.M;
        case GradeComparisonStates.VeryAhead:
          return readingLevels.M;
        default:
          break;
      }
      break;
    default:
      break;
  }
};

import React from 'react';
import cN from 'classnames';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from './FormRadioInput.styles';

interface Props extends WithStyles<typeof styles> {
  id: string;
  name: string;
  value: string;
  label: React.ReactNode;
  onChange: (r: any) => void;
  checked: boolean;
  className?: string;
  checkedClass?: string;
}

const FormRadioInput = ({
  classes,
  id,
  name,
  value,
  label,
  checked,
  onChange,
  className,
  checkedClass = '',
}: Props) => {
  return (
    <label htmlFor={id} className={cN({[checkedClass]: checked}, className)}>
      <input
        className={classes.radioInput}
        data-testid={id}
        type="radio"
        checked={checked}
        id={id}
        name={name}
        value={value}
        onChange={onChange}
      />
      {label}
    </label>
  );
};

export default withStyles(styles)(FormRadioInput);

export enum TabOptions {
  'current' = 'current',
  'upcoming' = 'upcoming',
  'previous' = 'previous',
}

export interface Tab {
  label: string;
  value: TabOptions;
}

export const TABS: Tab[] = [
  {
    label: 'Current',
    value: TabOptions.current,
  },
  {
    label: 'Upcoming',
    value: TabOptions.upcoming,
  },
  {
    label: 'Previous',
    value: TabOptions.previous,
  },
];

/**
 * Main app entrypoint for https://portal.helloello.com
 */

import React from 'react';
import ReactDOM from 'react-dom';
import 'shared/styles/index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import {initSentry} from './shared/utils/sentry';

initSentry();

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://create-react-app.dev/docs/making-a-progressive-web-app/
serviceWorker.unregister();

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    button: {
      width: '100%',
    },
  });

export default styles;

import React from 'react';
import {Chip} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import {PurchaseStatus} from 'shared/types/purchases';
import styles from './StatusChip.styles';
import cN from 'classnames';

interface StatusChipProps extends WithStyles<typeof styles> {
  status: PurchaseStatus;
  containerClass?: string;
}

const dueStatuses: PurchaseStatus[] = [
  PurchaseStatus.Due,
  PurchaseStatus.OverDue,
];
const activeStatuses: PurchaseStatus[] = [
  PurchaseStatus.Active,
  PurchaseStatus.Shipped,
  PurchaseStatus.Pending,
  PurchaseStatus.Returning,
];
const PurchaseStatusChip = ({
  classes,
  status,
  containerClass,
}: StatusChipProps) => {
  return (
    <Chip
      size="small"
      variant="outlined"
      className={cN(
        {
          [classes.chipDue]: status && dueStatuses.includes(status),
          [classes.chipActive]: status && activeStatuses.includes(status),
        },
        containerClass,
      )}
      label={status}
    />
  );
};

export default withStyles(styles)(PurchaseStatusChip);

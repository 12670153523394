import {Bugfender} from '@bugfender/sdk';

export default function initErrorMonitor() {
  const isProduction =
    process.env.NODE_ENV === 'production' &&
    !(
      window.location.href.includes('staging') ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('rav')
    );
  if (isProduction) {
    Bugfender.init({
      appKey: process.env.REACT_APP_BUGFENDER_API_KEY,
    });
  }
}

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';

const formatAmount = amount => {
  const parsedAmount = parseFloat(amount || '0');
  return isNaN(parsedAmount) ? '0.00' : parsedAmount.toFixed(2);
};

const Total = ({classes, trialPeriodDays, total, chargeableTrialAmount}) => {
  const displayTotal = trialPeriodDays
    ? formatAmount(chargeableTrialAmount)
    : formatAmount(total);

  return (
    <Box display="flex" justifyContent="space-between">
      <Box>
        <Typography className={classes.total} align="left">
          Total
        </Typography>
      </Box>
      <Box>
        <Typography className={classes.total} align="right">
          ${displayTotal}
        </Typography>
      </Box>
    </Box>
  );
};

export default Total;

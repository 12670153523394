import {CircularProgress, Button as MaterialButton} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cN from 'classnames';
import React from 'react';
import {ButtonType} from '.';
import styles from './Button.styles';

interface Props extends WithStyles<typeof styles> {
  type?: 'button' | 'submit';
  danger?: boolean;
  className?: string;
  children: React.ReactNode | string;
  variant?: ButtonType;
  onClick?: (a?: any) => void;
  isLoading?: boolean;
  disableFocusRipple?: boolean;
  disableRipple?: boolean;
  disabled?: boolean;
  sx?: any;
}

const Button = ({
  classes,
  danger = false,
  variant = ButtonType.Primary,
  children,
  type = 'button',
  className,
  isLoading,
  ...rest
}: Props) => {
  return (
    <MaterialButton
      type={type}
      variant={variant !== ButtonType.Icon ? variant : undefined}
      className={cN(
        classes.base,
        classes[variant],
        {[classes.danger]: danger},
        className,
      )}
      disableElevation
      disabled={isLoading}
      {...rest}>
      <>
        {children}{' '}
        {isLoading ? (
          <CircularProgress
            size={20}
            color="inherit"
            className={classes.spinner}
          />
        ) : null}
      </>
    </MaterialButton>
  );
};

export default withStyles(styles)(Button);

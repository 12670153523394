import React from 'react';
import {Container} from '@mui/material';
import {ReactComponent as IconBackArrow} from 'shared/assets/icons/arrow.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import {FlowBanner} from '../';
import flowContainerStyles from './FlowContainer.styles';
import {FlowState} from '../types';

interface FlowContainerProps {
  onGoBack: () => void;
  currentStep: FlowState;
  children: React.ReactNode;
}
const FlowContainer = ({
  onGoBack,
  currentStep,
  children,
}: FlowContainerProps) => {
  const classes = flowContainerStyles();
  return (
    <>
      <FlowBanner
        onBack={onGoBack}
        hideBackBtn={currentStep === FlowState.Success}
      />
      <Container maxWidth="xl" className={classes.container}>
        {currentStep !== FlowState.Success && (
          <div className={classes.backBtnContainer}>
            <Button
              variant={ButtonType.Icon}
              onClick={() => onGoBack()}
              aria-label="Go back"
              data-testid="backBtn">
              <IconBackArrow />
            </Button>
          </div>
        )}
        {children}
      </Container>
    </>
  );
};

export default FlowContainer;

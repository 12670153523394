import validator from 'validator';
import {
  isDevelop,
  isProduction,
  isStagingOrWebflow,
} from '../../utils/environments';

type OnboardingPanelProps = {
  redirectPath?: string;
  androidRedirectPath?: string;
};

function redirectPath(props: OnboardingPanelProps, tablet: string) {
  const isAndroidUser = hasSelectedAndroidDevice(tablet);
  if (isAndroidUser && props?.androidRedirectPath) {
    return props.androidRedirectPath;
  }

  return props?.redirectPath;
}

function hasSelectedAndroidDevice(tablet: string) {
  if (!tablet) {
    return false;
  }

  const androidTablets = [
    'android tablet',
    'amazon fire tablet',
    'samsung galaxy tablet',
  ];
  return androidTablets.includes(tablet.toLowerCase());
}

function isValidUSNumber(phone: string) {
  return validator.isMobilePhone(phone, ['en-US']);
}

function getValidationRedirectUrl(): string {
  if (isProduction()) {
    return 'https://portal.helloello.com';
  }

  if (isStagingOrWebflow()) {
    return 'https://staging-portal.helloello.com';
  }

  if (isDevelop()) {
    return 'https://develop-portal.helloello.com';
  }

  return 'http://localhost:3000';
}

export const OnboardingPanelService = {
  redirectPath,
  hasSelectedAndroidDevice,
  isValidUSNumber,
  getValidationRedirectUrl,
};

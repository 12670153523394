import {Grid, useMediaQuery} from '@mui/material';
import cN from 'classnames';
import React from 'react';
import {useLocation} from 'react-router-dom';
import {
  MOBILE_ACTIVE_KEYBOARD_HEIGHT,
  TABLET_BREAKPOINT,
} from 'shared/styles/breakpoints';
import {getPathWithoutTrailingSlash} from 'shared/utils/urlUtils';
import Logo from '../Logo/logo';
import {useAppBarStyles} from './Bar.styles';

interface BannerProps {
  action?: JSX.Element;
  logoutCta?: JSX.Element;
  isPublic?: boolean;
  children?: React.ReactChild;
  nonResponsive?: boolean;
  noBottomMargin?: boolean;
}

const AppBar = ({children, noBottomMargin}: BannerProps) => {
  const {pathname} = useLocation();
  const classes = useAppBarStyles();

  const isLoginScreen = getPathWithoutTrailingSlash(pathname) === '';
  const isKeyboardActiveOnMobile = useMediaQuery(
    `(max-height:${MOBILE_ACTIVE_KEYBOARD_HEIGHT}px) and (max-width:${TABLET_BREAKPOINT}px`,
  );

  return (
    <div
      className={cN(
        {[classes.hidden]: isKeyboardActiveOnMobile && isLoginScreen},
        {[classes.appBarMarginBottom]: !noBottomMargin},
        classes.appBar,
      )}>
      {children}
    </div>
  );
};

const Bar = ({
  logoutCta,
  action,
  isPublic,
  children,
  nonResponsive,
  noBottomMargin,
}: BannerProps) => {
  if (isPublic) {
    return (
      <AppBar noBottomMargin={noBottomMargin}>
        <Grid container alignItems="center">
          <Logo />
        </Grid>
      </AppBar>
    );
  }

  return (
    <AppBar noBottomMargin={noBottomMargin}>
      <>
        <Grid container alignItems="center">
          {action}
          <Logo nonResponsive={nonResponsive} />
          <Grid container item xs justifyContent="flex-end">
            {logoutCta}
          </Grid>
        </Grid>
        {children}
      </>
    </AppBar>
  );
};

export default Bar;

import gql from 'graphql-tag';

export const CUSTOMER_ADD_PROFILE_MUTATION = gql`
  mutation CustomerAddProfile($input: CustomerAddProfileInput!) {
    customerAddProfile(input: $input) {
      profiles {
        name
        profileCharacter
      }
    }
  }
`;

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  container: {
    width: '100%',
    maxWidth: 450,
    margin: '0 auto',
    textAlign: 'left',
  },
  checklistIcon: {
    color: theme.palette.primary.main,
  },
  checklistItem: {
    display: 'flex',
    marginBottom: theme.spacing(1),
  },
  checklistText: {
    fontSize: '0.875rem',
    marginLeft: theme.spacing(1),
  },
}));

import {Grid} from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {Book as BookType} from '../Library.types';
import Book from './Book';

type Props = {
  books: BookType[];
  maxWidth?: string;
  loadMore?: () => void;
};

const BooksList: FC<Props> = ({books, maxWidth, loadMore}: Props) => {
  const history = useHistory();
  const handleClick = (bookId?: string) => {
    return () => history.push(`/books/${bookId}/start`);
  };

  return (
    <Box>
      <Grid
        container
        sx={{maxWidth: maxWidth || '1360px', margin: '0 auto'}}
        justifyContent="center">
        {books?.map(book => (
          <Grid key={book.id} item>
            <Book book={book} handleClick={handleClick} />
          </Grid>
        ))}
      </Grid>
      {loadMore && (
        <Box
          sx={{
            maxWidth: '320px',
            margin: '0 auto',
            mt: 4,
          }}>
          <Button
            fullWidth
            onClick={loadMore}
            sx={{
              textTransform: 'none',
              fontSize: '1rem',
              fontWeight: 700,
            }}>
            Load More
          </Button>
        </Box>
      )}
    </Box>
  );
};

export default BooksList;

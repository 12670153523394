import gql from 'graphql-tag';

export const REQUEST_READING_LEVEL_ADJUSTMENT_MUTATION = gql`
  mutation RequestReadingLevelAdjustment(
    $input: RequestReadingLevelAdjustmentInput!
  ) {
    requestReadingLevelAdjustment(input: $input)
  }
`;

export const ADJUST_READING_LEVEL_MUTATION = gql`
  mutation AdjustProfileReadingLevel($input: AdjustReadingLevelInput!) {
    adjustProfileReadingLevel(input: $input) {
      id
      name
      readingLevel
      requestedLevelAdjustmentAt
    }
  }
`;

import React from 'react';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Avatar from '@mui/material/Avatar';
import styles from './ResetPasswordScreen.styles';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Typography from '@mui/material/Typography';

interface BasicFormProps extends WithStyles<typeof styles> {
  titleText: string | React.ReactNode;
  subtitleText?: string | React.ReactNode;
  customMessage?: string;
  children: React.ReactNode | string;
}
const BasicForm = ({
  titleText,
  subtitleText,
  customMessage,
  children,
  classes,
}: BasicFormProps) => {
  return (
    <>
      {!customMessage && (
        <Avatar className={classes.avatar}>
          <LockOutlinedIcon />
        </Avatar>
      )}

      <Typography component="h1" variant="h5" gutterBottom>
        {titleText}
      </Typography>

      {subtitleText && (
        <Typography component="h1" variant="body2" gutterBottom>
          {subtitleText}
        </Typography>
      )}

      {children}
    </>
  );
};

export default withStyles(styles)(BasicForm);

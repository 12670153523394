import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom';
import styles from './ProfilesScreen.styles';

import featureFlags, {FEATURE_IDS} from '../../../../shared/utils/featureFlags';
import {ReactComponent as SingleProfileIcon} from 'shared/assets/icons/iconAddChild.svg';
import {ReactComponent as WhiteBackgroundAddProfile} from 'shared/assets/icons/addProfileWhite.svg';

import useMobileLayout from '../../../utils/useMobileLayout';
import {PRIVATE_ROUTES} from '../../../../shared/components/Route/routes';
import {Profile} from '../../../../shared/types/profile';

interface props extends WithStyles<typeof styles> {
  profiles?: Profile[];
  openModal: () => void;
}

const Header = ({classes, profiles, openModal}: props) => {
  const showAddProfileButton = featureFlags.isOn(FEATURE_IDS.ADD_PROFILES);
  const isMobile = useMobileLayout();
  const history = useHistory();

  const addProfileHandler = () => {
    const canAddProfile = !profiles || (profiles && profiles.length < 3);

    if (canAddProfile) {
      history.push(PRIVATE_ROUTES.ADD_PROFILE.path);
      return;
    }

    if (isMobile) {
      history.push(PRIVATE_ROUTES.MAX_PROFILE.path);
      return;
    }

    openModal();
    return;
  };

  return (
    <Box className={classes.headerContainer}>
      <Typography component="h1" variant="h5" className={classes.header}>
        My children
      </Typography>

      {showAddProfileButton && (
        <Button
          className={classes.addChildButton}
          onClick={addProfileHandler}
          variant={isMobile ? 'text' : 'contained'}
          startIcon={
            isMobile ? (
              <SingleProfileIcon />
            ) : (
              <WhiteBackgroundAddProfile className={classes.addChildIcon} />
            )
          }>
          Add child
        </Button>
      )}
    </Box>
  );
};

export default withStyles(styles)(Header);

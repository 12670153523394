import React from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from './CustomerLoginScreen.styles';
import Button, {ButtonType} from 'shared/components/common/Button';
import {LoginStep} from './constants';

interface Props extends WithStyles<typeof styles> {
  currentStep: LoginStep;
  setCurrentStep: (s: LoginStep) => void;
}

const AlternativeLoginMethodButton = ({
  classes,
  currentStep,
  setCurrentStep,
}: Props) => {
  const isPhoneLogin = currentStep === LoginStep.PhoneStep;
  const buttonText = isPhoneLogin
    ? 'Sign in with email instead'
    : 'Sign in with phone number instead';

  const onClick = () => {
    setCurrentStep(isPhoneLogin ? LoginStep.EmailStep : LoginStep.PhoneStep);
  };
  return (
    <Button
      type="button"
      onClick={onClick}
      variant={ButtonType.Inline}
      className={classes.loginOptionButton}>
      {buttonText}
    </Button>
  );
};

export default withStyles(styles)(AlternativeLoginMethodButton);

import Box from '@mui/material/Box';
import TabContext from '@mui/lab/TabContext';
import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import ProfileBooksList from './ProfileBookList';
import ProfileSelector from './ProfileSelector';

type props = {
  profiles?: {id: string; name: string}[];
};

const WebReader: FC<props> = ({profiles}: props) => {
  const {profileId = profiles?.at(0)?.id} = useParams<{profileId: string}>();

  return (
    <Box>
      <TabContext value={profileId || ''}>
        <ProfileSelector profileId={profileId} profiles={profiles} />
        <ProfileBooksList profiles={profiles} />
      </TabContext>
    </Box>
  );
};

export default WebReader;

import {Typography} from '@mui/material';
import clsx from 'clsx';
import {subscriptionIcon} from 'customerPortal/utils/bundleIcons';
import React, {FC} from 'react';
import {subscriptionInfoStyles} from './AccountSubscription.styles';
import {QueryResult} from './AccountSubscription.types';
import {toTitleCase} from './AcountSubscription.service';

type Props = QueryResult & {
  isInactive?: boolean;
  productType: string | undefined;
};

export const SubscriptionInfo: FC<Props> = ({
  title,
  description,
  name,
  isInactive,
  productType,
}: Props) => {
  const classes = subscriptionInfoStyles();
  const formatedTitle = toTitleCase(title);
  return (
    <span className={classes.content}>
      <img
        src={subscriptionIcon(productType, name)}
        alt=""
        className={clsx(classes.icon, isInactive && classes.inactiveIcon)}
      />
      <span className={classes.desc}>
        <Typography component="h1" variant="h5" className={classes.name}>
          {formatedTitle}
        </Typography>
        <Typography component="p" variant="h6" className={classes.planSubtitle}>
          {description}
        </Typography>
      </span>
    </span>
  );
};

import createStyles from '@mui/styles/createStyles';
import {makeStyles} from '@mui/styles';
import {TABLET_BREAKPOINT} from '../../styles/breakpoints';
import * as palette from '../../styles/palette';

export default makeStyles(theme => {
  return createStyles({
    qrCodeContainer: {
      padding: '24px 16px',
      display: 'flex',
      borderRadius: '16px',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        padding: '24px',
        flexDirection: 'row',
      },
    },
    linkContainer: {
      padding: '24px 16px',
      borderRadius: '16px',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        padding: '24px',
        display: 'flex',
      },
    },
    iconContainer: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        minWidth: '200px',
      },
    },
    sectionHeader: {
      color: palette.gray900,
      fontSize: '22px',
      fontWeight: 700,
      lineHeight: '140%',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        textAlign: 'left',
      },
    },
    textContainer: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginLeft: '24px',
        textAlign: 'start',
        width: '100%',
      },
    },
    qrCodeIconsContainer: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'flex',
        alignItems: 'center',
        marginTop: '12px',
      },
    },
    bodyText: {
      color: palette.gray900,
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.16px',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        textAlign: 'left',
      },
    },
    qrCodeIconText: {
      color: palette.gray900,
      textAlign: 'center',
      fontSize: '13px',
      fontWeight: 400,
      lineHeight: '170%',
      letterSpacing: '0.13px',
    },
    qrCodeIconContainer: {
      marginInline: '12px',
    },
    shareLinkButton: {
      width: '100%',
      paddingTop: '14px',
      paddingBottom: '14px',
      borderRadius: '50px',
      border: `1px solid ${palette.blue500}`,
      backgroundColor: palette.white,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        maxWidth: '256px',
      },
    },
    linkHighlightText: {
      color: palette.teal500,
      fontWeight: 700,
    },
    shareLinkButtonText: {
      fontSize: '16px',
      fontWeight: 800,
      color: palette.blue500,
      letterSpacing: '-0.048px',
    },
    shareIcon: {
      marginRight: '12px',
      color: palette.blue500,
    },
  });
});

import React, {useEffect, useState} from 'react';
import {useParams} from 'react-router-dom';
import {
  Alert,
  Box,
  Button,
  Backdrop,
  CircularProgress,
  Snackbar,
} from '@mui/material';
import {useMutation} from '@apollo/client';
import Section from '../../../../components/Section';
import Header from '../Components/Header';
import {useProfile} from '../EditProfile/EditProfileScreen.service';
import RequestAdjustmentBookInfoScreen from './AdjustReadingLevel.book-info.screen';
import RequestAdjustmentSuccessScreen from './AdjustReadingLevel.self-adjustment-success.screen';
import RequestAdjustmentSelectLevelScreen from './AdjustReadingLevel.select-level.screen';
import RequestAdjustmentLevelSelectionScreen from './AdjustReadingLevel.level-selection.screen';
import {ADJUST_READING_LEVEL_MUTATION} from './AdjustReadingLevel.query';
import AdjustReadingLevelService from './AdjustReadingLevel.service';
import RequestAdjustmentConfirmSelectionScreen from './AdjustReadingLevel.confirm-selection.screen';
import {AdjustmentRequest} from './AdjustReadingLevel.types';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from '../ProfilesScreen.styles';
import AnalyticsService from 'analytics/Analytics.service';

const SelfAdjustReadingLevel = ({classes}: WithStyles<typeof styles>) => {
  const {profileId} = useParams<{profileId?: string}>();
  const {loading, data, error} = useProfile(profileId);
  const [activeStep, setActiveStep] = useState(0);
  const [saveLoading, setSaveLoading] = useState<boolean>(false);
  const [alertError, setAlertError] = useState<string>('');
  const [errorVisible, setErrorVisible] = useState<boolean>(false);
  const [selectedDifficulty, setSelectedDifficulty] =
    useState<AdjustmentRequest>();
  const [newReadingLevel, setNewReadingLevel] = useState<string>();

  const [adjustReadingLevelMutation, {loading: isAdjustLevelLoading}] =
    useMutation(ADJUST_READING_LEVEL_MUTATION);

  const profileName = data?.name;
  const currentProfileReadingLevel = data?.readingLevel;

  useEffect(() => {
    if (isAdjustLevelLoading) {
      setSaveLoading(true);
    } else {
      setSaveLoading(false);
    }
  }, [isAdjustLevelLoading]);

  const handleProcess = async () => {
    setSaveLoading(true);

    const input = {
      profileId: profileId as string,
      newLevel: newReadingLevel as string,
    };

    if (!input.profileId || !input.newLevel) {
      setAlertError(`Failed: profileId or newLevel is missing, please retry.`);
      setErrorVisible(true);
      return;
    }

    try {
      AnalyticsService.trackLevelAdjustmentCompleted(
        input.profileId,
        input.newLevel,
      );

      await AdjustReadingLevelService.adjustReadingLevel(
        adjustReadingLevelMutation,
        input,
      );

      setActiveStep(prevActiveStep => prevActiveStep + 1);
    } catch (error: any) {
      setAlertError(`Failed: ${error?.message}`);
      setErrorVisible(true);
    }
  };

  const handleNext = () => {
    if (activeStep === 3) {
      handleProcess();
    } else {
      setActiveStep(prevActiveStep => prevActiveStep + 1);
    }
  };

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1);
  };

  const isNextEnabled = (step: number) => {
    switch (step) {
      case 1:
        return selectedDifficulty !== undefined;
      case 2:
        return newReadingLevel !== undefined;
      default:
        return true;
    }
  };

  const renderStep = (step: number) => {
    switch (step) {
      case 0:
        return <RequestAdjustmentBookInfoScreen profileName={profileName} />;
      case 1:
        return (
          <RequestAdjustmentSelectLevelScreen
            profileName={profileName}
            selectedDifficulty={selectedDifficulty}
            setSelectedDifficulty={setSelectedDifficulty}
          />
        );
      case 2:
        return (
          <RequestAdjustmentLevelSelectionScreen
            currentProfileReadingLevel={currentProfileReadingLevel}
            newReadingLevel={newReadingLevel}
            profileName={profileName}
            selectedDifficulty={selectedDifficulty}
            setNewReadingLevel={setNewReadingLevel}
          />
        );
      case 3:
        return (
          <RequestAdjustmentConfirmSelectionScreen profileName={profileName} />
        );
      case 4:
        return <RequestAdjustmentSuccessScreen />;
      default:
        return null;
    }
  };

  const handleErrorClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorVisible(false);
  };

  return (
    <Section
      titleSection={<Header oldPath={profileName} currentPath="Adjust Level" />}
      loading={loading}
      error={error ? 'Error loading profile' : ''}>
      {renderStep(activeStep)}
      {activeStep !== 4 && (
        <Box>
          <Button
            variant="contained"
            color="primary"
            disabled={activeStep === 0}
            onClick={handleBack}
            sx={{mr: 2}}
            className={classes.navigationButton}>
            BACK
          </Button>
          <Button
            variant="contained"
            color="primary"
            disabled={!isNextEnabled(activeStep)}
            onClick={handleNext}
            className={classes.navigationButton}>
            {activeStep === 3 ? 'CONFIRM LEVEL CHANGE' : 'NEXT'}
          </Button>
        </Box>
      )}
      <Backdrop
        className={classes.backdrop}
        open={saveLoading}
        onClick={() => setSaveLoading(false)}>
        <CircularProgress color="primary" />
      </Backdrop>

      <Snackbar open={errorVisible} onClose={handleErrorClose}>
        <Alert onClose={handleErrorClose} severity="error">
          {alertError}
        </Alert>
      </Snackbar>
    </Section>
  );
};

export default withStyles(styles)(SelfAdjustReadingLevel);

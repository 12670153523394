import gql from 'graphql-tag';

export const QUERY_PROFILES = gql`
  query Products {
    profiles {
      name
      readingLevel
    }
    subscription {
      name
      nextCycleDate
    }
  }
`;

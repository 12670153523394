import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {gray500, gray900} from '../../../../shared/styles/palette';
import {Book as BookType} from '../Library.types';

const largerCardBreakPoint = '@media (min-width: 380px)';

type Props = {
  book: BookType;
  handleClick: (bookId?: string) => () => void;
};

const Book: FC<Props> = ({book, handleClick}: Props) => {
  return (
    <Box
      onClick={handleClick(book.id || '')}
      sx={{
        mr: 2,
        mt: 2,
        maxWidth: '120px',
        cursor: 'pointer',
        [largerCardBreakPoint]: {
          maxWidth: '150px',
        },
      }}>
      <Box sx={{minHeight: '100px'}}>
        <img src={book.coverPhotoURL} alt={book.title} width="100%" />
      </Box>
      <Typography sx={{fontSize: '1rem', fontWeight: 700, color: gray900}}>
        {book.title}
      </Typography>
      <Typography sx={{fontSize: '0.8125rem', fontWeight: 600, color: gray500}}>
        by {book.author}
      </Typography>
    </Box>
  );
};

export default Book;

import {Grid} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {WithStyles} from '@mui/styles';
import React from 'react';
import {ReactComponent as IconBackArrow} from 'shared/assets/icons/arrow.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import {Bar, DesktopBanner} from 'customerPortal/components/Banner';
import styles from './FlowBanner.styles';

interface Props extends WithStyles<typeof styles> {
  onBack: (state: any) => void;
  hideBackBtn: boolean;
}

const FlowBanner = ({classes, onBack, hideBackBtn}: Props) => {
  const showMobileBanner = useMobileLayout();

  return (
    <>
      {showMobileBanner ? (
        <Bar
          action={
            <Grid container item xs>
              {!hideBackBtn && (
                <Button
                  variant={ButtonType.Icon}
                  onClick={onBack}
                  aria-label="Go back"
                  className={classes.button}>
                  <IconBackArrow />
                </Button>
              )}
            </Grid>
          }
        />
      ) : (
        <DesktopBanner />
      )}
    </>
  );
};

export default withStyles(styles)(FlowBanner);

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {iconsBreakpoint, partialHorizontalBreakpoint} from '../BreakPoints';

function containerStyles(props: {isDigital: boolean}) {
  const styles = {
    margin: '0 auto',
    width: '150px',
    maxWidth: '370px',
    [partialHorizontalBreakpoint]: {
      width: '100%',
      height: '50px',
      alignSelf: 'center',
      margin: '0',
    },
  };

  if (props.isDigital) {
    return styles;
  }

  return {
    ...styles,
    [iconsBreakpoint]: {
      width: '270px',
      display: 'flex',
      flexWrap: 'wrap' as any,
      gap: '8px 16px',
    },
    [partialHorizontalBreakpoint]: {
      width: '100%',
      height: '120px',
      alignSelf: 'center',
      margin: '0',
    },
  };
}

export default makeStyles(theme =>
  createStyles({
    container: containerStyles,
  }),
);

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import ExternalNavigation from '../../components/ExternalNavigation/ExternalNavigation';
import ExternalBook from '../../components/WebReader/ExternalBook';

const ExternalReaderScreen: FC = () => {
  return (
    <Box>
      <ExternalNavigation />
      <ExternalBook />
    </Box>
  );
};

export default ExternalReaderScreen;

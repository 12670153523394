import {Snackbar} from '@mui/material';
import MaterialAlert from '@mui/material/Alert';
import React, {SyntheticEvent, useState} from 'react';

interface Props {
  error: string | null;
  autoHide?: boolean;
}

const Alert = ({error, autoHide = true}: Props) => {
  const [errorMsg, setErrorMsg] = useState<Props['error']>(error);

  const handleErrorClose = (event: SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    setErrorMsg('');
  };

  return (
    <Snackbar
      data-testid="alert"
      open={!!errorMsg}
      autoHideDuration={autoHide ? 3000 : null}
      onClose={handleErrorClose}>
      <MaterialAlert onClose={handleErrorClose} severity="error">
        {error}
      </MaterialAlert>
    </Snackbar>
  );
};

export default Alert;

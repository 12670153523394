import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import {markupComponents} from '../../ProductSelectionPanel/Choices/Choices.service';
import Benefits from './Benefits';

type Props = {
  image: string;
  title: string;
  benefits: string[];
};

const Header: FC<Props> = ({image, title, benefits}) => {
  return (
    <Box display="flex" width="100%">
      <Box sx={{width: 'calc(100% - 100px)', mr: 2}}>
        <Box>
          <Typography
            variant="h5"
            sx={{
              fontWeight: 800,
              fontSize: '1.875rem',
              color: palette.blue500,
              textAlign: 'left',
            }}>
            {markupComponents(title)}
          </Typography>
        </Box>

        <Benefits benefits={benefits} />
      </Box>
      <Box sx={{maxWidth: '200px'}}>
        <img src={image} alt={title} width="100%" />
      </Box>
    </Box>
  );
};

export default Header;

import createStyles from '@mui/styles/createStyles';

import {teal500, orange500, blue500, turquoise900} from 'shared/styles/palette';

const styles = () =>
  createStyles({
    chipActive: {
      borderColor: teal500,
      color: teal500,
      fontWeight: 700,
    },
    chipDue: {
      borderColor: orange500,
      color: orange500,
      fontWeight: 700,
    },
    chipKept: {
      backgroundColor: blue500,
      color: 'white',
      width: 'fit-content',
    },
    bookOrderChip: {
      backgroundColor: turquoise900,
      color: 'white',
      width: 'fit-content',
    },
    bookOrderDue: {
      backgroundColor: orange500,
      color: 'white',
      width: 'fit-content',
    },
  });

export default styles;

import makeStyles from '@mui/styles/makeStyles';
import {partialHorizontalBreakpoint} from '../BreakPoints';

const fontSize = '0.75rem';

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    marginBottom: theme.spacing(1),
    minWidth: '100px',
    height: '50px',
    [partialHorizontalBreakpoint]: {
      marginBottom: '0',
    },
  },
  device: {
    fontSize,
    fontWeight: 'bold',
  },
  icon: {
    width: '40px',
  },
  iconContainer: {
    marginRight: theme.spacing(1),
  },
  textContainer: {
    textAlign: 'left',
  },
  store: {
    fontSize,
  },
}));

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import Body from './Body';
import {TrialDetails} from '../TrialSelection.types';
import Header from './Header';
import {NumberParam, StringParam, useQueryParam} from 'use-query-params';
import ImageHeader from './ImageHeader';
import featureFlags, {FEATURE_IDS} from '../../../utils/featureFlags';

type Props = {
  details: TrialDetails;
  onNext: () => void;
  onValidationChanged: (isValid: boolean) => void;
};

const Section: FC<Props> = ({details, onNext, onValidationChanged}) => {
  const useUpdatedTrialPicker = featureFlags.isOn(
    FEATURE_IDS.USE_UPDATED_TRIAL_PICKER,
  );

  const border = `2px solid ${palette.turquoise900}`;
  const [, setSelectedProduct] = useQueryParam('priceId', StringParam);
  const [, setChargeableTrialAmount] = useQueryParam(
    'chargeableTrialAmount',
    NumberParam,
  );

  const onSelectProduct = (priceId: string) => {
    setSelectedProduct(priceId);
    onValidationChanged(true);

    const title = details.choices?.find(
      choice => choice.priceId === priceId,
    )?.ctaText;

    setChargeableTrialAmount(title && title.includes('$1') ? 1 : undefined);

    window.analytics.track('Onboarding SelectTrialPlan', {
      priceId,
      title,
    });

    onNext();
  };

  const showImageHeader = details.desktopImage || details.mobileImage;

  return (
    <Box
      component="div"
      sx={{
        padding: 1,
        paddingTop: 2,
        borderRadius: '16px',
        minHeight: '120px',
        cursor: 'pointer',
        maxWidth: '600px',
        margin: '0 -12px',
        position: 'relative',
        border: useUpdatedTrialPicker ? 'none' : border,
        '@media (min-width: 450px)': {
          margin: '0 auto',
        },
      }}>
      <Box
        sx={{
          padding: 2,
          height: '100%',
        }}>
        {showImageHeader && (
          <ImageHeader
            desktopImage={details.desktopImage}
            mobileImage={details.mobileImage}
          />
        )}
        {!showImageHeader && (
          <Header
            image={details.productImage}
            title={details.title}
            benefits={details.benefits}
          />
        )}
        <Body
          benefits={details.benefits}
          choices={details.choices}
          body={details.body}
          onSelected={onSelectProduct}></Body>
      </Box>
    </Box>
  );
};

export default Section;

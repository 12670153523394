import createStyles from '@mui/styles/createStyles';
import * as palette from 'shared/styles/palette';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const useStyles = (theme: Theme) =>
  createStyles({
    content: {
      paddingTop: theme.spacing(1),
      minHeight: '70vh',
      flexDirection: 'column',
      justifyContent: 'space-between',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        paddingTop: theme.spacing(4),
        minHeight: 'auto',
      },
    },
    topContainer: {
      flex: 1,
    },
    title: {
      padding: theme.spacing(2),
    },
    container: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
    button: {
      width: '100%',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        width: 'auto',
        paddingLeft: theme.spacing(8),
        paddingRight: theme.spacing(8),
      },
      '&:disabled': {
        backgroundColor: palette.gray100,
      },
    },
    skipButton: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        width: 'auto',
      },
      color: palette.gray700,
      backgroundColor: palette.white,
      '&:hover, &:focus': {
        backgroundColor: palette.gray,
      },
    },
    activeButton: {
      color: palette.turquoise900,
    },
    buttonContainer: {
      paddingLeft: theme.spacing(),
      paddingRight: theme.spacing(1),
      paddingTop: theme.spacing(0),
      flexDirection: 'row',
      justifyContent: 'center',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        paddingTop: theme.spacing(5),
        paddingBottom: theme.spacing(5),
      },
    },
    leftContainer: {
      flex: 1,
      alignItems: 'center',
    },
    rightContainer: {
      flex: 1,
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'flex-end',
    },
    textField: {
      display: 'flex',
      marginTop: theme.spacing(2),
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginLeft: theme.spacing(8),
        marginRight: theme.spacing(8),
        marginBottom: theme.spacing(0),
      },
    },
    hidden: {
      visibility: 'hidden',
    },
  });

export default useStyles;

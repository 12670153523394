import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {CTAVariant} from '../Product.types';
import Benefits from './Benefits';
import CTA from './CTA';
import PreviousBenefits from './PreviousBenefits';

type Props = {
  benefits: string[];
  ctaFinePrint: string;
  ctaText: string;
  ctaVariant: CTAVariant;
  onSelected: () => void;
  previousBenefitsText: string;
};

const Body: FC<Props> = ({
  benefits,
  ctaFinePrint,
  ctaText,
  ctaVariant,
  onSelected,
  previousBenefitsText,
}) => {
  return (
    <Box
      sx={{
        mt: 2,
        height: '100%',
        position: 'relative',
        pb: '100px',
      }}>
      <PreviousBenefits previousBenefitsText={previousBenefitsText} />
      <Benefits benefits={benefits} />
      <CTA
        onSelected={onSelected}
        ctaFinePrint={ctaFinePrint}
        ctaText={ctaText}
        ctaVariant={ctaVariant}
      />
    </Box>
  );
};

export default Body;

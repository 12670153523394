import {useEffect, useState} from 'react';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const useMobileLayout = (): boolean => {
  const [isMobile, setIsMobile] = useState<boolean>(
    window.innerWidth < TABLET_BREAKPOINT,
  );

  useEffect(() => {
    const handleResize = () => {
      const isNewSizeMobile = window.innerWidth < TABLET_BREAKPOINT;

      if (isNewSizeMobile !== isMobile) {
        setIsMobile(window.innerWidth < TABLET_BREAKPOINT);
      }
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isMobile]);

  return isMobile;
};

export default useMobileLayout;

import makeStyles from '@mui/styles/makeStyles';
import * as palette from 'shared/styles/palette';

export default makeStyles(theme => ({
  fieldContainer: {
    maxWidth: '400px',
    margin: '0 auto',
  },
  numberFieldContainer: {
    width: '250px',
  },
  textField: {
    [`& fieldset`]: {
      borderRadius: 8,
      borderColor: palette.gray300,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
  },
}));

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import Alert from '../../../shared/components/common/Alert/Alert';
import {Book as BookType} from './Book.type';
import BookHeader from './BookHeader';
import BookNavigation from './BookNavigation';
import BookPage from './BookPage';

type Props = {
  book?: BookType;
  error?: Error;
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  goBack?: () => void;
  onFinish?: () => void;
};

const Book: FC<Props> = ({
  book,
  error,
  pageNumber,
  setPageNumber,
  goBack,
  onFinish,
}: Props) => {
  return (
    <Box>
      <BookHeader
        bookTitle={book?.title}
        pageNumber={pageNumber}
        pageLength={book?.pages?.length}
        goBack={goBack}
        onFinish={onFinish}
      />
      <BookPage book={book} pageNumber={pageNumber} />
      <BookNavigation
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
        pageLength={book?.pages?.length}
      />
      {error && (
        <Alert
          error="Unable to retrieve book. Please check your internet connection and refresh"
          autoHide={false}
        />
      )}
    </Box>
  );
};

export default Book;

const magicCTAHook =
  'https://hook.us1.make.com/dh24oa3q01xrxubaj9qbxw5njtz9ax4s';
export async function handleEmailSubmit(email: string) {
  const payload = {
    flowName: 'ExternalFinishBook',
    customer: {
      email: email,
    },
  };

  try {
    const response = await fetch(magicCTAHook, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });

    if (!response.ok) {
      console.error(`Network response was not ok: ${response.statusText}`);
    }
  } catch (error) {
    console.error('Error sending payload to Make:', error);
  }
}

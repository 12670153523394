import Box from '@mui/material/Box';
import React, {FC} from 'react';
import amazonAppStoreIcon from '../../../assets/amazon-appstore-icon.png';
import appStoreIcon from '../../../assets/appstore-icon.png';
import playstoreIcon from '../../../assets/playstore-icon.png';
import AppStorePromptIcon from './Icon';
import useStyles from './Icons.styles';

const ipadIcon = {
  src: appStoreIcon,
  alt: 'App Store Icon',
  device: 'iPad',
  store: 'App Store',
};

const otherIcons = [
  {
    src: playstoreIcon,
    alt: 'Play Store Icon',
    device: 'Android Tablet',
    store: 'Play Store',
  },
  {
    src: amazonAppStoreIcon,
    alt: 'Amazon App Store Icon',
    device: 'Fire Tablet',
    store: 'App Store',
  },
];

type props = {
  isDigital: boolean;
};

const AppStorePromptIcons: FC<props> = ({isDigital}) => {
  const classes = useStyles({isDigital});

  return (
    <Box className={classes.container}>
      <AppStorePromptIcon icon={ipadIcon} />
      {!isDigital &&
        otherIcons.map((icon, index) => (
          <AppStorePromptIcon key={index} icon={icon} />
        ))}
    </Box>
  );
};

export default AppStorePromptIcons;

import React from 'react';
import Typography from '@mui/material/Typography';
import useStyles from './ReferralLink.styles';

type Props = {
  text?: string;
};

const Header = ({text}: Props) => {
  const classes = useStyles();

  return (
    <Typography className={classes.sectionHeader}>
      {text || 'Share the gift of reading'}
    </Typography>
  );
};

export default Header;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import useStyles from './Or.styles';

type props = {
  isDigital: boolean;
};

const Or: FC<props> = ({isDigital}) => {
  const classes = useStyles({isDigital});

  return (
    <Box className={classes.or}>
      <Typography>or</Typography>
    </Box>
  );
};

export default Or;

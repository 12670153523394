import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, {FC} from 'react';
import {Product} from '../Product.types';
import useStyles from '../ProductSelectionPanel.styles';

type props = {
  choices: Product[];
  selectedProduct: string;
  onSelectProduct: (priceId: string, step?: number) => void;
};

const UpgradeChoices: FC<props> = ({choices, onSelectProduct}) => {
  const classes = useStyles();

  return (
    <Box>
      <Grid container justifyContent="center" gap={1}>
        {choices.map((product, index) => (
          <Grid
            item
            key={index}
            xs={12}
            sm={4}
            md={3}
            lg={3}
            className={clsx(
              classes.tile,
              classes.tileSelectUpdated,
              classes.tileWithoutImageOrCheckbox,
            )}
            onClick={() => onSelectProduct(product.priceId, product.goToStep)}>
            <Typography
              component="h4"
              className={clsx(
                classes.tileTitle,
                classes.tileTitleSelectUpdated,
              )}>
              {product.title}
            </Typography>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default UpgradeChoices;

import React from 'react';
import {Link, useParams} from 'react-router-dom';
import {ReactComponent as IconBackArrow} from 'shared/assets/icons/arrow.svg';
import Section from 'customerPortal/components/Section';
import {CircularProgress, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './BookBrowseScreen.styles';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {QUERY__PURCHASE_BOOK_ORDERS} from './BookBrowseScreen.query';
import {useQuery} from '@apollo/react-hooks';
import Alert from 'shared/components/common/Alert/Alert';
import HorizontalBookList from './HorizontalBookList';
import VerticalBookList from './VerticalBookList';
import {ProfileReadingLevel} from 'shared/types/purchases';
import PurchaseStatusChip from 'customerPortal/components/StatusChip/PurchaseStatusChip';
const BookBrowseScreen = ({classes}: WithStyles<typeof styles>) => {
  const {subscriptionPeriodId} = useParams<{subscriptionPeriodId: string}>();
  const isMobile = useMobileLayout();

  const {data, error} = useQuery<{purchaseBookOrders: any}>(
    QUERY__PURCHASE_BOOK_ORDERS,
    {
      variables: {
        subscriptionPeriodId,
      },
      onError: error => console.error('Book browse::', error),
    },
  );

  if (error) {
    console.log(error);
    return <Alert error={'Unable to load books'} autoHide={false} />;
  }

  if (data?.purchaseBookOrders) {
    const bookOrdersReturned = data.purchaseBookOrders.bookOrders;
    const {
      status,
      subscriptionPeriod: {month, readingLevels},
    } = data.purchaseBookOrders.purchase;
    // Capitalise first letter of status in book orders
    const bookOrders = bookOrdersReturned?.map((bookOrder: any) => {
      return {
        ...bookOrder,
        status:
          bookOrder.status.charAt(0).toUpperCase() + bookOrder.status.slice(1),
      };
    });

    const readingLevelsWithoutName = readingLevels.map(
      ({readingLevel}: ProfileReadingLevel) => readingLevel,
    );

    const listedReadingLevels = Array.from(new Set(readingLevelsWithoutName));

    return (
      <Section
        titleSection={
          <Typography component="h1" variant="h5" className={classes.title}>
            Month {month} <PurchaseStatusChip status={status} />
          </Typography>
        }
        subtitle={
          <Typography component="p" className={classes.subtitle}>
            {listedReadingLevels.map((readingLevel, index: number) => {
              const punctuationAfterReadingLevel =
                index === listedReadingLevels.length - 1
                  ? ''
                  : index === listedReadingLevels.length - 2
                  ? ' & '
                  : ', ';
              return (
                <span key={index}>
                  <span
                    className={
                      classes.readingLevel
                    }>{`Level ${readingLevel} ${punctuationAfterReadingLevel}`}</span>
                </span>
              );
            })}
          </Typography>
        }>
        <div className={classes.navigation}>
          {!isMobile && (
            <Link to="." aria-label="Go back" className={classes.link}>
              <IconBackArrow />
            </Link>
          )}
        </div>

        {isMobile ? (
          <VerticalBookList books={bookOrders} profileObjects={readingLevels} />
        ) : (
          <HorizontalBookList
            books={bookOrders}
            profileObjects={readingLevels}
          />
        )}
      </Section>
    );
  }

  return <CircularProgress style={{margin: '50px auto', display: 'block'}} />;
};

export default withStyles(styles)(BookBrowseScreen);

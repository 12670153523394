import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import React, {FC} from 'react';
import useStyles from './BookNavigation.styles';

type props = {
  pageNumber: number;
  setPageNumber: (pageNumber: number) => void;
  pageLength?: number;
};

const BookNavigation: FC<props> = ({
  pageNumber,
  setPageNumber,
  pageLength,
}: props) => {
  const classes = useStyles();

  const nextPage = () => {
    if (!pageLength) {
      return;
    }

    if (pageNumber === pageLength - 1) {
      return;
    }
    setPageNumber(pageNumber + 1);
  };

  const previousPage = () => {
    if (pageNumber === 0) {
      return;
    }
    setPageNumber(pageNumber - 1);
  };

  const isFirstPage = pageNumber === 0;
  const isLastPage = pageLength && pageNumber === pageLength - 1;

  return (
    <Box className={classes.navigate}>
      {!isFirstPage && (
        <IconButton
          aria-label="back"
          className={classes.navigateBefore}
          onClick={previousPage}
          size="large">
          <NavigateBeforeIcon fontSize="large" />
        </IconButton>
      )}
      {!isLastPage && (
        <IconButton
          aria-label="next"
          className={classes.navigateNext}
          onClick={nextPage}
          size="large">
          <NavigateNextIcon fontSize="large" />
        </IconButton>
      )}
    </Box>
  );
};

export default BookNavigation;

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React, {FC} from 'react';
import {white} from '../../../shared/styles/palette';

type Props = {
  link: string;
  label: string;
};

const ExternalLink: FC<Props> = ({link, label}: Props) => {
  return (
    <Box mt={6}>
      <Link
        href={link}
        sx={{
          fontSize: '1.0625rem',
          fontWeight: 'bold',
          textDecoration: 'none',
          color: white,
        }}>
        {label}
      </Link>
    </Box>
  );
};

export default ExternalLink;

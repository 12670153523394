import React from 'react';
import {BooleanParam, useQueryParam} from 'use-query-params';
import {ShareMethod, shareableLinkParam} from './ShareableLink.types';
import CopyComponent from '../common/CopyComponent/CopyComponent';
import {useAssignRewardBooks} from './ShareableLink.hooks';
import AnalyticsService, {
  ShareMethod as AnalyticsShareMethod,
} from '../../../analytics/Analytics.service';

type Props = {
  link: string;
  dialogueText?: string;
};

const Link = ({link, dialogueText}: Props) => {
  const [, setQueryValue] = useQueryParam(shareableLinkParam, BooleanParam);
  const {assignRewardBooks} = useAssignRewardBooks();

  const onShare = async (copied: boolean) => {
    setQueryValue(copied);
    if (!copied) {
      return;
    }
    AnalyticsService.trackCopiedShareLink(AnalyticsShareMethod.NativeShare);
    await assignRewardBooks(ShareMethod.copyLink);
  };

  const onCopyClick = async (copied: boolean) => {
    setQueryValue(copied);
    if (!copied) {
      return;
    }

    AnalyticsService.trackCopiedShareLink(AnalyticsShareMethod.CopyToClipboard);
    await assignRewardBooks(ShareMethod.copyLink);
  };

  return (
    <CopyComponent
      link={link}
      dialogueText={dialogueText}
      onShare={onShare}
      onCopyClick={onCopyClick}
      copySheetStyle={{maxWidth: '342px'}}
      shareButtonText="Share with a friend"
      buttonType="primary"
    />
  );
};

export default Link;

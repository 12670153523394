import React from 'react';
import Typography from '@mui/material/Typography';

type Props = {
  text: string;
};

const BodyText = ({text}: Props) => {
  return (
    <Typography
      variant="body2"
      mb="20px"
      sx={{
        color: 'text',
        fontWeight: 400,
        fontSize: '1rem',
        letterSpacing: '1%',
      }}>
      {text}
    </Typography>
  );
};

export default BodyText;

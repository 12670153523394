import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    img: {
      marginRight: theme.spacing(5),
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        margin: 0,
      },
    },
    card: {
      display: 'flex',
      maxWidth: '700px',
      margin: `${theme.spacing(4)} 0`,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        margin: `0 0 ${theme.spacing(3)} 0`,
        flexDirection: 'column',
        alignItems: 'center',
        textAlign: 'center',
      },
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
    },
    desc: {
      fontWeight: 400,
      fontSize: `1.25rem`,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: `1rem`,
      },
    },
    bundle: {
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: `1.375rem`,
      },
    },
    button: {
      width: '100%',
    },
  });

export default styles;

import Box from '@mui/material/Box';
import {useState} from 'react';
import {LightTooltip} from './LightToolTip';
import useStyles from './Tooltip.styles';

const leaveTouchDelay = 30 * 1000;

const ToolTip = ({children, text}) => {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  const toggleToolTip = () => {
    setOpen(!open);
  };

  return (
    <LightTooltip
      title={text}
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      leaveTouchDelay={leaveTouchDelay}>
      <Box className={classes.infoContainer} onClick={toggleToolTip}>
        {children}
      </Box>
    </LightTooltip>
  );
};

export default ToolTip;

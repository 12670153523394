import Box from '@mui/material/Box';
import React, {FC, useState} from 'react';
import {blue500} from '../../../shared/styles/palette';
import ExternalDrawer from './ExternalDrawer';
import ExternalLogo from './ExternalLogo';
import ExternalMenuButton from './ExternalMenuButton';
import ExternalNavigationLinks from './ExternalNavigationLinks';

const ExternalNavigation: FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <Box>
      <Box
        p={2}
        sx={{
          display: 'flex',
          zIndex: '1500',
          position: 'fixed',
          width: '100%',
          top: 0,
          left: 0,
          backgroundColor: openDrawer ? blue500 : 'white',
        }}>
        <Box>
          <ExternalLogo />
        </Box>
        <Box
          sx={{
            display: 'none',
            marginLeft: 'auto',
            '@media (min-width: 1140px)': {display: 'block'},
          }}>
          <ExternalNavigationLinks />
        </Box>
        <Box
          sx={{
            marginLeft: 'auto',
            '@media (min-width: 1140px)': {display: 'none'},
          }}>
          <ExternalMenuButton
            openDrawer={openDrawer}
            setOpenDrawer={setOpenDrawer}
          />
        </Box>
      </Box>
      <ExternalDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer} />
    </Box>
  );
};

export default ExternalNavigation;

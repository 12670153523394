import gql from 'graphql-tag';

export const QUERY__PURCHASE_BOOK_ORDERS = gql`
  query PurchaseBookOrders($subscriptionPeriodId: ID!) {
    purchaseBookOrders(subscriptionPeriodId: $subscriptionPeriodId) {
      purchase {
        status
        subscriptionPeriod {
          id
          month
          readingLevels {
            name
            readingLevel
          }
        }
      }
      bookOrders {
        status
        author
        coverPhotoURL
        title
        isbn
      }
    }
  }
`;

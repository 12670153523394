import {gql} from '@apollo/client';

export const PARTIAL_ONBOARDING_MUTATION = gql`
  mutation PartialOnboarding(
    $flowName: String!
    $content: JSON!
    $onboardingResponseUid: ID
  ) {
    partialOnboarding(
      flowName: $flowName
      content: $content
      onboardingResponseUid: $onboardingResponseUid
    ) {
      confirmationId
    }
  }
`;

export const ONBOARDING_TRIAL_USER_MUTATION = gql`
  mutation OnboardingTrialUser(
    $flowName: String!
    $phone: String!
    $paymentMethod: String!
    $content: JSON!
    $name: String
    $email: String
    $uid: String
  ) {
    onboardingTrialUser(
      flowName: $flowName
      phoneNumber: $phone
      paymentMethod: $paymentMethod
      content: $content
      name: $name
      email: $email
      uid: $uid
    ) {
      confirmationId
    }
  }
`;

export const ONBOARD_PARTIAL_ACCOUNT_MUTATION = gql`
  mutation OnboardPartialAccount($input: PartialOnboardingInput!) {
    onboardPartialAccount(input: $input) {
      confirmationId
    }
  }
`;

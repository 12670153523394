import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {turquoise50, white} from '../../../styles/palette';
import {fullHorizontalBreakpoint} from '../BreakPoints';

function orStyles(props: {isDigital: boolean}) {
  const styles = {
    backgroundColor: turquoise50,
    width: '60px',
    height: '50px',
    margin: '0 auto',
    padding: '8px 16px',
    borderRadius: '40%',
    border: `4px solid ${white}`,
    marginTop: '-16px',
    zIndex: 1,
    position: 'relative' as any,
  };

  if (props.isDigital) {
    return {
      ...styles,
      [fullHorizontalBreakpoint]: {
        alignSelf: 'center',
        margin: '0',
        marginLeft: '-16px',
      },
    };
  }

  return styles;
}

export default makeStyles(theme =>
  createStyles({
    or: orStyles,
  }),
);

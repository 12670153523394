import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from '../../../shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: palette.turquoise300,
      borderRadius: '16px',
      padding: '24px 16px',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      marginBottom: '24px',
    },
    shareSheet: {
      width: '100%',
      paddingTop: '16px',
      paddingBottom: '14px',
      marginTop: '24px',
      marginBottom: '24px',
      color: palette.white,
      fontSize: '16px',
      fontWeight: 800,
      letterSpacing: '-0.048px',
      backgroundColor: palette.turquoise900,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'none',
      },
    },
    shareIcon: {
      marginRight: '12px',
      strokeWidth: '1.5px',
    },
    header: {
      display: 'none',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'block',
        color: palette.gray900,
        fontSize: '16px',
        fontWeight: 700,
        lineHeight: '27.2px',
        letterSpacing: '0.16px',
        marginTop: '8px',
      },
    },
    shareText: {
      color: palette.gray700,
      textAlign: 'center',
      fontSize: '16px',
      fontWeight: 600,
      lineHeight: '25.6px',
      letterSpacing: '-0.048px',
    },
    link: {
      color: palette.turquoise900,
      fontWeight: 700,
    },
    copySheet: {
      display: 'none',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'block',
        backgroundColor: palette.white,
        marginTop: '24px',
        marginBottom: '24px',
        widhth: '100%',
        '& .MuiOutlinedInput-root': {
          '&:hover fieldset': {
            border: `1px solid ${palette.turquoise900}`,
          },
        },
        '& fieldset': {
          border: `1px solid ${palette.gray300}`,
          borderRadius: '8px',
          padding: '3px 16px',
        },
      },
    },
    copySheetInput: {
      color: palette.gray700,
      fontSize: '16px',
      opacity: 1,
      lineHeight: '22.4px',
      fontWeight: 400,
      caretColor: 'transparent',
      cursor: 'text',
      letterSpacing: '-0.128px',
    },
  });

export default styles;

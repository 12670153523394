import React from 'react';

import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';

import styles from '../ProfilesScreen.styles';
import RequestAdjustmentButton from './AdjustReadingLevel.request-adjustment.button';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import {AdjustmentRequest, ScreenState} from './AdjustReadingLevel.types';

interface Props extends WithStyles<typeof styles> {
  profileId?: string;
  updateScreenState: (screenState: ScreenState) => void;
}

const ButtonContainer = ({classes, updateScreenState, profileId}: Props) => {
  return (
    <Box className={classes.requestAdjustmentButtonContainer}>
      <RequestAdjustmentButton
        Icon={<ArrowDownwardIcon />}
        text="Decrease Difficulty"
        updateScreenState={updateScreenState}
        profileId={profileId}
        adjustmentRequest={AdjustmentRequest.DecreaseDifficulty}
      />

      <RequestAdjustmentButton
        Icon={<ArrowUpwardIcon />}
        text="Increase Difficulty"
        updateScreenState={updateScreenState}
        profileId={profileId}
        adjustmentRequest={AdjustmentRequest.IncreaseDifficulty}
      />
    </Box>
  );
};

export default withStyles(styles)(ButtonContainer);

export type CancellationReasonsType = {
  [key: string]: {label: string; subchoices: {[key: string]: {label: string}}};
};
export const physicalCancellationReasons: CancellationReasonsType = {
  'too-expensive': {
    label: 'Ello is too expensive',
    subchoices: {
      'cannot-afford': {label: 'We can no longer afford Ello'},
      'not-worth': {label: 'Ello is not worth the price'},
      'other-cost': {label: 'Other'},
    },
  },
  'child-interest': {
    label: "My child is no longer interested or didn't like the app",
    subchoices: {
      'lost-interest': {label: 'Child lost interest over time'},
      'speech-recognition': {label: 'The app couldn’t understand my child'},
      'too-difficult': {label: 'It was too hard for them'},
      'too-easy': {label: 'It was too easy for them'},
      'other-child': {label: 'Other'},
    },
  },
  'app-issue': {
    label: 'Problems with the app',
    subchoices: {
      'cant-download': {label: 'The app would not download'},
      crashes: {label: 'The app crashed too much'},
      'speech-recognition': {label: 'The app couldn’t understand my child'},
      'other-app': {label: 'Other'},
    },
  },
  'no-tablet': {
    label: "I don't have a tablet",
    subchoices: {
      'tablet-broke': {label: 'My tablet broke'},
      'no-tablet': {label: 'I never had a tablet to begin with'},
      'other-tablet': {label: 'Other'},
    },
  },
  other: {
    label: 'Something else',
    subchoices: {
      'no-use': {label: 'We just never got around to using it'},
      'wrong-age': {label: 'My child was not the right age'},
      'other-activities': {label: 'My child gets books from school'},
      'shipping-issues': {label: 'We had shipping issues'},
      'complicated-process': {label: 'The return process is too complicated'},
      other: {label: 'Other'},
    },
  },
};

export const digitalCancellationReasons: CancellationReasonsType = {
  'speech-didnt-work': {
    label: "The speech recognition didn't work well enough",
    subchoices: {
      'too-aggresive': {label: '“Too aggressive”: Stopped child when right'},
      'other-program': {label: '"Too lenient”: Didn’t stop child when wrong'},
      other: {label: 'Other'},
    },
  },
  'didnt-work': {
    label: 'App didn’t work properly',
    subchoices: {
      'couldnt-download': {label: 'Couldn’t download'},
      crashing: {label: 'App Crashing'},
      understand: {label: 'App couldn’t understand my child'},
      other: {label: 'Other'},
    },
  },
  // Only display for trialing users
  'dont-want-to-pay': {
    label: "I don't want to commit to paying yet",
    subchoices: {},
  },
  'no-time': {
    label: "Didn't have enough time to use Ello",
    subchoices: {
      'too-short': {label: 'Trial period too short'},
      'other-program': {label: 'Using another program'},
      around: {label: 'Didn’t get around to it'},
      'too-much-school': {label: 'Too much school work'},
    },
  },
  'child-didnt-like': {
    label: 'My child did not like it',
    subchoices: {
      'child-old-young': {label: 'My child is too old/young for Ello'},
      'child-didnt-want': {label: 'My child didn’t want to read with Ello'},
      'child-didnt-like-books': {label: 'My child didn’t like the books'},
      other: {label: 'Other'},
    },
  },
  'too-expensive': {
    label: 'Too expensive',
    subchoices: {
      'not-enough-value': {label: 'Not enough value for the price'},
      'cant-afford': {label: 'I can’t afford it'},
      other: {label: 'Other'},
    },
  },
  'other-ways': {
    label: 'Reading other ways',
    subchoices: {
      'other-app': {label: 'We found an alternative that we like better'},
      'school-program': {label: 'School program'},
      'hired-tutor': {label: 'Hired a tutor'},
      'reading-together': {label: 'Reading together, no electronics'},
    },
  },
  other: {
    label: 'Other',
    subchoices: {
      'app-download-failed': {label: 'Could not download the app'},
      'child-too-young': {label: 'Child too old/young for Ello'},
      'dont-need': {label: 'We don’t need it anymore'},
      'no-compatible-device': {label: 'Don’t have a compatible device'},
      'child-didnt-like': {label: "Child didn't like the books"},
      other: {label: 'Other'},
    },
  },
};

export const other = 'Other';

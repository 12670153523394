import React from 'react';
import {AddChildFlow, Plan} from '../types';
import {
  ProfilePlanLevelMatch,
  BundlePlanLevelMatch,
  ProfilePlanLevelMismatch,
} from './LevelMatchDescription';
import flowLevelMismatch from 'shared/assets/flowLevelMismatch.png';
import flowShareBooks from 'shared/assets/flowShareBooks.png';

type DescriptionProps = Pick<AddChildFlow, 'formValues' | 'profiles'>;

type LevelProps = {
  title: string;
  description: (p: DescriptionProps) => JSX.Element;
  image: string;
  mainCta: string;
  secondaryCta?: string;
};

export const CONTENT_SHARE_BOOKS: LevelProps = {
  title: 'Great! Your children can share books',
  description: (props: DescriptionProps) => (
    <ProfilePlanLevelMatch {...props} />
  ),
  image: flowShareBooks,
  mainCta: 'Continue',
};

export const CONTENT_RECOMMENDATION: Record<Plan, LevelProps> = {
  [Plan.Bundle]: {
    title: `We recommend sharing books`,
    description: (props: DescriptionProps) => (
      <BundlePlanLevelMatch {...props} />
    ),
    image: flowShareBooks,
    mainCta: 'Share bundle',
    secondaryCta: 'Add bundle',
  },
  [Plan.Profile]: {
    title: 'Consider adding a personal bundle',
    description: (props: DescriptionProps) => (
      <ProfilePlanLevelMismatch {...props} />
    ),
    image: flowLevelMismatch,
    mainCta: 'Add bundle',
    secondaryCta: 'Continue',
  },
};

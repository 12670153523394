/*
 * A Google-maps backed auto-completing address entry component.
 *
 * Improvements for the future:
 * - Automatically show City & State label below the text field and provide
 *   those address components to onboarding query params
 * - International support
 *
 * This evolved from a simpler "location entry" component that we had before
 * that allowed for unspecific addesses and general viciniyy ("Paris, France").
 * If we need that kind of general location picker for any reason, we can revive
 * it from around SHA 9bc0c3ef235e09ec9b466f5ef8752d878cfa1dcf.
 */
import React, {useEffect, useMemo, useState} from 'react';
import TextField from '@mui/material/TextField';
import Script from 'react-load-script';
import Autocomplete from '@mui/material/Autocomplete';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import parse from 'autosuggest-highlight/parse';
import throttle from 'lodash/throttle';
import {useQueryParam, StringParam} from 'use-query-params';
import {useStrings} from 'shared/utils/localization';
import useStyles from './LocationPicker.styles';
import debounce from 'lodash.debounce';
import {LocationPickerService} from './LocationPicker.service';

const DEBOUNCE_DELAY = 300;

const autocompleteService = {current: null};
const placesService = {current: null};

function LocationPicker({
  field,
  onValidationChanged,
  onFocus,
  onBlur,
  includeNames,
}) {
  const classes = useStyles();
  const strings = useStrings();

  /* Query States */
  /* eslint-disable no-unused-vars */
  const [locationQuery, setLocationQuery] = useQueryParam(
    `${field}`,
    StringParam,
  );
  const [aptSuiteQuery, setAptSuiteQuery] = useQueryParam(
    `${field}_aptsuite`,
    StringParam,
  );
  const [cityQuery, setCityQuery] = useQueryParam(`${field}_city`, StringParam);
  const [stateQuery, setStateQuery] = useQueryParam(
    `${field}_state`,
    StringParam,
  );
  const [zipQuery, setZipQuery] = useQueryParam(`${field}_zip`, StringParam);

  const [addressFirstNameQuery, setAddressFirstNameQuery] = useQueryParam(
    `${field}_first_name`,
    StringParam,
  );
  const [addressLastNameQuery, setAddressLastNameQuery] = useQueryParam(
    `${field}_last_name`,
    StringParam,
  );
  /* eslint-enable no-unused-vars */

  /* Realtime Display States */
  const [inputValue, setInputValue] = useState('');
  const [options, setOptions] = useState([]);
  const [streetValue, setStreetValue] = useState(locationQuery || null);
  const [streetError, setStreetError] = useState(false);
  const [aptSuiteValue, setAptSuiteValue] = useState(aptSuiteQuery || null);
  const [cityValue, setCityValue] = useState(cityQuery || null);
  const [stateValue, setStateValue] = useState(stateQuery || null);
  const [zipValue, setZipValue] = useState(zipQuery || null);
  const [addressFirstName, setAddressFirstName] = useState(
    addressFirstNameQuery || null,
  );
  const [firstNameError, setFirstNameError] = useState(false);
  const [lastNameError, setLastNameError] = useState(false);

  const [addressLastName, setAddressLastName] = useState(
    addressLastNameQuery || null,
  );

  /* Debounced Query Setters */
  const debouncedAptSuiteQuery = useMemo(
    () =>
      debounce(value => {
        setAptSuiteQuery(value);
      }, DEBOUNCE_DELAY),
    [setAptSuiteQuery],
  );

  const debouncedCityQuery = useMemo(
    () =>
      debounce(value => {
        setCityQuery(value);
      }, DEBOUNCE_DELAY),
    [setCityQuery],
  );
  const debouncedStateQuery = useMemo(
    () =>
      debounce(value => {
        setStateQuery(value);
      }, DEBOUNCE_DELAY),
    [setStateQuery],
  );
  const debouncedZipQuery = useMemo(
    () =>
      debounce(value => {
        setZipQuery(value);
      }, DEBOUNCE_DELAY),
    [setZipQuery],
  );

  const debouncedAddressFirstNameQuery = useMemo(
    () =>
      debounce(value => {
        setAddressFirstNameQuery(value);
      }, DEBOUNCE_DELAY),
    [setAddressFirstNameQuery],
  );

  const debouncedAddressLastNameQuery = useMemo(
    () =>
      debounce(value => {
        setAddressLastNameQuery(value);
      }, DEBOUNCE_DELAY),
    [setAddressLastNameQuery],
  );

  /* Debounced Validation */
  const debouncedValidation = useMemo(
    () =>
      debounce(
        (street, city, state, zip, includeNames, firstName, lastName) => {
          const isValidAddress = LocationPickerService.validateAddress(
            street,
            city,
            state,
            zip,
          );
          const hasValidAddressNames =
            LocationPickerService.validateAddressNames(
              includeNames,
              firstName,
              lastName,
            );
          onValidationChanged(isValidAddress && hasValidAddressNames);
        },
        DEBOUNCE_DELAY,
      ),
    [onValidationChanged],
  );

  const fetch = useMemo(
    () =>
      throttle((request, callback) => {
        autocompleteService.current.getPlacePredictions(request, callback);
      }, 200),
    [],
  );

  useEffect(() => {
    let active = true;

    // Instantiate autocomplete service to retrieve addresses for autocomplete options
    if (!autocompleteService.current && window.google) {
      autocompleteService.current =
        new window.google.maps.places.AutocompleteService();
    }
    if (!autocompleteService.current) {
      return undefined;
    }

    // Instantiate places service to retrieve zip code for selected option
    if (!placesService.current && window.google) {
      placesService.current = new window.google.maps.places.PlacesService(
        document.createElement('div'),
      );
    }
    if (!placesService.current) {
      return undefined;
    }

    if (inputValue === '') {
      setOptions(streetValue ? [streetValue] : []);
      return undefined;
    }

    // Check if inputValue is part of the results
    const isInputSubstring = results => {
      const values = results
        .map(item => {
          return item?.structured_formatting?.main_text.toLowerCase();
        })
        .filter(item => item.includes(inputValue.toLowerCase()));
      return values.length > 0;
    };

    fetch(
      {
        input: inputValue,
        types: ['address'],
        componentRestrictions: {
          country: 'us',
        },
      },
      results => {
        if (active) {
          let newOptions = [];

          if (inputValue) {
            newOptions = [inputValue];
          }

          if (results) {
            // if inputValue is part of the results then don't include it in the options
            if (isInputSubstring(results)) {
              newOptions = [...results];
            } else {
              newOptions = [...newOptions, ...results];
            }
          }

          setOptions(newOptions);
        }
      },
    );

    return () => {
      active = false;
    };
  }, [streetValue, inputValue, fetch]);

  // Not called on right arrow for some reason -- only on return or on option click
  const onChange = (event, newValue) => {
    if (newValue) {
      const placeId = newValue.place_id;
      // Update options
      if (!options.includes(newValue)) setOptions([newValue, ...options]);

      // Update street address url query param
      if (newValue?.structured_formatting?.main_text)
        setLocationQuery(newValue?.structured_formatting?.main_text);
      else setLocationQuery(newValue);

      if (placeId) {
        // Fetch updated secondary address for each option
        placesService.current.getDetails(
          {placeId, fields: ['formatted_address']},
          details => {
            // Update text fields
            let formatted_address = details.formatted_address.trim();
            if (formatted_address) {
              // ignore the street information
              let [street, city, stateZip] = formatted_address.split(', ');
              let [state, zip] = stateZip.split(' ');
              const hasValidAddress = LocationPickerService.validateAddress(
                street,
                city,
                state,
                zip,
              );

              if (hasValidAddress) {
                setCityValue(city);
                setStateValue(state);
                setZipValue(zip);
                const hasValidAddressNames =
                  LocationPickerService.validateAddressNames(
                    includeNames,
                    addressFirstName,
                    addressLastName,
                  );
                if (hasValidAddressNames) {
                  onValidationChanged(true);
                }

                debouncedCityQuery(city);
                debouncedStateQuery(state);
                debouncedZipQuery(zip);
              } else {
                onValidationChanged(false);
              }
            }
          },
        );
      } else {
        const hasValidAddressNames = LocationPickerService.validateAddressNames(
          includeNames,
          addressFirstName,
          addressLastName,
        );
        const hasValidAddress = LocationPickerService.validateAddress(
          streetValue,
          cityValue,
          stateValue,
          zipValue,
        );
        // Otherwise validate anything as long as we also have valid city, zip, and state
        onValidationChanged(hasValidAddress && hasValidAddressNames);
      }
    } else {
      // Update options
      setOptions(options);
      // Update url query param
      setLocationQuery(null);
      onValidationChanged(false);
    }
  };

  const onCloseStreetAddress = (event, newValue) => {
    // Update text field
    setStreetValue(event.target.value);
    setStreetError(!event.target.value); // Error if empty

    // Update url query param
    setLocationQuery(event.target.value);
    const hasValidAddress = LocationPickerService.validateAddress(
      event.target.value,
      cityValue,
      stateValue,
      zipValue,
    );
    const hasValidAddressNames = LocationPickerService.validateAddressNames(
      includeNames,
      addressFirstName,
      addressLastName,
    );

    onValidationChanged(hasValidAddress && hasValidAddressNames);
  };

  const onFirstNameBlur = event => {
    onBlur();
    setFirstNameError(!event.target.value);
  };

  const onLastNameBlur = event => {
    onBlur();
    setLastNameError(!event.target.value);
  };

  return (
    <Grid
      container
      alignItems="center"
      component="div"
      spacing={1}
      className={classes.fieldContainer}>
      {/* Note: this key is restricted to having requests come from our domains. To test this locally, go to
          https://console.cloud.google.com/apis/credentials/key/12cbe22f-5f96-4eba-83f2-87eec3b40793?project=trustle-3bb01
          and add localhost:3000/* to the list of accepted referrers. */}
      <Script url="https://maps.googleapis.com/maps/api/js?key=AIzaSyCjIfaH6XlAu6OCqfVM3Vn03kaimDDLKI0&libraries=places" />
      {includeNames && (
        <>
          <Grid item sm={6} xs={12}>
            <TextField
              required
              onBlur={onFirstNameBlur}
              error={firstNameError}
              onFocus={onFocus}
              value={addressFirstName || ''}
              onInput={event => {
                setAddressFirstName(event.target.value);
                debouncedAddressFirstNameQuery(event.target.value);
                debouncedValidation(
                  streetValue,
                  cityValue,
                  stateValue,
                  zipValue,
                  includeNames,
                  event.target.value,
                  addressLastName,
                );
              }}
              label={strings.addressFirstName}
              fullWidth
              variant="outlined"
              inputProps={{inputMode: 'text'}}
            />
          </Grid>

          <Grid item sm={6} xs={12}>
            <TextField
              required
              onBlur={onLastNameBlur}
              onFocus={onFocus}
              error={lastNameError}
              value={addressLastName || ''}
              onInput={event => {
                setAddressLastName(event.target.value);
                debouncedAddressLastNameQuery(event.target.value);
                debouncedValidation(
                  streetValue,
                  cityValue,
                  stateValue,
                  zipValue,
                  includeNames,
                  addressFirstName,
                  event.target.value,
                );
              }}
              label={strings.addressLastName}
              fullWidth
              variant="outlined"
              inputProps={{inputMode: 'text'}}
            />
          </Grid>
        </>
      )}

      <Grid item sm={8} xs={12}>
        <Autocomplete
          id="location-picker"
          getOptionLabel={option =>
            typeof option === 'string'
              ? option
              : option.structured_formatting.main_text
          }
          classes={{
            noOptions: classes.noOptions,
          }}
          filterOptions={x => x}
          options={options}
          autoComplete
          autoHighlight
          freeSolo
          includeInputInList
          filterSelectedOptions
          value={streetValue || null}
          onChange={onChange}
          onInputChange={(event, newInputValue) => {
            setInputValue(newInputValue);
          }}
          onInput={event => {
            setStreetValue(event.target.value);
            debouncedValidation(
              event.target.value,
              cityValue,
              stateValue,
              zipValue,
              includeNames,
              addressFirstName,
              addressLastName,
            );
          }}
          onBlur={onCloseStreetAddress}
          renderInput={params => (
            <TextField
              onBlur={onBlur}
              onFocus={onFocus}
              {...params}
              label={strings.enterAddress}
              error={streetError}
              fullWidth
              variant="outlined"
            />
          )}
          renderOption={(props, option) => {
            let parts;

            // Sometimes we have a string as part of the options array
            if (typeof option !== 'string') {
              const matches =
                option?.structured_formatting.main_text_matched_substrings;
              parts = parse(
                option?.structured_formatting.main_text,
                matches.map(match => [
                  match.offset,
                  match.offset + match.length,
                ]),
              );
            }

            if (typeof option === 'string') {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      <span style={{fontWeight: 700}}>{option}</span>
                    </Grid>
                  </Grid>
                </li>
              );
            } else {
              return (
                <li {...props}>
                  <Grid container alignItems="center">
                    <Grid item>
                      <LocationOnIcon className={classes.icon} />
                    </Grid>
                    <Grid item xs>
                      {parts.map((part, index) => (
                        <span
                          key={index}
                          style={{fontWeight: part.highlight ? 700 : 400}}>
                          {part.text}
                        </span>
                      ))}

                      <Typography variant="body2" color="textSecondary">
                        {option.structured_formatting.secondary_text}
                      </Typography>
                    </Grid>
                  </Grid>
                </li>
              );
            }
          }}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <TextField
          onBlur={onBlur}
          onFocus={onFocus}
          value={aptSuiteValue || ''}
          onInput={event => {
            setAptSuiteValue(event.target.value);
            debouncedAptSuiteQuery(event.target.value);
          }}
          label={strings.aptSuite}
          fullWidth
          variant="outlined"
          inputProps={{inputMode: 'text'}}
        />
      </Grid>
      <Grid item sm={6} xs={12}>
        <TextField
          onBlur={onBlur}
          onFocus={onFocus}
          value={cityValue || ''}
          onInput={event => {
            setCityValue(event.target.value);
            debouncedValidation(
              streetValue,
              event.target.value,
              stateValue,
              zipValue,
              includeNames,
              addressFirstName,
              addressLastName,
            );
            debouncedCityQuery(event.target.value);
          }}
          label={strings.city}
          fullWidth
          variant="outlined"
          inputProps={{inputMode: 'text'}}
        />
      </Grid>
      <Grid item sm={2} xs={12}>
        <TextField
          onBlur={onBlur}
          onFocus={onFocus}
          value={stateValue || ''}
          onInput={event => {
            setStateValue(event.target.value.toUpperCase());
            debouncedValidation(
              streetValue,
              cityValue,
              event.target.value.toUpperCase(),
              zipValue,
              includeNames,
              addressFirstName,
              addressLastName,
            );
            debouncedStateQuery(event.target.value.toUpperCase());
          }}
          label={strings.state}
          fullWidth
          variant="outlined"
          inputProps={{
            inputMode: 'text',
            style: {textTransform: 'uppercase'},
            maxLength: 2,
          }}
        />
      </Grid>
      <Grid item sm={4} xs={12}>
        <TextField
          onBlur={onBlur}
          onFocus={onFocus}
          value={zipValue || ''}
          onInput={event => {
            setZipValue(event.target.value);
            debouncedValidation(
              streetValue,
              cityValue,
              stateValue,
              event.target.value,
              includeNames,
              addressFirstName,
              addressLastName,
            );
            debouncedZipQuery(event.target.value);
          }}
          label={strings.zipCode}
          fullWidth
          variant="outlined"
          inputProps={{inputMode: 'numeric', maxLength: 5}}
        />
      </Grid>
    </Grid>
  );
}

export default LocationPicker;

import {useQuery} from '@apollo/react-hooks';
import PurchaseList from 'customerPortal/components/PurchaseList/PurchaseList';
import React from 'react';
import {Purchase, PurchasesFilter} from 'shared/types/purchases';
import {QUERY_ACCOUNT_DETAILS} from '../../AccountDetailsScreen/AccountDetails.query';
import {QUERY_PURCHASES} from './Purchases.query';
interface PurchasesProps {
  filter: PurchasesFilter;
}
const Purchases = ({filter}: PurchasesProps) => {
  const {data, loading, error} = useQuery<{purchases: {purchases: Purchase[]}}>(
    QUERY_PURCHASES,
    {
      variables: {filter},
      onError: error => console.error('Current Purchases::', error),
    },
  );

  const {data: account} = useQuery(QUERY_ACCOUNT_DETAILS, {
    onError: error => console.error('account details', error),
  });

  return (
    <PurchaseList
      isLoading={loading}
      filter={filter}
      error={error ? 'Unable to load current books' : ''}
      items={data?.purchases.purchases}
      productType={account?.customer?.productType}
    />
  );
};

export default Purchases;

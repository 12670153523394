import {gql} from '@apollo/client';

export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation CancelSubscriptionMutation($input: CancelSubscriptionInput!) {
    cancelSubscription(input: $input) {
      success
    }
  }
`;

export const EXTEND_TRIAL_MUTATION = gql`
  mutation ExtendTrialPeriodMutation {
    extendTrialPeriod {
      success
    }
  }
`;

export const OFFER_DISCOUNT_MUTATION = gql`
  mutation OfferDiscountMutation {
    offerDiscount {
      success
    }
  }
`;

import React, {useState} from 'react';
import {Typography, Box, Grid} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Section from 'customerPortal/components/Section';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {RadioForm, TilePicker} from 'customerPortal/components/Form';
import Button, {ButtonType} from 'shared/components/common/Button';
import {WithStyles} from '@mui/styles';
import styles from './CancellationReason.styles';

interface Props extends WithStyles<typeof styles> {
  choices: {[key: string]: {label: string}};
  subscriptionStatus?: string;
  onConfirm: (option: string) => void;
  onSkip: () => void;
  titleText?: string;
  onBack?: () => void;
  hideDesktopBackButton?: boolean;
  hideSkipButton?: boolean;
}

const CancellationReason = ({
  classes,
  choices,
  subscriptionStatus,
  onConfirm,
  onSkip,
  titleText = "I'd like to cancel because:",
  onBack = () => {},
  hideDesktopBackButton = false,
  hideSkipButton = false,
}: Props) => {
  const isMobile = useMobileLayout();
  const [selected, setSelected] = useState('');

  const selectAndContinue = (option: string) => {
    setSelected(option);
    onConfirm(option);
  };

  const onSelect = (option: string) => {
    setSelected(option);
  };

  const onContinue = () => {
    if (!!selected) {
      onConfirm(selected);
    }
  };

  return (
    <>
      <Grid container className={classes.content}>
        {isMobile && <Section title="Cancellation" />}

        <Box className={classes.topContainer}>
          <Typography variant={'h6'} align={'center'} className={classes.title}>
            {titleText}
          </Typography>

          {isMobile ? (
            <>
              <TilePicker
                choices={choices}
                selected={selected}
                subscriptionStatus={subscriptionStatus}
                onSelect={selectAndContinue}
              />
            </>
          ) : (
            <Box className={classes.container}>
              <RadioForm
                choices={choices}
                selected={selected}
                subscriptionStatus={subscriptionStatus}
                onSelect={onSelect}
              />
            </Box>
          )}
        </Box>
        <Box>
          {isMobile ? (
            <>
              {!hideSkipButton && (
                <Grid container className={classes.buttonContainer}>
                  <Button className={classes.skipButton} onClick={onSkip}>
                    Skip
                  </Button>
                </Grid>
              )}
            </>
          ) : (
            <>
              <Grid container className={classes.buttonContainer}>
                <Grid container className={classes.leftContainer}>
                  <Grid item>
                    {!hideDesktopBackButton && (
                      <Button
                        className={classes.activeButton}
                        variant={ButtonType.Inline}
                        onClick={onBack}>
                        Back
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    disabled={!selected}
                    onClick={onContinue}>
                    Continue
                  </Button>
                </Grid>
                <Grid container className={classes.rightContainer}>
                  <Grid item>
                    {!selected && !hideSkipButton && (
                      <Button
                        className={classes.skipButton}
                        variant={ButtonType.Inline}
                        onClick={onSkip}>
                        Skip
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </Grid>
            </>
          )}
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationReason);

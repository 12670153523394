import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {WithStyles} from '@mui/styles';
import cN from 'classnames';
import moment from 'moment';
import React, {FC} from 'react';
import {
  getFormattedDate,
  isDateInPast,
  isWithin5Days,
} from '../../../shared/utils/dateUtils';
import {isReturningSubscription} from '../../../shared/utils/productType';
import {usePurchaseItemStyles} from './PurchaseList.styles';

interface props extends WithStyles<typeof usePurchaseItemStyles> {
  isMobile: boolean;
  orderReturnByDate?: Date;
  productType?: string;
  startDate: Date;
  upcomingPurchase?: boolean;
}

const PurchaseItemText: FC<props> = ({
  classes,
  isMobile,
  orderReturnByDate,
  productType,
  startDate,
  upcomingPurchase,
}: props) => {
  const returnBy = moment(orderReturnByDate).toDate();
  const datesOnScreen = upcomingPurchase ? startDate : returnBy;

  const returnByDates = getFormattedDate(new Date(returnBy).getTime());
  const formattedStartDate = getFormattedDate(new Date(startDate).getTime());

  const formattedDatesOnScreen = upcomingPurchase
    ? formattedStartDate
    : returnByDates;

  const dateElementWithinSubtext = (
    <b>
      <time dateTime={formattedDatesOnScreen.a11y}>
        {formattedDatesOnScreen.friendly(false)}
      </time>
    </b>
  );

  const showText = isReturningSubscription(productType) || upcomingPurchase;

  return (
    <Box>
      {showText && (
        <Box>
          <Typography
            component="p"
            className={cN(classes.returnBy, {
              [classes.returnByLaptop]: !isMobile,
              [classes.returnByMobile]: isMobile,
            })}>
            {upcomingPurchase ? 'Ships on ' : 'Return books before '}
            <time
              dateTime={formattedDatesOnScreen.a11y}
              className={cN({
                [classes.dueSoon]:
                  !upcomingPurchase &&
                  (isWithin5Days(new Date(datesOnScreen).getTime()) ||
                    isDateInPast(new Date(datesOnScreen).getTime())),
              })}>
              {formattedDatesOnScreen.friendly(true)}
            </time>
          </Typography>
          <Typography component="p" className={classes.disclaimer}>
            {upcomingPurchase ? (
              <>* Recommended books will ship on {dateElementWithinSubtext}</>
            ) : (
              <>
                * Free shipping. Or keep for $5 each.
                <br />
                We'll consider books shipped past {dateElementWithinSubtext} as
                kept
              </>
            )}
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PurchaseItemText;

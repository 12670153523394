import React from 'react';
import {CircularProgress} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './PageLoader.styles';

type Props = WithStyles<typeof styles>;

const PageLoader = ({classes}: Props) => {
  return (
    <div className={classes.container}>
      <CircularProgress color="primary" />
    </div>
  );
};

export default withStyles(styles)(PageLoader);

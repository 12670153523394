import React from 'react';
import Box from '@mui/material/Box';
import useStyles from './ShareableLink.styles';
import Header from './Shareablelink.header';
import ImageContainer from './ShareableLink.image';
import BodyText from './ShareableLink.body';
import Link from './ShareableLink.link';
import {TABLET_BREAKPOINT} from '../../styles/breakpoints';
import {useWindowSize} from '../../utils/layoutHooks';
import {ShareableLinkService} from './ShareableLink.service';

type Props = {
  header: string;
  image: string;
  link: string;
  shareDialogText?: string;
  copyLinkText?: string;
  shareLinkText?: string;
};

const ShareableLink = ({
  header,
  image,
  shareDialogText,
  shareLinkText,
  copyLinkText,
  link,
}: Props) => {
  const classes = useStyles();
  const [windowWidth] = useWindowSize();
  const isMobile = windowWidth < TABLET_BREAKPOINT;
  const canShareLink = ShareableLinkService.canShareLink();
  const showShareButton = canShareLink && isMobile;
  const bodyText = ShareableLinkService.bodyText(showShareButton, {
    shareLinkText,
    copyLinkText,
  });

  return (
    <Box className={classes.container}>
      <Header header={header} />
      <ImageContainer image={image} />
      <BodyText text={bodyText} />
      <Link link={link} dialogueText={shareDialogText} />
    </Box>
  );
};

export default ShareableLink;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Button from '@mui/material/Button';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';

import styles from './EditProfileScreen.styles';
import {useHistory} from 'react-router-dom';
import {EDIT_PROFILE_BASE_PATH} from '../../../../../shared/components/Route/routes';
import useMobileLayout from '../../../../utils/useMobileLayout';

interface props extends WithStyles<typeof styles> {
  profileId?: string;
  openModal: () => void;
}

const RemoveProfileButton = ({classes, profileId, openModal}: props) => {
  const history = useHistory();
  const isMobile = useMobileLayout();

  const removeProfilehandler = () => {
    if (isMobile) {
      history.push(`${EDIT_PROFILE_BASE_PATH}/${profileId}/remove`);
      return;
    }

    openModal();
  };

  return (
    <Button
      startIcon={<DeleteOutlinedIcon />}
      onClick={removeProfilehandler}
      className={classes.removeProfileButton}>
      Remove profile
    </Button>
  );
};

export default withStyles(styles)(RemoveProfileButton);

import React from 'react';
import cN from 'classnames';
import {CircularProgress, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './FlowLayout.styles';
import Alert from 'shared/components/common/Alert/Alert';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import ActionBar from './ActionBar';

interface Props extends WithStyles<typeof styles> {
  contentClass?: string;
  title: string;
  subtitle?: string;
  children: React.ReactNode;
  cta?: React.ReactNode;
  ctaContainerSize?: 'xs' | 'sm' | 'md' | 'lg' | 'xl' | false;
  isLoading?: boolean;
  error?: string;
  sideContent?: React.ReactNode;
  sideContentClass?: string;
}

const FlowLayout = ({
  classes,
  contentClass,
  title,
  subtitle,
  children,
  cta,
  ctaContainerSize = 'xs',
  isLoading = false,
  error,
  sideContent,
  sideContentClass,
}: Props) => {
  const isMobile = useMobileLayout();

  return (
    <div className={classes.container}>
      <section className={cN(classes.content, contentClass)}>
        <Typography component="h1" variant="h6" className={classes.title}>
          {title}
        </Typography>
        {subtitle && (
          <Typography component="p" className={classes.subtitle}>
            {subtitle}
          </Typography>
        )}
        {error ? (
          <Alert error={error} autoHide={false} />
        ) : (
          <>
            {isLoading ? (
              <div className={classes.progress}>
                <CircularProgress />
              </div>
            ) : (
              <>
                {children}
                {cta && (
                  <ActionBar ctaContainerSize={ctaContainerSize}>
                    {cta}
                  </ActionBar>
                )}
              </>
            )}
          </>
        )}
      </section>
      {error || isLoading ? null : (
        <>
          {sideContent && !isMobile && (
            <section className={cN(classes.sideContent, sideContentClass)}>
              {sideContent}
            </section>
          )}
        </>
      )}
    </div>
  );
};

export default withStyles(styles)(FlowLayout);

import {Box, Link} from '@mui/material';
import React from 'react';
import ImageSection from '../Sections/ImageSection';
import BodySection from '../Sections/BodySection';
import ActionsSection from '../Sections/ActionsSection';
import {ReactComponent as SadEllo} from 'shared/assets/Sad-Ello.svg';
import ContainerWrapper from '../Sections/ContainerWrapper';

const elloAccessLink =
  'https://airtable.com/app3xRv9ffTDDoJZ3/pagQKLQ2bCLBkh3IF/form';

export interface ElloAccessProps {
  onConfirm: () => void;
}

const ElloAccess: React.FC<ElloAccessProps> = ({onConfirm}) => {
  return (
    <ContainerWrapper>
      <Box>
        <Box mb="40px">
          <ImageSection>
            <SadEllo />
          </ImageSection>
        </Box>
        <BodySection
          title="Sorry to see you go..."
          description="Do you receive government assistance such as EBT/SNAP? You may qualify for our discounted Ello Access program."
        />
      </Box>
      <ActionsSection
        acceptLabel="Apply here"
        href={elloAccessLink}
        component={Link}
        cancelLabel="Continue Cancellation"
        onCancel={onConfirm}
      />
    </ContainerWrapper>
  );
};

export default ElloAccess;

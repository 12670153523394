import React, {useState} from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from './AdjustReadingLevel.styles';
import {ReadingLevel} from 'shared/types/readingLevels';
import Button from 'shared/components/common/Button';
import {AddChildFlow} from '../types';
import {FlowLayout} from 'customerPortal/components/Flow';
import {possessive} from 'shared/utils/stringUtils/stringUtils';
import FormSelect from 'customerPortal/components/Form/FormSelect/FormSelect';
import BookSampleViewer from 'customerPortal/components/BookSampleViewer/BookSampleViewer';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {getSelectInitialValue} from '../AddChildScreen.utils';
import {getReadingLevelOptions} from './AdjustReadingLevel.utils';
import {FlowState} from 'customerPortal/components/Flow/types';

type Props = Omit<AddChildFlow, 'setFormValues' | 'flowState'> &
  WithStyles<typeof styles>;

const AdjustReadingLevel = ({
  classes,
  formValues,
  recommendation,
  profiles,
  setFlowState,
  setRecommendationState,
}: Props) => {
  const isMobile = useMobileLayout();
  const [showError, setShowError] = useState<boolean>(false);
  const [newReadingLevel, setNewReadingLevel] = useState<ReadingLevel | null>(
    recommendation.selectedReadingLevel ?? formValues.readingLevel,
  );

  const options = getReadingLevelOptions(profiles, formValues.readingLevel);
  const selectInitialValue = getSelectInitialValue(newReadingLevel, options);

  const onSubmit = () => {
    setShowError(false);
    const isOneOfAvailableOptions = options.filter(
      p => p.value === newReadingLevel,
    ).length;

    if (!isOneOfAvailableOptions) {
      setShowError(true);
      return;
    }

    setRecommendationState(prev => ({
      ...prev,
      selectedReadingLevel: newReadingLevel,
    }));
    setFlowState(FlowState.SubscriptionPreview);
  };

  const {name} = formValues;

  return (
    <FlowLayout
      title={`Select ${possessive(name)} reading level`}
      subtitle={`Choose the correct reading level for ${name}. If you're not sure, reference this guide.`}
      contentClass={classes.content}
      ctaContainerSize={false}
      cta={
        <Button type="button" onClick={onSubmit} className={classes.button}>
          Continue
        </Button>
      }
      sideContentClass={classes.sideContent}
      sideContent={
        <BookSampleViewer level={newReadingLevel ?? ('A' as ReadingLevel)} />
      }>
      <form className={classes.form}>
        <FormSelect
          label={`Reading Level`}
          id="adjust-reading-level-dropdown"
          value={selectInitialValue}
          errorMessage={
            showError ? `Please select a reading level for ${name}` : ''
          }
          onChange={e => setNewReadingLevel(e.target.value)}
          items={options}
          containerClass={classes.dropdown}
        />
      </form>
      <p className={classes.text}>
        Here's some <b>example passages</b> from this reading level:
      </p>
      {isMobile && (
        <BookSampleViewer level={newReadingLevel ?? ('A' as ReadingLevel)} />
      )}
    </FlowLayout>
  );
};

export default withStyles(styles)(AdjustReadingLevel);

import Box from '@mui/material/Box';
import useStyles from './ProductSelectionPanel.styles';

const ProductImage = ({productImage, title, height}) => {
  const classes = useStyles();

  return (
    <Box className={classes.productImage}>
      <img src={productImage} alt={`${title}`} height={height} />
    </Box>
  );
};

export default ProductImage;

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {PUBLIC_ROUTES} from '../../../shared/components/Route/routes';
import ExternalNavigation from '../../components/ExternalNavigation/ExternalNavigation';
import CategoryByID from '../../components/Library/Category/CategoryById';

const backText = 'Return to Categories';

const CategoryScreen: FC = () => {
  const {categoryId} = useParams<{categoryId: string}>();
  const history = useHistory();
  const goBack = () => {
    history.push(PUBLIC_ROUTES.CATEGORIES);
  };

  return (
    <Box>
      <ExternalNavigation />
      <CategoryByID
        backText={backText}
        goBack={goBack}
        categoryId={categoryId}
      />
    </Box>
  );
};

export default CategoryScreen;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {
  gray300,
  gray700,
  gray900,
  iconButtonHoverColor,
  teal500,
} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    subtitle: {
      fontSize: '1.125rem',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
      },
    },
    readingLevel: {
      fontWeight: 700,
    },
    tabContainer: {
      flex: '1',
    },
    navigation: {
      display: 'flex',
      gap: theme.spacing(2),
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    link: {
      marginLeft: -theme.spacing(1.5),
      borderRadius: `50%`,
      minWidth: '50px',
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      outline: 'none',
      '&:hover, &:focus': {
        backgroundColor: iconButtonHoverColor,
      },
    },
    title: {
      fontSize: '1.625rem',
    },
    status: {
      color: teal500,
    },
  });

export default styles;

export const horizontalBookListStyles = (theme: Theme) =>
  createStyles({
    slide: {
      display: 'flex',
      minHeight: '400px',
      marginBottom: theme.spacing(4),
      borderRight: `1px solid ${gray300}`,
      '&:last-of-type': {
        borderRight: 'transparent',
      },
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        minHeight: 'auto',
      },
    },
    slideItem: {
      display: 'flex',
      flexDirection: 'column',
      padding: `0 ${theme.spacing(4)}`,
    },
    title: {
      fontSize: '1.25rem',
      fontWeight: 700,
      color: gray900,
    },
    author: {
      fontSize: '0.875rem',
      color: gray700,
      fontWeight: 400,
    },
    text: {
      overflow: 'hidden',
      position: 'relative',
      display: '-webkit-box',
      '-webkit-line-clamp': 2,
      '-webkit-box-orient': 'vertical',
    },
    statusContainer: {
      marginTop: theme.spacing(1),
    },
    profileViewer: {
      marginTop: theme.spacing(2),
    },
    img: {
      objectFit: 'contain',
      maxWidth: theme.spacing(25),
      maxHeight: theme.spacing(25),
      width: '100%',
      height: 'auto',
    },
    divImg: {
      width: theme.spacing(30),
      height: theme.spacing(30),
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
  });

export const verticalBookListStyles = (theme: Theme) =>
  createStyles({
    itemRow: {
      display: 'flex',
      alignItems: 'flex-start',
    },
    rowImg: {
      objectFit: 'contain',
      height: 'auto',
      width: '100%',
      marginRight: theme.spacing(2),
      maxWidth: theme.spacing(11),
      maxHeight: theme.spacing(11),
    },
    title: {
      fontSize: '1rem',
      fontWeight: 700,
      color: gray900,
    },
    author: {
      fontSize: '0.8125rem',
      color: gray700,
      fontWeight: 400,
      marginBottom: theme.spacing(1),
    },
    itemContainer: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'flex-start',
      justifyContent: 'space-between',
      borderBottom: `1px solid ${gray300}`,
      '&:last-of-type': {
        borderBottom: 'transparent',
      },
      padding: `${theme.spacing(4)} 0`,
    },
  });

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {useHistory} from 'react-router-dom';

import styles from './ProfilesScreen.styles';
import MaxProfilesImg from 'shared/assets/max-profiles.svg';
import useMobileLayout from '../../../utils/useMobileLayout';

interface props extends WithStyles<typeof styles> {
  onCloseModal?: () => void;
}

const MaxProfilesScreen = ({classes, onCloseModal}: props) => {
  const history = useHistory();
  const isMobile = useMobileLayout();

  const closeMaxProfilesScreen = () => {
    if (isMobile) {
      history.goBack();
      return;
    }

    onCloseModal && onCloseModal();
  };

  return (
    <Box className={classes.maxProfilesContainer}>
      <Box className={classes.maxProfilesHeaderContainer}>
        <Typography className={classes.maxProfilesHeader}>
          You have reached the profiles limit!
        </Typography>
        <img alt="" src={MaxProfilesImg} className={classes.maxProfilesImg} />
      </Box>

      <Typography className={classes.maxProfilesSubtitle}>
        You can add a maximum of 3 profiles to your account
      </Typography>

      <Button
        variant="contained"
        className={classes.maxProfilesButton}
        onClick={closeMaxProfilesScreen}>
        Close
      </Button>
    </Box>
  );
};

export default withStyles(styles)(MaxProfilesScreen);

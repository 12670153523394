import * as palette from 'shared/styles/palette';

import {Theme} from '@mui/material/styles';

declare module '@mui/styles/defaultTheme' {
  // eslint-disable-next-line @typescript-eslint/no-empty-interface
  interface DefaultTheme extends Theme {}
}

const MOBILE_ONLY = '@media (max-width: 767px)';
const DESKTOP_ONLY = '@media (min-width: 767px)';

export const materialTheme = {
  typography: {
    fontFamily: [
      'Muli',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    body1: {
      fontWeight: '600',
    },
    subtitle1: {
      fontWeight: 'bold',
    },
    subtitle2: {
      fontWeight: 'bold',
    },
    h1: {
      color: palette.blue500,
    },
    h2: {
      fontSize: '3rem',
      color: palette.blue500,
    },
    h3: {
      fontSize: '1.17em',
      textTransform: 'uppercase',
      fontWeight: '700',
      color: palette.blue500,
    },
    h4: {
      fontWeight: '800',
      color: palette.blue500,
    },
    h5: {
      fontWeight: '800',
      color: palette.blue500,
    },
    h6: {
      fontWeight: '800',
      color: palette.blue500,
    },
    caption: {
      color: palette.gray700,
    },
  },

  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: palette.turquoise500,
      contrastText: palette.white,
    },
    secondary: {
      main: palette.orange500,
      contrastText: palette.white,
      // light: could be calculated from palette.secondary.dark,
      // dark: will be calculated from palette.secondary.dark,
      // contrastText: palette.gray900,
    },
    text: {
      primary: palette.gray900,
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    contrastThreshold: 3,
    // Used by the functions below to shift a color's luminance by approximately
    // two indices within its tonal palette.
    // E.g., shift from Red 500 to Red 300 or Red 700.
    tonalOffset: 0.2,
  },

  overrides: {
    MuiCssBaseline: {
      body: {
        backgroundColor: palette.white,
        [MOBILE_ONLY]: {
          backgroundColor: palette.white,
        },
        fontSize: '0.875rem',
        lineHeight: 1.43,
        letterSpacing: '0.01071em',
      },

      // These styles are present to present a consistent browser experience
      // and prevent clashes with our webflow default styles that are not defined
      // within the material spect, in particular as they relate to HTML elements
      // rendered in the onboarding flows via markdown.
      // It's not perfect, but it will ensure more consistency between
      // 'portal.helloello.com/onboarding' and 'www.helloello.com/start'
      h1: {
        fontSize: '2em',
        marginBlockStart: '0.67em',
        marginBlockEnd: '0.67em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
      h2: {
        fontSize: '1.5em',
        marginBlockStart: '0.83em',
        marginBlockEnd: '0.83em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
      h3: {
        fontSize: '1.17em',
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
      h4: {
        fontSize: '1em',
        marginBlockStart: '1.33em',
        marginBlockEnd: '1.33em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
      h5: {
        fontSize: '0.83em',
        marginBlockStart: '1.67em',
        marginBlockEnd: '1.67em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
      h6: {
        fontSize: '0.67em',
        marginBlockStart: '1.67em',
        marginBlockEnd: '1.67em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        fontWeight: 'bold',
        lineHeight: 'normal',
      },
      p: {
        marginBlockStart: '1em',
        marginBlockEnd: '1em',
        marginInlineStart: '0px',
        marginInlineEnd: '0px',
        lineHeight: 'normal',
      },
    },

    // Fix calendar centering
    MuiPickersBasePicker: {
      containerLandscape: {
        justifyContent: 'center',
      },
    },

    // Custom stepper Layout
    MuiStep: {
      horizontal: {
        paddingLeft: 0,
        paddingRight: 0,
        [MOBILE_ONLY]: {
          // Mobile only, since there's no label.
          backgroundColor: palette.white,
        },
      },
      root: {
        '&.Mui-completed': {
          [DESKTOP_ONLY]: {
            backgroundColor: palette.blue500,
            '& span::after': {
              backgroundColor: palette.blue500,
            },
          },
        },
      },
    },

    MuiStepIcon: {
      root: {
        color: palette.gray500,
        [MOBILE_ONLY]: {
          color: palette.gray100,
          '& .MuiStepIcon-text': {
            fill: palette.gray700,
          },
          '&.Mui-active': {
            '& .MuiStepIcon-text': {
              fontWeight: 700,
              fill: palette.white,
            },
          },
        },

        [DESKTOP_ONLY]: {
          '&.Mui-completed': {
            color: palette.white,
          },
          '&.Mui-active': {
            color: palette.white,
            '& .MuiStepIcon-text': {
              fill: palette.blue500,
              fontWeight: 700,
            },
          },
        },
      },
      text: {
        fontWeight: 400,
      },
    },

    MuiStepLabel: {
      root: {
        overflow: 'hidden',
        paddingLeft: '20px',
        height: '60px',

        [MOBILE_ONLY]: {
          // Mobile only, since there's no icon.
          paddingRight: '20px',
        },

        [DESKTOP_ONLY]: {
          backgroundColor: palette.blue500,
          // Desktop only
          // CSS arrow block
          '&::after': {
            color: palette.blue500,
            backgroundColor: palette.white,
            borderLeft: '15px solid',
            borderTop: '30px solid transparent',
            borderBottom: '30px solid transparent',
            display: 'inline-block',
            content: '""',
            position: 'relative',
          },

          '&.Mui-disabled&::after': {
            display: 'none',
            color: palette.white,
          },
          '&.Mui-disabled': {
            backgroundColor: palette.white,
          },
        },
      },
      label: {
        paddingRight: '20px',
        fontWeight: 400,
        '&.Mui-active': {
          fontWeight: 700,
          color: palette.white,
        },
        '&.Mui-completed': {
          fontWeight: 500,
          color: palette.white,
        },
      },

      labelContainer: {
        [MOBILE_ONLY]: {
          // Completely hide on mobile
          display: 'none',
        },
      },

      iconContainer: {
        transform: 'scale(1.5)',
        height: 'fit-content',

        // Mobile
        paddingRight: '0',
        [DESKTOP_ONLY]: {
          // Padding for text label on desktop
          paddingRight: '15px',
        },
      },
    },
    MuiCardActionArea: {
      root: {
        '&:hover $focusHighlight': {
          opacity: 0,
        },
      },
    },
  },
};

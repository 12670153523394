export function emphasizeText(text, emphasis) {
  if (!emphasis) {
    return text;
  }

  const emphasizedText = emphasizeWords(text, emphasis);
  const components = parseToComponents(emphasizedText);

  return components;
}

function emphasizeWords(text, emphasis) {
  emphasis.forEach((word, index) => {
    const emphasized = emphasizeWord(text, word, index);
    text = emphasized;
  });

  return text;
}

function emphasizeWord(text, word, index) {
  const emphasisRegex = new RegExp(`(${word})|(<strong[^>]*>.*</strong>)`, 'g');

  const splitByEmpasis = text
    .split(emphasisRegex)
    .filter(part => part !== undefined && part !== '');

  const emphasized = splitByEmpasis
    .map((part, innerIndex) => {
      if (part !== word) {
        return part;
      }

      if (alreadyEmphasized(splitByEmpasis, index)) {
        return part;
      }

      return `<strong key="${index},${innerIndex}"}>${part}</strong>`;
    })
    .join('');

  return emphasized;
}

function alreadyEmphasized(splitByEmpasis, index) {
  const previousPart = splitByEmpasis[index - 1];
  const alreadyEmphasized =
    previousPart &&
    previousPart.includes('<strong') &&
    !previousPart.includes('</strong>');

  return alreadyEmphasized;
}

function parseToComponents(inputStr) {
  const regex = /<strong key=(.*?)>(.*?)<\/strong>/g;

  let match;
  let lastIndex = 0;
  const components = [];

  while ((match = regex.exec(inputStr)) !== null) {
    if (match.index > lastIndex) {
      components.push(inputStr.substring(lastIndex, match.index));
    }

    const key = match[1];
    const content = match[2];
    components.push(<strong key={key}>{content}</strong>);

    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < inputStr.length) {
    components.push(inputStr.substring(lastIndex));
  }

  return components;
}

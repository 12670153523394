import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import React, {useState} from 'react';
import {FC} from 'react';
import {handleEmailSubmit} from './HandleEmailSubmit';

const EmailSubmitForm: FC = () => {
  const [email, setEmail] = useState('');
  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleFormSubmit = async (
    event:
      | React.FormEvent<HTMLFormElement>
      | React.MouseEvent<HTMLButtonElement>,
  ) => {
    event.preventDefault();
    await handleEmailSubmit(email);
    const link = `https://www.ello.com/start?flow=digitalello&email=${email}`;
    const encodedlink = encodeURI(link);
    window.location.href = encodedlink;
  };
  return (
    <Box>
      <form onSubmit={handleFormSubmit}>
        <Box
          sx={{
            display: {xs: 'block', md: 'flex'},
            alignItems: 'center',
            mb: '24px',
            gap: {md: '8px'},
          }}>
          <TextField
            variant="outlined"
            fullWidth
            placeholder="Enter Your Email"
            value={email}
            onChange={handleEmailChange}
            size={'small'}
            sx={{
              mb: {xs: '16px', md: 0},
              flex: 1,
            }}
          />
          <Button
            type="submit"
            variant="contained"
            color="primary"
            sx={{
              width: {xs: '100%', md: 'auto'},
              borderRadius: '15px',
            }}>
            Start
          </Button>
        </Box>
      </form>
    </Box>
  );
};

export default EmailSubmitForm;

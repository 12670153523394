import Box from '@mui/material/Box';
import {Typography, Grid, Button} from '@mui/material';
import React, {FC} from 'react';
import useStyles from './LandingPage.styles';

type imageBodyMap = {
  image: string;
  body: string;
};

type Props = {
  textBody: string;
  content: imageBodyMap[];
  buttonText: string;
  onNext: () => void;
};

const LandingPage: FC<Props> = ({textBody, content, buttonText, onNext}) => {
  const classes = useStyles({});

  return (
    <Box>
      <Typography className={classes.body}>{textBody}</Typography>
      <Grid container spacing={4}>
        {content.map((item, index) => (
          <Grid item xs={12} sm={4} key={index}>
            <img
              src={item.image}
              alt="background"
              style={{width: '100%', height: 200, objectFit: 'cover'}}
            />
            <Typography className={classes.explainContent}>
              {item.body}
            </Typography>
          </Grid>
        ))}
      </Grid>
      <Grid item xs>
        <Button
          variant="contained"
          type="submit"
          onClick={e => {
            e.preventDefault();
            onNext();
          }}
          className={classes.tryForFreeButton}>
          {buttonText}
        </Button>
      </Grid>
    </Box>
  );
};

export default LandingPage;

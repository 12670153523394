import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {ImageQuestion} from './Image.types';

type Props = {
  question: ImageQuestion;
};

const DefaultImage: FC<Props> = ({question}: Props) => {
  return (
    <Box
      sx={{
        maxWidth: '100%',
        maxHeight: '300px',
      }}>
      <img
        style={{
          maxWidth: '100%',
          maxHeight: '300px',
          ...(question.imageWidth ? {width: question.imageWidth} : {}),
          ...(question.maxWidth ? {maxWidth: question.maxWidth} : {}),
          ...(question.maxHeight ? {maxHeight: question.maxHeight} : {}),
        }}
        src={question.image}
        alt="Onboarding"
      />
    </Box>
  );
};

export default DefaultImage;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import FormHelperText from '@mui/material/FormHelperText';

import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import styles from './AddProfileScreen.styles';
import {allGrades} from './AddProfileScreen.service';
import {addedProfileErrorType, addedProfileType} from '../ProfilesScreen.types';

interface props extends WithStyles<typeof styles> {
  profileBeingAdded: addedProfileType;
  gradeError: boolean;
  setProfileBeingAdded: React.Dispatch<React.SetStateAction<addedProfileType>>;
  setError: React.Dispatch<React.SetStateAction<addedProfileErrorType>>;
}

const EditGrade = ({
  classes,
  profileBeingAdded,
  gradeError,
  setProfileBeingAdded,
  setError,
}: props) => {
  const profileGrade = allGrades.find(
    ({grade}) => grade === profileBeingAdded.grade,
  );

  const updateProfileGrade = (e: any) => {
    if (!e.target.value) {
      return;
    }

    setError((error: addedProfileErrorType) => ({
      ...error,
      gradeError: false,
    }));

    const selectedGrade = allGrades.find(({value}) => value === e.target.value);
    if (!selectedGrade?.grade) {
      return;
    }

    setProfileBeingAdded((profile: addedProfileType) => ({
      ...profile,
      grade: selectedGrade.grade,
    }));
  };

  return (
    <FormControl
      variant="outlined"
      className={classes.inputField}
      error={gradeError}>
      <InputLabel id="grade-label" className={classes.fieldLabel}>
        Child's Grade
      </InputLabel>
      <Select
        labelId="grade-label"
        label="Child's Grade"
        className={classes.input}
        value={profileGrade?.value || ''}
        IconComponent={KeyboardArrowDownIcon}
        onChange={updateProfileGrade}>
        {allGrades.map(({grade, value}, index) => (
          <MenuItem value={value} key={index}>
            {grade}
          </MenuItem>
        ))}
      </Select>
      {gradeError && (
        <FormHelperText>The child's grade is required</FormHelperText>
      )}
    </FormControl>
  );
};

export default withStyles(styles)(EditGrade);

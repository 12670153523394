import {Theme} from '@mui/material';
import {makeStyles} from '@mui/styles';

export default makeStyles((theme: Theme) => ({
  verificationInput: {
    margin: 'auto',
    '& input': {
      fontFamily: theme.typography.fontFamily,
    },
    '& input:focus': {
      outline: 'none',
      border: `2px solid ${theme.palette.primary.light}`,
      caretColor: theme.palette.text.primary,
    },
  },
}));

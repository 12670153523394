import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {LAPTOP_BREAKPOINT} from 'shared/styles/breakpoints';
import {Purchase, PurchasesFilter} from 'shared/types/purchases';
import SwiperCore, {Scrollbar} from 'swiper';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import PurchaseItem from './PurchaseItem';
import {usePurchaseListStyles} from './PurchaseList.styles';

SwiperCore.use([Scrollbar]);
interface HorizontalPurchaseListProps
  extends WithStyles<typeof usePurchaseListStyles> {
  items: Purchase[];
  filter?: PurchasesFilter;
  productType?: string;
}
const HorizontalPurchaseList = ({
  classes,
  items,
  filter,
  productType,
}: HorizontalPurchaseListProps) => (
  <Swiper
    grabCursor
    spaceBetween={20}
    slidesPerView={1}
    scrollbar={true}
    breakpoints={{
      [LAPTOP_BREAKPOINT]: {
        slidesPerView: 2,
      },
    }}>
    {items.map(
      (item: Purchase, i: number) =>
        item.status && (
          <SwiperSlide key={i} className={classes.slider}>
            <PurchaseItem
              item={item}
              upcomingPurchase={filter?.showUpcomingPurchases}
              productType={productType}
            />
          </SwiperSlide>
        ),
    )}
  </Swiper>
);

export default withStyles(usePurchaseListStyles)(HorizontalPurchaseList);

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {TabContext, TabPanel} from '@mui/lab';
import Section from 'customerPortal/components/Section';
import {WithStyles} from '@mui/styles';
import {tabStyles} from './BookManagementScreen.styles';
import {useHistory, useParams} from 'react-router-dom';
import {TabOptions} from 'customerPortal/constants/bookTabs';
import Tabs from 'customerPortal/components/Tabs/Tabs';
import Purchases from './Purchases/Purchases';

const BookManagementScreen = ({classes}: WithStyles<typeof tabStyles>) => {
  const history = useHistory();
  const {tab} = useParams<{tab: TabOptions}>();

  const handleChange = (event: any, newTab: TabOptions) => {
    history.push(`/account/books/${newTab}`);
  };

  return (
    <Section title={'My Boxes'}>
      <TabContext value={tab}>
        <Tabs activeTab={tab} onChange={handleChange} />
        <TabPanel value={TabOptions.current} className={classes.panel}>
          <Purchases filter={{showCurrentPurchases: true}} />
        </TabPanel>
        <TabPanel value={TabOptions.upcoming} className={classes.panel}>
          <Purchases filter={{showUpcomingPurchases: true}} />
        </TabPanel>
        <TabPanel value={TabOptions.previous} className={classes.panel}>
          <Purchases filter={{showPastPurchases: true}} />
        </TabPanel>
      </TabContext>
    </Section>
  );
};

export default withStyles(tabStyles)(BookManagementScreen);

import {Typography} from '@mui/material';
import Box from '@mui/material/Box';
import React, {FC, useEffect, useState} from 'react';
import {blue500} from '../../../../shared/styles/palette';
import {useExternalBooks} from '../Library.hooks';
import LibraryService from '../Library.service';
import {Book, BookFilter, Pagination} from '../Library.types';
import BooksList from './BooksList';

const loadMore = (
  pageInfo: {page: number; totalPages: number; cursor: string},
  pagination: Pagination,
  setPagination: (pagination: Pagination) => void,
) => {
  const newPagination = {
    ...pagination,
    cursor: pageInfo.cursor,
  };

  if (!pageInfo.page) {
    return;
  }

  if (pageInfo.page < pageInfo.totalPages) {
    setPagination(newPagination);
  }
};

type Props = {
  pagination: Pagination;
  filter: BookFilter;
  setPagination: (pagination: Pagination) => void;
};

const Books: FC<Props> = ({pagination, filter, setPagination}: Props) => {
  const [books, setBooks] = useState<Book[]>([]);

  const {data: fetchedBooks, pageInfo} = useExternalBooks<Book>(
    pagination,
    filter,
  );

  useEffect(() => {
    if (pageInfo.page === 0) {
      const isBlankPageInfo = pageInfo.cursor === '';
      if (isBlankPageInfo) {
        return;
      }

      const booksReset = books.length === 0;
      if (booksReset) {
        return;
      }

      const noBooks = books.length > 0;
      if (noBooks) {
        setBooks([]);
        return;
      }
    }

    const paginationReset = pageInfo.page === 1;
    if (paginationReset) {
      setBooks(fetchedBooks);
      return;
    }

    const uniqueBooks = LibraryService.uniqueBooks([...books, ...fetchedBooks]);
    const booksAlreadyAdded = uniqueBooks.length === books.length;
    if (booksAlreadyAdded) {
      return;
    }

    setBooks(uniqueBooks);
    return;
  }, [books, fetchedBooks, pageInfo, setBooks]);

  return (
    <Box mt={6} mb={4}>
      <Box sx={{maxWidth: '1360px', margin: '0 auto'}}>
        <Typography
          sx={{
            mt: 8,
            fontSize: '1.625rem',
            fontWeight: 700,
            color: blue500,
          }}>
          All Books
        </Typography>
      </Box>
      <BooksList
        books={books}
        loadMore={() => loadMore(pageInfo, pagination, setPagination)}
      />
    </Box>
  );
};

export default Books;

import React from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import Lottie from 'react-lottie-player';
import elloSuccess from 'shared/assets/lottie/elloSuccess.json';
import styles from './AddChildSuccess.styles';
import {useHistory} from 'react-router-dom';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import Button from 'shared/components/common/Button';
import {FlowLayout} from 'customerPortal/components/Flow';
import {toUSDateFormat} from 'shared/utils/dateUtils';

interface Props extends WithStyles<typeof styles> {
  childName: string;
  nextCycleDate?: string;
}

const AddChildSuccess = ({classes, childName, nextCycleDate}: Props) => {
  const history = useHistory();
  const nextMailingDate =
    nextCycleDate && toUSDateFormat(new Date(nextCycleDate).getTime());

  return (
    <FlowLayout
      title="Your subscription has been updated"
      subtitle={`${childName} is now ready to start reading! ${
        nextMailingDate
          ? `They will receive their first set of books on ${nextMailingDate}`
          : ''
      }`}
      cta={
        <Button
          onClick={() => history.push(PRIVATE_ROUTES.ACCOUNT_SUB.path)}
          className={classes.button}>
          Finish
        </Button>
      }>
      <Lottie loop animationData={elloSuccess} play />
    </FlowLayout>
  );
};

export default withStyles(styles)(AddChildSuccess);

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import moment from 'moment';

const TrialCompliance = ({
  classes,
  priceString,
  trialPeriodDays,
  rewardCoupon,
  giftCode,
}) => {
  const trialEnd = moment().add(trialPeriodDays, 'days');

  const calculateEndDate = () => {
    if (giftCode) {
      return moment(trialEnd).add(3, 'months');
    }
    if (rewardCoupon) {
      return moment(trialEnd).add(1, 'month');
    }
    return trialEnd;
  };

  const getEndMessage = () => {
    if (giftCode) {
      return 'Gift Subscription ends';
    }
    if (rewardCoupon) {
      return 'first free month ends';
    }
    return 'trial ends';
  };

  const endDate = calculateEndDate();
  const formattedEndDate = endDate.format('MMM D YYYY');
  const endMessage = getEndMessage();

  return (
    <Box mt={1} mb={1}>
      <Box>
        <Typography
          align="left"
          component="p"
          variant="caption"
          className={classes.subscriptionDetails}>
          {`Cancel any time. You will be charged $${priceString} on ${formattedEndDate} when your ${endMessage}. Sales tax may apply.`}
        </Typography>
      </Box>
    </Box>
  );
};

export default TrialCompliance;

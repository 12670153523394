import {Chip} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import moment from 'moment';
import React from 'react';
import {
  isWithin5Days,
  getFormattedDate,
  isDateInPast,
} from 'shared/utils/dateUtils';
import styles from './ReturnByDateChip.styles';
import cN from 'classnames';

interface ReturnByDateChipProps extends WithStyles<typeof styles> {
  returnByDate: string;
  isMobile?: boolean;
}
const ReturnByDateChip = ({
  classes,
  returnByDate,
  isMobile = false,
}: ReturnByDateChipProps) => {
  const returnBy = moment(returnByDate).toDate();
  const isDueDateWithin5days = isWithin5Days(new Date(returnBy).getTime());
  const pastDueDate = isDateInPast(new Date(returnBy).getTime());
  const formattedDate = getFormattedDate(new Date(returnBy).getTime()).friendly(
    false,
  );
  const remainingDaysToReturn =
    moment(new Date(returnBy).getTime()).diff(
      moment(new Date().getTime()),
      'days',
    ) + 1;
  const daysWord = remainingDaysToReturn === 1 ? 'day' : 'days';
  const chipText = isDueDateWithin5days
    ? `Return in ${remainingDaysToReturn} ${daysWord}`
    : isMobile
    ? `Return by ${formattedDate}`
    : formattedDate;
  return (
    <Chip
      size="small"
      label={chipText}
      variant="outlined"
      className={cN(
        classes.chip,
        isDueDateWithin5days || pastDueDate
          ? classes.almostDueDateChip
          : classes.dueDateChip,
      )}
    />
  );
};

export default withStyles(styles)(ReturnByDateChip);

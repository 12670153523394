import Box from '@mui/material/Box';
import React, {FC} from 'react';
import ExternalNavigation from '../../components/ExternalNavigation/ExternalNavigation';
import Library from '../../components/Library/Library';

const ExternalListScreen: FC = () => {
  return (
    <Box>
      <ExternalNavigation />
      <Library />
    </Box>
  );
};

export default ExternalListScreen;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from '../../../../../shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    modalBox: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      maxWidth: 550,
      width: '80vw',
      borderRadius: '1em',
      backgroundColor: palette.white,
      padding: '1rem',
    },
    modalHeader: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    modalHeaderText: {
      fontWeight: 700,
      color: palette.gray900,
    },
    profileCharactersContainer: {
      display: 'flex',
      flexWrap: 'wrap',
      overflow: 'scroll',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginTop: '2rem',
      },
    },
    profilePicButton: {
      minWidth: '45%',
      flex: 1,
      padding: 0,
      borderRadius: '1rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        minWidth: '30%',
      },
    },
    profilePictureImage: {
      width: '100%',
      height: '100%',
      borderRadius: '1rem',
      padding: '0.2rem',
    },
    changeProfilepPictureHeader: {
      fontWeight: 700,
      fontSize: '22px',
      color: palette.blue500,
      textAlign: 'center',
      marginBottom: '2rem',
    },
    changePicButton: {
      textTransform: 'none',
      color: palette.turquoise900,
      fontWeight: 700,
      fontSize: '16px',
      maxHeight: '3rem',
      marginTop: '1rem',
    },
    profilePic: {
      borderRadius: '50%',
      width: '30%',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginRight: '2rem',
      },
    },
    editImageContainer: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginTop: '2rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flexDirection: 'row',
      },
    },
    editPicActions: {
      display: 'flex',
      justifyContent: 'flex-end',
      alignItems: 'center',
      height: '45px',
      marginTop: '1rem',
    },
    saveButton: {
      minWidth: '200px',
      height: '100%',
      margin: '1.5rem 0',
      color: palette.white,
      borderRadius: '2rem',
      backgroundColor: palette.turquoise900,
      fontWeight: 800,
      textTransform: 'none',
      fontSize: '1rem',
      lineHeight: '1.5rem',
      '&:hover': {
        backgroundColor: palette.turquoise900,
      },
    },
    cancelButton: {
      marginRight: '0.5rem',
      height: '100%',
      minWidth: '200px',
      borderRadius: '2rem',
      color: palette.blue500,
      fontWeight: 800,
      textTransform: 'none',
      fontSize: '1rem',
      lineHeight: '1.5rem',
    },
    nextButton: {
      width: '100%',
      height: '100%',
      fontWeight: 800,
      color: palette.white,
      borderRadius: '2rem',
      backgroundColor: palette.turquoise900,
      '&:hover': {
        backgroundColor: palette.turquoise900,
      },
    },
    backButton: {
      width: '100%',
      height: '100%',
      fontWeight: 800,
      margin: '1rem 0',
      color: palette.gray900,
    },
    removeProfileButton: {
      color: palette.gray500,
      fontWeight: 600,
      fontSize: '1rem',
      marginTop: '3rem',
      paddingLeft: 0,
      textTransform: 'none',
    },
    removeProfileHeader: {
      fontWeight: 800,
      fontSize: '1.75rem',
      lineHeight: '2.5rem',
      color: palette.blue500,
      margin: `${theme.spacing(2)}px 0`,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        margin: 0,
      },
    },
    removeProfileSubtitle: {
      fontWeight: 500,
      fontSize: '1.2rem',
      lineHeight: '2rem',
      color: palette.blue500,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginBottom: '2rem',
      },
    },
    removeProfileActions: {
      display: 'flex',
      flexDirection: 'column',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flexDirection: 'row-reverse',
      },
    },
    removeProfileContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      padding: '1rem',
      height: '90vh',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        height: 'auto',
      },
    },
    removeProfileModalBox: {
      position: 'absolute' as const,
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      width: '50vw',
      borderRadius: '1em',
      backgroundColor: palette.white,
      padding: '1rem',
    },
    removeProfileModalHeaderContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      marginBottom: '20px',
    },
    removeProfileConfirmButton: {
      color: palette.white,
      backgroundColor: palette.orange500,
      borderRadius: '2rem',
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textTransform: 'none',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      '&:hover': {
        backgroundColor: palette.orange500,
      },
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        padding: 0,
        width: '200px',
      },
    },
    removeProfileCancelButton: {
      color: palette.blue500,
      borderRadius: '2rem',
      fontWeight: 800,
      fontSize: '1rem',
      lineHeight: '1.5rem',
      textTransform: 'none',
      padding: `${theme.spacing(2)}px ${theme.spacing(4)}px`,
      marginTop: '2rem',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        marginTop: 0,
        width: '200px',
        marginRight: '2rem',
      },
    },
    closeIcon: {
      fontSize: '2rem',
    },
  });

export default styles;

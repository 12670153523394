import {Box, Typography} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import React from 'react';
import {ReactComponent as SubscriptionCancelledSVG} from 'shared/assets/subscriptionCancelled.svg';
import styles from './CancellationConfirmed.styles';

interface Props extends WithStyles<typeof styles> {
  cancellationText: string;
  subtext: string;
}

export const CancellationConfirmedBody = withStyles(styles)(
  ({classes, subtext, cancellationText}: Props) => {
    return (
      <Box className={classes.topContainer}>
        <Typography variant={'h6'} align={'center'} className={classes.title}>
          {cancellationText}
        </Typography>
        <Box>
          <SubscriptionCancelledSVG className={classes.icon} />
        </Box>
        <Typography className={classes.subtext}>{subtext}</Typography>
      </Box>
    );
  },
);

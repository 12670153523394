import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {Category as CategoryType} from '../Library.types';
import Category from './Category';

const categoryListBreakPoint = '@media (min-width: 1410px)';
type Props = {
  categories: CategoryType[];
};

const CategoryList: FC<Props> = ({categories}: Props) => {
  return (
    <Box
      sx={{
        maxWidth: '1360px',
        [categoryListBreakPoint]: {
          margin: '0 auto',
        },
      }}>
      {categories.map(category => (
        <Category key={category.id} category={category} />
      ))}
    </Box>
  );
};

export default CategoryList;

import {LoginErrorMessage} from 'customerPortal/screens/CustomerLoginScreen/constants';
import {
  CountryType,
  phoneNumberRegex,
} from '../screens/CustomerLoginScreen/LoginPhoneNumber/LoginPhoneNumber.service';

interface QueryVariables {
  variables: {
    phoneNumber: string;
  };
}

interface QueryResult {
  data: {
    validatePhoneUser: boolean;
  };
}

const validateNumberAndProceedToAuth = async (
  phoneNumber: string,
  country: CountryType,
  setIsLoading: (l: boolean) => void,
  setError: (e: any) => void,
  queryPhoneValidation: (variables: QueryVariables) => Promise<QueryResult>,
  requestAuthCode: (phoneNumber: string) => Promise<void>,
) => {
  setIsLoading(true);

  const sanitizedNumber = phoneNumber.replace(/[^0-9+]/g, '');
  const regex = phoneNumberRegex(country);
  const isValidPhone = regex.test(sanitizedNumber);

  if (!isValidPhone) {
    setError(
      `The number you have entered is not a valid ${country.label} number`,
    );
    setIsLoading(false);
    return;
  }

  try {
    const formattedNumber = `+${country.phone}${sanitizedNumber}`;
    const {data: queryResult} = await queryPhoneValidation({
      variables: {
        phoneNumber: formattedNumber,
      },
    });

    if (!queryResult.validatePhoneUser) {
      setError(LoginErrorMessage.UnregisteredNumber);
      setIsLoading(false);
      return;
    }

    await requestAuthCode(formattedNumber);
    setIsLoading(false);
  } catch (e) {
    console.error('Validation::', e);
    setError(LoginErrorMessage.ApiError);
    setIsLoading(false);
  }
};

export default validateNumberAndProceedToAuth;

import makeStyles from '@mui/styles/makeStyles';
import * as palette from 'shared/styles/palette';

export default makeStyles(theme => ({
  finishButton: {
    // Center align
    '&:only-child': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    border: `0.05em solid ${theme.palette.primary.light}`,
    backgroundColor: '#fafafa',
    color: '#a3a3a3',
  },
  submitButton: {
    // Center align
    '&:only-child': {
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    color: '#ffffff',
  },
  buttons: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'space-between',
    backgroundColor: 'transparent',
  },
  buttonsFinish: {
    padding: theme.spacing(2),
    display: 'flex',
    justifyContent: 'center',
  },
  button: {
    // More or less the same as Webflow
    paddingLeft: '20px',
    paddingRight: '20px',
    paddingTop: '10px',
    paddingBottom: '10px',
    fontSize: '16px',
    borderRadius: '50px',
    minWidth: '120px',
    maxWidth: '150px',
  },
  nextButton: {
    // Pull to right when there's no back button
    '&:only-child': {
      marginLeft: 'auto',
    },
  },
  fabNext: {
    position: 'fixed',
    bottom: 24,
    right: 24,
  },
  fabBack: {
    position: 'fixed',
    top: 24,
    left: 8,
    width: 50,
    height: 50,
    zIndex: 1,
    color: palette.blue500,
  },
  backButton: {
    color: palette.blue500,
    '&:hover': {
      backgroundColor: palette.iconButtonHoverColor,
    },
    width: 50,
    height: 50,
  },
  fabSpacer: {
    width: '100%',
    height: 80, // 56px FAB + 24px bottom padding
  },
}));

import firebase from 'firebase';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import Button, {ButtonType} from 'shared/components/common/Button';
import {PUBLIC_ROUTES} from '../../../../shared/components/Route/routes';
import {blue500} from '../../../../shared/styles/palette';

function deleteAccessToken() {
  document.cookie =
    'ello_access_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;';
}

const LogoutCTA: FC = () => {
  const history = useHistory();

  const onSignOut = () => {
    firebase.auth().signOut();
    deleteAccessToken();
    history.push(PUBLIC_ROUTES.ROOT);
  };

  return (
    <Button
      variant={ButtonType.Inline}
      onClick={onSignOut}
      sx={{
        fontSize: '1.125rem',
        color: blue500,
      }}>
      Log Out
    </Button>
  );
};

export default LogoutCTA;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import {useWindowSize} from 'shared/utils/layoutHooks';
import useStyles from './ProductSelectionPanel.styles';
import ToolTip from './Tooltip';

const ProductDescription = ({description}) => {
  const classes = useStyles();

  const [windowWidth] = useWindowSize();
  const limitDescription = windowWidth < 385;
  const truncatedDescription = description.substring(0, 50) + '...';

  return (
    <Box>
      {limitDescription ? (
        <ToolTip text={description}>
          <Typography className={classes.productBody}>
            {truncatedDescription}
          </Typography>
        </ToolTip>
      ) : (
        <Typography className={classes.productBody}>{description}</Typography>
      )}
    </Box>
  );
};

export default ProductDescription;

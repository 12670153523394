import withStyles from '@mui/styles/withStyles';
import React from 'react';
import cN from 'classnames';
import {WithStyles} from '@mui/styles';
import styles from './Card.styles';

interface Props extends WithStyles<typeof styles> {
  children: React.ReactNode;
  className?: string;
}

const Card = ({classes, className, children}: Props) => {
  return <div className={cN(classes.container, className)}>{children}</div>;
};

export default withStyles(styles)(Card);

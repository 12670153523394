import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {BORDER_RADIUS, TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {blue500, gray, gray700, turquoise900} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    figure: {
      margin: 0,
      width: '100%',
    },
    img: {
      width: '100%',
    },
    passage: {
      backgroundColor: gray,
      borderRadius: BORDER_RADIUS,
      margin: `${theme.spacing(2)} 0`,
      padding: theme.spacing(2),
      color: blue500,
      fontWeight: 700,
      fontSize: `1rem`,
      lineHeight: '25px',
      overflow: 'hidden',
      position: 'relative',
      display: '-webkit-box',
      '-webkit-line-clamp': 3,
      '-webkit-box-orient': 'vertical',
      '&::after': {
        content: '""',
        width: '100%',
        height: theme.spacing(3),
        position: 'absolute',
        left: 0,
        bottom: -theme.spacing(1),
        background: gray,
      },
    },
    caption: {
      textAlign: 'center',
      color: gray700,
      fontSize: '0.875rem',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '0.8125rem',
        fontWeight: 400,
      },
    },
    previewBtn: {
      color: turquoise900,
      fontSize: `1rem`,
      fontWeight: 700,
      margin: `${theme.spacing(1)} auto`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    iconEye: {
      marginRight: theme.spacing(1),
    },
  });

export default styles;

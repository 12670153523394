import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React, {FC} from 'react';
import {blue500, white} from '../../../shared/styles/palette';

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
};

const ExternalMenuButton: FC<Props> = ({openDrawer, setOpenDrawer}: Props) => {
  const toggleOpen = () => {
    setOpenDrawer(!openDrawer);
  };

  return (
    <Box>
      <Box sx={{mt: '-4px', mr: -1}}>
        <IconButton onClick={toggleOpen}>
          {!openDrawer && <MenuIcon fontSize="large" sx={{color: blue500}} />}
          {openDrawer && <CloseIcon fontSize="large" sx={{color: white}} />}
        </IconButton>
      </Box>
    </Box>
  );
};

export default ExternalMenuButton;

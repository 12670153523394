import {FetchResult, MutationFunctionOptions} from '@apollo/client';
import AnalyticsService, {
  ShareMethod,
} from '../../../analytics/Analytics.service';
import {collectQueryParams} from '../../utils/queryParams';

async function assignRewardBooks(
  assignRewardBooks: (options: MutationFunctionOptions) => Promise<FetchResult>,
  shareMethod: string,
): Promise<void> {
  try {
    const params = collectQueryParams();
    const uid = confirmationId(params);
    await assignRewardBooks({
      variables: {
        input: {onboardingResponseUID: uid, shareMethod},
      },
    });
  } catch (e) {
    console.log(e);
    throw e;
  }
}

function bodyText(
  showShareButton: boolean,
  text: {
    copyLinkText?: string;
    shareLinkText?: string;
  },
): string {
  const defaultShareLinkText =
    'Click the button below to share Ello with a friend';
  if (showShareButton) {
    return text.shareLinkText || defaultShareLinkText;
  }

  const defaultCopyLinkText = 'Click the “Copy link” button and share Ello';
  return text.copyLinkText || defaultCopyLinkText;
}

function confirmationId(params: {
  confirmationId?: string;
  onboardingResponseUid?: string;
}): string {
  if (params.confirmationId) {
    return params.confirmationId;
  }

  if (params.onboardingResponseUid) {
    return params.onboardingResponseUid;
  }

  return '';
}

async function copyLinkToClipboard(link: string): Promise<boolean> {
  try {
    if (!navigator?.clipboard) {
      return false;
    }

    await navigator.clipboard.writeText(link);
    AnalyticsService.trackCopiedShareLink(ShareMethod.CopyToClipboard);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

async function copyLinkAndShare(link: string, text?: string): Promise<boolean> {
  try {
    if (!canShareLink()) {
      return false;
    }

    await navigator.share({
      title: 'Read with Ello',
      text: text || 'Check out this cool new reading app I found!',
      url: link,
    });
    AnalyticsService.trackCopiedShareLink(ShareMethod.NativeShare);
    return true;
  } catch (e) {
    console.log(e);
    return false;
  }
}

function canShareLink(): boolean {
  return !!navigator?.share;
}

export const ShareableLinkService = {
  assignRewardBooks,
  bodyText,
  confirmationId,
  copyLinkToClipboard,
  canShareLink,
  copyLinkAndShare,
};

import React from 'react';
import Section from './Section/Section';
import {TrialDetails} from './TrialSelection.types';

type Props = {
  details: TrialDetails;
  onNext: () => void;
  onValidationChanged: (isValid: boolean) => void;
};

function TrialSelectionPanel(props: Props) {
  const {details, onNext, onValidationChanged} = props;
  return (
    <Section
      details={details}
      onNext={onNext}
      onValidationChanged={onValidationChanged}>
      Trial selection
    </Section>
  );
}

export default TrialSelectionPanel;

import pageLevelA from 'shared/assets/bookPages/pageLevelA.jpg';
import pageLevelB from 'shared/assets/bookPages/pageLevelB.jpg';
import pageLevelC from 'shared/assets/bookPages/pageLevelC.jpg';
import pageLevelD from 'shared/assets/bookPages/pageLevelD.jpg';
import pageLevelE from 'shared/assets/bookPages/pageLevelE.jpg';
import pageLevelF from 'shared/assets/bookPages/pageLevelF.jpg';
import pageLevelG from 'shared/assets/bookPages/pageLevelG.jpg';
import pageLevelH from 'shared/assets/bookPages/pageLevelH.jpg';
import pageLevelI from 'shared/assets/bookPages/pageLevelI.jpg';
import pageLevelJ from 'shared/assets/bookPages/pageLevelJ.jpg';
import pageLevelK from 'shared/assets/bookPages/pageLevelK.jpg';
import pageLevelL from 'shared/assets/bookPages/pageLevelL.jpg';
import pageLevelM from 'shared/assets/bookPages/pageLevelM.jpg';
import pageLevelN from 'shared/assets/bookPages/pageLevelN.jpg';
import {ReadingLevel} from 'shared/types/readingLevels';

interface BookSample {
  level: ReadingLevel;
  title: string;
  author: string;
  text: string;
  image: string;
}

export const BOOK_SAMPLES: Record<ReadingLevel, BookSample> = {
  [ReadingLevel.A]: {
    level: ReadingLevel.A,
    title: 'Jump',
    author: 'David McPhail',
    text: 'A frog can jump.',
    image: pageLevelA,
  },
  [ReadingLevel.B]: {
    level: ReadingLevel.B,
    title: 'Pig Has a Plan',
    author: 'Ethan Long',
    text: 'Fly wants to sip. Pig cannot nap.',
    image: pageLevelB,
  },
  [ReadingLevel.C]: {
    level: ReadingLevel.C,
    title: 'Three Little Pigs',
    author: 'Michèle Dufresne',
    text: `"Here is my house,” said the little pig. “I like my house.”`,
    image: pageLevelC,
  },
  [ReadingLevel.D]: {
    level: ReadingLevel.D,
    title: 'Fox the Tiger',
    author: 'Corey R. Tabor',
    text: '"I am not Turtle. I am Race Car," says Race Car. "I zip and zoom.”',
    image: pageLevelD,
  },
  [ReadingLevel.E]: {
    level: ReadingLevel.E,
    title: 'Pete the Cat: Too Cool for School',
    author: 'James Dean',
    text: 'Pete puts on HIS favorite socks. Pete puts on HIS favorite shoes. Pete puts on his sunglasses.',
    image: pageLevelE,
  },
  [ReadingLevel.F]: {
    level: ReadingLevel.F,
    title: 'A Color of His Own',
    author: 'Leo Lionni',
    text: 'With this thought he cheerfully climbed onto the greenest leaf.',
    image: pageLevelF,
  },
  [ReadingLevel.G]: {
    level: ReadingLevel.G,
    title: 'Just for you',
    author: 'Mercer Mayer',
    text: 'I wanted to take a nap just for you, but the bed was too bouncy.',
    image: pageLevelG,
  },
  [ReadingLevel.H]: {
    level: ReadingLevel.H,
    title: 'Swimmy',
    author: 'Leo Lionni',
    text: 'A happy school of little fish lived in a corner of the sea somewhere. They were all red. Only one of them was as black as a mussel shell. He swam faster than his brothers and sisters. His name was Swimmy.',
    image: pageLevelH,
  },
  [ReadingLevel.I]: {
    level: ReadingLevel.I,
    title: 'When I Am Old With You',
    author: 'Angela Johnson',
    text: `When I'm old with you, Grandaddy, I will sit in a big rocking chair beside you and talk about everything.`,
    image: pageLevelI,
  },
  [ReadingLevel.J]: {
    level: ReadingLevel.J,
    title: 'Diary of a Wombat',
    author: 'Jackie French',
    text: `I'm a wombat. I live in Australia. As you can see from my picture, I look a little like a bear, but smaller. I live in a hole in the ground. I come out mostly at night, and during the day I sleep. I eat grass and roots and, of course, the occasional treat...`,
    image: pageLevelJ,
  },
  [ReadingLevel.K]: {
    level: ReadingLevel.K,
    title: 'Sheila Rae, the Brave',
    author: 'Kevin Henkes',
    text: `At school, Sheila Rae giggled when the principal walked by. And when her classmate Wendell stole her jump rope during recess, Sheila Rae tied him up until the bell rang. “I am very brave,” Sheila Rae said, patting herself on the back.`,
    image: pageLevelK,
  },
  [ReadingLevel.L]: {
    level: ReadingLevel.L,
    title: `Imogene's Antlers`,
    author: 'David Small',
    text: 'Her brother, Norman, consulted the encyclopedia, and then announced that Imogene had turned into a rare form of miniature elk!',
    image: pageLevelL,
  },
  [ReadingLevel.M]: {
    level: ReadingLevel.M,
    title: 'Dream Wolf',
    author: 'Paul Goble',
    text: `In the old days the people traveled over the plains. They followed the great herds of buffalo. Every year when the berries were ripe, they would leave the plains and go up into the hills. They made camp in a valley where the berry bushes grow. Everyone picked great quantities. They mashed the berries into little cakes which they dried in the sun. These they stored in painted bags for the winter.`,
    image: pageLevelM,
  },
  [ReadingLevel.N]: {
    level: ReadingLevel.N,
    title: 'Fletcher and the Falling Leaves',
    author: 'Julia Rawlinson',
    text: `The world was changing. Each morning, when Fletcher bounded out of the den, everything seemed just a little bit different. The rich green of the forest was turning to a dusty gold, and the soft, swishing sound of summer was fading to a crinkly whisper. Fletcher's favorite tree looked dull, dry, and brown. Fletcher was beginning to get worried.`,
    image: pageLevelN,
  },
};

import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const flowContainerStyles = makeStyles((theme: Theme) => ({
  container: {
    padding: 0,
    display: 'flex',
    alignItems: 'baseline',
    marginBottom: theme.spacing(10),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'block',
    },
  },
  backBtnContainer: {
    flex: `0 1 ${theme.spacing(11)}`,
    marginLeft: -theme.spacing(1),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
}));

export default flowContainerStyles;

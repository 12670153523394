/**
 * A list of bullet checkmarks that we use as a stylistic element in the onboarding flows.
 */

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useStyles from './CheckmarkList.styles';
import CheckIcon from '@mui/icons-material/Check';

function CheckmarkList({bullets}) {
  const classes = useStyles();

  return (
    <Box className={classes.container}>
      {bullets.map(bullet => (
        <div className={classes.checklistItem}>
          <div className={classes.checklistIcon}>
            <CheckIcon />
          </div>
          <Typography className={classes.checklistText} component="p">
            {bullet}
          </Typography>
        </div>
      ))}
    </Box>
  );
}

export default CheckmarkList;

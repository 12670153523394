import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      textAlign: 'center',
    },
    lottie: {
      maxWidth: '200px',
      margin: '0 auto',
    },
    text: {
      margin: `${theme.spacing(5)} 0`,
      fontWeight: 400,
      fontSize: '1.25rem',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
        margin: `${theme.spacing(4)} 0`,
      },
    },
    button: {
      width: '100%',
    },
  });

export default styles;

import gql from 'graphql-tag';

export const QUERY_PURCHASES = gql`
  query Purchases($filter: PurchasesFilterArgs) {
    purchases(filter: $filter) {
      purchases {
        status
        orderReturnByDate
        subscriptionPeriod {
          id
          mainOrderId
          month
          readingLevels {
            readingLevel
          }
          startDate
          endDate
        }
        mainOrderShippingTrackingNumber
      }
    }
  }
`;

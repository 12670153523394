import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {BORDER_RADIUS} from 'shared/styles/breakpoints';
import * as palette from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    section: {
      paddingBottom: theme.spacing(1),
      alignItems: 'center',
      margin: 0,
    },
    navList: {
      padding: 0,
      margin: 0,
      listStyleType: 'none',
      '& li': {
        borderTop: `1px solid ${palette.gray300}`,
        padding: `${theme.spacing(0.5)} 0`,
      },
      '& li:first-of-type': {
        borderTop: `none`,
      },
    },
    link: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      borderRadius: BORDER_RADIUS,
      textDecoration: 'none',
      fontWeight: 700,
      color: palette.black,
      outline: 'none',
      padding: `${theme.spacing(1.4)} ${theme.spacing(1.8)}`,
      margin: `0 -${theme.spacing(2)}`,
      '&:hover, &:focus': {
        backgroundColor: palette.gray,
        color: palette.blue500,
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
    },
    icon: {
      marginLeft: -theme.spacing(1.7),
    },
    subtitle: {
      fontWeight: 'normal',
      color: palette.gray700,
    },
    chevronIcon: {
      marginLeft: theme.spacing(2.5),
    },
    title: {
      fontSize: '1.375rem',
    },
  });

export default styles;

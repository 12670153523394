/*
 * Text fields with validation used for onboarding flow.
 */

import Box from '@mui/material/Box';
import InputAdornment from '@mui/material/InputAdornment';
import TextField from '@mui/material/TextField';
import clsx from 'clsx';
import React, {useCallback, useEffect, useRef, useState} from 'react';
import {debounce} from 'shared/utils/layoutHooks';
import {useLocale, useStrings} from 'shared/utils/localization';
import {resolveQueryString} from 'shared/utils/stringUtils/stringUtils';
import {StringParam, useQueryParam} from 'use-query-params';
import {
  OnboardingFlowField,
  OnboardingFlowType,
} from '../../../types/onboardingFlow.type';
import useStyles from './FormTextField.styles';

function FormTextField({
  type,
  field,
  label,
  adornment,
  onValidationChanged,
  onFocus,
  onBlur,
  required,
  className,
}) {
  const classes = useStyles();
  const [error, setError] = useState('');
  const [queryValue, setQueryValue] = useQueryParam(field, StringParam);
  const didInitialValidation = useRef(false);
  const {locale} = useLocale();
  const strings = useStrings();

  // Validation status wrapper for performance, so we only update parent when needed
  const [validated, setValidated] = useState(null);

  const fields = {
    name: {
      type: 'text',
      label: strings.name,
      validate: value => {
        if (!(value.trim().split(' ').length > 1))
          return strings.fullNamePlease;
        else return null;
      },
    },
    phone: {
      type: 'tel',
      label: strings.phoneNumber,
      placeholder: '10 digit number',
      validate: value => {
        if (!value.match(/^\+[1]\d{10}$/)) return strings.invalidPhone;
        else return null;
      },
    },
    email: {
      type: 'email',
      label: strings.email,
      validate: value => {
        // eslint-disable-next-line no-useless-escape
        if (!value.match(/^[^\s@]+@[^\s@]+\.[^\s@]+$/))
          return strings.invalidEmail;
        else return null;
      },
    },
    text: {label: resolveQueryString(label, locale), type: 'text'},
    number: {label, type: 'number'},
    date: {
      type: 'date',
      label: strings.date,
      validate: value => {
        const year = value.split('-')[0];
        const minYear = 2020;
        const maxYear = 2100;

        if (year < minYear || year > maxYear) {
          return 'Invalid Date';
        } else {
          return null;
        }
      },
    },
  }[type];

  const validate = useCallback(
    (value, validator) => {
      // If we have a validate, trigger it. Otherwise everything goes.
      let valid = validator ? !validator(value) : true;
      // For text fields that don't have validation, check if text is nonempty
      if (required && valid) valid = value.length > 0;
      return valid;
    },
    [required],
  );

  const cleanInput = value => {
    if (type !== 'phone') {
      return value.trim();
    }

    const cleanValue = value.replace(/[^A-Za-z0-9+]+/g, '');
    const prefixedValue = cleanValue.startsWith('+1')
      ? cleanValue
      : `+1${cleanValue}`;
    return prefixedValue;
  };

  const inputHandler = ({target: {value}}) => {
    let cleanValue = cleanInput(value);

    let valid = validate(cleanValue, fields.validate);

    // Only toggle parent when change or when making updates to already valid value (e.g. changing email's .co to .com)
    // to avoid overloading the browser
    if (valid || valid !== validated) {
      // Set error messages, if we can get them
      debounce(() => {
        if (fields.validate) setError(fields.validate(cleanValue));
      }, 100);

      setValidated(valid);
      onValidationChanged(valid);

      // We commit the value to the query params here because (a) setting it with every keystroke
      // would slow the UI down too much, but (b) setting it only in onBlur causes an issue with
      // browser autocompleters where onBlur is never called.
      setQueryValue(cleanValue);

      // Update analytics records (email, phone, name), as they become finalized
      const customerInfo = [
        OnboardingFlowType.phone,
        OnboardingFlowType.email,
        OnboardingFlowType.name,
      ];
      const hasCustomerInfo =
        customerInfo.includes(type) &&
        field !== OnboardingFlowField.shareElloEmail;
      if (valid && hasCustomerInfo) {
        window.analytics.identify({
          [type]: cleanValue,
        });
      }
    }
  };

  useEffect(() => {
    // Perform an initial validation if value is provided via query params
    if (!didInitialValidation.current) {
      const needsValidate = !!queryValue && !validated;
      if (needsValidate) {
        const valid = validate(queryValue);
        setValidated(valid);
        onValidationChanged(valid);
      }
      didInitialValidation.current = true;
    }
  }, [queryValue, validate, onValidationChanged, validated]);

  return (
    <Box
      component="div"
      className={clsx(
        className,
        classes.fieldContainer,
        type === 'number' && classes.numberFieldContainer,
      )}>
      <TextField
        variant="outlined"
        id={field}
        type={fields.type}
        label={error ? error : fields.label}
        fullWidth={fields.type !== 'number'}
        className={classes.textField}
        placeholder={resolveQueryString(fields.placeholder, locale)}
        error={!!error}
        defaultValue={queryValue}
        // Yes, InputProps and inputProps are two different things in the material UI API...
        InputProps={
          !!adornment
            ? {
                endAdornment: (
                  <InputAdornment position="end">
                    {resolveQueryString(adornment, locale)}
                  </InputAdornment>
                ),
              }
            : {}
        }
        InputLabelProps={
          type === 'date'
            ? {
                shrink: true,
              }
            : null
        }
        inputProps={{
          // Force number pad on mobile, since we don't do decimals
          inputMode: type === 'number' ? 'numeric' : undefined,
        }}
        onKeyDown={event => {
          if (event.key === 'Enter') {
            // Perform validation and update once more to ensure we didn't miss input because
            // we're returning straight out of the text field (aka onBlur won't be called)
            inputHandler(event);
          }
        }}
        // Alternative look: helperText={error}
        onFocus={e => {
          setError('');
          if (onFocus) onFocus(e);
        }}
        onBlur={event => {
          let cleanValue = cleanInput(event.target.value);
          // Always display errors (if any) in onBlur, no matter if validation status changed
          // in the inputHandler().
          if (fields.validate) setError(fields.validate(cleanValue));

          inputHandler(event);
          if (onBlur) onBlur(event);
        }}
        // We use the more aggressive onInput event to get native callbacks and catch autofill
        // https://github.com/facebook/react/issues/1159
        // https://stackoverflow.com/questions/55244590/autofill-does-not-trigger-onchange
        // http://avernet.blogspot.com/2010/11/autocomplete-and-javascript-change.html
        onInput={inputHandler}
      />
    </Box>
  );
}

export default FormTextField;

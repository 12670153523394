/*
 * A dropdown Select form component.
 */

import React from 'react';
import {useQueryParam, StringParam} from 'use-query-params';
// We use NativeSelect because it looks better on mobile
import Select from '@mui/material/Select';
import {useLocale, Locales} from 'shared/utils/localization';
import {resolveQueryString} from 'shared/utils/stringUtils/stringUtils';
import useStyles from './FormDropdown.styles';

function FormDropdown({choices, field, onValidationChanged, onFocus, onBlur}) {
  const classes = useStyles();

  const [currentValue, setValue] = useQueryParam(field, StringParam);
  const {locale} = useLocale();

  return (
    <div className={classes.fieldContainer}>
      <Select
        native
        className={classes.slider}
        variant="outlined"
        fullWidth
        valueLabelDisplay="auto"
        defaultValue={currentValue}
        onFocus={onFocus}
        onBlur={onBlur}
        onChange={event => {
          setValue(event.target.value);
          if (event.target.value) onValidationChanged(true);
        }}>
        <option aria-label="None" value="" />
        {choices.map((value, index) => (
          <option value={resolveQueryString(value, Locales.enUS)}>
            {resolveQueryString(value, locale)}
          </option>
        ))}
      </Select>
    </div>
  );
}

export default FormDropdown;

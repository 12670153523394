import CheckIcon from '@mui/icons-material/Check';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import {markupComponents} from '../../ProductSelectionPanel/Choices/Choices.service';

type Props = {
  benefit: string;
};

const Benefit: FC<Props> = ({benefit}) => {
  return (
    <Box sx={{display: 'flex', mb: 1}}>
      <CheckIcon sx={{mt: '-2px', color: palette.turquoise900, mr: '4px'}} />
      <Typography
        variant="body2"
        sx={{fontWeight: 700, textAlign: 'left', whiteSpace: 'nowrap'}}>
        {markupComponents(benefit)}
      </Typography>
    </Box>
  );
};

export default Benefit;

import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  moneyBackContainer: {
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    backgroundColor: palette.turquoise100,
    borderRadius: '8px',
    paddingRight: '24px',
  },
  moneyBackBadge: {
    paddingLeft: theme.spacing(3),
    paddingRight: theme.spacing(3),
  },
  moneyBackText1: {
    fontWeight: 'bold',
    fontSize: '14px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '13px',
    },
    color: palette.blue500,
  },
  moneyBackText2: {
    fontWeight: 'normal',
    fontSize: '12px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '11px',
    },
    color: palette.gray900,
  },
}));

import React from 'react';
import {blockResubscriptionModalStyles as styles} from './AccountSubscription.styles';
import Box from '@mui/material/Box';
import RobotSVG from 'shared/assets/robot.svg';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import useMobileLayout from '../../../utils/useMobileLayout';
import {useHistory} from 'react-router-dom';

type Props = {
  onCloseModal?: () => void;
};

const BlockResubscriptionScreen = ({onCloseModal}: Props) => {
  const classes = styles();
  const history = useHistory();
  const isMobile = useMobileLayout();

  const closeScreen = () => {
    if (isMobile) {
      history.goBack();
      return;
    }

    onCloseModal && onCloseModal();
  };

  return (
    <Box className={classes.blockSubscriptionContainer}>
      <img src={RobotSVG} alt="Block resubscription" />

      <Typography className={classes.header}>
        Subscription Renewal Limited to Apple Devices
      </Typography>

      <Typography className={classes.subttitle}>
        We support subscription renewals only through Apple devices. If you're
        on a different device, please switch to an iPhone, iPad, or Mac to
        complete your renewal
      </Typography>

      <Button
        variant="contained"
        className={classes.button}
        onClick={closeScreen}>
        Close
      </Button>
    </Box>
  );
};
export default BlockResubscriptionScreen;

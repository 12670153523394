import React from 'react';
import FormControlLabel from '@mui/material/FormControlLabel';
import Box from '@mui/material/Box';
import Radio from '@mui/material/Radio';
import {ShippingOption as ShippingOptionType} from './ShippingOption.type';

type Props = {
  option: ShippingOptionType;
};

export const ShippingOption: React.FC<Props> = ({option}) => (
  <Box
    width="100%"
    sx={{
      m: '4px 4px 4px 0',
      width: '100%',
    }}>
    <FormControlLabel
      sx={{
        m: 0,
        width: '100%',
        paddingRight: '8px',
        '& .MuiFormControlLabel-label': {
          width: '100%',
        },
      }}
      value={option.type}
      control={
        <Radio
          size="small"
          sx={{
            padding: '4px',
            '& .MuiRadio-root': {},
          }}
        />
      }
      label={
        <Box display="flex" width="100%" alignItems="center">
          <Box
            sx={{
              fontSize: '0.8rem',
              flexGrow: 1,
              textAlign: 'left',
            }}>
            {option.title}
          </Box>
          <Box
            sx={{
              fontSize: '0.8rem',
              textAlign: 'right',
              marginLeft: 'auto',
            }}>
            {`$${Number(option.amount).toFixed(2)}`}
          </Box>
        </Box>
      }
    />
  </Box>
);

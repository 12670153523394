import {Theme} from '@mui/material/styles';

import makeStyles from '@mui/styles/makeStyles';

export const useLogoStyles = makeStyles((theme: Theme) => ({
  logo: {
    padding: theme.spacing(1),
    display: 'block',
  },
}));

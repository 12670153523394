import {supportsDigital} from '../../utils/productType';
import {
  ONBOARDING_MUTATION,
  ONBOARD_DIGITAL_CUSTOMER_MUTATION,
  PURCHASE_COUPON_MUTATION,
  PURCHASE_GIFT_BUNDLE,
  RESUBSCRIBE_MUTATION,
} from './PaymentPanel.query';
import {collectQueryParams} from '../../utils/queryParams';

export const PaymentAction = {
  PURCHASE_COUPON: 'purchase-coupon',
  RESUBSCRIBE: 'resubscribe',
};

export function getMutation(action, productType) {
  const params = collectQueryParams();

  if (params.giftBundle) {
    return {
      mutation: PURCHASE_GIFT_BUNDLE,
      supportsTrials: false,
    };
  }

  if (action === PaymentAction.PURCHASE_COUPON) {
    return {
      mutation: PURCHASE_COUPON_MUTATION,
      supportsTrials: false,
    };
  }

  if (action === PaymentAction.RESUBSCRIBE) {
    return {
      mutation: RESUBSCRIBE_MUTATION,
      supportsTrials: false,
    };
  }

  if (supportsDigital(productType)) {
    return {
      mutation: ONBOARD_DIGITAL_CUSTOMER_MUTATION,
      supportsTrials: true,
    };
  }

  return {
    mutation: ONBOARDING_MUTATION,
    supportsTrials: false,
  };
}

export function getShippingAmount(productDetails) {
  if (productDetails.shipping) {
    return productDetails.shipping;
  }

  const shippingOptions = productDetails.shippingOptions || [];
  if (shippingOptions.length) {
    const selectedOption = shippingOptions.find(option => option.selected);
    return selectedOption ? selectedOption.amount : shippingOptions[0].amount;
  }

  return 0;
}

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import Selector from '../Selector';

type Props = {
  grade?: string;
  options: {label: string; value: string}[];
  handleGradeChange: (grade: string) => void;
};

const FilterSelection: FC<Props> = ({
  grade,
  options,
  handleGradeChange,
}: Props) => {
  return (
    <Box sx={{maxWidth: '600px', margin: '0 auto', mt: 7}}>
      <Selector
        label="Reading Level"
        options={options}
        value={grade || 'all'}
        onChange={handleGradeChange}
      />
    </Box>
  );
};

export default FilterSelection;

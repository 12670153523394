import React from 'react';
import {Purchase, PurchasesFilter} from 'shared/types/purchases';
import PurchaseItem from './PurchaseItem';

interface VerticalPurchaseListProps {
  items: Purchase[];
  filter?: PurchasesFilter;
  productType?: string;
}
const VerticalPurchaseList = ({
  items,
  filter,
  productType,
}: VerticalPurchaseListProps) => (
  <div>
    {items.map(
      (item, index) =>
        item.status && (
          <PurchaseItem
            item={item}
            key={index}
            upcomingPurchase={filter?.showUpcomingPurchases}
            productType={productType}
          />
        ),
    )}
  </div>
);

export default VerticalPurchaseList;

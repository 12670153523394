import React, {FC, useEffect} from 'react';
import {useHistory, useLocation} from 'react-router-dom';
import {PRIVATE_ROUTES} from '../../../shared/components/Route/routes';
import {useAuth} from '../../../shared/utils/providers/authProvider';
import {useCustomer, useValidateToken} from './AccountManagerScreen.hooks';

type Props = {
  children: React.ReactNode;
};

const publicAccessTag = 'public-access';
function isPublicAccess(tags: string[]): boolean {
  if (!tags) {
    return false;
  }

  return tags.includes(publicAccessTag);
}

export const AccountManagerPinAuthProvider: FC<Props> = ({children}: Props) => {
  const location = useLocation();
  const history = useHistory();

  const auth = useAuth();
  const {customer} = useCustomer(!auth.user);
  const {validateToken} = useValidateToken();

  useEffect(() => {
    if (!auth.user) {
      return;
    }

    const {pathname} = location;
    if (pathname === PRIVATE_ROUTES.ACCOUNT_MANAGER_PIN.path) {
      return;
    }

    if (!isPublicAccess(customer?.tags)) {
      return;
    }

    const handleValidateToken = async () => {
      const valid = await validateToken();

      if (!valid?.success) {
        history.push(PRIVATE_ROUTES.ACCOUNT_MANAGER_PIN.path);
      }
    };
    handleValidateToken();
  }, [auth, customer, history, location, validateToken]);

  return <>{children}</>;
};

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import GoBackButton from '../GoBackButton';
import {useCategory} from '../Library.hooks';
import Category from './Category';

type Props = {
  backText: string;
  categoryId: string;
  goBack: () => void;
};

const CategoryByID: FC<Props> = ({backText, goBack, categoryId}: Props) => {
  const {data} = useCategory(categoryId);

  return (
    <Box mt={12} mb={4}>
      <GoBackButton backText={backText} goBack={goBack} />
      <Category category={data} />
    </Box>
  );
};

export default CategoryByID;

import React, {useEffect, useState} from 'react';

import {CircularProgress, TextField, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import CircleIcon from '@mui/icons-material/Check';
import {PasswordResetErrorMessage} from '../CustomerLoginScreen/constants';
import firebase from 'firebase/app';
import {WithStyles} from '@mui/styles';
import styles from './ResetPasswordScreen.styles';
import Button, {ButtonType} from '../../../shared/components/common/Button';
import BasicForm from './ResetPasswordScreen.form';
import {
  PasswordResetStep,
  checkPasswordsMatch,
} from './ResetPasswordScreen.service';
import PasswordVisibilityButton from '../../components/PasswordVisibilityButton/PasswordVisibilityButton';

interface Props extends WithStyles<typeof styles> {
  setError: (e: string) => void;
  setResetStep: (step: PasswordResetStep) => void;
  resetMode: string | undefined;
  requestCode: string | undefined;
}
const ResetPassword = ({
  resetMode,
  requestCode,
  setResetStep,
  setError,
  classes,
}: Props) => {
  const [password, setPassword] = useState('');
  const [confirmedPassword, setConfirmedPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmedPassword, setShowConfirmedPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [passwordError, setPasswordError] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const handleErrors = () => {
      setError(PasswordResetErrorMessage.InvalidLink);
      setResetStep(PasswordResetStep.GetEmail);
      setIsLoading(false);
    };

    if (!resetMode || !requestCode) {
      handleErrors();
      return;
    }

    if (resetMode !== 'resetPassword') {
      handleErrors();
      return;
    }

    firebase
      .auth()
      .verifyPasswordResetCode(requestCode)
      .then(email => {
        setEmail(email);
        setIsLoading(false);
      })
      .catch(error => {
        handleErrors();
      });
  }, [resetMode, requestCode, setError, setResetStep]);

  const passwordsDontMatch = () => {
    setError(PasswordResetErrorMessage.PasswordsDontMatch);
    setPasswordError(true);
  };

  const checkPasswordsMatchOnEntry = (
    enteredPassword: string,
    comparedPassword: string,
  ) => {
    const passwordsMatch = checkPasswordsMatch(
      enteredPassword,
      comparedPassword,
    );

    if (!passwordsMatch) {
      passwordsDontMatch();
      return;
    }

    setPasswordError(false);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(e.target.value);
    checkPasswordsMatchOnEntry(e.target.value, confirmedPassword);
  };

  const onChangeConfirmedPassword = (
    e: React.ChangeEvent<HTMLInputElement>,
  ) => {
    e.preventDefault();

    setConfirmedPassword(e.target.value);

    checkPasswordsMatchOnEntry(password, e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleConfirmedPasswordVisibility = () => {
    setShowConfirmedPassword(!showConfirmedPassword);
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    setIsLoading(true);
    event.preventDefault();

    if (!resetMode || !requestCode) {
      setError(PasswordResetErrorMessage.InvalidLink);
      setResetStep(PasswordResetStep.GetEmail);
      setIsLoading(false);
      return;
    }

    if (!password) {
      setPasswordError(true);
      setError(PasswordResetErrorMessage.EmptyPassword);
      setIsLoading(false);

      return;
    }

    if (password !== confirmedPassword) {
      setPasswordError(true);
      setError(PasswordResetErrorMessage.PasswordsDontMatch);
      setIsLoading(false);
      return;
    }

    setPasswordError(false);

    firebase
      .auth()
      .confirmPasswordReset(requestCode, password)
      .then(() => {
        setResetStep(PasswordResetStep.PasswordResetSuccess);
      })
      .catch(error => {
        setError(error.message);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <>
      {isLoading && (
        <CircularProgress
          color="primary"
          className={classes.loadingIndicator}
        />
      )}

      {!isLoading && (
        <BasicForm
          titleText={
            <>
              Setup your password for <b>{email}</b>
            </>
          }>
          <form onSubmit={onSubmit} className={classes.form}>
            <TextField
              style={{marginTop: 30}}
              id="password"
              label="New Password"
              name="password"
              type={showPassword ? 'text' : 'password'}
              error={passwordError}
              value={password}
              onChange={onChangePassword}
              variant="outlined"
              placeholder="New Password"
              autoFocus
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityButton
                    showPassword={showPassword}
                    onClick={togglePasswordVisibility}
                  />
                ),
              }}
            />

            <TextField
              style={{marginTop: 30}}
              id="confirm-password"
              label="Confirm New Password"
              name="confirm-password"
              type={showConfirmedPassword ? 'text' : 'password'}
              error={passwordError}
              value={confirmedPassword}
              onChange={onChangeConfirmedPassword}
              variant="outlined"
              placeholder="Confirm New Password"
              fullWidth
              required
              InputProps={{
                endAdornment: (
                  <PasswordVisibilityButton
                    showPassword={showConfirmedPassword}
                    onClick={toggleConfirmedPasswordVisibility}
                  />
                ),
              }}
            />

            <Typography
              style={{
                textAlign: 'left',
                marginTop: 30,
                color: 'gray',
                alignItems: 'center',
                display: 'flex',
              }}
              variant="subtitle2">
              {password.length < 6 ? (
                <span style={{marginRight: 10, fontSize: 20}}>&#8226; </span>
              ) : (
                <CircleIcon className={classes.passwordRequirementsButton} />
              )}
              Password must be at least 6 characters long
            </Typography>

            <Button
              variant={ButtonType.Primary}
              disabled={!password || password.length < 6}
              type="submit"
              className={classes.submitButton}>
              Save
            </Button>
          </form>
        </BasicForm>
      )}
    </>
  );
};

export default withStyles(styles)(ResetPassword);

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {gray200} from '../../../styles/palette';
import {
  fullHorizontalBreakpoint,
  paddingBreakpoint,
  partialHorizontalBreakpoint,
} from '../BreakPoints';

function appStorePromptStyles(props: {isDigital: boolean}) {
  const styles = {
    backgroundColor: gray200,
    margin: '0 auto',
    padding: '16px',
    borderRadius: '16px',
    marginTop: '-16px',

    [paddingBreakpoint]: {
      padding: '24px',
    },
    [partialHorizontalBreakpoint]: {
      minWidth: '500px',
      maxWidth: '740px',
    },
  };

  if (props.isDigital) {
    return {
      ...styles,
      [fullHorizontalBreakpoint]: {
        alignSelf: 'center',
        margin: '0',
        marginLeft: '-16px',
        minWidth: '364px',
        minHeight: '340px',
      },
    };
  }

  return styles;
}

export default makeStyles(theme => {
  return createStyles({
    appStorePrompt: appStorePromptStyles,
    elloIcon: {
      marginTop: '32px',
      marginBottom: '40px',
      [partialHorizontalBreakpoint]: {
        marginTop: '0',
        marginBottom: '0',
        alignSelf: 'center',
      },
    },
    icons: {
      [partialHorizontalBreakpoint]: {
        display: 'flex',
        marginTop: '32px',
        height: '130px',
      },
    },
    iconDivider: {
      display: 'none',
      [partialHorizontalBreakpoint]: {
        height: '64px',
        margin: '0 32px',
        display: 'block',
        alignSelf: 'center',
      },
    },
  });
});

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {gray900} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    tableContainer: {
      padding: 0,
      margin: `${theme.spacing(5)} 0 ${theme.spacing(8)} 0`,
    },
    shippingHeader: {
      width: '30%',
    },
    disclaimer: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: gray900,
    },
    button: {
      width: '100%',
    },
  });

export default styles;

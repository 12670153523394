import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    base: {
      textDecoration: 'none',
      justifyContent: 'center',
      display: 'flex',
      padding: theme.spacing(1.5),
      fontWeight: 700,
      alignItems: 'center',
      outline: 'none',
    },
    spinner: {
      marginLeft: theme.spacing(1),
    },
    contained: {
      textTransform: 'uppercase',
      borderRadius: '50px',
      backgroundColor: palette.turquoise500,
      color: palette.white,
      '&:hover, &:focus': {
        backgroundColor: palette.turquoise900,
      },
    },
    danger: {
      backgroundColor: palette.orange500,
      '&:hover': {
        backgroundColor: palette.orange300,
      },
    },
    outlined: {
      border: `2px solid ${palette.blue500}`,
      color: palette.blue500,
      borderRadius: '50px',
    },
    text: {
      textTransform: 'none',
    },
    icon: {
      borderRadius: `50%`,
      minWidth: '50px',
      width: '50px',
      height: '50px',
    },
  });

export default styles;

import React from 'react';
import robot from 'shared/assets/robot.svg';
import {Container, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './Error.styles';
import {ErrorMessage} from '.';
import {useLocation} from 'react-router-dom';
import {PUBLIC_ROUTES} from 'shared/components/Route/routes';
import Bar from 'customerPortal/components/Banner/Bar/Bar';
import Link from 'customerPortal/components/Link/Link';

interface Props extends WithStyles<typeof styles> {
  error: ErrorMessage;
  showBanner?: boolean;
}

interface Location {
  homepageLink?: string;
}

const Error = ({classes, error, showBanner = true}: Props) => {
  const {state} = useLocation<Location | undefined>();

  return (
    <>
      {showBanner && <Bar isPublic />}
      <Container maxWidth="lg" className={classes.container}>
        <img src={robot} alt="" className={classes.img} />
        <Typography component="h1" variant="h2" className={classes.title}>
          {error}
        </Typography>
        {error === ErrorMessage.ServerError ? (
          <Typography component="p" className={classes.msg}>
            Sorry, we're having a problem with our system. If you need help
            while the system is down, please text us at (415) 214-8119.
          </Typography>
        ) : (
          <Link
            to={state?.homepageLink ?? PUBLIC_ROUTES.ROOT}
            className={classes.link}>
            Go back to homepage
          </Link>
        )}
      </Container>
    </>
  );
};

export default withStyles(styles)(Error);

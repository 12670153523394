import React from 'react';
import {Card, CardContent, Link, Typography} from '@mui/material';
import {gray700, turquoise300, turquoise900} from 'shared/styles/palette';
import {supportEmail, supportPhoneNumber} from '../../../constants/constants';

const DesktopFeedbackContact = () => {
  return (
    <Card
      elevation={0}
      sx={{
        textAlign: 'center',
        padding: '24px 32px',
        borderRadius: '16px',
        background: turquoise300,
      }}>
      <CardContent sx={{padding: 0, '&:last-child': {paddingBottom: 0}}}>
        <Typography variant="body2" color={gray700} lineHeight="160%">
          💬 Have feedback or issues?
          <br />
          Email{' '}
          <Link
            href="mailto:support@ello.com"
            sx={{color: turquoise900, fontWeight: 'bold'}}>
            {supportEmail}
          </Link>{' '}
          or
          <br />
          text us at {supportPhoneNumber}
        </Typography>
      </CardContent>
    </Card>
  );
};

export default DesktopFeedbackContact;

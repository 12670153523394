import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {gray700, gray900} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    item: {
      marginBottom: theme.spacing(3),
    },
    term: {
      fontSize: '1.25rem',
      fontWeight: 700,
      color: gray900,
    },
    desc: {
      margin: 0,
      fontSize: '1.25rem',
      color: gray700,
    },
  });

export default styles;

import createStyles from '@mui/styles/createStyles';

const useStyles = () =>
  createStyles({
    label: {
      fontWeight: 100,
    },
  });

export default useStyles;

import {gql} from '@apollo/client';

export const AUTHENTICATE_USER = gql`
  query AuthenticateUser($token: String!) {
    authenticateUser(token: $token) {
      customToken
    }
  }
`;

export const AUTHENTICATE_USER_EMAIL = gql`
  query ValidateEmailUser($email: String!) {
    validateEmailUser(email: $email)
  }
`;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {blue500} from '../../../shared/styles/palette';
import {Book} from './Book.type';
import {desktopBreakpoint} from './BreakPoints';

type Props = {
  book?: Book;
};

const TitleAndAuthor: FC<Props> = ({book}: Props) => {
  return (
    <Box
      mt={3}
      sx={{
        textAlign: 'center',
        [desktopBreakpoint]: {
          textAlign: 'left',
        },
      }}>
      <Typography
        sx={{
          fontSize: '1.625rem',
          fontWeight: 800,
          color: blue500,
        }}>
        {book?.title}
      </Typography>
      <Typography>by {book?.author}</Typography>
    </Box>
  );
};

export default TitleAndAuthor;

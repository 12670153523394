import {useLazyQuery, useMutation, useQuery} from '@apollo/client';
import {QUERY_ACCOUNT_DETAILS} from '../AccountSettingsScreen/AccountDetailsScreen/AccountDetails.query';
import {
  validatePinMutation,
  validateTokenMutation,
} from './AccountManagerScreen.query';
import {AccountManagerScreenService} from './AccountManagerScreen.service';

export function useValidatePin(): {validatePin: (pin: string) => void} {
  const [clientValidatePin] = useMutation(validatePinMutation);

  return {
    validatePin: (pin: string) => {
      return AccountManagerScreenService.validatePin(clientValidatePin, pin);
    },
  };
}

export function useValidateToken(): {
  validateToken: () => Promise<{success: boolean}>;
} {
  const [clientValidateToken] = useLazyQuery(validateTokenMutation);

  return {
    validateToken: async () => {
      return AccountManagerScreenService.validateToken(clientValidateToken);
    },
  };
}

export function useCustomer(skip?: boolean): {customer: {tags: string[]}} {
  const {data} = useQuery(QUERY_ACCOUNT_DETAILS, {skip});
  const customer = data?.customer as {tags: string[]};
  return {customer};
}

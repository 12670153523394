import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import * as palette from 'shared/styles/palette';

export default makeStyles(() => {
  return createStyles({
    profileBooks: {},
    tab: {
      fontWeight: 'bold',
      fontSize: '1.25rem',
      textTransform: 'none',
      minWidth: '120px',
    },
    tabHeader: {
      borderBottom: `1px solid ${palette.gray300}`,
    },
    tabPanel: {
      padding: '32px 0',
    },
    title: {
      fontWeight: 600,
      fontSize: '1.953rem',
      marginBottom: '16px',
    },
  });
});

import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import useStyles from './MoneyBackBadge.styles';
import moneyBackBadge from 'shared/assets/icons/moneyBackBadge.svg';

function MoneyBackBadge() {
  const classes = useStyles();

  return (
    <Box display="flex" className={classes.moneyBackContainer}>
      <img
        src={moneyBackBadge}
        alt="Thirty day money back guarantee"
        className={classes.moneyBackBadge}
      />
      <Box>
        <Typography
          component="p"
          align="left"
          className={classes.moneyBackText1}>
          30-day Money Back Guarantee
        </Typography>
        <Typography
          component="p"
          align="left"
          className={classes.moneyBackText2}>
          If you're not satisfied, we'll offer you a full refund.
        </Typography>
      </Box>
    </Box>
  );
}

export default MoneyBackBadge;

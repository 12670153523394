import React, {useEffect, useState} from 'react';
import {useQuery} from '@apollo/react-hooks';
import {BOOKS_QUERY} from './ExampleBookPanel.query';
import {collectQueryParams} from 'shared/utils/queryParams';
import {calculateLevel} from './calculateLevel';
import {useQueryParam, StringParam} from 'use-query-params';
import BookPanels from './BookPanels';

function ExampleBooksPanel(props) {
  const [books, setBooks] = useState(null);
  // eslint-disable-next-line no-unused-vars
  const [queryValue, setQueryValue] = useQueryParam(
    'estimated_reading_level',
    StringParam,
  );

  const params = collectQueryParams();

  const level = calculateLevel(
    params.grade === 'We are homeschooling'
      ? params.homeschool_grade
      : params.grade,
    params.reading_level,
    new Date().getMonth(),
  );

  const {loading} = useQuery(BOOKS_QUERY, {
    variables: {level},
    onCompleted: data => {
      props.onValidationChanged(true);
      setBooks(data.exampleBooks);
    },
    onError: error => {
      console.error('Example Books:', error);
      props.onValidationChanged(true);
    },
  });

  useEffect(() => {
    setQueryValue(level);
  }, [level, setQueryValue]);

  return <BookPanels books={books} isLoading={loading} />;
}

export default ExampleBooksPanel;

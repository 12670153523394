import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {useParams} from 'react-router-dom';
import {blue500} from '../../../shared/styles/palette';
import {useExternalBook} from './Book.service';
import CoverPhoto from './CoverPhoto';
import ReturnToLibrary from './ReturnToLibrary';
import SocialMediaLinks from './SocialMedia/SocialMediaLinks';
import BookFinishVideo from './BookFinishVideo';
import EmailSubmitForm from './EmailSubmitForm';
import {Destination} from './SocialMedia/SocialMedia.types';

const ExternalFinishBook: FC = () => {
  const {bookId} = useParams<{bookId: string}>();
  const {book} = useExternalBook(bookId);
  const shareLink = `https://portal.helloello.com/books/${bookId}/start`;

  return (
    <Box
      mt={12}
      sx={{
        textAlign: 'center',
        mx: 'auto',
        maxWidth: {xs: '600px', md: '1200px'},
        padding: '16px',
      }}>
      <ReturnToLibrary />
      <Box
        sx={{
          display: {xs: 'block', md: 'flex'},
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}>
        <Box sx={{flex: 1, pr: {md: '24px'}}}>
          <Typography
            sx={{
              fontSize: '1.625rem',
              fontWeight: 800,
              color: blue500,
              mb: '24px',
            }}>
            Great Reading!
          </Typography>
          <BookFinishVideo />
          <Typography sx={{mb: '24px'}}>
            Enter your email below to bring this book to life with a free trial
            to Ello!
          </Typography>

          <EmailSubmitForm />
        </Box>
        <Box
          sx={{
            flex: 1,
            pl: {md: '24px'},
            textAlign: {xs: 'center', md: 'center'},
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}>
          <Box
            sx={{
              mb: '24px',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              width: {xs: '160px', md: '240px'},
              height: {xs: '160px', md: '240px'},
              mx: 'auto',
            }}>
            <CoverPhoto book={book} />
          </Box>
          <Box sx={{display: 'flex', justifyContent: 'center', mb: '24px'}}>
            <SocialMediaLinks
              destination={Destination.startBook}
              link={shareLink}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalFinishBook;

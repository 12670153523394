import makeStyles from '@mui/styles/makeStyles';
import {gray200, gray700, turquoise900} from '../../../styles/palette';
import {
  buttonTextBreakpoint,
  partialHorizontalBreakpoint,
} from '../BreakPoints';

export default makeStyles(theme => ({
  copyButton: {
    minWidth: '32px',
  },
  copyButtonText: {
    display: 'none',
    [buttonTextBreakpoint]: {
      display: 'inline',
      marginLeft: '12px',
      fontSize: '0.75rem',
    },
  },
  copied: {
    fontSize: '0.75rem',
    margin: '0 auto',
    marginTop: '8px',
  },
  link: {
    color: turquoise900,
    fontSize: '1.125rem',
    fontWeight: 'bold',
  },
  linkDivider: {
    height: '34px',
    margin: '0',
  },
  openLinkText: {
    fontSize: '0.75rem',
    color: gray700,
    marginTop: '8px',
    [partialHorizontalBreakpoint]: {
      textAlign: 'left',
    },
  },
  linkButton: {
    [buttonTextBreakpoint]: {
      width: '160px',
    },
  },
  linkContainer: {
    width: 'calc(100% - 35px)',
    [buttonTextBreakpoint]: {
      width: 'calc(100% - 70px)',
    },
  },
  linkTab: {
    backgroundColor: gray200,
    display: 'flex',
    padding: '8px',
    borderRadius: '24px',
    [partialHorizontalBreakpoint]: {
      padding: '8px',
      height: '50px',
      width: '100%',
    },
  },
  linkTextContainer: {
    width: '100%',
    alignSelf: 'center',
    maxWidth: '300px',
    margin: '0 auto',
    [partialHorizontalBreakpoint]: {
      marginLeft: '16px',
    },
  },
  linkTypography: {
    marginTop: '4px',
  },
}));

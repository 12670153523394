import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    img: {
      margin: `${theme.spacing(0.5)} ${theme.spacing(1)} 0 0`,
    },
  });

export default styles;

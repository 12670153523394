import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {supportsDigital} from '../../utils/productType';
import {collectQueryParams} from '../../utils/queryParams';
import AppStorePrompt from './AppStorePrompt/Prompt';
import useStyles from './DownloadPrompt.styles';
import Or from './Or/Or';
import QRLink from './QRLink/QRLink';

type props = {
  digitalBody: string;
  physicalBody: string;
  title: string;
  physicalLink?: string;
  digitalLink?: string;
};

const DownloadPrompt: FC<props> = ({
  title,
  physicalBody,
  digitalBody,
  physicalLink,
  digitalLink,
}) => {
  const {productType} = collectQueryParams() as {productType?: string};
  const isDigital = supportsDigital(productType);
  const classes = useStyles({isDigital});

  return (
    <Box>
      <Typography component="h2" variant="h6" className={classes.title}>
        {title}
      </Typography>
      <Typography className={classes.body}>
        {isDigital ? digitalBody : physicalBody}
      </Typography>
      <Box className={classes.downloadPrompt}>
        <QRLink
          isDigital={isDigital}
          physicalLink={physicalLink}
          digitalLink={digitalLink}
        />
        <Or isDigital={isDigital} />
        <AppStorePrompt isDigital={isDigital} />
      </Box>
    </Box>
  );
};

export default DownloadPrompt;

import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

export const useAppBarStyles = makeStyles((theme: Theme) => ({
  appBar: {
    transition: 'transform 0.3s',
  },
  appBarMarginBottom: {
    marginBottom: theme.spacing(6),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginBottom: theme.spacing(3),
    },
  },
  hidden: {
    transform: 'translateY(-100%)',
  },
}));

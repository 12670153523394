import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, {FC} from 'react';
import elloIcon from '../../../assets/app-icon.svg';
import SectionCopy from '../SectionCopy';
import AppStorePromptIcons from './Icons';
import useStyles from './Prompt.styles';

type props = {
  isDigital: boolean;
};

const AppStorePrompt: FC<props> = ({isDigital}) => {
  const classes = useStyles({isDigital});

  return (
    <Box className={classes.appStorePrompt}>
      <SectionCopy
        header={'Directly from App Store'}
        text={
          'Go to the app store on your child’s tablet. Search for “Read with Ello” and download the app with this picture of Ello!'
        }
        emphasis={['Read with Ello']}
      />
      <Box className={classes.icons}>
        <Box className={classes.elloIcon}>
          <img src={elloIcon} alt="Ello Icon" />
        </Box>
        <Divider orientation="vertical" className={classes.iconDivider} />
        <AppStorePromptIcons isDigital={isDigital} />
      </Box>
    </Box>
  );
};
export default AppStorePrompt;

const styles = theme => ({
  loginContainer: {
    height: '100vh',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: 0,
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.primary.dark,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
    textAlign: 'center',
  },
  submitButton: {
    width: theme.spacing(16),
    margin: theme.spacing(3, 0, 2),
  },
  forgotPassword: {
    color: 'rgb(100, 100, 105)',
    fontWeight: 'Semi Bold',
    fontSize: '13px',
    textDecoration: 'none',
  },
  loadingIndicator: {
    marginTop: '40px',
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
});

export default styles;

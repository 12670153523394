import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import Benefit from './Benefit';

type Props = {
  previousBenefitsText: string;
};

const PreviousBenefits: FC<Props> = ({previousBenefitsText}) => {
  return (
    <Box>
      {previousBenefitsText && (
        <Box>
          <Benefit benefit={previousBenefitsText} />
          <Divider
            sx={{
              fontWeight: 'normal',
              color: palette.gray700,
            }}>
            <Typography
              sx={{
                color: palette.gray700,
              }}>
              PLUS
            </Typography>
          </Divider>
        </Box>
      )}
    </Box>
  );
};

export default PreviousBenefits;

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import qrCode from '../../../assets/v1-qr-code.png';
import useStyles from './QRCode.styles';

type props = {
  isDigital: boolean;
};

const QRCode: FC<props> = ({isDigital}) => {
  const classes = useStyles();

  return (
    <Box>
      <Box className={classes.qrcode}>
        {isDigital && <img src={qrCode} alt="QR Code" />}
        {!isDigital && <img src={qrCode} alt="QR Code" />}
      </Box>
    </Box>
  );
};
export default QRCode;

import React from 'react';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import ReactCodeInput from 'react-verification-code-input';
import styles from './LoginVerificationScreen.styles';
import {WithStyles} from '@mui/styles';

interface Props extends WithStyles<typeof styles> {
  onComplete: (v: string) => void;
}

const LoginVerificationScreen = ({classes, onComplete}: Props) => {
  return (
    <>
      <Typography component="h1" variant="h5" className={classes.title}>
        Verification
      </Typography>
      <Typography paragraph className={classes.subtitle}>
        Please enter the verification code that we texted to your phone number.
      </Typography>

      <ReactCodeInput
        className={classes.verificationInput}
        onComplete={onComplete}
        fieldWidth={55}
        fieldHeight={55}
      />
    </>
  );
};

export default withStyles(styles)(LoginVerificationScreen);

import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React from 'react';
import {ReactComponent as ShareIcon} from '../../../assets/share.svg';
import {CopyComponentService} from './CopyComponent.service';
import useStyles from './CopyComponent.styles';

type Props = {
  link: string;
  buttonType: 'primary' | 'secondary';
  buttonText: string;
  dialogueText?: string;
  onShare?: (value: boolean) => void;
};

const ShareSheet = ({
  link,
  buttonText,
  buttonType,
  dialogueText,
  onShare,
}: Props) => {
  const isPrimary = buttonType === 'primary';
  const classes = useStyles({isPrimary});
  const shareLink = async () => {
    const copied = await CopyComponentService.copyLinkAndShare(
      link,
      dialogueText,
    );
    onShare && onShare(copied);
  };

  return (
    <Button
      variant="outlined"
      className={classes.shareButton}
      onClick={shareLink}>
      <ShareIcon className={classes.shareIcon} />
      <Typography className={classes.shareButtonText}>{buttonText}</Typography>
    </Button>
  );
};

export default ShareSheet;

import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {gray500} from '../../../shared/styles/palette';

const ReturnToLibrary: FC = () => {
  const history = useHistory();
  const handleReturn = () => {
    history.push(`/books`);
  };

  return (
    <Box>
      <Box sx={{display: 'flex', ml: -2, fontWeight: 700, cursor: 'pointer'}}>
        <IconButton size="large" onClick={handleReturn}>
          <KeyboardBackspaceIcon fontSize="inherit" sx={{color: gray500}} />
        </IconButton>
        <Box onClick={handleReturn}>
          <Typography sx={{py: '16px', fontSize: '1rem', color: gray500}}>
            Return to Library
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default ReturnToLibrary;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {gray900} from '../../../../shared/styles/palette';
import {ReadingList} from '../Library.types';
import Thumbnail from './Thumbnail';

const thumbnailBreakpoint = '@media (min-width: 380px)';

type Props = {
  name: string;
  readingLists: ReadingList[];
  onClick: () => void;
};

const CategoryItem: FC<Props> = ({name, readingLists, onClick}: Props) => {
  return (
    <Box onClick={onClick}>
      <Box
        sx={{
          width: '220px',
          minWidth: '220px',
          mr: 2,
          cursor: 'pointer',
          [thumbnailBreakpoint]: {
            width: '280px',
            minWidth: '280px',
          },
        }}>
        <Thumbnail readingLists={readingLists} />
        <Typography
          sx={{
            fontSize: '1.375rem',
            color: gray900,
            mt: 2,
            fontWeight: 700,
            textAlign: 'center',
          }}>
          {name}
        </Typography>
      </Box>
    </Box>
  );
};

export default CategoryItem;

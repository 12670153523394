import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    radioInput: {
      opacity: 0,
      position: 'absolute',
    },
  });

export default styles;

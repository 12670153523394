import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useStyles from './ReferralLink.styles';

type Props = {
  header?: string;
  body?: string;
};

const QRCodeContainerHeader = ({header, body}: Props) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography className={classes.sectionHeader}>
        {header || 'Download the app'}
      </Typography>
      <Typography my="12px" className={classes.bodyText}>
        {body ||
          'Scan the QR code using the camera on your child’s device to install the app.'}
      </Typography>
    </Box>
  );
};

export default QRCodeContainerHeader;

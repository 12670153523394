import KeyboardBackspaceIcon from '@mui/icons-material/KeyboardBackspace';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {gray500} from '../../../shared/styles/palette';

type Props = {
  backText: string;
  goBack?: () => void;
};

const GoBackButton: FC<Props> = ({backText, goBack}: Props) => {
  return (
    <Box sx={{display: 'flex'}} onClick={goBack}>
      <Box sx={{mr: 1}}>
        <KeyboardBackspaceIcon sx={{color: gray500}} />
      </Box>
      <Typography sx={{fontSize: '1rem', fontWeight: 700, color: gray500}}>
        {backText}
      </Typography>
    </Box>
  );
};

export default GoBackButton;

import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import cN from 'classnames';
import styles from './TableRow.styles';
import React from 'react';
import {TableCell, TableRow as Row} from '@mui/material';

interface RowProps extends WithStyles<typeof styles> {
  label: string;
  value: string;
  headerClassName?: string;
  cellAlign?: TableCellProps['align'];
  isFooter?: boolean;
}

const TableRow = ({
  classes,
  headerClassName,
  label,
  value,
  cellAlign,
  isFooter,
}: RowProps) => {
  return (
    <Row key={label}>
      <TableCell
        component="th"
        scope="row"
        className={cN(
          classes.cell,
          classes.cellHeader,
          {[classes.footer]: isFooter},
          headerClassName,
        )}>
        {label}
      </TableCell>
      <TableCell
        component="td"
        scope="row"
        align={cellAlign}
        className={cN(classes.cell, {[classes.footer]: isFooter})}>
        {value}
      </TableCell>
    </Row>
  );
};

export default withStyles(styles)(TableRow);

import makeStyles from '@mui/styles/makeStyles';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from '../../styles/breakpoints';

export default makeStyles(theme => ({
  tagImage: {
    height: '100px',
    marginBottom: '20px',
  },

  productBox: {
    borderRadius: '16px',
    minHeight: '120px',
    cursor: 'pointer',
    border: `1px solid ${palette.gray500}`,
    padding: '1px',
  },
  productBoxSelected: {
    border: `2px solid ${palette.turquoise900}`,
    padding: '0px',
  },
  pillPlaceholder: {
    height: '16px',
  },
  productPillText: {
    // Pull pill to the right (padding + border width) and up (50% of height)
    marginRight: '-2px',
    marginTop: '-16px', // half of 32px height
    color: 'white',
    fontWeight: '700', // Bold
    fontSize: '14px',
    padding: '6px 10px 6px 10px',
    borderRadius: '16px 16px 0 16px',
  },
  productTitle: {
    fontWeight: 700,
    fontSize: '20px',
    color: palette.gray700,
  },
  productTitleSelected: {
    color: palette.blue500,
  },
  productBody: {
    fontSize: '15px',
    '& p': {
      lineHeight: '1.6',
      marginTop: '8px',
    },
    textAlign: 'left',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '8px',
  },
  productImage: {
    padding: '4px',
    marginLeft: 'auto',
  },
  priceContainer: {
    marginTop: '16px',
    margin: '0 auto',
    width: '65%',
  },
  priceLabel: {
    fontWeight: 700,
    fontSize: '18px',
    color: palette.black,
  },
  price: {
    fontWeight: 700,
    fontSize: '22px',
    color: palette.turquoise900,
    marginLeft: '8px',
  },
  priceSaving: {
    fontWeight: 700,
    color: palette.blue500,
    fontSize: '18px',
  },
  mobileMoneyBackBox: {
    marginTop: '24px',
  },
  center: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  tile: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    flexDirection: 'row',
    justifyContent: 'left',
    borderRadius: 10,
    border: '3px solid white',
    textAlign: 'center',
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      border: '6px solid white',
      flexDirection: 'column',
      height: 175,
      justifyContent: 'center',
      borderRadius: 30,
      '&:hover': {
        background: '#fafafa',
      },
      textAlign: 'center',
    },
  },
  tileWithoutImageOrCheckbox: {
    justifyContent: 'center',
  },
  tileSelectUpdated: {
    padding: '16px 24px 14px 24px',
    borderRadius: 8,
    textAlign: 'left',
    border: `1px solid ${palette.gray300}`,
    background: palette.gray,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      textAlign: 'center',
      borderRadius: 30,
    },
  },
  tileTitle: {
    padding: 20,
    fontSize: 17,
  },
  tileTitleSelectUpdated: {
    padding: 0,
  },
}));

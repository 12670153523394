import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import React, {FC} from 'react';
import {gray100, white} from '../../../shared/styles/palette';
import BookHeaderRow from './BookHeaderRow';

type props = {
  bookTitle?: string;
  pageNumber: number;
  pageLength?: number;
  goBack?: () => void;
  onFinish?: () => void;
};

const BookHeader: FC<props> = ({
  bookTitle,
  pageNumber,
  pageLength,
  goBack,
  onFinish,
}: props) => {
  return (
    <Box
      sx={{
        position: 'fixed',
        left: 0,
        width: '100%',
        backgroundColor: white,
        paddingRight: '8px',
      }}>
      <BookHeaderRow
        bookTitle={bookTitle}
        pageNumber={pageNumber}
        pageLength={pageLength}
        goBack={goBack}
        onFinish={onFinish}
      />
      <Divider
        sx={{
          backgroundColor: gray100,
          height: '2px',
          width: '100vw',
        }}
      />
    </Box>
  );
};

export default BookHeader;

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import Alert from '../../../../shared/components/common/Alert/Alert';
import GoBackButton from '../GoBackButton';
import {useCategories} from '../Library.hooks';
import CategoriesBanner from './CategoriesBanner';
import CategoryList from './CategoryList';

type Props = {
  backText: string;
  goBack?: () => void;
};

const categoryNames = [
  'Grade Level',
  'Reading Level (By Skill Set)',
  'Popular Book Sets',
];

const Categories: FC<Props> = ({backText, goBack}: Props) => {
  const {data, error} = useCategories(
    {first: 20},
    {names: categoryNames, includeReadingLists: true},
  );

  return (
    <Box mt={12} mb={4}>
      <GoBackButton backText={backText} goBack={goBack} />
      <CategoriesBanner />
      {data && <CategoryList categories={data} />}
      {error && (
        <Alert
          error={`Unable to retrieve books. Please check your internet connection and refresh the page.`}
          autoHide={false}
        />
      )}
    </Box>
  );
};

export default Categories;

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import {setContext} from '@apollo/client/link/context';
import {onError} from '@apollo/client/link/error';
import {RetryLink} from '@apollo/client/link/retry';
import TimeoutLink from 'apollo-link-timeout';
import firebase from 'firebase/app';
import 'firebase/auth';
import {
  isAndroidEmulator,
  isDevelop,
  isProduction,
  isRavensburger,
  isStagingOrWebflow,
} from './environments';

const APOLLO_BASE_URL = (() => {
  switch (true) {
    case isProduction():
      return 'https://api.helloello.com/graphql';
    case isStagingOrWebflow():
      return 'https://staging-api.helloello.com/graphql';
    case isRavensburger():
      return 'https://ello-backend-ravensburger-dot-trustle-babydesk.appspot.com/graphql';
    case isAndroidEmulator():
      return 'http://10.0.2.2:4000/graphql';
    case isDevelop():
      return 'https://develop-api.helloello.com/graphql';
    default:
      return 'http://localhost:4000/graphql';
  }
})();

const httpLink = new HttpLink({
  uri: APOLLO_BASE_URL,
  fetch,
  credentials: 'include', // pass along cookies
});
const errorLink = onError(({graphQLErrors, networkError, response}) => {
  if (graphQLErrors) {
    try {
      console.error('GraphQLErrors::', JSON.stringify(graphQLErrors, null, 2));
    } catch (e) {
      console.error('GraphQLErrors::', graphQLErrors);
    }
  }

  if (networkError) {
    console.error('NetworkError::', networkError.message);
  }
});

const authLink = setContext(async (_, {headers}) => {
  // Once a user is signed in, we add the firebase auth token to all API calls, so that
  // the backend can authenticate us.
  const user = firebase.auth().currentUser;
  if (user) {
    const token = await user.getIdToken();
    return {
      headers: {
        ...headers,
        'x-token': token,
      },
    };
  } else {
    return null;
  }
});

const onboardingFlowPublic = 'OnboardingFlowPublic';
const retryLink = new RetryLink({
  attempts: {
    max: 5,
    retryIf: (error, operation) => {
      const shouldRetry =
        !!error && operation.operationName === onboardingFlowPublic;
      return shouldRetry;
    },
  },
  delay: {
    initial: 100,
    max: 800,
    jitter: true,
  },
});

const timeoutLink = new ApolloLink((operation, forward) => {
  if (operation.operationName === onboardingFlowPublic) {
    return new TimeoutLink(4000).request(operation, forward);
  }
  return forward(operation);
});

const cache = new InMemoryCache({
  addTypename: false,
  typePolicies: {
    Query: {
      fields: {
        subscription: {
          merge(existing, incoming, {mergeObjects}) {
            return mergeObjects(existing, incoming);
          },
        },
      },
    },
  },
});

const link = ApolloLink.from([
  authLink,
  errorLink,
  retryLink,
  timeoutLink,
  httpLink,
]);

let client;

const getClient = async () => {
  if (!client) {
    client = new ApolloClient({
      link,
      cache,
    });
  }
  return client;
};

export default getClient;

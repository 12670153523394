import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import {useReadingListsByCategories} from '../Library.hooks';
import LibraryService from '../Library.service';
import {Category, ReadingList} from '../Library.types';
import CategoryItem from './CategoryItem';

const categoryItemsListBreakPoint = '@media (min-width: 991px)';

type Props = {
  scrollRef: React.RefObject<HTMLDivElement>;
  readingLists?: ReadingList[];
  subcategories?: Category[];
};

const CategoryItemList: FC<Props> = ({
  readingLists,
  subcategories,
  scrollRef,
}: Props) => {
  const history = useHistory();
  const handleSubcategoryClick = (id?: string) => {
    return () => history.push(`/categories/${id}`);
  };

  const handleReadingListClick = (id?: string) => {
    return () => history.push(`/reading-lists/${id}`);
  };

  const subcategoryIds =
    (subcategories
      ?.map(subcategory => subcategory.id)
      .filter(id => !!id) as string[]) || [];

  const {readingLists: subcategoryReadingLists} =
    useReadingListsByCategories(subcategoryIds);

  const readingListsMap =
    subcategoryReadingLists?.reduce(
      LibraryService.readingListsByCategory,
      {},
    ) || {};

  const hasSubcategories = !!subcategories?.length;

  return (
    <Box>
      <Box
        ref={scrollRef}
        mt={3}
        sx={{
          display: 'flex',
          justifyContent: 'center',
          overflowX: 'scroll',
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          '&::-webkit-scrollbar': {
            display: 'none',
          },
          mr: '-24px',
          [categoryItemsListBreakPoint]: {
            mr: 0,
          },
        }}>
        {hasSubcategories &&
          subcategories?.map(subcategory => (
            <CategoryItem
              key={subcategory.id}
              name={subcategory.name}
              readingLists={readingListsMap[subcategory.id || '']}
              onClick={handleSubcategoryClick(subcategory.id)}
            />
          ))}
        {!hasSubcategories &&
          readingLists?.map(readingList => (
            <CategoryItem
              key={readingList.id}
              name={readingList.name}
              readingLists={[readingList]}
              onClick={handleReadingListClick(readingList.id)}
            />
          ))}
      </Box>
    </Box>
  );
};

export default CategoryItemList;

import React, {useState} from 'react';
import {TextField} from '@mui/material';
import {useLazyQuery} from '@apollo/react-hooks';
import firebase from 'firebase/app';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {useHistory} from 'react-router-dom';

import CustomerLoginScreenForm from './CustomerLoginScreen.form';
import Button, {ButtonType} from '../../../shared/components/common/Button';
import styles from './CustomerLoginScreen.styles';
import {AUTHENTICATE_USER_EMAIL} from './CustomerLoginScreen.query';
import {LoginErrorMessage} from './constants';
import {validateEmail} from './CustomerLoginScreen.service';
import {PUBLIC_ROUTES} from '../../../shared/components/Route/routes';
import PasswordVisibilityButton from '../../components/PasswordVisibilityButton/PasswordVisibilityButton';

interface Props extends WithStyles<typeof styles> {
  setError: (e: string) => void;
  setIsLoading: (l: boolean) => void;
  onSuccessLogin: (method: string, user?: any) => void;
}

const LoginEmail = ({
  classes,
  setError,
  setIsLoading,
  onSuccessLogin,
}: Props) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState(false);
  const [passwordError, setPasswordError] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const history = useHistory();

  const onChangeEmail = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setEmail(e.target.value);
  };

  const onChangePassword = (e: React.ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const [validateUserEmail] = useLazyQuery(AUTHENTICATE_USER_EMAIL, {
    fetchPolicy: 'no-cache',
    onError: error => console.error('Email validation::', error),
  });

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!email) {
      setEmailError(true);
      setError(LoginErrorMessage.EmptyEmail);
      return;
    }

    if (!password) {
      setPasswordError(true);
      setError(LoginErrorMessage.EmptyPassword);
      return;
    }

    if (!validateEmail(email)) {
      setEmailError(true);
      setError(LoginErrorMessage.InvalidEmail);
      setIsLoading(false);
      return;
    }

    setEmailError(false);
    setPasswordError(false);
    setIsLoading(true);

    const {data: queryResult} = await validateUserEmail({
      variables: {
        email,
      },
    });

    if (!queryResult || !queryResult.validateEmailUser) {
      setError(LoginErrorMessage.InvalidEmailOrPassword);
      setIsLoading(false);
      return;
    }

    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(data => {
        onSuccessLogin('email', data?.user);
      })
      .catch(error => {
        console.error('Email login::', error);
        setError(LoginErrorMessage.InvalidEmailOrPassword);
        setIsLoading(false);
      });
  };

  const onResetPassword = () => {
    history.push(PUBLIC_ROUTES.REQUEST_PASSWORD_RESET);
  };

  return (
    <CustomerLoginScreenForm onSubmit={onSubmit}>
      <TextField
        autoFocus
        id="email"
        label="Email address"
        onChange={onChangeEmail}
        error={emailError}
        value={email}
        name="email"
        type="email"
        variant="outlined"
        placeholder="example@domain.com"
        fullWidth
        required
      />

      <TextField
        style={{marginTop: 30}}
        id="password"
        label="Password"
        name="password"
        type={showPassword ? 'text' : 'password'}
        error={passwordError}
        value={password}
        onChange={onChangePassword}
        variant="outlined"
        placeholder="Password"
        fullWidth
        required
        InputProps={{
          endAdornment: (
            <PasswordVisibilityButton
              showPassword={showPassword}
              onClick={togglePasswordVisibility}
            />
          ),
        }}
      />
      <Button
        className={classes.forgotPasswordButton}
        type="button"
        variant={ButtonType.Inline}
        onClick={onResetPassword}>
        Forgot password?
      </Button>
    </CustomerLoginScreenForm>
  );
};

export default withStyles(styles)(LoginEmail);

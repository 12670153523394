import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {ReadingList} from '../Library.types';

type Props = {
  readingLists: ReadingList[];
};

const Thumbnail: FC<Props> = ({readingLists}: Props) => {
  const books = readingLists?.flatMap(readingList => readingList.books) || [];
  const coverPhotoURLs = books
    .map(book => book?.coverPhotoURL)
    .filter(book => !!book);

  const firstFourCoverPhotoURLs = coverPhotoURLs.slice(0, 4) || [];

  return (
    <Box>
      <Box
        sx={{
          borderRadius: '24px',
          overflow: 'hidden',
          minHeight: '220px',
        }}>
        <Box sx={{display: 'flex', flexWrap: 'wrap'}}>
          {firstFourCoverPhotoURLs.map((imageUrl, index) => (
            <Box
              key={`${index}:${imageUrl}`}
              sx={{
                width: 'calc(100%/2)',
                height: 0,
                pb: 'calc(100% / 2)',
              }}>
              <img src={imageUrl} alt="thumbnail" width="100%" />
            </Box>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default Thumbnail;

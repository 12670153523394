import React from 'react';
import {Link} from 'react-router-dom';
import Button from '@mui/material/Button';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from './ResetPasswordScreen.styles';

interface props extends WithStyles<typeof styles> {
  linkTo?: string;
}

const ReturnToLoginButton = ({linkTo, classes}: props) => {
  return (
    <Button
      variant="contained"
      color="primary"
      component={Link}
      to={linkTo || '/'}
      className={classes.submitButton}>
      Return to Login
    </Button>
  );
};

export default withStyles(styles)(ReturnToLoginButton);

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {gray900} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    description: {
      fontSize: '1.25rem',
      fontWeight: 400,
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
      },
    },
    button: {
      display: 'inline-block',
      width: '100%',
    },
    content: {
      margin: `${theme.spacing(5)} 0 ${theme.spacing(12)} 0`,
      padding: 0,
      textAlign: 'center',
    },
    table: {
      color: gray900,
      '& th': {
        fontWeight: 400,
      },
      '& td': {
        fontWeight: 600,
      },
      '& tfoot *': {
        fontWeight: 700,
      },
    },
  });

export default styles;

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {PUBLIC_ROUTES} from '../../../shared/components/Route/routes';
import ExternalNavigation from '../../components/ExternalNavigation/ExternalNavigation';
import ReadingList from '../../components/Library/ReadingList/ReadingList';

const backText = 'Return to Categories';

const ReadingListScreen: FC = () => {
  const {readingListId} = useParams<{readingListId: string}>();
  const history = useHistory();
  const goBack = () => {
    history.push(PUBLIC_ROUTES.CATEGORIES);
  };
  return (
    <Box>
      <ExternalNavigation />
      <ReadingList
        backText={backText}
        goBack={goBack}
        readingListId={readingListId}
      />
    </Box>
  );
};

export default ReadingListScreen;

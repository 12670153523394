import {collectQueryParams} from '../../../utils/queryParams';
import {CustomerProductType} from '../../../utils/productType';
import {isProduction} from '../../../utils/environments';

export const magicCTAHook =
  'https://hook.us1.make.com/cnnfrooct7uucpd1ekxc1gqr8l1dxx86';

interface ProductDetails {
  [key: string]: {productType: string};
}

interface Question {
  type: string;
  productDetails?: ProductDetails;
}

interface Step {
  questions: Question[];
}

interface FlowSpec {
  steps: Step[];
}

function getProductType(flowSpec: FlowSpec, priceId: string): string {
  for (const step of flowSpec.steps) {
    for (const question of step.questions) {
      if (question.type === 'payment' && question.productDetails) {
        const productType = question.productDetails[priceId]?.productType;
        if (productType) {
          return productType;
        }
      }
    }
  }
  return CustomerProductType.physicalSubscription;
}

interface QueryParams {
  email?: string;
  name?: string;
  phone?: string;
  priceId?: string;
  giftBundle?: string;
}

export function handleEmailSubmit(
  flowName: string,
  flowSpec: FlowSpec,
  activeStep: number,
) {
  const stepIncludesEmailSubmit = flowSpec?.steps[activeStep]?.questions?.find(
    q => q.type === 'emailSubmit',
  );

  if (!stepIncludesEmailSubmit || !isProduction()) {
    return;
  }

  const {email, name, phone, priceId, giftBundle}: QueryParams =
    collectQueryParams();
  if (!email) {
    return;
  }

  const payload: {
    flowName: string;
    customer: {
      email: string;
      name?: string;
      phone?: string;
      priceId?: string;
      productType?: string;
    };
  } = {
    flowName,
    customer: {email: email},
  };

  if (name) {
    payload.customer.name = name;
  }

  if (phone) {
    payload.customer.phone = phone;
  }

  if (priceId) {
    payload.customer.priceId = priceId;
    payload.customer.productType = getProductType(flowSpec, priceId);
  }

  if (giftBundle) {
    payload.customer.productType = 'gift-purchaser';
  }

  fetch(magicCTAHook, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

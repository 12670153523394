import React from 'react';
import {FormControl, RadioGroup, Radio, FormControlLabel} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './RadioForm.styles';
import {WithStyles} from '@mui/styles';

interface Props extends WithStyles<typeof styles> {
  choices: {[key: string]: {label: string}};
  selected?: string;
  subscriptionStatus?: string;
  onSelect(index: string): void;
}

const RadioForm = ({
  classes,
  choices,
  selected = '',
  subscriptionStatus,
  onSelect,
}: Props) => {
  const isTriallingToSkip = (
    key: string,
    subscriptionStatus?: string,
  ): boolean => {
    return key === 'dont-want-to-pay' && subscriptionStatus !== 'trialing';
  };

  return (
    <>
      <FormControl>
        <RadioGroup
          onChange={event => {
            if (!!event.currentTarget) {
              onSelect(event.currentTarget.value);
            }
          }}>
          {Object.entries(choices).map(([key, {label}]) =>
            isTriallingToSkip(key, subscriptionStatus) ? null : (
              <FormControlLabel
                key={key}
                value={key}
                control={<Radio color={'primary'} />}
                label={label}
                checked={selected === key}
                className={classes.label}
              />
            ),
          )}
        </RadioGroup>
      </FormControl>
    </>
  );
};

export default withStyles(styles)(RadioForm);

import React, {useState} from 'react';
import {WithStyles} from '@mui/styles';
import {TextField} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import Button, {ButtonType} from 'shared/components/common/Button';
import styles from './AddChildForm.styles';
import {AddChildFlow, Form, Plan} from '../types';
import {Grade, GradeState} from 'shared/types/readingLevels';
import FormSelect from 'customerPortal/components/Form/FormSelect/FormSelect';
import LightTooltip from 'customerPortal/components/Tooltip/Tooltip';
import addChildFlow from 'shared/assets/addChildFlow.png';
import {FlowLayout} from 'customerPortal/components/Flow';
import {calculateLevel} from 'shared/components/ExampleBooksPanel/calculateLevel';
import {isMatchingAnyExistingLevels} from '../AddChildScreen.utils';
import ActionBar from 'customerPortal/components/Flow/FlowLayout/ActionBar';
import {FlowState} from 'customerPortal/components/Flow/types';
import {supportEmail, supportPhoneNumber} from '../../../constants/constants';

type Props = Omit<AddChildFlow, 'flowState' | 'recommendation'> &
  WithStyles<typeof styles>;

const getGradeOptions = () => {
  return Object.values(Grade).reduce((res, value) => {
    res.push({value, label: value});
    return res;
  }, [] as any);
};

const getReadingLevelOptions = () => {
  return Object.values(GradeState).reduce((res, value) => {
    res.push({value, label: value});
    return res;
  }, [] as any);
};

const errorState = {
  name: null,
  grade: null,
  readingAbility: null,
};

const AddChildForm = ({
  classes,
  formValues,
  profiles,
  setFlowState,
  setFormValues,
  setRecommendationState,
}: Props) => {
  const [name, setName] = useState<string>(formValues.name);
  const [readingAbility, setReadingAbility] = useState<GradeState | null>(
    formValues.readingAbility,
  );
  const [grade, setGrade] = useState<Grade | null>(formValues.grade);
  const [error, setError] =
    useState<Record<keyof Omit<Form, 'plan' | 'readingLevel'>, boolean | null>>(
      errorState,
    );

  const onNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (error.name) {
      setError(state => ({...state, name: false}));
    }

    const newName = e.target.value;
    if (!newName.length) {
      setName(e.target.value);
      return;
    }

    const capitalizedName = newName[0].toUpperCase() + newName.slice(1);
    setName(capitalizedName);
  };

  const onReadingAbilitySelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (error.readingAbility) {
      setError(state => ({...state, readingAbility: false}));
    }
    setReadingAbility(e.target.value as GradeState);
  };

  const onGradeSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    if (error.grade) {
      setError(state => ({...state, grade: false}));
    }
    setGrade(e.target.value as Grade);
  };

  const validateForm = () => {
    const errorsByField = {
      readingAbility: !readingAbility,
      grade: !grade,
      name: !name,
    };

    setError(errorsByField);
    return errorsByField;
  };

  const onSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const validationErrors = validateForm();

    const hasNoError = Object.values(validationErrors).every(e => !e);

    if (!hasNoError) {
      return;
    }

    const calculatedLevel = calculateLevel(
      grade,
      readingAbility,
      new Date().getMonth(),
    );

    const isMatchingAnyLevel = isMatchingAnyExistingLevels(
      profiles,
      calculatedLevel,
    );

    setFormValues((prev: Form) => {
      return {
        ...prev,
        name,
        grade,
        readingAbility,
        readingLevel: calculatedLevel,
      };
    });

    if (formValues.plan === Plan.Bundle) {
      setRecommendationState({
        recommendedPlan: isMatchingAnyLevel ? Plan.Profile : null,
        selectedRecommendation: null,
        selectedReadingLevel: null,
      });

      if (!isMatchingAnyLevel) {
        setFlowState(FlowState.AdjustReadingLevel);
        return;
      }
    } else if (formValues.plan === Plan.Profile) {
      setRecommendationState({
        recommendedPlan: !isMatchingAnyLevel ? Plan.Bundle : null,
        selectedRecommendation: null,
        selectedReadingLevel: null,
      });
    }

    setFlowState(FlowState.ConfirmPlan);
  };

  return (
    <FlowLayout
      contentClass={classes.container}
      title={`Your child's details`}
      subtitle={`Tell us a bit about your child so we can suggest the right reading level.`}
      sideContent={<img src={addChildFlow} alt="" width={'100%'} />}>
      <form className={classes.form} onSubmit={onSubmit}>
        <TextField
          variant="outlined"
          id="child-name"
          label="Child's name"
          name="child-name"
          type="text"
          placeholder="Child's name"
          value={name}
          onChange={onNameChange}
          error={error.name ?? false}
          helperText={error.name ? "Please enter your child's name" : ''}
          inputProps={{'data-testid': 'child-name-input'}}
        />
        <FormSelect
          label={"Child's Grade"}
          id="child-grade-select"
          value={grade ?? ''}
          errorMessage={error.grade ? 'Please select a grade' : ''}
          items={getGradeOptions()}
          onChange={onGradeSelect}
        />

        <FormSelect
          label={'Reading Ability'}
          id="reading-ability-select"
          value={readingAbility ?? ''}
          items={getReadingLevelOptions()}
          errorMessage={
            error.readingAbility ? 'Please select reading ability' : ''
          }
          onChange={onReadingAbilitySelect}
        />

        <LightTooltip
          PopperProps={{
            disablePortal: true,
          }}
          TransitionProps={{
            timeout: 0,
          }}
          title={`If you are not sure about your child's reading ability,
                  reach out to us and we can help. Email ${supportEmail} or text ${supportPhoneNumber}`}
          placement="top-start"
          className={classes.tooltip}
          enterTouchDelay={0}>
          <span>
            <Button
              variant={ButtonType.Inline}
              className={classes.tooltipTrigger}>
              I'm not sure how to determine this
            </Button>
          </span>
        </LightTooltip>

        <ActionBar>
          <Button type="submit" className={classes.button}>
            Next
          </Button>
        </ActionBar>
      </form>
    </FlowLayout>
  );
};

export default withStyles(styles)(AddChildForm);

import Box from '@mui/material/Box';
import useMediaQuery from '@mui/material/useMediaQuery';
import React, {FC, useState} from 'react';
import {useParams} from 'react-router-dom';
import {DesktopBanner} from '../Banner';
import Book from './Book';
import {useBook} from './Book.service';
import {
  hideLogoHeightBreakpoint,
  maxMobileWidthBreakpoint,
} from './BreakPoints';

const ProfileBook: FC = () => {
  const [pageNumber, setPageNumber] = useState(0);
  const {bookId} = useParams<{bookId: string}>();

  const hideLogo = useMediaQuery(hideLogoHeightBreakpoint);
  const isMobile = useMediaQuery(maxMobileWidthBreakpoint);
  const showLogo = !hideLogo && !isMobile;

  const {book, error} = useBook(bookId);

  return (
    <Box sx={{height: '100vh'}}>
      <Box
        sx={{
          width: '100%',
          maxWidth: 1920,
        }}>
        {showLogo && <DesktopBanner nonResponsive noBottomMargin />}
      </Box>
      <Book
        book={book}
        error={error}
        pageNumber={pageNumber}
        setPageNumber={setPageNumber}
      />
    </Box>
  );
};

export default ProfileBook;

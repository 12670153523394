import Box from '@mui/material/Box';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import React, {FC} from 'react';

type Props = {
  label: string;
  options: {label: string; value: string}[];
  value: string;
  onChange: (value: string) => void;
};

const Selector: FC<Props> = ({label, options, value, onChange}: Props) => {
  const labelId = `${label}-label`;
  return (
    <Box mt={3}>
      <FormControl fullWidth>
        <InputLabel id={labelId}>{label}</InputLabel>
        <Select
          labelId={labelId}
          label={label}
          value={value}
          onChange={e => onChange(e.target.value)}>
          {options.map(({label, value}, index) => (
            <MenuItem key={index} value={value}>
              {label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Box>
  );
};

export default Selector;

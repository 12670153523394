import bundleDigital from 'shared/assets/bundles/bundleDigital.svg';
import bundleDoubleIndividual from 'shared/assets/bundles/bundleDoubleIndividual.svg';
import bundleDoubleShared from 'shared/assets/bundles/bundleDoubleShared.svg';
import bundleGeneric from 'shared/assets/bundles/bundleGeneric.svg';
import bundleTripleShared from 'shared/assets/bundles/bundleTripleShared.svg';
import {Bundle} from 'shared/types/subscriptionPreview';
import {CustomerProductType} from '../../shared/utils/productType';

export const getBundleIcon = (bundleName: string | undefined) => {
  switch (bundleName) {
    case Bundle['ello-double-shared']:
      return bundleDoubleShared;
    case Bundle['ello-double-individual']:
      return bundleDoubleIndividual;
    case Bundle['ello-triple-shared']:
      return bundleTripleShared;
    default:
      return bundleGeneric;
  }
};

export const subscriptionIcon = (
  productType: string | undefined,
  subscriptionName: string | undefined,
) => {
  if (
    !productType ||
    productType === CustomerProductType.physicalSubscription
  ) {
    return getBundleIcon(subscriptionName);
  }

  return bundleDigital;
};

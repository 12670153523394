import Box from '@mui/material/Box';
import React from 'react';
import * as palette from 'shared/styles/palette';

const colorMap: Record<string, string> = {
  turquoise: palette.turquoise900,
  blue: palette.blue500,
  teal: palette.teal500,
  yellow: palette.yellow900,
};

export function getPillColor(pillColor: string): string {
  return colorMap[pillColor] || palette.turquoise900;
}

export function markupComponents(text: string): React.ReactNode[] {
  if (!text) {
    return [];
  }

  const result = text.split(/(<s>.*?<\/s>)/);
  const components = result.map((item, index) => {
    if (item.includes('<s>')) {
      return (
        <Box key={index} component="span" sx={{textDecoration: 'line-through'}}>
          {item.replace(/<s>|<\/s>/g, '')}
        </Box>
      );
    }

    return (
      <Box key={index} component="span">
        {item}
      </Box>
    );
  });
  return components;
}

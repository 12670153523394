import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    main: {
      padding: 0,
      display: 'flex',
      alignItems: 'flex-start',
    },
    content: {
      flex: '1',
      minWidth: 0,
    },
    aside: {
      marginRight: theme.spacing(12.5),
      flex: '0 0 264px',
    },
  });

export default styles;

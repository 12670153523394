import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {white} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    closeButton: {
      color: white,
      position: 'absolute',
      right: theme.spacing(1),
      top: 0,
      width: theme.spacing(4),
      height: theme.spacing(4),
    },
    dialog: {
      backgroundColor: 'transparent',
    },
  });

export default styles;

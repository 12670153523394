import React from 'react';
import {Button as MaterialButton, useTheme} from '@mui/material';
import {ButtonType} from 'shared/components/common/Button';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

type Props = {
  onClick?: () => void;
  children: React.ReactNode;
  loading?: boolean;
  to?: string;
  component?: React.ElementType;
  rel?: string;
  href?: string;
  target?: string;
};

const Button: React.FC<Props> = ({onClick, children, loading, ...rest}) => {
  const theme = useTheme();
  return (
    <MaterialButton
      disableElevation
      disableRipple
      variant={ButtonType.Primary}
      onClick={onClick}
      disabled={loading}
      sx={{
        fontSize: '16px',
        fontWeight: 800,
        width: '100%',
        pt: '16px',
        pb: '14px',
        borderRadius: '50px',
        letterSpacing: '-0.054px',
        '&:hover': {
          backgroundColor: palette.turquoise900,
        },
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          fontSize: '18px',
          padding: '21px 64px 20px 64px',
          width: 'fit-content',
        },
      }}
      {...rest}>
      {children}
    </MaterialButton>
  );
};

export default Button;

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import FormControl from '@mui/material/FormControl';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import InputLabel from '@mui/material/InputLabel';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import Box from '@mui/material/Box';
import FormHelperText from '@mui/material/FormHelperText';

import styles from './AddProfileScreen.styles';
import {readingAbilities} from './AddProfileScreen.service';
import {addedProfileErrorType, addedProfileType} from '../ProfilesScreen.types';

interface props extends WithStyles<typeof styles> {
  profileBeingAdded: addedProfileType;
  readingAbilityError: boolean;
  setProfileBeingAdded: React.Dispatch<React.SetStateAction<addedProfileType>>;
  setError: React.Dispatch<React.SetStateAction<addedProfileErrorType>>;
}

const EditReadingAbility = ({
  classes,
  readingAbilityError,
  profileBeingAdded,
  setError,
  setProfileBeingAdded,
}: props) => {
  const label = 'Reading ability';

  const profileReadingAbility = readingAbilities.find(
    ({ability}) => ability === profileBeingAdded.readingAbility,
  );

  const updateProfileReadingAbility = (e: any) => {
    if (!e.target.value) {
      return;
    }

    setError((error: addedProfileErrorType) => ({
      ...error,
      readingAbilityError: false,
    }));

    const selectedReadingAbility = readingAbilities.find(
      ({value}) => value === e.target.value,
    );

    if (!selectedReadingAbility?.ability) {
      return;
    }

    setProfileBeingAdded((profile: addedProfileType) => ({
      ...profile,
      readingAbility: selectedReadingAbility.ability,
    }));
  };

  return (
    <Box>
      <FormControl
        variant="outlined"
        className={classes.inputField}
        error={readingAbilityError}>
        <InputLabel id="reading-ability-label" className={classes.fieldLabel}>
          {label}
        </InputLabel>
        <Select
          labelId="reading-ability-label"
          label={label}
          className={classes.input}
          value={profileReadingAbility?.value || ''}
          IconComponent={KeyboardArrowDownIcon}
          onChange={updateProfileReadingAbility}>
          {readingAbilities.map(({ability, value}, index) => (
            <MenuItem value={value} key={index}>
              {ability}
            </MenuItem>
          ))}
        </Select>
        {readingAbilityError && (
          <FormHelperText>
            The child's reading ability is required
          </FormHelperText>
        )}
      </FormControl>
    </Box>
  );
};

export default withStyles(styles)(EditReadingAbility);

import React from 'react';
import IconButton from '@mui/material/IconButton';
import InputAdornment from '@mui/material/InputAdornment';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import VisibilityOffOutlinedIcon from '@mui/icons-material/VisibilityOffOutlined';

interface Props {
  showPassword: boolean;
  onClick: () => void;
}

const PasswordVisibilityButton = ({showPassword, onClick}: Props) => {
  return (
    <InputAdornment position="end">
      <IconButton onClick={onClick} size="large">
        {showPassword ? (
          <VisibilityOutlinedIcon />
        ) : (
          <VisibilityOffOutlinedIcon />
        )}
      </IconButton>
    </InputAdornment>
  );
};

export default PasswordVisibilityButton;

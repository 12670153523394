import createStyles from '@mui/styles/createStyles';
import withStyles from '@mui/styles/withStyles';
import {Theme} from '@mui/material/styles';
import * as palette from 'shared/styles/palette';
import Tooltip from '@mui/material/Tooltip';
import {TABLET_BREAKPOINT} from '../../../../../shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'row',
      gap: theme.spacing(6),
    },
    formContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-between',
      flex: 1,
      height: '85vh',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        height: '100%',
        marginTop: theme.spacing(3),
      },
    },
    addChildImgContainer: {
      display: 'none',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'inline',
        flex: 2,
        maxWidth: '700px',
      },
    },
    titleHeader: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '2rem',
      color: palette.blue500,
      marginBottom: theme.spacing(2),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        textAlign: 'left',
      },
    },
    errorHeader: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '2rem',
      color: palette.blue500,
      marginBottom: theme.spacing(2),
    },
    errorContainer: {
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
    },
    errorSubtitle: {
      color: palette.blue500,
      fontWeight: 500,
      fontSize: '1rem',
      textAlign: 'center',
      margin: `${theme.spacing(10)}px 0`,
    },
    successScreenHeader: {
      textAlign: 'center',
      fontWeight: 700,
      fontSize: '2rem',
      lineHeight: '2rem',
      color: palette.blue500,
      marginBottom: theme.spacing(2),
    },
    successScreenContainer: {
      height: '70vh',
    },
    successScreenAnimation: {
      height: '100%',
    },
    subtitleHeader: {
      textAlign: 'center',
      fontWeight: 400,
      lineHeight: '1.5rem',
      color: palette.gray900,
      fontSize: '1rem',
      marginBottom: theme.spacing(2.5),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        textAlign: 'left',
      },
    },
    inputField: {
      width: '100%',
      marginBottom: theme.spacing(4),
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        maxWidth: '400px',
      },
    },
    input: {
      borderRadius: theme.spacing(1),
    },
    fieldLabel: {
      color: palette.gray500,
      fontWeight: 400,
    },
    tooltipButton: {
      color: palette.turquoise900,
      fontWeight: 700,
      fontSize: '1rem',
      marginTop: theme.spacing(1),
    },
    readingAbilityText: {
      fontWeight: 700,
    },
    nextButton: {
      backgroundColor: palette.turquoise900,
      color: palette.white,
      width: '100%',
      borderRadius: theme.spacing(3),
      fontWeight: 800,
      fontSize: '1rem',
      '&:hover': {
        backgroundColor: palette.turquoise900,
      },
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flex: 1,
      },
    },
    backButton: {
      height: '100%',
      width: '100%',
      fontWeight: 800,
      borderRadius: theme.spacing(3),
      textTransform: 'none',
      fontSize: '1rem',
      color: palette.blue500,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        flex: 1,
      },
    },
    actionsContainer: {
      height: '50px',
      display: 'flex',
      flexDirection: 'column-reverse',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'flex',
        flexDirection: 'row',
        marginTop: theme.spacing(3),
        gap: theme.spacing(3),
      },
    },
  });

export default styles;

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: palette.gray,
    boxShadow: theme.shadows[1],
    padding: '1rem',
    color: palette.gray700,
    fontWeight: 400,
    fontSize: '1rem',
  },
}))(Tooltip);

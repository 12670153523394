import React from 'react';
import {FormControlLabel, Radio, RadioGroup, Typography} from '@mui/material';
import Title from './AdjustReadingLevel.request-adjustment.title';
import withStyles from '@mui/styles/withStyles';
import {AdjustmentRequest} from './AdjustReadingLevel.types';
import styles from '../ProfilesScreen.styles';
import {WithStyles} from '@mui/styles';

interface Props extends WithStyles<typeof styles> {
  profileName?: string;
  selectedDifficulty?: AdjustmentRequest;
  setSelectedDifficulty: (value: AdjustmentRequest) => void;
}

const RequestAdjustmentSelectLevelScreen = ({
  classes,
  profileName,
  selectedDifficulty,
  setSelectedDifficulty,
}: Props) => {
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSelectedDifficulty(event.target.value as AdjustmentRequest);
  };

  return (
    <>
      <Title text={`How do you find ${profileName}'s current reading level?`} />
      <Typography
        variant="body1"
        mt={3}
        style={{
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        }}>
        <RadioGroup onChange={handleChange}>
          <FormControlLabel
            value={AdjustmentRequest.IncreaseDifficulty}
            control={<Radio sx={{display: 'none'}} />}
            label="Too Easy"
            className={`${classes.adjustmentRadioButton} ${
              selectedDifficulty === AdjustmentRequest.IncreaseDifficulty
                ? classes.adjustmentRadioButtonSelected
                : ''
            }`}
          />
          <FormControlLabel
            value={AdjustmentRequest.DecreaseDifficulty}
            control={<Radio sx={{display: 'none'}} />}
            label="Too hard"
            className={`${classes.adjustmentRadioButton} ${
              selectedDifficulty === AdjustmentRequest.DecreaseDifficulty
                ? classes.adjustmentRadioButtonSelected
                : ''
            }`}
          />
        </RadioGroup>
      </Typography>
    </>
  );
};

export default withStyles(styles)(RequestAdjustmentSelectLevelScreen);

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {emphasizeText} from '../../utils/emphasizeText';
import useStyles from './SectionCopy.styles';

type props = {
  header: string;
  text: string;
  emphasis?: string[];
};

export const ScanQRCodeText: FC<props> = ({header, text, emphasis}) => {
  const classes = useStyles();

  return (
    <Box>
      <Typography align="left" className={classes.header}>
        {header}
      </Typography>
      <Typography align="left">{emphasizeText(text, emphasis)}</Typography>
    </Box>
  );
};
export default ScanQRCodeText;

import {FetchResult, MutationFunctionOptions} from '@apollo/client';

async function validatePin(
  validatePin: (options: MutationFunctionOptions) => Promise<FetchResult>,
  pin: string,
): Promise<void> {
  try {
    await validatePin({variables: {pin}});
  } catch (error) {
    console.error('Error validating pin', error);
  }
}

async function validateToken(
  validateToken: (options: MutationFunctionOptions) => Promise<FetchResult>,
): Promise<{success: boolean}> {
  try {
    const result = await validateToken({});
    return result.data?.validateToken as {success: boolean};
  } catch (error) {
    console.error('Error validating token', error);
    return {success: false};
  }
}

export const AccountManagerScreenService = {
  validatePin,
  validateToken,
};

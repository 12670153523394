import {Fade} from '@mui/material';
import {FlowContainer} from 'customerPortal/components/Flow';
import React, {useState} from 'react';
import {useHistory} from 'react-router-dom';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import {FlowState} from 'customerPortal/components/Flow/types';
import RemoveChildPreview from './RemoveChildPreview/RemoveChildPreview';
import RemoveChildSuccess from './RemoveChildSuccess/RemoveChildSuccess';

const RemoveChildScreen = () => {
  const history = useHistory();
  const [flowState, setFlowState] = useState(FlowState.PaymentSummary);

  const onGoBack = () => {
    if (flowState === FlowState.PaymentSummary) {
      history.push(PRIVATE_ROUTES.ACCOUNT_SUB_DETAILS.path);
    } else {
      setFlowState(FlowState.PaymentSummary);
    }
  };

  return (
    <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
      <Fade
        in={true}
        key={flowState}
        onEnter={() => window.scrollTo(0, 0)}
        timeout={500}
        addEndListener={(node, done) => {
          node.addEventListener('transitionend', done, false);
        }}>
        <>
          {flowState === FlowState.PaymentSummary && (
            <RemoveChildPreview setFlowState={setFlowState} />
          )}
          {flowState === FlowState.Success && <RemoveChildSuccess />}
        </>
      </Fade>
    </FlowContainer>
  );
};

export default RemoveChildScreen;

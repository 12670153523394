export enum PasswordResetStep {
  GetEmail = 'reset_password_email',
  ConfirmRequestSent = 'reset_password_confirm_request_sent',
  ResetPassword = 'reset_password',
  PasswordResetSuccess = 'password_reset_success',
}

export const checkPasswordsMatch = (
  password: string,
  confirmedPassword: string,
) => {
  if (password.length < confirmedPassword.length) {
    return false;
  }

  const minLength = Math.min(password.length, confirmedPassword.length);
  const enteredPasswordSubString = password.slice(0, minLength);
  const comparedPasswordSubString = confirmedPassword.slice(0, minLength);

  const passwordsMatch = enteredPasswordSubString === comparedPasswordSubString;

  return passwordsMatch;
};

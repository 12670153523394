export enum GradeState {
  VeryBelow = 'Far behind grade level',
  Below = 'A little behind grade level',
  AtGradeLevel = 'As expected for grade level',
  Ahead = 'A little ahead of grade level',
  VeryAhead = 'Far ahead of grade level',
}

export enum ReadingLevel {
  A = 'A',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
  H = 'H',
  I = 'I',
  J = 'J',
  K = 'K',
  L = 'L',
  M = 'M',
  N = 'N',
}

export enum Grade {
  NotInSchool = 'Not in school',
  PreK = 'Pre-K',
  K = 'K',
  One = '1',
  Two = '2',
  Three = '3',
}

/*
 * A stripe credit card field.
 */

import React, {forwardRef, useImperativeHandle} from 'react';
import {Box, Typography} from '@mui/material';
import {useStripe, useElements, CardElement} from '@stripe/react-stripe-js';
import styles, {stripeStyle} from './CreditCardField.styles';
import withStyles from '@mui/styles/withStyles';
import paymentLogos from '../../../assets/paymentLogos.png';

const CreditCardField = forwardRef((props, ref) => {
  const stripe = useStripe();
  const elements = useElements();

  const processPayment = () => {
    if (stripe) {
      const cardElement = elements.getElement('card');
      window.analytics.track('Onboarding ProcessPaymentCard');

      stripe
        .createPaymentMethod({
          type: 'card',
          card: cardElement,
        })
        .then(payload => props.onPayment(payload));
    } else {
      console.log("Stripe.js hasn't loaded yet.");
    }
  };

  // Expose the processPayment function through the ref
  useImperativeHandle(ref, () => ({
    processPayment,
  }));

  return (
    <>
      <Box className={props.classes.paymentLogos}>
        <Typography align="left" className={props.classes.cardDetails}>
          {props.isApplePayReady ? (
            <Box>Or Pay with card</Box>
          ) : (
            <Box>Card Details</Box>
          )}
        </Typography>
        <img
          width={100}
          src={paymentLogos}
          alt="Credit Card Logos"
          align="right"
        />
      </Box>
      <Box component="div" className={props.classes.fieldContainer}>
        <CardElement
          options={{style: stripeStyle}}
          onChange={el => props.complete(el.complete)}
        />
      </Box>
    </>
  );
});

export default withStyles(styles)(CreditCardField);

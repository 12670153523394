import Box from '@mui/material/Box';
import React, {FC} from 'react';
import ExternalNavigation from '../../components/ExternalNavigation/ExternalNavigation';
import ExternalFinishBook from 'customerPortal/components/WebReader/ExternalFinishBook';

const ExternalFinishBookScreen: FC = () => {
  return (
    <Box>
      <ExternalNavigation />
      <ExternalFinishBook />
    </Box>
  );
};

export default ExternalFinishBookScreen;

import {Box, Grid, Typography} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import clsx from 'clsx';
import Section from 'customerPortal/components/Section';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';
import {ReactComponent as CancelSubscriptionSVG} from 'shared/assets/cancelSubscription.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import styles from './CancellationConfirmation.styles';
import {isRevenueCat} from '../CancellationForm.service';

interface Props extends WithStyles<typeof styles> {
  onConfirm: () => Promise<void>;
  onBack?: () => void;
  endDate?: string;
  profileNames: string[];
  subscriptionStatus: string;
  billingSource: string;
}

const CancellationConfirmation = ({
  classes,
  onConfirm,
  endDate,
  onBack = () => {},
  profileNames,
  subscriptionStatus,
  billingSource,
}: Props) => {
  const isMobile = useMobileLayout();
  const isRevenueCatSource = isRevenueCat(billingSource);

  const names =
    profileNames.slice(0, -1).join(', ') +
    (profileNames.length > 1 ? ' & ' : '') +
    profileNames.slice(-1);

  const subscriptionEndDateConfirmation =
    (endDate &&
      `Your ${
        subscriptionStatus === 'trialing' ? 'trial' : 'subscription'
      } will end on ${endDate} and ${names} will no longer have access to Ello's award-winning reading program.`) ||
    '';
  const confirmationText = `${subscriptionEndDateConfirmation} Are you sure?`;
  const gridSize = isMobile ? 12 : 6;

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          {isMobile && <Section title="Cancellation" />}

          <Box>
            <CancelSubscriptionSVG className={classes.icon} />
          </Box>
          <Typography variant={'h6'} align={'center'} className={classes.title}>
            {confirmationText}
          </Typography>
        </Box>
        <Box>
          <Grid container>
            <Grid container spacing={1}>
              <Grid item xs={gridSize}>
                <Button
                  className={clsx(classes.button, classes.backButton)}
                  variant={ButtonType.Secondary}
                  onClick={onBack}>
                  I Still Want Ello
                </Button>
              </Grid>
              <Grid item xs={gridSize}>
                <Button
                  className={clsx(
                    classes.button,
                    isRevenueCatSource && classes.revenueCatButton,
                  )}
                  onClick={onConfirm}>
                  {isRevenueCatSource
                    ? 'I Want To Cancel'
                    : 'Confirm Cancellation'}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationConfirmation);

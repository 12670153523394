import React from 'react';
import {customizedFlowsMapping} from './CustomizedFlowsMapping';
import {Box} from '@mui/material';

type Props = {
  cancelCategory: string;
  cancelDetail: string;
  onClose: () => void;
  onConfirm: () => void;
  setHideBackButton: (hide: boolean) => void;
};

const CustomizedCancellation: React.FC<Props> = ({
  cancelCategory,
  cancelDetail: propCancelDetail,
  onClose,
  onConfirm,
  setHideBackButton,
}) => {
  const cancelDetail = propCancelDetail || 'default';
  const CustomizedFlow =
    customizedFlowsMapping[cancelCategory]?.[cancelDetail]?.component;
  return (
    <Box sx={{flex: '1 1 auto', pb: '40px'}}>
      {CustomizedFlow ? (
        <CustomizedFlow
          onClose={onClose}
          onConfirm={onConfirm}
          setHideBackButton={setHideBackButton}
        />
      ) : null}
    </Box>
  );
};

export default CustomizedCancellation;

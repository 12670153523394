import Box from '@mui/material/Box';

import Button from '@mui/material/Button';
import React, {useState} from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import {useHistory} from 'react-router-dom';

import styles from './EditProfileScreen.styles';
import {profileImageByName} from './EditProfileScreen.service';
import EditProfileCharacterModal from './EditProfileScreen.picture-modal';
import useMobileLayout from '../../../../utils/useMobileLayout';
import {EDIT_PROFILE_BASE_PATH} from '../../../../../shared/components/Route/routes';
interface props extends WithStyles<typeof styles> {
  profileCharacter?: string;
  refetchProfile?: () => void;
  id?: string;
}

const EditProfilePicture = ({
  classes,
  id,
  profileCharacter,
  refetchProfile,
}: props) => {
  const isMobile = useMobileLayout();
  const history = useHistory();
  const [openModal, setOpenModal] = useState(false);

  const closeModal = () => {
    setOpenModal(false);
  };

  return (
    <Box>
      <Box className={classes.editImageContainer}>
        <img
          src={profileImageByName(profileCharacter)}
          alt=""
          className={classes.profilePic}
        />
        <Button
          onClick={() => {
            if (isMobile) {
              history.push(`${EDIT_PROFILE_BASE_PATH}/${id}/profileCharacter`);
              return;
            }
            setOpenModal(true);
          }}
          className={classes.changePicButton}>
          Change picture
        </Button>
      </Box>

      <EditProfileCharacterModal
        open={openModal}
        onCloseModal={closeModal}
        profileCharacter={profileCharacter}
        refetchProfile={refetchProfile}
        profileId={id}
      />
    </Box>
  );
};

export default withStyles(styles)(EditProfilePicture);

import createStyles from '@mui/styles/createStyles';

const styles = () =>
  createStyles({
    content: {
      flex: 1,
    },
    progress: {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
      alignSelf: 'center',
    },
  });

export default styles;

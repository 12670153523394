import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Radio, {RadioProps} from '@mui/material/Radio';
import React, {useEffect, useState} from 'react';
import {TrialChoice} from '../TrialSelection.types';
import * as palette from '../../../styles/palette';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import RadioButtonUncheckedIcon from '@mui/icons-material/RadioButtonUnchecked';
import useMobileLayout from '../../../../customerPortal/utils/useMobileLayout';

type Props = {
  choices: TrialChoice[];
  onValueChange: (value: TrialChoice) => void;
};

export default function ChoicesRadioGroup({choices, onValueChange}: Props) {
  const [value, setValue] = useState('');
  const isMobile = useMobileLayout();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value;
    const choice = choices.find(choice => choice.ctaText === value);

    setValue(value);
    if (choice) {
      onValueChange(choice);
    }
  };

  useEffect(() => {
    const selectedChoice = choices.find(choice => choice.selected);

    if (selectedChoice) {
      setValue(selectedChoice.ctaText);
    }
  }, [choices]);

  return (
    <FormControl fullWidth>
      <RadioGroup
        row={!isMobile}
        name="choice-radio-buttons-group"
        value={value}
        onChange={handleChange}
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          width: '100%',
        }}>
        {choices.map((choice, idx) => {
          return (
            <FormControlLabel
              key={idx}
              value={choice.ctaText}
              control={<CustomRadio />}
              label={choice.ctaText}
              sx={{
                border:
                  value === choice.ctaText
                    ? `1px solid ${palette.turquoise900}`
                    : `1px solid ${palette.gray300}`,
                color:
                  value === choice.ctaText ? palette.blue500 : palette.gray300,
                borderRadius: '8px',
                padding: '4px',
                margin: '4px',
                flexGrow: 1,
              }}
            />
          );
        })}
      </RadioGroup>
    </FormControl>
  );
}

function CustomRadio(props: RadioProps) {
  return (
    <Radio
      color="default"
      checkedIcon={<CheckCircleIcon sx={{color: palette.turquoise900}} />}
      icon={<RadioButtonUncheckedIcon sx={{color: palette.gray300}} />}
      {...props}
    />
  );
}

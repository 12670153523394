import {Box, Grid} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';
import Button, {ButtonType} from 'shared/components/common/Button';
import styles from './CancellationConfirmed.styles';

interface Props extends WithStyles<typeof styles> {
  hideDesktopBackButton?: boolean;
  onConfirm: () => void;
  onBack: () => void;
}

export const CancellationConfirmedStripeActions = withStyles(styles)(
  ({
    classes,
    hideDesktopBackButton = false,
    onConfirm,
    onBack = () => {},
  }: Props) => {
    const isMobile = useMobileLayout();

    return (
      <Box>
        <Grid container className={classes.buttonContainer}>
          {isMobile && (
            <Button className={classes.button} onClick={onConfirm}>
              OK
            </Button>
          )}
          {!isMobile && (
            <>
              <Grid container className={classes.leftContainer}>
                <Grid item>
                  {!hideDesktopBackButton && (
                    <Button
                      className={classes.activeButton}
                      variant={ButtonType.Inline}
                      onClick={onBack}>
                      Back
                    </Button>
                  )}
                </Grid>
              </Grid>
              <Grid item>
                <Button className={classes.button} onClick={onConfirm}>
                  OK
                </Button>
              </Grid>
              <Grid container className={classes.rightContainer}></Grid>
            </>
          )}
        </Grid>
      </Box>
    );
  },
);

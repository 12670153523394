import Box from '@mui/material/Box';
import React from 'react';
import {FC} from 'react';
import {Icon} from './SocialMedia.types';

type Props = {
  icon: Icon;
};

const SocialMediaLink: FC<Props> = ({icon}: Props) => {
  return (
    <Box>
      <img
        src={icon.src}
        alt={icon.alt}
        onClick={icon.onClick}
        style={{cursor: 'pointer'}}
      />
    </Box>
  );
};

export default SocialMediaLink;

import React from 'react';
import {Stack, SxProps, useTheme} from '@mui/material';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import Section from 'customerPortal/components/Section';

interface ContainerWrapperProps {
  sx?: SxProps;
  children: React.ReactNode;
  showCancellationTitle?: boolean;
}

const ContainerWrapper: React.FC<ContainerWrapperProps> = ({
  sx,
  children,
  showCancellationTitle = true,
}) => {
  const theme = useTheme();
  const isMobile = useMobileLayout();

  return (
    <Stack
      sx={{
        padding: theme.spacing(1),
        minHeight: '70vh',
        height: '100%',
        justifyContent: 'space-between',
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          padding: '72px 36px 40px 36px',
          minHeight: '580px',
        },
        ...sx,
      }}>
      {isMobile && showCancellationTitle && <Section title="Cancellation" />}
      {children}
    </Stack>
  );
};

export default ContainerWrapper;

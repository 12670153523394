import React from 'react';
import {ReactComponent as CopyIcon} from '../../../assets/copy.svg';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import useStyles from './CopyComponent.styles';

type Props = {
  copyToClipboard: () => void;
};

const CopyButton = ({copyToClipboard}: Props) => {
  const classes = useStyles();
  return (
    <Button onClick={copyToClipboard}>
      <CopyIcon className={classes.copyIcon} />
      <Typography className={classes.copySheetButtonText}>Copy</Typography>
    </Button>
  );
};

export default CopyButton;

import Typography from '@mui/material/Typography';
import React from 'react';

const RequestReceivedBodyText = () => {
  return (
    <Typography
      variant="body1"
      mt={3}
      style={{
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
      }}>
      Our curriculum specialist is going to reach out and help calibrate the
      perfect level for your child within the next 24-48 hours.
    </Typography>
  );
};

export default RequestReceivedBodyText;

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {ReactComponent as CopiedIcon} from '../../../assets/copied-icon.svg';
import useStyles from './CopyComponent.styles';

const CopiedText = () => {
  const classes = useStyles();

  return (
    <Box className={classes.copiedContainer}>
      <CopiedIcon className={classes.copiedIcon} />
      <Typography className={classes.copiedText}>Copied</Typography>
    </Box>
  );
};

export default CopiedText;

import {Destination} from './SocialMedia.types';

const bookSubject = "This digital children's book made me think of you!";
const homepageSubject =
  "This free children's book library made me think of you!";
function startBookBody(link: string): string {
  return `Hi! I found this exciting library that I think your child will love. It's fun and free!

Check out this book: ${link}

Enjoy!
`;
}

function homepageBody(link: string): string {
  return `Hi! I found this exciting library that I think your child will love. It's fun and free!

Check out this page: ${link}

Enjoy!
`;
}

export function emailLink(link: string, destination: Destination) {
  let body;
  let subject;

  if (destination === Destination.homepage) {
    body = homepageBody;
    subject = homepageSubject;
  } else {
    body = startBookBody;
    subject = bookSubject;
  }

  const bodyText = encodeURIComponent(body(link));
  const mailtoLink = `mailto:?subject=${encodeURIComponent(
    subject,
  )}&body=${bodyText}`;
  return mailtoLink;
}

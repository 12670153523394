import React from 'react';
import {Grid, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './TilePicker.styles';
import clsx from 'clsx';

interface Props extends WithStyles<typeof styles> {
  choices: {[key: string]: {label: string}};
  selected?: string;
  subscriptionStatus?: string;
  onSelect(index: string): void;
}
const TilePicker = ({
  classes,
  choices,
  selected = '',
  subscriptionStatus,
  onSelect,
}: Props) => {
  return (
    <Grid container justifyContent="center">
      {Object.entries(choices).map(([key, {label}]) =>
        key === 'dont-want-to-pay' &&
        subscriptionStatus !== 'trialing' ? null : (
          <Grid
            item
            key={key}
            xs={12}
            md={6}
            className={clsx(
              classes.tile,
              selected === key && classes.tileSelected,
            )}
            onClick={() => {
              onSelect(key);
            }}>
            <Typography component="h4" className={clsx(classes.tileTitle)}>
              {label}
            </Typography>
          </Grid>
        ),
      )}
    </Grid>
  );
};

export default withStyles(styles)(TilePicker);

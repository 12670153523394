import gql from 'graphql-tag';

export const QUERY_BILLING_DETAILS = gql`
  query SubscriptionPreview($readingLevel: String!) {
    stripeSubscription {
      paymentData {
        card {
          last4
          brand
        }
        billingDetails {
          address {
            city
            country
            line1
            line2
            postalCode
            state
          }
          name
        }
      }
    }
    subscriptionPreview(readingLevel: $readingLevel) {
      description
      name
      nextCycleDate
      price
      title
      currentSubscription {
        description
        name
        nextCycleDate
        price
        title
      }
    }
  }
`;

export const UPDATE_SUBSCRIPTION = gql`
  mutation UpdateSubscription($input: ProfileInput!) {
    updateSubscription(input: $input) {
      id
    }
  }
`;

import makeStyles from '@mui/styles/makeStyles';
import {landscapeLayoutBreakPoint} from './BreakPoints';

type styleParams = {
  contentFontSize: string;
  contentFontWeight: string | number;
  pagePhotoUrl: string;
};

export const horizontalBreakPoint = `@media ${landscapeLayoutBreakPoint}`;

export default makeStyles(theme => ({
  imageContainer: {
    [horizontalBreakPoint]: {
      display: 'flex',
      order: 2,
      height: '100%',
    },
  },
  image: (params: styleParams) => ({
    backgroundImage: `url(${params.pagePhotoUrl})`,
    backgroundPosition: 'right',
    backgroundSize: 'cover',
    width: '40vw',
    height: '40vw',
    maxWidth: '600px',
    maxHeight: '600px',
    margin: '0 auto',
    [horizontalBreakPoint]: {
      minHeight: '100px',
      right: 0,
      alignSelf: 'center',
    },
  }),
  contentContainer: {
    display: 'flex',
    minHeight: '35vh',
    marginTop: '16px',
    [horizontalBreakPoint]: {
      order: 1,
      width: '60vw',
      maxWidth: '600px',
      marginRight: '16px',
      height: '100%',
    },
  },
  content: (params: styleParams) => ({
    fontWeight: params.contentFontWeight as any,
    fontSize: params.contentFontSize,
    alignSelf: 'center',
    userSelect: 'none',
  }),
  page: {
    padding: '120px 16px 0 16px',
    [horizontalBreakPoint]: {
      display: 'flex',
      alignContents: 'center',
      height: '90vh',
      margin: '0 auto',
      maxWidth: '1200px',
    },
  },
}));

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  icon: {
    color: theme.palette.text.secondary,
    marginRight: theme.spacing(2),
  },
  fieldContainer: {
    display: 'flex',
    justifyContent: 'center',
    maxWidth: 500,
    margin: 'auto',
  },
  noOptions: {
    display: 'none',
  },
}));

import {gql} from '@apollo/client';

const categoryFields = `
  id
  name
  subcategories {
    id
    name
  }
  readingLists {
    id
    name
    books {
      id
      author
      coverPhotoURL
      title
    }
  }
  archivedAt
  createdAt
  updatedAt
`;

export const externalBooksQuery = gql`
  query ExternalBooks($pagination: PaginationArgs!, $filter: BookFilterArgs) {
    externalBooks(pagination: $pagination, filter: $filter) {
      books {
        id
        author
        coverPhotoURL
        title
      }
      pageInfo {
        page
        cursor
        totalPages
      }
    }
  }
`;

export const categoriesQuery = gql`
  query Categories($pagination: PaginationArgs!, $filter: CategoryFilter) {
    categories(pagination: $pagination, filter: $filter) {
      categories {
        ${categoryFields}
      }
    }
  }
`;

export const categoryQuery = gql`
  query Category($id: String!) {
    category(id: $id) {
      ${categoryFields}
    }
  }
`;

export const readingListsByCategoriesQuery = gql`
  query ReadingListsByCategories($categoryIds: [String!]!) {
    readingListsByCategories(categoryIds: $categoryIds) {
      readingLists {
        id
        name
        categories {
          id
          name
        }
        ancestors {
          id
          name
        }
        creator {
          name
        }
        books {
          id
          author
          coverPhotoURL
          title
        }
        archivedAt
        createdAt
        updatedAt
      }
    }
  }
`;

export const readingListQuery = gql`
  query ReadingList($id: String!) {
    readingList(id: $id) {
      id
      name
      books {
        id
        author
        coverPhotoURL
        title
      }
      archivedAt
      createdAt
      updatedAt
    }
  }
`;

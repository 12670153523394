import React, {Fragment} from 'react';
import {
  TableContainer,
  TableBody,
  Table as MaterialTable,
  TableFooter,
} from '@mui/material';
import {TableCellProps} from '@mui/material/TableCell/TableCell';
import TableRow from '../TableRow/TableRow';

interface Props {
  entries: Record<string, string>;
  footerEntries?: Record<string, string>;
  cellAlign?: TableCellProps['align'];
  headerClassName?: string;
  tableClassName?: string;
}

const Table = ({
  entries,
  footerEntries,
  cellAlign,
  headerClassName,
  tableClassName,
}: Props) => {
  return (
    <TableContainer className={tableClassName}>
      <MaterialTable>
        <TableBody>
          {Object.entries(entries).map(([key, value]) => (
            <Fragment key={key}>
              <TableRow
                label={key}
                value={value}
                cellAlign={cellAlign}
                headerClassName={headerClassName}
              />
            </Fragment>
          ))}
        </TableBody>
        {footerEntries && (
          <TableFooter>
            {Object.entries(footerEntries).map(([key, value]) => (
              <Fragment key={key}>
                <TableRow
                  isFooter
                  label={key}
                  value={value}
                  cellAlign={cellAlign}
                />
              </Fragment>
            ))}
          </TableFooter>
        )}
      </MaterialTable>
    </TableContainer>
  );
};

export default Table;

import createStyles from '@mui/styles/createStyles';

const useStyles = () =>
  createStyles({
    container: {
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
  });

export default useStyles;

import React, {FC} from 'react';
import {useWindowSize} from '../../../utils/layoutHooks';
import {TABLET_BREAKPOINT} from '../../../styles/breakpoints';
import Box from '@mui/material/Box';

type Props = {
  desktopImage: string;
  mobileImage: string;
};

const ImageHeader: FC<Props> = ({desktopImage, mobileImage}) => {
  const [windowWidth] = useWindowSize();
  const isTablet = windowWidth < TABLET_BREAKPOINT;

  const image = isTablet ? mobileImage : desktopImage;
  return (
    <Box display="flex" justifyContent="flex-start">
      <img
        src={image}
        alt="Trial Plan"
        style={{
          width: '100%',
          maxHeight: '200px',
          objectFit: 'contain',
        }}
      />
    </Box>
  );
};

export default ImageHeader;

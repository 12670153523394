import {IconButton} from '@mui/material';
import Box from '@mui/material/Box';
import React, {FC, useEffect, useState} from 'react';
import {Link, matchPath, useHistory, useLocation} from 'react-router-dom';
import {ReactComponent as IconBackArrow} from 'shared/assets/icons/arrow.svg';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import {getPathWithoutTrailingSlash} from 'shared/utils/urlUtils';
import useStyles from './BackButton.styles';

const determineReversePath = (pathname: string): string | null => {
  const matchingPath = Object.values(PRIVATE_ROUTES).find(route => {
    const match = matchPath(pathname, {
      path: route.path,
      exact: true,
    });

    return match !== null;
  });

  return matchingPath?.reversePath ?? null;
};
type props = {
  onClickHandler?: () => void;
};
const BackButton: FC<props> = ({onClickHandler}: props) => {
  const location = useLocation();
  const history = useHistory();
  const classes = useStyles();

  const [showBackBtn, setShowBackBtn] = useState<boolean>(true);
  const [path, setPath] = useState<string | null>(null);

  useEffect(() => {
    const accountPagePath = getPathWithoutTrailingSlash(location.pathname);
    setShowBackBtn(PRIVATE_ROUTES.ACCOUNT.path !== accountPagePath);

    // removing the trailing slash is required
    // when determining the relative path
    // used for the Link
    const hasTrailingSlash = location.pathname.endsWith('/');
    if (hasTrailingSlash) {
      history.replace(accountPagePath);
    }

    setPath(determineReversePath(accountPagePath));
  }, [location, history, path]);

  return (
    <Box>
      {onClickHandler ? (
        <IconButton
          onClick={onClickHandler}
          aria-label="Go back"
          className={classes.link}>
          <IconBackArrow />
        </IconButton>
      ) : (
        showBackBtn &&
        path && (
          <Link to={path} aria-label="Go back" className={classes.link}>
            <IconBackArrow />
          </Link>
        )
      )}
    </Box>
  );
};

export default BackButton;

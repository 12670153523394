import Box from '@mui/material/Box';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import {Product} from '../Product.types';
import Body from './Body';
import Header from './Header';

type Props = {
  product: Product;
  selectedProduct: string;
  onSelectProduct: (priceId: string) => void;
};

const Choice: FC<Props> = ({product, selectedProduct, onSelectProduct}) => {
  const selected = product.priceId === selectedProduct;

  const border = selected
    ? `2px solid ${palette.turquoise900}`
    : `1px solid ${palette.gray500}`;

  const onSelected = () => {
    onSelectProduct(product.priceId);
  };

  return (
    <Box
      component="div"
      sx={{
        padding: 1,
        paddingTop: 2,
        borderRadius: '16px',
        minHeight: '120px',
        cursor: 'pointer',
        maxWidth: '400px',
        margin: '0 -12px',
        height: '100%',
        position: 'relative',
        border,
        '@media (min-width: 450px)': {
          margin: '0 auto',
        },
      }}>
      <Box
        sx={{
          padding: 2,
          height: '100%',
        }}>
        <Header image={product.productImage} title={product.title} />
        <Body
          benefits={product.benefits}
          ctaFinePrint={product.ctaFinePrint}
          ctaText={product.ctaText}
          ctaVariant={product.ctaVariant}
          onSelected={onSelected}
          previousBenefitsText={product.previousBenefitsText}></Body>
      </Box>
    </Box>
  );
};

export default Choice;

import {useQuery} from '@apollo/client';
import {QUERY_PROFILES} from './WebReaderScreen.query';

type profile = {
  id: string;
  name: string;
};

export function useProfiles(): {
  profiles?: profile[];
  error?: Error;
  loading: boolean;
} {
  const {data, error, loading} = useQuery<{profiles: profile[]}>(
    QUERY_PROFILES,
  );
  const profiles = data?.profiles;
  return {profiles, error, loading};
}

import React, {useState} from 'react';
import {useParams} from 'react-router-dom';
import Button from '@mui/material/Button';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import {useHistory} from 'react-router-dom';

import styles from './EditProfileScreen.styles';
import {
  ProfileCharacter,
  updateProfileWithMutation,
  useProfile,
} from './EditProfileScreen.service';
import {useMutation} from '@apollo/client';
import {CUSTOMER_UPDATE_PROFILE_MUTATION} from './EditProfileScreen.query';
import * as palette from 'shared/styles/palette';
import ProfileCharacters from '../ProfilesScreen.characters';
const EditProfileCharacterScreen = ({classes}: WithStyles<typeof styles>) => {
  const {profileId} = useParams<{profileId?: string}>();
  const {data} = useProfile(profileId);
  const profileCharacter = data?.profileCharacter;

  const [selectedProfileCharacter, setSelectedProfileCharacter] = useState(
    profileCharacter || ProfileCharacter.Bull,
  );
  const history = useHistory();

  const [customerUpdateProfileMutation, {loading}] = useMutation(
    CUSTOMER_UPDATE_PROFILE_MUTATION,
  );
  const updateProfile = updateProfileWithMutation(
    customerUpdateProfileMutation,
  );
  const backToEditProfileScreen = () => {
    history.goBack();
  };

  const updateProfileCharacter = async () => {
    if (profileId) {
      await updateProfile(profileId, {
        profileCharacter: selectedProfileCharacter,
      });
    }
    backToEditProfileScreen();
  };

  return (
    <Box>
      <Typography className={classes.changeProfilepPictureHeader}>
        Change Picture
      </Typography>

      <ProfileCharacters
        selectedProfileCharacter={selectedProfileCharacter}
        setSelectedProfileCharacter={setSelectedProfileCharacter}
      />

      <Box
        sx={{
          padding: '0 2rem',
          marginTop: '2rem',
        }}>
        <Button
          variant="contained"
          disabled={loading}
          style={{
            backgroundColor: loading ? palette.gray500 : palette.turquoise900,
          }}
          className={classes.nextButton}
          onClick={updateProfileCharacter}>
          Next
          {loading && (
            <CircularProgress
              size={20}
              style={{
                marginLeft: '1rem',
              }}
              color="inherit"
            />
          )}
        </Button>

        <Button
          className={classes.backButton}
          onClick={backToEditProfileScreen}>
          Back
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(EditProfileCharacterScreen);

import React from 'react';
import {Button as MaterialButton, useTheme} from '@mui/material';
import {ButtonType} from 'shared/components/common/Button';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

interface SecondaryButtonProps {
  children: React.ReactNode;
  onClick: () => void;
}

const SecondaryButton: React.FC<SecondaryButtonProps> = ({
  children,
  onClick,
}) => {
  const theme = useTheme();
  return (
    <MaterialButton
      variant={ButtonType.Inline}
      disableRipple
      onClick={onClick}
      sx={{
        fontSize: '16px',
        fontWeight: 700,
        color: palette.gray700,
        textTransform: 'capitalize',
        lineHeight: '170%',
        letterSpacing: '0.18px',
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          fontSize: '18px',
        },
      }}>
      {children}
    </MaterialButton>
  );
};

export default SecondaryButton;

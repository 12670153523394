import Box from '@mui/material/Box';
import {ReactComponent as IconInfo} from 'shared/assets/icons/iconInfo.svg';
import ToolTip from './Tooltip';

const ExtraInfoTooltip = ({text}) => {
  return (
    <Box sx={{padding: '4px', display: 'inline'}}>
      <ToolTip text={text}>
        <IconInfo width={15} height={15} />
      </ToolTip>
    </Box>
  );
};

export default ExtraInfoTooltip;

import React from 'react';
import Box from '@mui/material/Box';

import Header from './AddProfileScreen.header';
import EditName from './AddProfileScreen.name';
import EditGrade from './AddProfileScreen.grade';
import EditReadingAbility from './AddProfileScreen.ability';
import {addedProfileErrorType, addedProfileType} from '../ProfilesScreen.types';

type props = {
  profileBeingAdded: addedProfileType;
  error: addedProfileErrorType;
  setProfileBeingAdded: React.Dispatch<React.SetStateAction<addedProfileType>>;
  setError: React.Dispatch<React.SetStateAction<addedProfileErrorType>>;
};
const AddProfileScreenForm = ({
  profileBeingAdded,
  error,
  setError,
  setProfileBeingAdded,
}: props) => {
  return (
    <Box>
      <Header />

      <EditName
        nameError={error.nameError}
        setError={setError}
        profileBeingAdded={profileBeingAdded}
        setProfileBeingAdded={setProfileBeingAdded}
      />

      <EditGrade
        gradeError={error.gradeError}
        profileBeingAdded={profileBeingAdded}
        setProfileBeingAdded={setProfileBeingAdded}
        setError={setError}
      />

      <EditReadingAbility
        readingAbilityError={error.readingAbilityError}
        profileBeingAdded={profileBeingAdded}
        setProfileBeingAdded={setProfileBeingAdded}
        setError={setError}
      />
    </Box>
  );
};

export default AddProfileScreenForm;

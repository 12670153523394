import createStyles from '@mui/styles/createStyles';
import {makeStyles} from '@mui/styles';

const bodyBreakpoint = '@media (min-width: 640px)';
const bodyMaxWidthBreakpoint = '@media (min-width: 700px)';

export default makeStyles(theme => {
  return createStyles({
    body: {
      margin: '0 auto',
      padding: '0 auto',
      marginBottom: theme.spacing(4),
      [bodyBreakpoint]: {
        padding: '0 24px',
      },
      [bodyMaxWidthBreakpoint]: {
        maxWidth: '740px',
      },
    },
    explainContent: {
      marginTop: theme.spacing(4),
    },
    tryForFreeButton: {
      paddingLeft: '75px',
      paddingRight: '75px',
      paddingTop: '20px',
      paddingBottom: '20px',
      fontSize: '20px',
      borderRadius: '50px',
      textAlign: 'center',
      background: '#28B8B8',
      marginTop: theme.spacing(5),
      marginBottom: theme.spacing(3),
      fontWeight: 'bolder',
    },
  });
});

import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import React, {FC} from 'react';
import {blue500, turquoise900} from '../../../shared/styles/palette';
import {links} from './ExternalNavigation.constants';

const ExternalNavigationLinks: FC = () => {
  const currentUrl = window.location.href;

  return (
    <Box>
      <Box sx={{display: 'flex'}}>
        <Box mt={'10px'}>
          {links.map(({link, label}, index) => (
            <Link
              key={index}
              href={link}
              sx={{
                color: currentUrl === link ? turquoise900 : blue500,
                textDecoration: 'none',
                padding: '10px',
                fontSize: '1.125rem',
                fontWeight: currentUrl === link ? 700 : 600,
                mr: '28px',
              }}>
              {label}
            </Link>
          ))}
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalNavigationLinks;

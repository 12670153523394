import * as Sentry from '@sentry/react';

const applicationKey = process.env.REACT_APP_SENTRY_APPLICATION_KEY || '';

export function initSentry() {
  Sentry.init({
    attachStacktrace: true,
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [
      Sentry.browserTracingIntegration(),
      Sentry.browserProfilingIntegration(),
      Sentry.captureConsoleIntegration({levels: ['error']}),
      Sentry.thirdPartyErrorFilterIntegration({
        filterKeys: [applicationKey],
        behaviour: 'drop-error-if-contains-third-party-frames',
      }),
    ],
    sampleRate: 0.1,
    tracesSampleRate: 0.01,
    profilesSampleRate: 0.5,
    tracePropagationTargets: ['api.helloello.com'],
  });
}

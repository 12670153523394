import {FetchResult, MutationFunctionOptions} from '@apollo/client';
import moment from 'moment';
import {LevelSentence} from './AdjustReadingLevel.types';

async function adjustReadingLevel(
  adjustReadingLevelMutation: (
    options: MutationFunctionOptions,
  ) => Promise<FetchResult>,
  {
    profileId,
    newLevel,
  }: {
    profileId: string;
    newLevel: string;
  },
) {
  try {
    const {data} = await adjustReadingLevelMutation({
      variables: {
        input: {
          profileId,
          newLevel,
        },
      },
    });
    return data?.adjustProfileReadingLevel;
  } catch (error: any) {
    throw error;
  }
}

function disabledFromRecentRequest(requestedLevelAdjustmentAt?: Date | string) {
  if (!requestedLevelAdjustmentAt) {
    return false;
  }
  const requestedAt = moment(requestedLevelAdjustmentAt);
  const now = moment();
  const twentyFourHoursAgo = moment(now).subtract(24, 'hours');

  return requestedAt.isAfter(twentyFourHoursAgo) && requestedAt.isBefore(now);
}

const getSentencesAfterLevel = (
  level: string,
  levelSentences: LevelSentence[],
): LevelSentence[] => {
  const currentIndex = levelSentences.findIndex(
    (sentence: LevelSentence) => sentence.level === level,
  );

  if (currentIndex === -1) {
    return [];
  }

  const stopIndex = Math.max(currentIndex + 4, 0);
  return levelSentences.slice(currentIndex + 1, stopIndex);
};

function getSentencesBeforeLevel(
  level: string,
  levelSentences: LevelSentence[],
): LevelSentence[] {
  const currentIndex = levelSentences.findIndex(
    (sentence: LevelSentence) => sentence.level === level,
  );

  if (currentIndex === -1) {
    return [];
  }

  const startIndex = Math.max(0, currentIndex - 3);
  return levelSentences.slice(startIndex, currentIndex);
}

async function requestReadingLevelAdjustment(
  requestReadingLevelAdjustmentMutation: (
    options: MutationFunctionOptions,
  ) => Promise<FetchResult>,
  {
    profileId,
    adjustmentRequest,
  }: {
    profileId: string;
    adjustmentRequest: string;
  },
) {
  try {
    const input = {
      profileId,
      adjustmentRequest,
    };

    const {data} = await requestReadingLevelAdjustmentMutation({
      variables: {input},
    });

    return data?.requestReadingLevelAdjustment;
  } catch (error) {
    console.log(error);
  }
}

const AdjustReadingLevelService = {
  adjustReadingLevel,
  disabledFromRecentRequest,
  getSentencesAfterLevel,
  getSentencesBeforeLevel,
  requestReadingLevelAdjustment,
};

export default AdjustReadingLevelService;

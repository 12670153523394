const styles = () => ({
  loadingIndicator: {
    marginTop: '40px',
    marginBottom: '40px',
    marginLeft: 'auto',
    marginRight: 'auto',
    display: 'block',
  },
});

export default styles;

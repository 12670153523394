import {Book} from './book';
import {ReadingLevel} from './readingLevels';

export enum PurchaseStatus {
  Pending = 'Pending',
  Active = 'Active',
  Returning = 'Returning',
  Due = 'Due',
  OverDue = 'Overdue',
  Shipped = 'Shipped',
}

export interface ProfileReadingLevel {
  name: string;
  readingLevel: ReadingLevel;
}

interface SubscriptionPeriod {
  id: string;
  month: number;
  readingLevels: ProfileReadingLevel[];
  startDate: Date;
  endDate: Date;
  dueDate: Date;
  mainOrderId?: string;
}

export interface Purchase {
  subscriptionPeriod: SubscriptionPeriod;
  orderReturnByDate?: Date;
  status?: PurchaseStatus;
  mainOrderShippingTrackingNumber: string;
}

export interface PurchasesFilter {
  showCurrentPurchases?: boolean;
  showPastPurchases?: boolean;
  showUpcomingPurchases?: boolean;
}

export enum BookOrderStatus {
  Shipped = 'Shipped',
  Delivered = 'Delivered',
  Due = 'Due',
  Kept = 'Kept',
  Returned = 'Returned',
  Created = 'Created',
}

export interface BookOrder extends Book {
  status: BookOrderStatus;
}

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {ReactComponent as SummerIcon} from '../../../shared/assets/summer-icon.svg';
import {blue500, gray900, turquoise900} from '../../../shared/styles/palette';
import SocialMediaLinks from '../WebReader/SocialMedia/SocialMediaLinks';
import {Destination} from '../WebReader/SocialMedia/SocialMedia.types';

const tabletBreakpoint = '@media (min-width: 768px)';

const LibraryBanner: FC = () => {
  const shareLink = `https://portal.helloello.com/books/`;

  return (
    <Box sx={{maxWidth: '600px', margin: '0 auto'}}>
      <Box sx={{maxWidth: '225px', margin: '0 auto'}}>
        <SummerIcon />
      </Box>
      <Typography
        sx={{
          fontSize: '1.625rem',
          fontWeight: 800,
          color: blue500,
          textAlign: 'center',
          mt: 2,
          [tabletBreakpoint]: {
            fontSize: '2.25rem',
          },
        }}>
        Confident, Independent Reading
      </Typography>
      <Box
        sx={{
          maxWidth: '480px',
          margin: '0 auto',
          [tabletBreakpoint]: {
            maxWidth: '600px',
          },
        }}>
        <Typography
          sx={{
            fontWeight: 600,
            fontSize: '1rem',
            textAlign: 'center',
            color: gray900,
            mt: 2,
            [tabletBreakpoint]: {
              fontSize: '1.25rem',
            },
          }}>
          Over 700 decodable books that match your child's reading ability. Help
          prevent the summer slump, without frustration
        </Typography>
      </Box>
      <Box
        sx={{
          maxWidth: '300px',
          margin: '0 auto',
          [tabletBreakpoint]: {
            maxWidth: '350px',
          },
        }}>
        <Button
          fullWidth
          variant="contained"
          href="/categories"
          sx={{
            backgroundColor: turquoise900,
            p: '12px',
            borderRadius: '50px',
            boxShadow: 'none',
            mt: 4,
            [tabletBreakpoint]: {
              p: 2,
            },
          }}>
          <Typography
            variant="button"
            sx={{
              fontSize: '1rem',
              fontWeight: 800,
              [tabletBreakpoint]: {
                fontSize: '1.125rem',
              },
            }}>
            EXPLORE READING SETS
          </Typography>
        </Button>
      </Box>
      <Box sx={{display: 'flex', justifyContent: 'center', mt: '24px'}}>
        <SocialMediaLinks destination={Destination.homepage} link={shareLink} />
      </Box>
    </Box>
  );
};

export default LibraryBanner;

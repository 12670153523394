import Box from '@mui/material/Box';
import React, {FC} from 'react';
import SectionCopy from '../SectionCopy';
import Link from './Link';
import QRCode from './QRCode';
import useStyles from './QRLink.styles';

type props = {
  isDigital: boolean;
  physicalLink?: string;
  digitalLink?: string;
};

const isLink = function (url: string | undefined): boolean {
  if (!url) {
    return false;
  }
  return url.startsWith('https://') || url.startsWith('http://');
};

const QRLink: FC<props> = ({isDigital, physicalLink, digitalLink}) => {
  const classes = useStyles({isDigital});

  if (physicalLink) {
    physicalLink = isLink(physicalLink)
      ? physicalLink
      : 'https://' + physicalLink;
  }

  if (digitalLink) {
    digitalLink = isLink(digitalLink) ? digitalLink : 'https://' + digitalLink;
  }

  return (
    <Box className={classes.container}>
      <SectionCopy
        header={'Scan QR Code'}
        text={"Scan this QR code using the camera on your child's tablet:"}
      />
      <Box className={classes.qrlink}>
        <QRCode isDigital={isDigital} />
        {isDigital && <Link link={digitalLink} />}
        {!isDigital && <Link link={physicalLink} />}
      </Box>
    </Box>
  );
};
export default QRLink;

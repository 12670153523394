import Grid from '@mui/material/Grid';
import cN from 'classnames';
import React, {FC} from 'react';
import logoEllo from 'shared/assets/logoEllo.svg';
import useMobileLayout from '../../../utils/useMobileLayout';
import {useLogoStyles} from './logo.styles';

type props = {
  nonResponsive?: boolean;
};

const Logo: FC<props> = ({nonResponsive}: props) => {
  const isMobile = useMobileLayout();
  const classes = useLogoStyles();

  const responsiveStyle = isMobile ? 'center' : 'flex-start';
  const justifyContent = nonResponsive ? 'flex-start' : responsiveStyle;

  return (
    <Grid
      container
      item
      xs
      alignContent="center"
      alignItems="center"
      justifyContent={justifyContent}>
      <img src={logoEllo} alt="" className={cN(classes.logo)} />
    </Grid>
  );
};

export default Logo;

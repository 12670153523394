export function isProduction(): boolean {
  return (
    process.env.NODE_ENV === 'production' &&
    !(
      window.location.href.includes('staging') ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('webflow.io') ||
      window.location.href.includes('rav') ||
      window.location.href.includes('develop-portal')
    )
  );
}

export function isStagingOrWebflow(): boolean {
  return (
    window.location.href.includes('staging') ||
    process.env.REACT_APP_VARIANT === 'staging' ||
    window.location.href.includes('webflow.io')
  );
}

export function isRavensburger(): boolean {
  return window.location.href.includes('rav');
}

export function isAndroidEmulator(): boolean {
  return window.location.href.includes('10.0.2.2');
}

export function isDevelop(): boolean {
  return window.location.href.includes('develop-portal');
}

import {Typography} from '@mui/material';
import React, {useState} from 'react';
import {ProfileReadingLevel} from 'shared/types/purchases';
import {DarkTooltip} from '../Tooltip/Tooltip';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cN from 'classnames';
import {ReactComponent as SharedIcon} from 'shared/assets/icons/sharedIcon.svg';
import {ReactComponent as SingleProfileIcon} from 'shared/assets/icons/singleProfileIcon.svg';
import styles from './BookOrdersProfileViewer.styles';
interface BookOrdersProfileViewerProps extends WithStyles<typeof styles> {
  profileObjects: ProfileReadingLevel[];
  showText?: boolean;
  showBackground?: boolean;
  containerClass?: string;
}
const BookOrdersProfileViewer = ({
  classes,
  profileObjects,
  containerClass,
  showText = false,
  showBackground = false,
}: BookOrdersProfileViewerProps) => {
  const sharedBook = profileObjects.length > 1;
  const [showBoxShadow, setShowBoxShadow] = useState(false);
  return (
    <div
      className={cN(classes.profileIcon, containerClass, {
        [classes.background]: showBackground,
        [classes.boxShadow]: showBackground && showBoxShadow,
      })}>
      {sharedBook ? (
        <DarkTooltip
          onClick={() => {
            setShowBoxShadow(true);
          }}
          onClose={() => {
            setShowBoxShadow(false);
          }}
          enterTouchDelay={0}
          title={profileObjects.map(({name}, index) => (
            <Typography className={classes.tooltipProfileName} key={index}>
              {name}
            </Typography>
          ))}>
          <SharedIcon aria-hidden />
        </DarkTooltip>
      ) : (
        <SingleProfileIcon aria-hidden />
      )}
      {showText && (
        <Typography className={classes.profileName}>
          {sharedBook ? 'Shared' : profileObjects[0].name}
        </Typography>
      )}
    </div>
  );
};

export default withStyles(styles)(BookOrdersProfileViewer);

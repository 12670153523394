import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {BORDER_RADIUS, TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {gray700, gray900} from 'shared/styles/palette';

export const optionStyles = makeStyles((theme: Theme) => ({
  option: {
    cursor: 'pointer',
    flex: '0 1 327px',
    justifyContent: 'space-around',
    display: 'flex',
    flexDirection: 'column',
    borderRadius: BORDER_RADIUS,
    padding: `${theme.spacing(4)} ${theme.spacing(3)}`,
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.11)',
    margin: theme.spacing(1),
    border: '2px solid transparent',
    '& > *': {
      cursor: 'pointer',
    },
    '&:hover, &:focus-within': {
      borderColor: theme.palette.primary.main,
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      margin: `0 0 ${theme.spacing(2)} 0`,
      flex: 'initial',
      padding: theme.spacing(3),
    },
  },
  selected: {
    borderColor: theme.palette.primary.main,
  },
  header: {
    display: 'flex',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      justifyContent: 'space-between',
    },
  },
  headerContent: {
    display: 'flex',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexWrap: 'wrap',
    },
  },
  label: {
    flex: '1 0 90px',
  },
  icon: {
    flex: '0 0 64px',
    marginRight: theme.spacing(4),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginRight: theme.spacing(2),
    },
  },
  chevron: {
    display: 'none',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginLeft: theme.spacing(1),
      display: 'block',
      minWidth: '20px',
    },
  },
  title: {
    fontWeight: 700,
    color: gray900,
    display: 'block',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1rem',
    },
  },
  price: {
    fontWeight: 400,
    color: gray700,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '1rem',
    },
  },
  desc: {
    fontSize: '0.875rem',
    fontWeight: 400,
    color: gray900,
    marginTop: theme.spacing(2),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '0.8125rem',
    },
  },
}));

export const containerStyles = makeStyles((theme: Theme) => ({
  fieldset: {
    margin: `${theme.spacing(5)} 0`,
    display: 'flex',
    alignItems: 'center',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
      margin: 0,
    },
  },
  optionContainer: {
    display: 'flex',
    justifyContent: 'center',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      flexDirection: 'column',
    },
  },
  button: {
    width: '100%',
  },
}));

import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './EditProfileScreen.styles';
import {
  ProfileCharacter,
  updateProfileWithMutation,
} from './EditProfileScreen.service';
import * as palette from 'shared/styles/palette';
import {useMutation} from '@apollo/client';
import {CUSTOMER_UPDATE_PROFILE_MUTATION} from './EditProfileScreen.query';
import ProfileCharacters from '../ProfilesScreen.characters';
interface props extends WithStyles<typeof styles> {
  open: boolean;
  onCloseModal: () => void;
  profileId?: string;
  refetchProfile?: () => void;
  profileCharacter?: string;
}
const EditProfileCharacterModal = ({
  classes,
  open,
  onCloseModal,
  refetchProfile,
  profileId,
  profileCharacter,
}: props) => {
  const [selectedProfileCharacter, setSelectedProfileCharacter] = useState(
    profileCharacter || ProfileCharacter.Bull,
  );

  const [customerUpdateProfileMutation, {loading}] = useMutation(
    CUSTOMER_UPDATE_PROFILE_MUTATION,
  );
  const updateProfile = updateProfileWithMutation(
    customerUpdateProfileMutation,
  );

  const updateProfileCharacter = async () => {
    if (profileId) {
      await updateProfile(profileId, {
        profileCharacter: selectedProfileCharacter,
      });
    }
    refetchProfile && refetchProfile();
    onCloseModal();
  };

  return (
    <Modal open={open} onClose={onCloseModal}>
      <Box className={classes.modalBox}>
        <Box className={classes.modalHeader}>
          <Typography variant="h5" className={classes.modalHeaderText}>
            Change Picture
          </Typography>
          <IconButton aria-label="close" onClick={onCloseModal}>
            <CloseIcon className={classes.closeIcon} />
          </IconButton>
        </Box>

        <ProfileCharacters
          selectedProfileCharacter={selectedProfileCharacter}
          setSelectedProfileCharacter={setSelectedProfileCharacter}
        />

        <Box className={classes.editPicActions}>
          <Button className={classes.cancelButton} onClick={onCloseModal}>
            Cancel
          </Button>

          <Button
            variant="contained"
            disabled={loading}
            style={{
              backgroundColor: loading ? palette.gray500 : palette.turquoise900,
            }}
            className={classes.saveButton}
            onClick={updateProfileCharacter}>
            Save
            {loading && (
              <CircularProgress
                size={20}
                style={{
                  marginLeft: '1rem',
                }}
                color="inherit"
              />
            )}
          </Button>
        </Box>
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(EditProfileCharacterModal);

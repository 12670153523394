import React from 'react';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Button from 'shared/components/common/Button';
import {AddChildFlow} from '../types';
import Card from 'customerPortal/components/Card/Card';
import {FlowLayout} from 'customerPortal/components/Flow';
import {QUERY_SUBSCRIPTION_PREVIEW} from './SubscriptionPreview.query';
import {useQuery} from '@apollo/react-hooks';
import {SubscriptionPreview} from 'shared/types/subscriptionPreview';
import {toUSDateFormat} from 'shared/utils/dateUtils';
import {getBundleIcon} from 'customerPortal/utils/bundleIcons';
import styles from './SubscriptionPreview.styles';
import {FlowState} from 'customerPortal/components/Flow/types';

type Props = Pick<
  AddChildFlow,
  'formValues' | 'setFlowState' | 'recommendation'
> &
  WithStyles<typeof styles>;

const SubscriptionPreviewStep = ({
  classes,
  setFlowState,
  formValues,
  recommendation,
}: Props) => {
  const {loading, data, error} = useQuery<{
    subscriptionPreview: SubscriptionPreview;
  }>(QUERY_SUBSCRIPTION_PREVIEW, {
    variables: {
      readingLevel:
        recommendation.selectedReadingLevel ?? formValues.readingLevel,
    },
    onError: error => console.error('Subscription Preview::', error),
  });

  const getBundleQuantityString = (bundles: number | undefined) => {
    switch (bundles) {
      case 1:
        return 'a shared set';
      case 2:
        return 'two sets';
      case 3:
        return 'three sets';
      default:
        return 'an updated set';
    }
  };

  const numBundles = data?.subscriptionPreview?.bundles;
  const bundleTitle = data?.subscriptionPreview?.title;
  const bundleName = data?.subscriptionPreview?.name;
  const nextCycleDate = data?.subscriptionPreview?.nextCycleDate;

  return (
    <FlowLayout
      isLoading={loading}
      error={error ? 'Unable to load update to subscription' : ''}
      title="Update to your subscription"
      cta={
        <Button
          onClick={() => setFlowState(FlowState.PaymentSummary)}
          className={classes.button}>
          Continue
        </Button>
      }>
      <Card className={classes.card}>
        <>
          <img src={getBundleIcon(bundleName)} alt="" className={classes.img} />
          <span className={classes.content}>
            <Typography component="p" className={classes.desc}>
              Starting{' '}
              <time>
                {nextCycleDate
                  ? toUSDateFormat(new Date(nextCycleDate).getTime())
                  : null}
              </time>
              , you will receive {getBundleQuantityString(numBundles)} of books
              within your monthly package. Your subscription will be updated to:
            </Typography>
            {bundleTitle && (
              <Typography component="p" variant="h4" className={classes.bundle}>
                {bundleTitle}
              </Typography>
            )}
          </span>
        </>
      </Card>
    </FlowLayout>
  );
};

export default withStyles(styles)(SubscriptionPreviewStep);

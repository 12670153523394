import React from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from './DataList.styles';

interface Props extends WithStyles<typeof styles> {
  data: Record<string, string | JSX.Element>;
}

const DataList = ({classes, data}: Props) => {
  return (
    <dl>
      {Object.entries<string | JSX.Element>(data).map(
        ([key, value], index: number) => (
          <div className={classes.item} key={index}>
            <dt className={classes.term}>{key}</dt>
            <dd className={classes.desc}>{value}</dd>
          </div>
        ),
      )}
    </dl>
  );
};

export default withStyles(styles)(DataList);

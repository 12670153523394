import Typography from '@mui/material/Typography';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import cN from 'classnames';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import React from 'react';
import {useRouteMatch} from 'react-router-dom';
import bookBox from 'shared/assets/bookBox.png';
import {ButtonType} from 'shared/components/common/Button';
import {Purchase, PurchaseStatus} from 'shared/types/purchases';
import {getFormattedDate} from 'shared/utils/dateUtils';
import {getPathWithoutTrailingSlash} from 'shared/utils/urlUtils';
import ExternalLink from '../Link/ExternalLink';
import Link from '../Link/Link';
import PurchaseStatusChip from '../StatusChip/PurchaseStatusChip';
import PurchaseItemText from './PurchaseItemText';
import {usePurchaseItemStyles} from './PurchaseList.styles';

interface PurchaseItemProps extends WithStyles<typeof usePurchaseItemStyles> {
  item: Purchase;
  productType?: string;
  upcomingPurchase?: boolean;
}

const PurchaseItem = ({
  classes,
  item,
  productType,
  upcomingPurchase,
}: PurchaseItemProps) => {
  const {url} = useRouteMatch();
  const {id, month, startDate, endDate, readingLevels, mainOrderId} =
    item.subscriptionPeriod;
  const {status, orderReturnByDate, mainOrderShippingTrackingNumber} = item;

  const USPS_TRACKING_URL = `https://tools.usps.com/go/TrackConfirmAction?tLabels=${mainOrderShippingTrackingNumber}`;
  const dueStatuses = [PurchaseStatus.Due, PurchaseStatus.OverDue];
  const showTextStatuses = [PurchaseStatus.Active, ...dueStatuses];
  const subscriptionBeginDates = getFormattedDate(
    new Date(startDate).getTime(),
  );

  const subscriptionEndDates = getFormattedDate(new Date(endDate).getTime());

  const isMobile = useMobileLayout();
  const showText =
    (status && showTextStatuses.includes(status)) || upcomingPurchase;

  const readingLevelsWithoutName = readingLevels.map(
    ({readingLevel}) => readingLevel,
  );

  const showTrackYourBoxLink = status === PurchaseStatus.Shipped;

  const listedReadingLevels = Array.from(new Set(readingLevelsWithoutName));

  return (
    <div
      className={cN({
        [classes.item]: !isMobile,
        [classes.mobileItem]: isMobile,
      })}>
      <div>
        <div
          className={cN(classes.header, {
            [classes.laptoph1]: !isMobile,
            [classes.mobileh1]: isMobile,
          })}>
          <img src={bookBox} alt="" className={classes.box} />
          <div className={classes.headerContent}>
            <span>
              <Typography component="h1" variant="h5">
                Month {month}
              </Typography>
              <time dateTime={subscriptionBeginDates.a11y}>
                {subscriptionBeginDates.friendly(false)}
              </time>{' '}
              -{' '}
              <time dateTime={subscriptionEndDates.a11y}>
                {subscriptionEndDates.friendly(false)}
              </time>
            </span>

            <span>
              <Typography component="h2" variant="h6">
                Level{listedReadingLevels.length > 1 ? 's' : ''}{' '}
                {listedReadingLevels.map((readingLevel, index) => {
                  const punctuationAfterReadingLevel =
                    index === listedReadingLevels.length - 1
                      ? ''
                      : index === listedReadingLevels.length - 2
                      ? ' & '
                      : ', ';
                  return readingLevel + punctuationAfterReadingLevel;
                })}
              </Typography>

              <PurchaseStatusChip status={status!} />
            </span>
          </div>
        </div>

        {showText && (
          <PurchaseItemText
            isMobile={isMobile}
            orderReturnByDate={orderReturnByDate}
            startDate={startDate}
            upcomingPurchase={upcomingPurchase}
            classes={classes}
            productType={productType}
          />
        )}
      </div>
      {mainOrderId && (
        <Link
          to={`${getPathWithoutTrailingSlash(url)}/${id}`}
          variant={ButtonType.Secondary}
          className={classes.link}>
          View Books
        </Link>
      )}

      {showTrackYourBoxLink && mainOrderShippingTrackingNumber && (
        <ExternalLink
          href={USPS_TRACKING_URL}
          variant={ButtonType.Secondary}
          className={cN([classes.link, classes.trackYourBoxlink])}>
          Track your box
        </ExternalLink>
      )}
    </div>
  );
};

export default withStyles(usePurchaseItemStyles)(PurchaseItem);

/**
 * With lotsa inspiration from
 * https://www.pluralsight.com/guides/re-render-react-component-on-window-resize
 * and https://stackoverflow.com/questions/19014250.
 */
import {useLayoutEffect, useState} from 'react';

/**
 * Debounce layout change helper, which ensures that the wrapped
 * function is called at most once per time interval (with a delay).
 * (Could be improved to avoid delay, but fine for this purpose.)
 *
 * @param {Closure} fn: the function to debounce
 * @param {Number} ms: every how many miliseconds may this function
 *  be called?
 */
function debounce(fn, ms) {
  let timer;
  return () => {
    clearTimeout(timer);
    timer = setTimeout(_ => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

/**
 * React hook that provides window size for conditional rendering
 * with throttling so we don't re-render on every pixel change.
 */
function useWindowSize() {
  const [size, setSize] = useState([window.innerWidth, window.innerHeight]);
  useLayoutEffect(() => {
    const updateSize = debounce(function () {
      setSize([window.innerWidth, window.innerHeight]);
    }, 500);
    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);
  return size;
}

export {debounce, useWindowSize};

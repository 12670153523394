import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {Theme} from '@mui/material/styles';
import {iconButtonHoverColor} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    link: {
      marginTop: theme.spacing(1.5),
      marginLeft: -theme.spacing(1.5),
      borderRadius: `50%`,
      minWidth: '50px',
      width: '50px',
      height: '50px',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      outline: 'none',
      '&:hover, &:focus': {
        backgroundColor: iconButtonHoverColor,
      },
    },
  });

export default makeStyles(styles);

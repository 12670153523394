export type CountryType = {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
};

export const defaultCountry = {
  code: 'US',
  label: 'United States',
  phone: '1',
};

export const countries: CountryType[] = [
  {code: 'KE', label: 'Kenya', phone: '254'},
  {code: 'PH', label: 'Philippines', phone: '63'},
  defaultCountry,
];

export function phoneNumberRegex(country: CountryType) {
  if (country.code === 'KE') {
    return /^\d{9}$/;
  }

  return /^\d{10}$/;
}

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import * as palette from 'shared/styles/palette';
import useMobileLayout from '../../../../customerPortal/utils/useMobileLayout';

type Props = {
  ctaText: string;
  onSelected: () => void;
};

const CTA: FC<Props> = ({ctaText, onSelected}) => {
  const ctaTextStyles: {fontWeight: number; color?: string} = {
    fontWeight: 700,
  };

  const text = ctaText.includes('$1')
    ? 'START 30-DAY TRIAL FOR $1'
    : 'START 14-DAY TRIAL';

  const isMobile = useMobileLayout();

  return (
    <Box mt={'auto'}>
      <Button
        disableElevation
        onClick={onSelected}
        variant={'contained'}
        sx={{
          py: 1,
          borderRadius: 6,
          marginTop: 'auto',
          borderColor: palette.turquoise900,
          border: `1px solid ${palette.turquoise900} !important`,
          backgroundColor: palette.turquoise900,
          minWidth: isMobile ? '100%' : '50%',
        }}>
        <Typography variant="button" sx={ctaTextStyles}>
          {text}
        </Typography>
      </Button>
    </Box>
  );
};

export default CTA;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {gray700} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    verificationInput: {
      margin: 'auto',
      '& input': {
        fontFamily: theme.typography.fontFamily,
      },
      '& input:focus': {
        outline: 'none',
        border: `2px solid ${theme.palette.primary.light}`,
        caretColor: theme.palette.text.primary,
      },
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      fontWeight: 400,
      marginBottom: theme.spacing(5),
      color: gray700,
    },
  });

export default styles;

import firebase from 'firebase/app';
import React, {createContext, useContext, useEffect, useState} from 'react';
import PageLoader from 'shared/components/common/PageLoader';
import featureFlags from '../featureFlags';

interface FirebaseAuth {
  user: firebase.User | null;
  isLoading: boolean;
  setIsLoading: (l: boolean) => void;
}

const useFirebaseAuth = (): FirebaseAuth => {
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    const unsubscribe = firebase.auth().onAuthStateChanged(firebaseUser => {
      if (firebaseUser) {
        setUser(firebaseUser);
        featureFlags.setAttributes({
          environment: process.env.REACT_APP_FEATURE_FLAGS_ENV,
          loggedIn: true,
          id: firebaseUser.uid,
          email: firebaseUser.email,
          phoneNumber: firebaseUser.phoneNumber,
        });
      } else {
        setUser(null);
        featureFlags.setAttributes({
          loggedIn: false,
          environment: process.env.REACT_APP_FEATURE_FLAGS_ENV,
        });
      }
      setIsLoading(false);
    });

    return () => unsubscribe();
  }, []);

  return {user, isLoading, setIsLoading};
};

const FirebaseAuthContext = createContext<FirebaseAuth>({
  user: null,
  isLoading: true,
  setIsLoading: () => {},
});

export const useAuth = (): FirebaseAuth => {
  return useContext(FirebaseAuthContext);
};

export const FirebaseAuthProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const auth = useFirebaseAuth();
  return (
    <FirebaseAuthContext.Provider value={auth}>
      <FirebaseAuthContext.Consumer>
        {({isLoading}: Pick<FirebaseAuth, 'isLoading'>) =>
          isLoading ? <PageLoader /> : children
        }
      </FirebaseAuthContext.Consumer>
    </FirebaseAuthContext.Provider>
  );
};

import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import React, {FC} from 'react';
import {blue500} from '../../../shared/styles/palette';
import ExternalLink from './ExternalLink';
import {links} from './ExternalNavigation.constants';

type Props = {
  openDrawer: boolean;
  setOpenDrawer: (open: boolean) => void;
};

const ExternalDrawer: FC<Props> = ({openDrawer, setOpenDrawer}: Props) => {
  return (
    <Box>
      <Drawer
        open={openDrawer}
        onClose={() => setOpenDrawer(false)}
        anchor="left"
        PaperProps={{
          sx: {
            backgroundColor: blue500,
            padding: 3,
            width: '100vw',
          },
        }}>
        <Box pt={5}>
          {links.map(({link, label}, index) => (
            <ExternalLink key={index} link={link} label={label} />
          ))}
        </Box>
      </Drawer>
    </Box>
  );
};

export default ExternalDrawer;

import React from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from '../ProfilesScreen.styles';
import {AdjustmentRequest, ScreenState} from './AdjustReadingLevel.types';
import AnalyticsService from '../../../../../analytics/Analytics.service';
import AdjustReadingLevelService from './AdjustReadingLevel.service';
import {REQUEST_READING_LEVEL_ADJUSTMENT_MUTATION} from './AdjustReadingLevel.query';
import {useMutation} from '@apollo/client';
import {LoadingButton} from '@mui/lab';

interface Props extends WithStyles<typeof styles> {
  Icon: React.ReactNode;
  text: string;
  updateScreenState: (screenState: ScreenState) => void;
  adjustmentRequest: AdjustmentRequest;
  profileId?: string;
}

const RequestAdjustmentButton = ({
  classes,
  Icon,
  text,
  updateScreenState,
  adjustmentRequest,
  profileId,
}: Props) => {
  const [requestReadingLevelAdjustmentMutation, {loading}] = useMutation(
    REQUEST_READING_LEVEL_ADJUSTMENT_MUTATION,
  );

  const onClick = async () => {
    if (profileId) {
      AnalyticsService.trackLevelAdjustmentCompleted(
        profileId,
        adjustmentRequest,
      );

      await AdjustReadingLevelService.requestReadingLevelAdjustment(
        requestReadingLevelAdjustmentMutation,
        {profileId, adjustmentRequest},
      );
    }

    updateScreenState(ScreenState.RequestReceived);
  };

  return (
    <LoadingButton
      loading={loading}
      startIcon={Icon}
      className={classes.requestAdjustmentButton}
      onClick={onClick}>
      {text}
    </LoadingButton>
  );
};

export default withStyles(styles)(RequestAdjustmentButton);

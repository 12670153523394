import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {
  gray300,
  gray700,
  gray900,
  orange500,
  teal500,
  turquoise900,
} from 'shared/styles/palette';

export const usePurchaseItemStyles = (theme: Theme) =>
  createStyles({
    item: {
      flexBasis: '100%',
      padding: `0 ${theme.spacing(4)}`,
    },
    mobileItem: {
      padding: `${theme.spacing(4)} 0`,
      flexBasis: '100%',
      borderBottom: `1px solid ${gray300}`,
      '&:last-of-type': {
        borderBottom: 'transparent',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      '& h2': {
        fontWeight: 700,
        color: gray900,
      },
    },
    laptoph1: {
      '& h1': {
        fontSize: '1.5rem',
        fontWeight: 700,
      },
    },
    mobileh1: {
      '& h1': {
        fontSize: '1.375rem',
        fontWeight: 700,
      },
      '& h2': {
        fontSize: '1rem',
      },
    },
    headerContent: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
    },
    returnBy: {
      color: gray900,
      fontWeight: 700,
    },
    returnByLaptop: {
      fontSize: '1.25rem',
    },
    returnByMobile: {
      fontSize: '1rem',
    },
    disclaimer: {
      fontSize: '0.875rem',
      fontWeight: 400,
      color: gray700,
      marginBottom: theme.spacing(3),
    },
    box: {
      maxWidth: '140px',
    },
    link: {
      fontSize: '1.125rem',
      textTransform: 'uppercase',
    },
    chipActive: {
      borderColor: teal500,
      color: teal500,
      fontWeight: 700,
    },
    chipDue: {
      borderColor: orange500,
      color: orange500,
      fontWeight: 700,
    },
    dueSoon: {
      color: orange500,
    },
    trackYourBoxlink: {
      color: turquoise900,
      marginTop: theme.spacing(3),
      border: `2px solid ${turquoise900}`,
      borderRadius: '50px',
    },
  });

export const usePurchaseListStyles = (theme: Theme) =>
  createStyles({
    list: {
      padding: 0,
      margin: 0,
      display: 'flex',
      overflow: 'scroll',
    },
    slider: {
      display: 'flex',
      marginBottom: theme.spacing(4),
      borderRight: `1px solid ${gray300}`,
      '&:last-of-type': {
        borderRight: 'transparent',
      },
    },
  });

export const useNoBooksStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
    },
    title: {
      fontSize: '1.625rem',
      fontWeight: 400,
      color: gray700,
    },
  });

import React from 'react';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import {useHistory} from 'react-router-dom';

import {ReactComponent as LeftChevron} from 'shared/assets/icons/left-chevron.svg';
import * as palette from 'shared/styles/palette';
import useMobileLayout from '../../../../utils/useMobileLayout';

type Props = {
  oldPath?: string;
  currentPath?: string;
};

const Header = ({oldPath, currentPath}: Props) => {
  const history = useHistory();
  const isMobile = useMobileLayout();

  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
      }}>
      {!isMobile && (
        <IconButton onClick={() => history.goBack()}>
          <LeftChevron />
        </IconButton>
      )}
      <Typography
        variant="h5"
        style={{
          color: palette.gray500,
          fontWeight: 700,
          marginLeft: isMobile ? 0 : '0.6rem',
        }}>
        {oldPath} /
      </Typography>
      <Typography variant="h5" style={{marginLeft: '0.5rem'}}>
        {currentPath}
      </Typography>
    </Box>
  );
};

export default Header;

export const PUBLIC_ROUTES = {
  ROOT: '/',
  BOOK: '/books/:bookId',
  BOOKS: '/books',
  PAGE: '/books/:bookId/pages/:page',
  STARTBOOK: '/books/:bookId/start',
  CATEGORIES: '/categories',
  CATEGORY: '/categories/:categoryId',
  READINGLIST: '/reading-lists/:readingListId',
  FINISHBOOK: '/books/:bookId/finish',
  CUSTOMER_PORTAL: '/customerportal',
  EMAIL_LOGIN: '/email-login',
  PHONE_LOGIN: '/phone-login',
  HEALTH: '/health',
  MANAGE: '/manage',
  ONBOARDING: '/onboarding',
  REQUEST_PASSWORD_RESET: '/request-password-reset',
  RESET_PASSWORD: '/reset-password',
  WELCOME: '/welcome',
  NOT_FOUND: '/404',
};
interface Routes {
  path: string;

  /**
   * Determines how to go back to the previous screen on mobile.
   */
  reversePath?: string;
}

export const EDIT_PROFILE_BASE_PATH = '/account/profiles';
export const PRIVATE_ROUTES: Record<string, Routes> = {
  ACCOUNT: {
    path: '/account',
  },
  ACCOUNT_SUB: {
    path: '/account/subscriptions',
    reversePath: '.',
  },
  ACCOUNT_DETAILS: {
    path: '/account/details',
    reversePath: '.',
  },
  ACCOUNT_PROFILES: {
    path: '/account/profiles',
    reversePath: '.',
  },
  BLOCK_RESUBSCRIPTION: {
    path: '/account/block-resubscription',
    reversePath: '.',
  },
  EDIT_PROFILE: {
    path: `${EDIT_PROFILE_BASE_PATH}/:profileId`,
    reversePath: '.',
  },
  REMOVE_PROFILE: {
    path: `${EDIT_PROFILE_BASE_PATH}/:profileId/remove`,
    reversePath: '.',
  },
  EDIT_PROFILE_CHARACTER: {
    path: `${EDIT_PROFILE_BASE_PATH}/:profileId/profileCharacter`,
    reversePath: '.',
  },
  ADD_PROFILE: {
    path: `${EDIT_PROFILE_BASE_PATH}/add-profile`,
    reversePath: '.',
  },
  MAX_PROFILE: {
    path: `${EDIT_PROFILE_BASE_PATH}/max-profiles`,
    reversePath: '.',
  },
  ADJUST_READING_LEVEL: {
    path: `${EDIT_PROFILE_BASE_PATH}/:profileId/readingLevel`,
    reversePath: '.',
  },
  SELF_ADJUST_READING_LEVEL: {
    path: `${EDIT_PROFILE_BASE_PATH}/:profileId/adjustReadingLevel`,
    reversePath: '.',
  },
  ACCOUNT_BOOKS: {
    path: '/account/books/:tab',
    reversePath: '..',
  },
  ACCOUNT_BOOK_BROWSE: {
    path: '/account/books/:tab/:subscriptionPeriodId',
    reversePath: '.',
  },
  ACCOUNT_BOOKS_CURRENT: {
    path: '/account/books/current',
    reversePath: '..',
  },
  ACCOUNT_READER: {
    path: '/account/reader',
    reversePath: '.',
  },
  ACCOUNT_READER_PROFILE: {
    path: '/account/reader/:profileId',
    reversePath: '..',
  },
  ACCOUNT_READER_PROFILE_BOOK: {
    path: '/account/reader/:profileId/books/:bookId',
    reversePath: '../..',
  },
  ADD_CHILD: {
    path: '/add-child',
  },
  REMOVE_CHILD: {
    path: '/remove-child',
  },
  ACCOUNT_MANAGER_PIN: {
    path: '/account-manager/pin',
  },
};

import {Box} from '@mui/material';
import React from 'react';
import ImageSection from '../Sections/ImageSection';
import BodySection from '../Sections/BodySection';
import ActionsSection from '../Sections/ActionsSection';
import {ReactComponent as ExtendTrial} from 'shared/assets/Extend-Trial.svg';
import ContainerWrapper from '../Sections/ContainerWrapper';

interface Props {
  onAccept: () => void;
  onCancel: () => void;
  onAcceptLoading: boolean;
}

const OfferScreen: React.FC<Props> = ({
  onAccept,
  onCancel,
  onAcceptLoading,
}) => {
  return (
    <ContainerWrapper>
      <Box>
        <Box mb="40px">
          <ImageSection>
            <ExtendTrial />
          </ImageSection>
        </Box>
        <BodySection
          title="Get a longer trial, on us!"
          description="We’d love for you to get a chance to try Ello a bit more before deciding to leave us. We're giving you 14 more days to try Ello"
        />
      </Box>
      <ActionsSection
        acceptLabel="Yes! Extend my trial"
        cancelLabel="Continue Cancellation"
        onAccept={onAccept}
        onCancel={onCancel}
        onAcceptLoading={onAcceptLoading}
      />
    </ContainerWrapper>
  );
};

export default OfferScreen;

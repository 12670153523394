import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import {Book} from './Book.type';
import BookCard from './BookCard';

type Props = {
  books: Book[];
  handleClick: (bookId?: string) => () => void;
};

const Books: FC<Props> = ({books, handleClick}: Props) => {
  return (
    <Box>
      <Grid container spacing={1}>
        {books &&
          books?.map(book => (
            <Grid key={book?.id} item xs={6} sm={6} md={4} lg={3}>
              <BookCard book={book} onClick={handleClick(book.id)} />
            </Grid>
          ))}
      </Grid>
    </Box>
  );
};

export default Books;

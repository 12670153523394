/*
 * A graphical slider form component.
 */

import React from 'react';
import {useQueryParam, NumberParam} from 'use-query-params';
import Slider from '@mui/material/Slider';
import useStyles from './FormSlider.styles';

function FormSlider({
  field,
  min,
  max,
  marks,
  defaultValue,
  onValidationChanged,
}) {
  const classes = useStyles();

  const [sliderValue, setSliderValue] = useQueryParam(field, NumberParam);

  let sliderDefault =
    sliderValue || defaultValue || Math.floor((min + max) / 2);

  return (
    <Slider
      className={classes.slider}
      valueLabelDisplay="auto"
      max={max}
      min={min}
      marks={marks || true}
      defaultValue={sliderDefault}
      onChangeCommitted={(event, value) => {
        setSliderValue(value);
        onValidationChanged(true);
      }}
    />
  );
}

export default FormSlider;

export enum OnboardingFlowType {
  payment = 'payment',
  validate = 'validate',
  submit = 'submit',
  checkmarks = 'checkmarks',
  landingPage = 'landingPage',
  inAppSubmit = 'inAppSubmit',
  survey = 'survey',
  goto = 'goto',
  body = 'body',
  text = 'text',
  number = 'number',
  location = 'location',
  select = 'select',
  package = 'package',
  trialPlans = 'trialPlans',
  language = 'language',
  name = 'name',
  intlPhone = 'intlPhone',
  phone = 'phone',
  email = 'email',
  emailSubmit = 'emailSubmit',
  date = 'date',
  multiple = 'multiple',
  slider = 'slider',
  dropdown = 'dropdown',
  exampleBooks = 'example_books',
  downloadPrompt = 'downloadPrompt',
  shareableLink = 'shareableLink',
  referralLink = 'referralLink',
  selectUpdated = 'selectUpdated',
  copyReferralLink = 'copyReferralLink',
  downloadInstructions = 'downloadInstructions',
}

export const unvalidatedOnboardingFlowTypes = [
  OnboardingFlowType.payment,
  OnboardingFlowType.validate,
  OnboardingFlowType.submit,
  OnboardingFlowType.checkmarks,
  OnboardingFlowType.landingPage,
  OnboardingFlowType.inAppSubmit,
  OnboardingFlowType.survey,
  OnboardingFlowType.goto,
  OnboardingFlowType.shareableLink,
  OnboardingFlowType.copyReferralLink,
  OnboardingFlowType.downloadInstructions,
];

export enum OnboardingFlowField {
  shareElloEmail = 'share_ello_email',
}

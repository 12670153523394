import React, {useState} from 'react';
import {Grid} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {FlowLayout} from 'customerPortal/components/Flow';
import FormSelect from 'customerPortal/components/Form/FormSelect/FormSelect';
import {ReactComponent as IconInfo} from 'shared/assets/icons/iconInfo.svg';
import Button, {ButtonType} from 'shared/components/common/Button';
import {Profile} from 'shared/types/profile';
import {ReadingLevel} from 'shared/types/readingLevels';
import {AddChildFlow, Plan} from '../types';
import styles from './ConfirmPlan.styles';
import LightTooltip from 'customerPortal/components/Tooltip/Tooltip';
import {
  getSelectInitialValue,
  isMatchingAnyExistingLevels,
} from '../AddChildScreen.utils';
import {
  CONTENT_SHARE_BOOKS,
  CONTENT_RECOMMENDATION,
} from './ConfirmPlan.constants';
import {FlowState} from 'customerPortal/components/Flow/types';

type ReadingLevelOptions = {label: string; value: string}[];
const getReadingLevelOptions = (
  profiles: Profile[],
  recommendedLevel: ReadingLevel | null,
): ReadingLevelOptions => {
  const availableReadingLevels = profiles
    .map(p => p.readingLevel)
    .filter(Boolean);

  const options: ReadingLevelOptions = [];
  new Set(availableReadingLevels).forEach(readingLevel => {
    const isRecommendedLevel = readingLevel === recommendedLevel;
    options.push({
      label: `Level ${readingLevel} ${
        isRecommendedLevel ? '(Recommended)' : ''
      }`,
      value: readingLevel!,
    });
  });

  return options;
};

type Props = Omit<AddChildFlow, 'flowState' | 'setFormValues'> &
  WithStyles<typeof styles>;
const ConfirmPlan = ({
  classes,
  formValues,
  profiles,
  recommendation,
  setFlowState,
  setRecommendationState,
}: Props) => {
  const [selectedOption, setSelectedOption] = useState<ReadingLevel | null>(
    recommendation.selectedReadingLevel ?? formValues.readingLevel,
  );
  const [showDropdownError, setShowDropdownError] = useState<boolean>(false);

  const {plan, readingLevel} = formValues;
  const {recommendedPlan} = recommendation;
  const hasAnyMatchingLevel = isMatchingAnyExistingLevels(
    profiles,
    readingLevel!,
  );
  const content =
    recommendedPlan === null
      ? CONTENT_SHARE_BOOKS
      : CONTENT_RECOMMENDATION[plan!];

  const onReadingLevelSelect = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value as ReadingLevel;
    setSelectedOption(value);
  };

  const options = getReadingLevelOptions(profiles, formValues.readingLevel);
  const selectInitialValue = getSelectInitialValue(selectedOption, options);
  const shouldShowDropdown =
    (plan === Plan.Bundle && hasAnyMatchingLevel) ||
    (plan === Plan.Profile && !hasAnyMatchingLevel);

  const onShareBundleClick = () => {
    if (!recommendedPlan) {
      setFlowState(FlowState.SubscriptionPreview);
      return;
    }

    setShowDropdownError(false);

    const matchesExistingLevels =
      selectedOption && isMatchingAnyExistingLevels(profiles, selectedOption);
    if (!selectedOption || !matchesExistingLevels) {
      setShowDropdownError(true);
      return;
    }

    setRecommendationState(prev => ({
      ...prev,
      selectedReadingLevel: selectedOption,
      selectedRecommendation: Plan.Profile,
    }));
    setFlowState(FlowState.SubscriptionPreview);
  };

  const onAddBundleClick = () => {
    setRecommendationState(prev => ({
      ...prev,
      selectedReadingLevel: selectedOption,
      selectedRecommendation: Plan.Bundle,
    }));
    setFlowState(FlowState.AdjustReadingLevel);
  };

  return (
    <FlowLayout
      title={content.title}
      contentClass={classes.content}
      ctaContainerSize={false}
      sideContent={<img src={content.image} alt="" width={'100%'} />}
      cta={
        <Grid container spacing={3} justifyContent="space-between">
          <Grid item xs={12} sm={12} md={6}>
            <Button
              className={classes.button}
              onClick={() => {
                hasAnyMatchingLevel ? onShareBundleClick() : onAddBundleClick();
              }}
              data-testid="main-cta">
              {content.mainCta}
            </Button>
          </Grid>
          {content.secondaryCta && (
            <Grid item xs={12} sm={12} md={6}>
              <Button
                className={classes.button}
                variant={ButtonType.Secondary}
                onClick={
                  hasAnyMatchingLevel ? onAddBundleClick : onShareBundleClick
                }
                data-testid="secondary-cta">
                {content.secondaryCta}
              </Button>
            </Grid>
          )}
        </Grid>
      }>
      <>
        {content.description({
          formValues,
          profiles,
        })}
        {shouldShowDropdown && (
          <div className={classes.selectContainer}>
            <FormSelect
              label={'Child reading level'}
              id={'child-reading-level-select'}
              value={selectInitialValue}
              errorMessage={
                showDropdownError
                  ? `Please select a bundle level that ${formValues.name} shares with your other reader(s)`
                  : ''
              }
              items={options}
              onChange={onReadingLevelSelect}
              containerClass={classes.dropdown}
            />

            <LightTooltip
              PopperProps={{
                disablePortal: true,
              }}
              TransitionProps={{
                timeout: 0,
              }}
              title={`These are the current bundle levels that are included in your current Ello subscription`}
              placement="top-start"
              enterTouchDelay={0}>
              <div className={classes.tooltip}>
                <IconInfo />
              </div>
            </LightTooltip>
          </div>
        )}
      </>
    </FlowLayout>
  );
};

export default withStyles(styles)(ConfirmPlan);

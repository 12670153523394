export const CustomerProductType = {
  digitalSubscription: 'digital-subscription',
  digitalPlusSubscription: 'digital-plus-subscription',
  noReturnsSubscription: 'no-returns-subscription',
  partialSubscription: 'partial-subscription',
  physicalSubscription: 'physical-subscription',
};

export function isReturningSubscription(
  customerProductType: string | undefined,
) {
  if (!customerProductType) {
    return true;
  }

  return CustomerProductType.physicalSubscription === customerProductType;
}

const digitalSubscriptionTypes = [
  CustomerProductType.digitalSubscription,
  CustomerProductType.digitalPlusSubscription,
];
export function supportsDigital(customerProductType: string | undefined) {
  if (!customerProductType) {
    return false;
  }

  return digitalSubscriptionTypes.includes(customerProductType);
}

export function isDigitalSubscription(customerProductType: string | undefined) {
  if (!customerProductType) {
    return false;
  }

  return CustomerProductType.digitalSubscription === customerProductType;
}

export function isDigitalPlusSubscription(
  customerProductType: string | undefined,
) {
  if (!customerProductType) {
    return false;
  }

  return CustomerProductType.digitalPlusSubscription === customerProductType;
}

export function isUpgradableCustomer(productType: string | undefined) {
  return (
    !productType || productType !== CustomerProductType.digitalSubscription
  );
}

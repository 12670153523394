import {Box} from '@mui/material';
import React from 'react';
import ImageSection from '../Sections/ImageSection';
import BodySection from '../Sections/BodySection';
import ActionsSection from '../Sections/ActionsSection';
import {ReactComponent as AdjustLevel} from 'shared/assets/Adjust-Level.svg';
import ContainerWrapper from '../Sections/ContainerWrapper';
import {Link} from 'react-router-dom';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';

export interface LevelAdjustmentProps {
  onConfirm: () => void;
}

const LevelAdjustment: React.FC<LevelAdjustmentProps> = ({onConfirm}) => {
  return (
    <ContainerWrapper>
      <Box>
        <Box mb="40px">
          <ImageSection>
            <AdjustLevel />
          </ImageSection>
        </Box>
        <BodySection
          title="Before we say goodbye to our reading adventures, have you tried adjusting the reading level for your child?"
          description="Children are often reluctant readers if the level is too hard, or bored if the level is too easy."
          sxTitle={{fontSize: '22px', letterSpacing: '-0.176px'}}
          sxDescription={{fontSize: '16px', letterSpacing: '0.16px'}}
        />
      </Box>
      <ActionsSection
        acceptLabel="Go to Level adjustment"
        component={Link}
        to={PRIVATE_ROUTES.ACCOUNT_PROFILES.path}
        cancelLabel="Continue Cancellation"
        onCancel={onConfirm}
      />
    </ContainerWrapper>
  );
};

export default LevelAdjustment;

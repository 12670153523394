import React from 'react';
import Error, {ErrorMessage} from 'shared/components/common/Error';

interface Props {
  children: React.ReactNode;
  // hack; for some reason the type definitions for react-router
  // are not recognized. Ideally we should be using RouteComponentProps
  location?: {
    pathname: string;
  };
}

interface State {
  hasError: boolean;
}

export default class ErrorBoundary extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {hasError: false};
  }

  componentDidUpdate(prevProps: Props) {
    if (prevProps.location?.pathname !== this.props.location?.pathname) {
      this.setState({hasError: false});
    }
  }

  static getDerivedStateFromError(): State {
    return {hasError: true};
  }

  componentDidCatch(error: any) {
    console.error('Uncaught error::', error);
  }

  render(): React.ReactNode {
    const {hasError} = this.state;
    const {children} = this.props;

    if (hasError) {
      return <Error error={ErrorMessage.ServerError} showBanner={false} />;
    }

    return children;
  }
}

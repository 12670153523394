import React from 'react';
import Box from '@mui/material/Box';
import useStyles from './ReferralLink.styles';
import {ReactComponent as ShareImage} from '../../assets/share-icons.svg';
import * as palette from '../../styles/palette';
import Header from './ReferralLink.link-container.header';
import BodyText from './ReferralLink.link-container.body';
import CopyComponent from '../common/CopyComponent/CopyComponent';
import AnalyticsService, {
  ShareMethod,
} from '../../../analytics/Analytics.service';
import {ReferralLinkService} from './ReferralLink.service';
import {shareBodyMessage} from '../../../customerPortal/components/ReferralLink/ReferralLink.service';

type Props = {
  referralLink: string;
  header?: string;
  body?: string;
};

const LinkContainer = ({header, body, referralLink}: Props) => {
  const classes = useStyles();
  const {trackReferral} = ReferralLinkService.useTrackReferralLinkClick();

  const referralCode = referralLink.split('/').pop() || '';

  const onCopyClick = () => {
    AnalyticsService.trackOnboardingFlowLinkClicked(
      ShareMethod.CopyToClipboard,
    );
    trackReferral(referralCode);
  };

  const onShare = () => {
    AnalyticsService.trackOnboardingFlowLinkClicked(ShareMethod.NativeShare);
    trackReferral(referralCode);
  };

  return (
    <Box
      className={classes.linkContainer}
      sx={{
        backgroundColor: palette.turquoise400,
      }}>
      <Box className={classes.iconContainer}>
        <ShareImage />
      </Box>

      <Box className={classes.textContainer}>
        <Header text={header} />

        <BodyText text={body} />
        <CopyComponent
          link={referralLink}
          copySheetStyle={{width: '100%'}}
          shareButtonText="Share your link"
          dialogueText={shareBodyMessage}
          buttonType="secondary"
          onCopyClick={onCopyClick}
          onShare={onShare}
        />
      </Box>
    </Box>
  );
};

export default LinkContainer;

export const gradeMap = {
  all: 'All Ages (4-8)',
  kindergarten: 'Kindergarten',
  firstGrade: '1st Grade',
  secondGrade: '2nd Grade',
  thirdGrade: '3rd Grade',
};

export type BookFilter = {
  excludeFullText?: boolean;
  grade?: string;
};

export type Book = {
  id?: string;
  author?: string;
  coverPhotoURL?: string;
  title?: string;
  pages?: {
    pagePhotoURL?: string;
    content?: string;
  }[];
};

export type CategoryFilter = {
  includeReadingLists?: boolean;
  names?: string[];
};

export type Category = {
  id?: string;
  name: string;
  parentCategory?: string;
  subcategories?: Category[];
  readingLists?: ReadingList[];
  archivedAt?: Date;
  createdAt?: Date;
  updatedAt?: Date;
};

export enum CategoryName {
  gradeLevel = 'Grade Level',
  readingLevel = 'Reading Level (By Skill Set)',
  popularBookSets = 'Popular Book Sets',
  kindergarten = 'Kindergarten',
  firstGrade = '1st Grade',
  secondGrade = '2nd Grade',
  thirdGrade = '3rd Grade',
}

export function isGradeLevelCategory(name: CategoryName): boolean {
  return name === CategoryName.gradeLevel;
}

export type Pagination = {
  first: number;
  cursor?: string;
};

export type Creator = {
  email?: string;
  name?: string;
  type: string;
};

export type ReadingList = {
  id?: string;
  name: string;
  categories?: Category[];
  ancestors?: Category[];
  creator?: Creator;
  books?: Book[];
  archivedAt?: Date;
  createdAt: Date;
  updatedAt: Date;
};

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => {
  return createStyles({
    tabPanel: {
      padding: '32px 0',
    },
  });
});

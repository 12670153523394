import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      flexDirection: 'column',
      height: '100%',
      padding: '0 24px',
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
    },
  });

export default useStyles;

import * as palette from 'shared/styles/palette';

export const stripeStyle = {
  base: {
    fontSize: '16px',
    color: palette.black,
    fontFamily: 'Muli, monospace',
    '::placeholder': {
      color: 'rgba(0, 0, 0, 0.54)',
    },
    fontWeight: '500',
  },
  invalid: {
    color: '#9e2146',
  },
};

const styles = () => ({
  fieldContainer: {
    margin: '0 auto',
    border: `1px solid ${palette.gray300}`,
    borderRadius: '8px',
    padding: '15px',
    maxHeight: '53px',
    marginTop: '10px',
  },
  paymentLogos: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  cardDetails: {
    fontSize: '17px',
    fontWeight: 400,
  },
});

export default styles;

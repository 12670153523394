import React, {useState} from 'react';
import {CircularProgress, Fade} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import AddChildForm from './AddChildForm/AddChildForm';
import AdjustReadingLevel from './AdjustReadingLevel/AdjustReadingLevel';
import PaymentSummary from './PaymentSummary/PaymentSummary';
import SubscriptionPreview from './SubscriptionPreview/SubscriptionPreview';
import AddChildSuccess from './AddChildSuccess/AddChildSuccess';
import {Form, Plan, Recommendation} from './types';
import styles from './AddChildScreen.styles';
import {useHistory} from 'react-router-dom';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import SelectPlan from './SelectPlan/SelectPlan';
import ConfirmPlan from './ConfirmPlan/ConfirmPlan';
import {useQuery} from '@apollo/client';
import {Profile} from 'shared/types/profile';
import Alert from 'shared/components/common/Alert/Alert';
import {QUERY_PROFILES} from './AddChildScreen.query';
import CustomPlanError from './CustomPlanError/CustomPlanError';
import {FlowContainer} from 'customerPortal/components/Flow';
import {FlowState} from 'customerPortal/components/Flow/types';
import {CustomerSubscription} from 'shared/types/customer';

const formInitialState: Form = {
  name: '',
  readingAbility: null,
  grade: null,
  plan: null,
  readingLevel: null,
};

const recommendation: Recommendation = {
  recommendedPlan: null,
  selectedRecommendation: null,
  selectedReadingLevel: null,
};

interface QueryResult {
  profiles: Profile[];
  subscription: Pick<CustomerSubscription, 'nextCycleDate' | 'name'>;
}

const AddChildScreen = ({classes}: WithStyles<typeof styles>) => {
  const history = useHistory();
  const [formValues, setFormValues] = useState<Form>(formInitialState);
  const [flowState, setFlowState] = useState<FlowState>(FlowState.SelectPlan);
  const [recommendationState, setRecommendationState] =
    useState<Recommendation>(recommendation);

  const {
    loading,
    data: profilesData,
    error: profilesError,
  } = useQuery<QueryResult>(QUERY_PROFILES, {
    onError: error => console.error('Add Child::', error),
  });

  const onGoBack = (): void => {
    switch (flowState) {
      case FlowState.AdjustReadingLevel:
        if (recommendationState.selectedRecommendation) {
          setFlowState(FlowState.ConfirmPlan);
          break;
        }

        setFlowState(FlowState.AddChild);
        break;

      case FlowState.SubscriptionPreview:
        const {selectedRecommendation} = recommendationState;
        const {plan} = formValues;
        if (!selectedRecommendation && plan === Plan.Profile) {
          setFlowState(FlowState.ConfirmPlan);
          break;
        }
        if (!selectedRecommendation && plan === Plan.Bundle) {
          setFlowState(FlowState.AdjustReadingLevel);
          break;
        }
        if (selectedRecommendation === Plan.Bundle) {
          setFlowState(FlowState.AdjustReadingLevel);
          break;
        }
        setFlowState(FlowState.ConfirmPlan);
        break;
      case FlowState.PaymentSummary:
        setFlowState(FlowState.SubscriptionPreview);
        break;
      case FlowState.AddChild:
        setFlowState(FlowState.SelectPlan);
        break;
      case FlowState.ConfirmPlan:
        setFlowState(FlowState.AddChild);
        break;
      case FlowState.Success:
      case FlowState.SelectPlan:
        history.push(PRIVATE_ROUTES.ACCOUNT_SUB.path);
        break;
    }
  };

  if (profilesError) {
    return (
      <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
        <Alert
          error={
            'Unable to initialize form. Please text us at (415) 214-8119 for help.'
          }
          autoHide={false}
        />
      </FlowContainer>
    );
  }

  if (loading) {
    return (
      <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
        <div className={classes.progress}>
          <CircularProgress />
        </div>
      </FlowContainer>
    );
  }

  if (profilesData?.subscription.name === 'ello-default') {
    return (
      <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
        <CustomPlanError />
      </FlowContainer>
    );
  }

  if (profilesData?.profiles.length === 3) {
    return (
      <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
        <CustomPlanError
          message={
            'Adding more than 3 children under one subscription requires a custom plan.'
          }
        />
      </FlowContainer>
    );
  }

  // there should at least be 1 existing reader
  // because the ConfirmPlan step requires comparison against
  // existing readers when the user initially chooses
  // the Profile plan.
  if (profilesData?.profiles && profilesData.profiles.length > 0) {
    return (
      <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
        <Fade
          in={true}
          key={flowState}
          onEnter={() => window.scrollTo(0, 0)}
          timeout={500}
          addEndListener={(node, done) => {
            node.addEventListener('transitionend', done, false);
          }}>
          <main className={classes.content}>
            {flowState === FlowState.SelectPlan && (
              <SelectPlan
                formValues={formValues}
                setFormValues={setFormValues}
                setFlowState={setFlowState}
              />
            )}
            {flowState === FlowState.AddChild && (
              <AddChildForm
                formValues={formValues}
                profiles={profilesData.profiles}
                setFlowState={setFlowState}
                setFormValues={setFormValues}
                setRecommendationState={setRecommendationState}
              />
            )}
            {flowState === FlowState.ConfirmPlan && (
              <ConfirmPlan
                formValues={formValues}
                profiles={profilesData.profiles}
                recommendation={recommendationState}
                setFlowState={setFlowState}
                setRecommendationState={setRecommendationState}
              />
            )}
            {flowState === FlowState.AdjustReadingLevel && (
              <AdjustReadingLevel
                setFlowState={setFlowState}
                setRecommendationState={setRecommendationState}
                formValues={formValues}
                recommendation={recommendationState}
                profiles={profilesData.profiles}
              />
            )}
            {flowState === FlowState.SubscriptionPreview && (
              <SubscriptionPreview
                formValues={formValues}
                recommendation={recommendationState}
                setFlowState={setFlowState}
              />
            )}
            {flowState === FlowState.PaymentSummary && (
              <PaymentSummary
                formValues={formValues}
                recommendation={recommendationState}
                setFlowState={setFlowState}
              />
            )}
            {flowState === FlowState.Success && (
              <AddChildSuccess
                nextCycleDate={profilesData?.subscription.nextCycleDate}
                childName={formValues.name}
              />
            )}
          </main>
        </Fade>
      </FlowContainer>
    );
  } else {
    return (
      <FlowContainer onGoBack={onGoBack} currentStep={flowState}>
        <Alert
          error={
            'No existing profiles found. Please text us at (415) 214-8119 for help.'
          }
          autoHide={false}
        />
      </FlowContainer>
    );
  }
};

export default withStyles(styles)(AddChildScreen);

import React from 'react';
import Box from '@mui/material/Box';

import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';

import Modal from '@mui/material/Modal';
import styles from './EditProfileScreen.styles';
import RemoveProfileScreen from './EditProfileScreen.remove-profile.screen';

interface props extends WithStyles<typeof styles> {
  open: boolean;
  onCloseModal: () => void;
}

const RemoveProfileModal = ({classes, open, onCloseModal}: props) => {
  return (
    <Modal open={open} onClose={onCloseModal}>
      <Box className={classes.removeProfileModalBox}>
        <RemoveProfileScreen onCloseModal={onCloseModal} />
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(RemoveProfileModal);

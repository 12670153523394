import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import styles from './AddProfileScreen.styles';
import useMobileLayout from '../../../../utils/useMobileLayout';

const Header = ({classes}: WithStyles<typeof styles>) => {
  const isMobileView = useMobileLayout();

  return (
    <Box>
      <Typography className={classes.titleHeader}>
        {isMobileView ? "Your child's details" : "Let's add a new reader"}
      </Typography>
      <Typography className={classes.subtitleHeader}>
        Tell us a bit about your child so we can suggest the right reading
        level.
      </Typography>
    </Box>
  );
};

export default withStyles(styles)(Header);

import React from 'react';
import {Stack} from '@mui/material';
import Button from './Button';
import SecondaryButton from './SecondaryButton';

type Props = {
  acceptLabel: string;
  cancelLabel?: string;
  onAccept?: () => void;
  onCancel?: () => void;
  onAcceptLoading?: boolean;
  to?: string;
  component?: React.ElementType;
  rel?: string;
  href?: string;
  target?: string;
};

const ActionsSection: React.FC<Props> = ({
  acceptLabel,
  cancelLabel,
  onAccept,
  onCancel,
  onAcceptLoading,
  to,
  component,
  ...props
}) => {
  const isRouterLink = !!(to && component);
  const isExternalLink = props?.href;
  const nonLink = !isRouterLink && !isExternalLink;

  return (
    <Stack alignItems="center" gap="20px">
      {isExternalLink && (
        <Button target="_blank" rel="noreferrer" {...props}>
          {acceptLabel}
        </Button>
      )}
      {isRouterLink && (
        <Button component={component} to={to}>
          {acceptLabel}
        </Button>
      )}
      {nonLink && (
        <Button loading={onAcceptLoading} onClick={onAccept}>
          {acceptLabel}
        </Button>
      )}
      {cancelLabel && onCancel && (
        <SecondaryButton onClick={onCancel}>{cancelLabel}</SecondaryButton>
      )}
    </Stack>
  );
};

export default ActionsSection;

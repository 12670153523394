import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {turquoise900} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'flex-start',
      flex: `0 1 400px`,
    },
    form: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      '& > *': {
        marginTop: theme.spacing(4),
      },
    },
    tooltip: {
      margin: `${theme.spacing(1)} 0`,
    },
    tooltipTrigger: {
      fontSize: '0.875rem',
      color: turquoise900,
      padding: `0 ${theme.spacing(2)}`,
      textAlign: 'left',
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    button: {
      width: '100%',
    },
  });

export default styles;

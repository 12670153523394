import React from 'react';
import cN from 'classnames';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from 'shared/components/common/Button/Button.styles';
import {ButtonType} from 'shared/components/common/Button';
import {Link} from '@mui/material';

interface Props extends WithStyles<typeof styles> {
  className?: string;
  children: React.ReactNode | string;
  variant?: ButtonType;
  href?: string;
}

const ExternalLink = ({
  classes,
  variant = ButtonType.Primary,
  className,
  href,
  ...rest
}: Props) => (
  <Link
    underline="none"
    target="_blank"
    rel="noopener"
    className={cN(classes.base, classes[variant], className)}
    href={href}
    {...rest}
  />
);

export default withStyles(styles)(ExternalLink);

import firebase from 'firebase/app';

// Set up global firebase instance
if (!firebase.apps.length) {
  const firebaseConfig =
    process.env.NODE_ENV === 'production' &&
    !(
      window.location.href.includes('staging') ||
      window.location.href.includes('localhost') ||
      window.location.href.includes('rav') ||
      window.location.href.includes('develop-portal')
    )
      ? {
          apiKey: 'AIzaSyCjIfaH6XlAu6OCqfVM3Vn03kaimDDLKI0',
          authDomain: 'trustle-3bb01.firebaseapp.com',
          databaseURL: 'https://trustle-3bb01.firebaseio.com',
          projectId: 'trustle-3bb01',
          storageBucket: 'trustle-3bb01.appspot.com',
          messagingSenderId: '88590385676',
          appId: '1:88590385676:web:d48015886e95900f0b6429',
          measurementId: 'G-TEG2H29ZH2',
        }
      : window.location.href.includes('staging') ||
        window.location.href.includes('rav') ||
        process.env.REACT_APP_VARIANT === 'staging' // Local dev with staging servers
      ? {
          apiKey: 'AIzaSyD7eFU_OjR04KKdxQfcAxwtFD3xZ4q7Gco',
          authDomain: 'trustle-babydesk.firebaseapp.com',
          databaseURL: 'https://trustle-babydesk.firebaseio.com',
          projectId: 'trustle-babydesk',
          storageBucket: 'trustle-babydesk.appspot.com',
          messagingSenderId: '216588930153',
          appId: '1:216588930153:web:6485c3bbfe99c42d4966f8',
          measurementId: 'G-F5K7X74YGZ',
        }
      : {
          apiKey: 'AIzaSyDVwkMgbgw7JVafbMLDi1Wxn3ScyLbflhc',
          authDomain: 'coachdesk-test.firebaseapp.com',
          databaseURL: 'https://coachdesk-test.firebaseio.com',
          projectId: 'coachdesk-test',
          storageBucket: 'coachdesk-test.appspot.com',
          messagingSenderId: '1076116207154',
          appId: '1:1076116207154:web:bd9f8d3ce84fbac09dae2b',
        };
  firebase.initializeApp(firebaseConfig);
  firebase.auth().setPersistence(firebase.auth.Auth.Persistence.LOCAL);
}

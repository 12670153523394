import React from 'react';
import {Typography, Grid, Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Button from 'shared/components/common/Button';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import styles from './DeleteAccountConfirmed.styles';

interface Props extends WithStyles<typeof styles> {
  onConfirm: () => void;
}

const DeleteAccountConfirmed = ({classes, onConfirm}: Props) => {
  const isMobile = useMobileLayout();

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          <Typography variant={'h6'} align={'center'} className={classes.title}>
            Your account deletion is in progress and can take up to 48 hours to
            complete
          </Typography>
        </Box>
        <Box>
          <Grid container className={classes.buttonContainer}>
            {isMobile ? (
              <>
                <Button className={classes.button} onClick={onConfirm}>
                  OK
                </Button>
              </>
            ) : (
              <>
                <Grid container className={classes.leftContainer}></Grid>
                <Grid item>
                  <Button className={classes.button} onClick={onConfirm}>
                    OK
                  </Button>
                </Grid>
                <Grid container className={classes.rightContainer}></Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(DeleteAccountConfirmed);

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import React, {FC} from 'react';
import {Product} from '../Product.types';
import Choice from './Choice';

type props = {
  choices: Product[];
  selectedProduct: string;
  onSelectProduct: (priceId: string) => void;
};

const UpdatedChoices: FC<props> = ({
  choices,
  selectedProduct,
  onSelectProduct,
}) => {
  return (
    <Box>
      <Grid container spacing={2} alignItems="stretch">
        {choices.map((product, idx) => (
          <Grid key={idx} item xs={12} md={6}>
            <Choice
              product={product}
              selectedProduct={selectedProduct}
              onSelectProduct={onSelectProduct}
            />
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default UpdatedChoices;

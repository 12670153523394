import Box from '@mui/material/Box';
import React, {FC} from 'react';
import FilterGrade from './FilterGrade';

type Props = {
  grade?: string;
  options: {label: string; value: string}[];
  handleGradeChange: (grade: string) => void;
};

const FilterGrades: FC<Props> = ({
  grade,
  options,
  handleGradeChange,
}: Props) => {
  return (
    <Box
      sx={{
        display: 'flex',
        flexWrap: 'wrap',
        maxWidth: '732px',
        margin: '0 auto',
        mt: 8,
      }}>
      {options?.map((option, index) => (
        <FilterGrade
          key={index}
          option={option}
          grade={grade}
          handleGradeChange={handleGradeChange}
        />
      ))}
    </Box>
  );
};

export default FilterGrades;

type fontType = {
  fontSize: string;
  fontWeight: string | number;
};

export function determineFontSize(maxPageLength?: number): fontType {
  const base = {
    fontSize: '3.28125rem',
    fontWeight: 'bold',
  };

  if (!maxPageLength) {
    return base;
  }

  if (maxPageLength < 50) {
    return {fontSize: '2.625rem', fontWeight: 'bold'};
  }

  if (maxPageLength < 100) {
    return {fontSize: '1.96875rem', fontWeight: 400};
  }

  return {
    fontSize: '0.984375ren',
    fontWeight: 400,
  };
}

import {DocumentNode} from 'graphql';
import {CustomerProductType} from '../../utils/productType';
import {ONBOARD_PARTIAL_ACCOUNT_MUTATION} from './GenericSubmitPanel.query';
import {
  errorState,
  onboardPartialAccountAction,
} from './GenericSubmitPanel.types';

type PartialOnboardingInput = {
  email: string;
  phoneNumber: string;
  uid: string;
  content?: Record<string, any>;
  flowName?: string;
  isbns?: string;
  tuneTransactionId?: string;
};

type Client = {
  mutate: (input: {
    mutation: DocumentNode;
    variables: {input: PartialOnboardingInput};
  }) => Promise<{data: {onboardPartialAccount: {confirmationId: string}}}>;
};

type Params = {
  email: string;
  isbns: string;
  name: string;
  phone: string;
  uid: string;
  transaction_id: string;
};

type Props = {
  action: string;
  client: Client;
  flowName: string;
  onConfirm: OnConfirm;
};

type OnConfirm = (
  response: {confirmationId: string},
  params: {email: string; phone: string; productType: string},
) => void;

type SetState = (state: {
  error?: string;
  errorVisible?: boolean;
  loading?: boolean;
}) => void;

async function onboardPartialAccount(
  props: Props,
  params: Params,
  setState: SetState,
): Promise<boolean> {
  if (props.action !== onboardPartialAccountAction) {
    return false;
  }

  try {
    const input = {
      content: params,
      email: params.email,
      isbns: params.isbns,
      flowName: props.flowName,
      name: params.name,
      phoneNumber: params.phone,
      tuneTransactionId: params.transaction_id,
      uid: params.uid,
    };

    const onboardingResult = await props.client.mutate({
      mutation: ONBOARD_PARTIAL_ACCOUNT_MUTATION,
      variables: {input},
    });
    if (!onboardingResult) {
      setState(errorState);
      return false;
    }

    props.onConfirm(onboardingResult.data.onboardPartialAccount, {
      email: params.email,
      phone: params.phone,
      productType: CustomerProductType.partialSubscription,
    });

    setState({loading: false});
    return true;
  } catch (error) {
    setState(errorState);
    setState({loading: false});
    return false;
  }
}

export const GenericSubmitPanelService = {
  onboardPartialAccount,
};

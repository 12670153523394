import {Tooltip} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {Theme} from '@mui/material/styles';
import {gray, gray900} from 'shared/styles/palette';

const LightTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: gray900,
    boxShadow: theme.shadows[1],
    fontSize: '0.875rem',
  },
}))(Tooltip);

export default LightTooltip;

export const DarkTooltip = withStyles(() => ({
  tooltip: {
    backgroundColor: gray,
    color: gray900,
    width: 100,
    borderRadius: '16px',
    boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
  },
}))(Tooltip);

import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

import * as palette from 'shared/styles/palette';

type Props = {
  readingLevel?: string;
};

const ReadingLevelText = ({readingLevel}: Props) => {
  return (
    <Box>
      <Typography
        style={{color: palette.gray700, fontWeight: 500, fontSize: '14px'}}>
        Reading Level
      </Typography>

      <Typography
        style={{
          color: palette.gray900,
          fontWeight: 600,
          fontSize: '16px',
          marginTop: '0.5rem',
        }}>
        {readingLevel}
      </Typography>
    </Box>
  );
};

export default ReadingLevelText;

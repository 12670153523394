import Box from '@mui/material/Box';
import React, {FC} from 'react';
import ExternalNavigation from '../../components/ExternalNavigation/ExternalNavigation';
import ExternalStartBook from '../../components/WebReader/ExternalStartBook';

const ExternalStartBookScreen: FC = () => {
  return (
    <Box>
      <ExternalNavigation />
      <ExternalStartBook />
    </Box>
  );
};

export default ExternalStartBookScreen;

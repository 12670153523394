import React from 'react';
import cN from 'classnames';
import {Tab as MaterialTab, Tabs as MaterialTabs} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {Tab, TabOptions, TABS} from 'customerPortal/constants/bookTabs';
import styles from './Tabs.styles';

interface Props extends WithStyles<typeof styles> {
  activeTab: TabOptions;
  disabled?: boolean;
  containerClass?: string;
  onChange: (p: any, a: any) => void;
}

const Tabs = ({
  classes,
  activeTab,
  disabled = false,
  containerClass,
  onChange,
}: Props) => {
  return (
    <MaterialTabs
      className={cN(classes.tabContainer, containerClass)}
      TabIndicatorProps={{
        style: {display: 'none'},
      }}
      variant="fullWidth"
      value={activeTab}
      onChange={onChange}>
      {TABS.map((tabItem: Tab) => (
        <MaterialTab
          disabled={disabled}
          key={tabItem.value}
          className={cN(classes.tabItem, {
            [classes.selected]: activeTab === tabItem.value,
          })}
          component="a"
          onClick={(event: any) => {
            event.preventDefault();
          }}
          {...tabItem}
        />
      ))}
    </MaterialTabs>
  );
};

export default withStyles(styles)(Tabs);

import gql from 'graphql-tag';

export const QUERY_SUBSCRIPTION_PREVIEW = gql`
  query SubscriptionPreview($readingLevel: String!) {
    subscriptionPreview(readingLevel: $readingLevel) {
      title
      nextCycleDate
      bundles
      name
    }
  }
`;

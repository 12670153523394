import Box from '@mui/material/Box';
import React from 'react';
import AdjustLevelButton from '../AdjustReadingLevel/AdjustReadingLevel.button';
import SupportText from './EditProfileScreen.reading-level.support-text';
import ReadingLevelText from './EditProfileScreen.reading-level.level-text';

type props = {
  readingLevel?: string;
  profileId: string;
  requestedLevelAdjustmentAt?: Date | string;
};

const EditProfileReadingLevel = ({
  readingLevel,
  profileId,
  requestedLevelAdjustmentAt,
}: props) => {
  return (
    <Box
      sx={{
        marginTop: '2rem',
      }}>
      <Box
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}>
        <ReadingLevelText readingLevel={readingLevel} />

        <AdjustLevelButton
          profileId={profileId}
          requestedLevelAdjustmentAt={requestedLevelAdjustmentAt}
        />
      </Box>

      <SupportText />
    </Box>
  );
};

export default EditProfileReadingLevel;

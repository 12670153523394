import React, {useState} from 'react';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {BOOK_SAMPLES} from 'customerPortal/constants/bookSamples';
import {ReadingLevel} from 'shared/types/readingLevels';
import Button, {ButtonType} from 'shared/components/common/Button';
import Modal from 'customerPortal/components/Modal/Modal';
import {ReactComponent as IconEye} from 'shared/assets/icons/iconEye.svg';
import styles from './BooksSampleViewer.styles';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';

interface Props extends WithStyles<typeof styles> {
  level: ReadingLevel;
}

const BookSampleViewer = ({classes, level}: Props) => {
  const isMobile = useMobileLayout();
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const {image, text, title, author} = BOOK_SAMPLES[level];

  return (
    <>
      <figure className={classes.figure}>
        {!isMobile && <img src={image} alt="" className={classes.img} />}
        <Typography component="p" className={classes.passage}>
          {text}
        </Typography>
        <figcaption className={classes.caption}>
          From <b>{title}</b> by <b>{author}</b>
        </figcaption>
      </figure>

      {isMobile && (
        <>
          <Button
            variant={ButtonType.Inline}
            onClick={() => setIsModalOpen(true)}
            className={classes.previewBtn}>
            <IconEye className={classes.iconEye} /> Preview book page
          </Button>
          <Modal handleClose={() => setIsModalOpen(false)} isOpen={isModalOpen}>
            <img src={image} alt="" className={classes.img} />
          </Modal>{' '}
        </>
      )}
    </>
  );
};

export default withStyles(styles)(BookSampleViewer);

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    loginContainer: {
      padding: 0,
      height: '100vh',
      justifyContent: 'center',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
    },
    avatar: {
      margin: theme.spacing(1),
      backgroundColor: theme.palette.primary.dark,
    },
    form: {
      width: '100%', // Fix IE 11 issue.
      marginTop: theme.spacing(1),
      textAlign: 'center',
    },
    submitButton: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
    loadingIndicator: {
      marginTop: '40px',
      marginBottom: '40px',
      marginLeft: 'auto',
      marginRight: 'auto',
      display: 'block',
    },
    passwordRequirementsButton: {
      fontSize: 20,
      marginRight: 5,
      color: theme.palette.primary.main,
    },
  });

export default styles;

import React from 'react';
import qrCode from '../../assets/v1-qr-code.png';
import Box from '@mui/material/Box';

const QRCode = () => {
  return (
    <Box my="12px">
      <img src={qrCode} alt="QR Code" />
    </Box>
  );
};

export default QRCode;

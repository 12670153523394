const styles = () => ({
  expressCheckoutContainer: {
    marginTop: '10px',
    marginBottom: '10px',
  },
  paymentLogos: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
});

export default styles;

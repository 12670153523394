import React, {useEffect} from 'react';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';
import {ShippingOption} from './ShippingOption';
import {ShippingOption as ShippingOptionType} from './ShippingOption.type';
import {useQueryParam, StringParam} from 'use-query-params';
import * as palette from '../../../styles/palette';

type Props = {
  shippingOptions: ShippingOptionType[];
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

const ShippingOptionSelector: React.FC<Props> = ({
  shippingOptions,
  onChange,
}) => {
  const selectedOption =
    shippingOptions.find(option => option.selected) || shippingOptions[0];
  const [value, setValue] = React.useState(selectedOption.type);

  const [, setShippingOptionQuery] = useQueryParam(
    'shippingOption',
    StringParam,
  );

  useEffect(() => {
    setShippingOptionQuery(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onChangeOption = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
    onChange(event);
    setShippingOptionQuery(event.target.value);
  };

  return (
    <Box
      sx={{
        width: '100%',
        backgroundColor: palette.gray,
        padding: '12px 16px 16px 16px',
        borderRadius: '16px',
        margin: '8px 0px',
      }}>
      <FormControl sx={{width: '100%'}}>
        <FormLabel
          sx={{
            fontSize: '1rem',
            textAlign: 'left',
            fontWeight: 700,
            color: palette.gray900,
            '&.Mui-focused': {
              color: palette.gray900,
            },
          }}
          id="shipping-radio-buttons-group">
          Select Your Shipping Method
        </FormLabel>

        <RadioGroup
          aria-labelledby="shipping-radio-buttons-group"
          name="shipping-radio-buttons-group-name"
          value={value}
          onChange={onChangeOption}>
          {shippingOptions.map(option => (
            <ShippingOption key={option.type} option={option} />
          ))}
        </RadioGroup>
      </FormControl>
    </Box>
  );
};

export default ShippingOptionSelector;

import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  productBody: {
    fontSize: '12px',
    '& p': {
      lineHeight: '1.6',
      marginTop: '8px',
    },
    textAlign: 'center',
    paddingLeft: '24px',
    paddingRight: '24px',
    marginTop: '8px',
    marginLeft: 'auto',
    marginRight: 'auto',
    width: '80%',
  },
}));

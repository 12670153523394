import React, {useState} from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import {ReactComponent as MoreInfo} from 'shared/assets/icons/more-info.svg';
import useMobileLayout from '../../../utils/useMobileLayout';
import styles from './ProfilesScreen.styles';
import AdjustReadingLevelButton from './AdjustReadingLevel/AdjustReadingLevel.button';
import {supportEmail, supportPhoneNumber} from '../../../constants/constants';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    boxShadow: theme.shadows[1],
    padding: '14px',
  },
}))(Tooltip);

interface props extends WithStyles<typeof styles> {
  readingLevel?: string;
  profileId: string;
  requestedLevelAdjustmentAt?: Date | string;
}

const ProfileReadingLevel = ({
  classes,
  readingLevel,
  profileId,
  requestedLevelAdjustmentAt,
}: props) => {
  const [open, setOpen] = useState(false);
  const isMobile = useMobileLayout();

  return (
    <Box
      onClick={() => {
        setOpen(!open && isMobile);
      }}
      className={classes.readingLevelContainer}>
      <Box className={classes.readingLevelTextContainer}>
        <Typography className={classes.readingLevelText}>
          Reading Level {readingLevel}{' '}
        </Typography>
        <LightTooltip
          placement="bottom-end"
          arrow
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          title={
            <Typography variant="subtitle1" className={classes.tooltipText}>
              Your child reading ability was determined by answers you provided
              upon adding a child profile. <br />
              If you have any questions regarding your child's level you can
              reach us via email{' '}
              <Typography component="b" className={classes.tooltipSupportText}>
                {supportEmail}
              </Typography>{' '}
              or text{' '}
              <Typography component="b" className={classes.tooltipNumber}>
                {supportPhoneNumber}
              </Typography>
            </Typography>
          }>
          <MoreInfo className={classes.moreInfoIcon} />
        </LightTooltip>
      </Box>

      <AdjustReadingLevelButton
        profileId={profileId}
        requestedLevelAdjustmentAt={requestedLevelAdjustmentAt}
      />
    </Box>
  );
};

export default withStyles(styles)(ProfileReadingLevel);

import React from 'react';
import {Container, Grid, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './PaymentSummary.styles';
import {AddChildFlow} from '../types';
import Button from 'shared/components/common/Button';
import Table from 'customerPortal/components/Table/Table/Table';
import {FlowLayout} from 'customerPortal/components/Flow';
import {
  QUERY_BILLING_DETAILS,
  UPDATE_SUBSCRIPTION,
} from './PaymentSummary.query';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {StripeSubscription} from 'shared/types/stripeSubscription';
import Alert from 'shared/components/common/Alert/Alert';
import {CustomerSubscription} from 'shared/types/customer';
import {toUSDateFormat} from 'shared/utils/dateUtils';
import {FlowState} from 'customerPortal/components/Flow/types';
import {
  getCostBreakdown,
  getShippingInfo,
} from 'customerPortal/utils/costBreakdown';

type Props = Pick<
  AddChildFlow,
  'formValues' | 'recommendation' | 'setFlowState'
> &
  WithStyles<typeof styles>;

type SummaryQueryResult = {
  stripeSubscription: StripeSubscription;
  subscriptionPreview: CustomerSubscription;
};

const PaymentSummary = ({
  classes,
  formValues,
  recommendation,
  setFlowState,
}: Props) => {
  const readingLevel =
    recommendation.selectedReadingLevel ?? formValues.readingLevel;
  const [updateSubscription, {loading: isUpdatingSub, error: updateError}] =
    useMutation(UPDATE_SUBSCRIPTION, {
      variables: {
        input: {
          name: formValues.name,
          readingLevel,
        },
      },
      onCompleted: () => {
        setFlowState(FlowState.Success);
      },
      onError: error => console.error('Payment submit::', error),
    });

  const {
    loading: isCostDataLoading,
    data: costData,
    error: costDataError,
  } = useQuery<SummaryQueryResult>(QUERY_BILLING_DETAILS, {
    variables: {
      readingLevel,
    },
    onError: error => console.error('Cost::', error),
  });

  const {total, ...costBreakdown} = getCostBreakdown(
    costData?.subscriptionPreview,
  );
  const nextBillingDate = costData?.subscriptionPreview?.nextCycleDate;

  return (
    <>
      <FlowLayout
        title={`Update to your payment plan`}
        ctaContainerSize="md"
        isLoading={isCostDataLoading}
        error={costDataError ? 'Unable to load payment summary' : ''}
        cta={
          <Grid
            container
            spacing={3}
            alignContent="stretch"
            alignItems="stretch">
            <Grid item xs={12} sm={7}>
              <Typography component="p" className={classes.disclaimer}>
                By clicking confirm, your subscription will change, and you will
                be charged the updated amount starting the next billing cycle on{' '}
                {nextBillingDate
                  ? toUSDateFormat(new Date(nextBillingDate).getTime())
                  : ''}
                .
              </Typography>
            </Grid>
            <Grid item xs={12} sm={5}>
              <Button
                onClick={updateSubscription}
                className={classes.button}
                isLoading={isUpdatingSub}>
                Continue
              </Button>
            </Grid>
          </Grid>
        }>
        <Container maxWidth="md" className={classes.tableContainer}>
          <>
            {costData && (
              <>
                <Table
                  entries={costBreakdown}
                  footerEntries={{Total: total}}
                  cellAlign="right"
                />
                <Table
                  entries={getShippingInfo(costData?.stripeSubscription)}
                  headerClassName={classes.shippingHeader}
                />
              </>
            )}
          </>
        </Container>
      </FlowLayout>
      {updateError && (
        <Alert
          error={
            'Unable to update subscription. Please text us at (415) 214-8119 for help.'
          }
        />
      )}
    </>
  );
};

export default withStyles(styles)(PaymentSummary);

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import TextField from '@mui/material/TextField';

import styles from './AddProfileScreen.styles';
import {
  addedProfileErrorType,
  addedProfileType,
  nameErrorType,
} from '../ProfilesScreen.types';

interface props extends WithStyles<typeof styles> {
  profileBeingAdded: addedProfileType;
  nameError: nameErrorType;
  setProfileBeingAdded: React.Dispatch<React.SetStateAction<addedProfileType>>;
  setError: React.Dispatch<React.SetStateAction<addedProfileErrorType>>;
}

const EditName = ({
  classes,
  profileBeingAdded,
  nameError,
  setProfileBeingAdded,
  setError,
}: props) => {
  const updateProfileName = (e: any) => {
    setProfileBeingAdded((profile: addedProfileType) => ({
      ...profile,
      name: e.target.value,
    }));

    if (!e.target.value) {
      return;
    }

    setError((error: addedProfileErrorType) => ({
      ...error,
      nameError: {
        required: false,
        duplicate: false,
      },
    }));
  };

  const nameErrorText = () => {
    if (nameError.required) {
      return "The child's name is required";
    }
    if (nameError.duplicate) {
      return 'There exists a profile under your account with the same name. Enter a different profile name to continue';
    }
    return '';
  };

  return (
    <TextField
      label="Child's Name"
      error={nameError.required || nameError.duplicate}
      helperText={nameErrorText()}
      value={profileBeingAdded.name}
      onChange={updateProfileName}
      name="name"
      variant="outlined"
      className={classes.inputField}
      InputProps={{
        className: `${classes.input}`,
      }}
      InputLabelProps={{
        className: `${classes.fieldLabel}`,
      }}
    />
  );
};

export default withStyles(styles)(EditName);

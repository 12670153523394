import {Box, SxProps, Typography} from '@mui/material';
import React from 'react';
import * as palette from 'shared/styles/palette';

interface BodySectionProps {
  title: string;
  description?: string;
  sxTitle?: SxProps;
  sxDescription?: SxProps;
}

const BodySection: React.FC<BodySectionProps> = ({
  title,
  description,
  sxTitle,
  sxDescription,
}) => {
  return (
    <Box>
      <Typography
        textAlign="center"
        variant="h6"
        color={palette.blue500}
        sx={{fontSize: '26px', color: palette.blue500, mb: '16px', ...sxTitle}}>
        {title}
      </Typography>
      {description && (
        <Typography
          textAlign="center"
          variant="body2"
          color={palette.gray900}
          sx={{...sxDescription}}>
          {description}
        </Typography>
      )}
    </Box>
  );
};

export default BodySection;

import React, {FC} from 'react';
import Bar from '../Bar/Bar';
import LogoutCTA from './LogoutCTA';

type Props = {
  noBottomMargin?: boolean;
  nonResponsive?: boolean;
  nologoutCta?: boolean;
};

const DesktopBanner: FC<Props> = ({noBottomMargin, nonResponsive}: Props) => {
  return (
    <Bar
      noBottomMargin={noBottomMargin}
      nonResponsive={nonResponsive}
      logoutCta={<LogoutCTA />}
    />
  );
};

export default DesktopBanner;

import gql from 'graphql-tag';

export const ONBOARDING_MUTATION = gql`
  mutation Onboarding(
    $flowName: String!
    $phone: String!
    $paymentMethod: String!
    $content: JSON!
    $name: String
    $email: String
    $coupon: String
    $uid: String
    $referral: String
    $stripeBillingPostalCode: String
    $stripePriceId: String
    $productType: String
    $tuneTransactionId: String
    $isbns: String
    $referralCode: String
    $giftCode: String
  ) {
    onboarding(
      flowName: $flowName
      phoneNumber: $phone
      paymentMethod: $paymentMethod
      content: $content
      name: $name
      email: $email
      coupon: $coupon
      uid: $uid
      referral: $referral
      stripeBillingPostalCode: $stripeBillingPostalCode
      stripePriceId: $stripePriceId
      productType: $productType
      tuneTransactionId: $tuneTransactionId
      isbns: $isbns
      referralCode: $referralCode
      giftCode: $giftCode
    ) {
      confirmationId
      customerReferralCode
    }
  }
`;

export const ONBOARD_DIGITAL_CUSTOMER_MUTATION = gql`
  mutation OnboardDigitalCustomer(
    $flowName: String
    $phone: String!
    $content: JSON!
    $name: String!
    $email: String!
    $paymentMethod: String
    $productType: String
    $coupon: String
    $trialPeriodDays: Int
    $stripeBillingPostalCode: String
    $stripePriceId: String
    $tags: [String]
    $uid: String
    $tuneTransactionId: String
    $isbns: String
    $referralCode: String
    $giftCode: String
  ) {
    onboardDigitalCustomer(
      flowName: $flowName
      phoneNumber: $phone
      paymentMethod: $paymentMethod
      productType: $productType
      content: $content
      name: $name
      email: $email
      coupon: $coupon
      trialPeriodDays: $trialPeriodDays
      stripeBillingPostalCode: $stripeBillingPostalCode
      stripePriceId: $stripePriceId
      tags: $tags
      uid: $uid
      tuneTransactionId: $tuneTransactionId
      isbns: $isbns
      referralCode: $referralCode
      giftCode: $giftCode
    ) {
      confirmationId
      customerReferralCode
    }
  }
`;

export const PURCHASE_COUPON_MUTATION = gql`
  mutation PurchaseCoupon(
    $flowName: String!
    $phone: String!
    $paymentMethod: String!
    $content: JSON!
    $name: String
    $email: String
    $uid: String
    $referral: String
    $stripePriceId: String
    $tuneTransactionId: String
  ) {
    purchaseCoupon(
      flowName: $flowName
      phoneNumber: $phone
      paymentMethod: $paymentMethod
      content: $content
      name: $name
      email: $email
      uid: $uid
      referral: $referral
      stripePriceId: $stripePriceId
    ) {
      confirmationId
    }
  }
`;

export const PURCHASE_GIFT_BUNDLE = gql`
  mutation PurchaseGift(
    $email: String!
    $giftBundle: String!
    $name: String!
    $phone: String!
    $content: JSON
    $amount: Int!
    $paymentMethod: String
    $taxCalculationId: String
    $uid: String
  ) {
    purchaseGift(
      email: $email
      giftBundle: $giftBundle
      name: $name
      phoneNumber: $phone
      content: $content
      amount: $amount
      paymentMethod: $paymentMethod
      taxCalculationId: $taxCalculationId
      uid: $uid
    ) {
      confirmationId
    }
  }
`;

export const VALIDATE_COUPON = gql`
  query ValidateCoupon($coupon: String!, $priceId: String!) {
    validateCoupon(coupon: $coupon, priceId: $priceId) {
      name
      amountOff
      percentOff
    }
  }
`;

export const ESTIMATE_TAX = gql`
  query EstimateTax(
    $amount: Int!
    $city: String
    $country: String
    $currency: String
    $line1: String
    $postalCode: String
    $state: String
    $priceId: String
    $taxCode: String
  ) {
    estimateTax(
      amount: $amount
      city: $city
      country: $country
      currency: $currency
      line1: $line1
      postalCode: $postalCode
      state: $state
      priceId: $priceId
      taxCode: $taxCode
    ) {
      id
      amountTotal
      taxAmountExclusive
      taxAmountInclusive
    }
  }
`;

export const GET_REFERRAL_RECEIVER_REWARD = gql`
  query ReferralReceiverReward(
    $referralCode: String!
    $billingSource: BillingSource!
  ) {
    referralReceiverReward(
      referralCode: $referralCode
      billingSource: $billingSource
    ) {
      couponId
    }
  }
`;

export const RESUBSCRIBE_MUTATION = gql`
  mutation Resubscribe(
    $paymentMethod: String!
    $stripePriceId: String!
    $userUid: String!
    $coupon: String
    $productType: String
    $stripeBillingPostalCode: String
    $content: JSON!
  ) {
    resubscribe(
      paymentMethod: $paymentMethod
      stripePriceId: $stripePriceId
      userUid: $userUid
      coupon: $coupon
      productType: $productType
      stripeBillingPostalCode: $stripeBillingPostalCode
      content: $content
    ) {
      confirmationId
    }
  }
`;

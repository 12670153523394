import React from 'react';
import Typography from '@mui/material/Typography';
import * as palette from '../../styles/palette';

type Props = {
  text?: string;
};

const Header = ({text}: Props) => {
  return (
    <Typography
      component="h2"
      variant="h6"
      my="8px"
      style={{
        color: palette.blue500,
        textAlign: 'center',
        fontSize: '26px',
        fontWeight: '800',
        lineHeight: '140%',
        letterSpacing: '-0.104px',
      }}>
      {text || 'You are all set!'}
    </Typography>
  );
};

export default Header;

import React from 'react';
import BasicForm from './ResetPasswordScreen.form';
import ReturnToLoginButton from './ResetPasswordScreen.login-button';
import {PUBLIC_ROUTES} from '../../../shared/components/Route/routes';

const SuccessfulReset = () => {
  return (
    <BasicForm
      titleText="Password Reset Successful!"
      subtitleText="Your password has been successfully reset. You can now log in with your new password">
      <ReturnToLoginButton linkTo={PUBLIC_ROUTES.EMAIL_LOGIN} />
    </BasicForm>
  );
};

export default SuccessfulReset;

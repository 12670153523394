import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(() => {
  return createStyles({
    author: {
      fontSize: '0.9rem',
      color: '#647070',
    },
    bookCard: {
      maxWidth: '200px',
      marginBottom: '32px',
      width: '100%',
      cursor: 'pointer',
    },
    imgContainer: (props: {coverPhotoURL?: string}) => ({
      height: '160px',
      backgroundImage: `url(${props.coverPhotoURL})`,
      backgroundSize: 'cover',
      '@media (min-width: 375px)': {
        height: '180px',
      },
      '@media (min-width: 415px)': {
        height: '200px',
      },
    }),
    title: {
      fontWeight: 'bold',
      fontSize: '1.375rem',
      marginTop: '16px',
    },
  });
});

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {gray300, gray900} from 'shared/styles/palette';

const useStyles = (theme: Theme) =>
  createStyles({
    cell: {
      borderBottom: 'transparent',
      fontSize: '1.25rem',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
        padding: theme.spacing(1),
      },
    },
    cellHeader: {
      fontWeight: 700,
    },
    footer: {
      borderTop: `1px solid ${gray300}`,
      fontWeight: 700,
      color: gray900,
    },
  });

export default useStyles;

import React from 'react';
import {Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';

import Modal from '@mui/material/Modal';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import styles from './ProfilesScreen.styles';
import MaxProfilesScreen from './ProfilesScreen.max-profiles-screen';
interface props extends WithStyles<typeof styles> {
  open: boolean;
  onCloseModal: () => void;
}

const MaxProfilesModal = ({classes, open, onCloseModal}: props) => {
  return (
    <Modal open={open} onClose={onCloseModal}>
      <Box className={classes.modalBox}>
        <Box className={classes.closeIconContainer}>
          <IconButton>
            <CloseIcon onClick={onCloseModal} className={classes.closeIcon} />
          </IconButton>
        </Box>

        <MaxProfilesScreen onCloseModal={onCloseModal} />
      </Box>
    </Modal>
  );
};

export default withStyles(styles)(MaxProfilesModal);

import React from 'react';

import Title from './AdjustReadingLevel.request-adjustment.title';
import {List, ListItem, Typography} from '@mui/material';

type Props = {
  profileName?: string;
};

const RequestAdjustmentBookInfoScreen = ({profileName}: Props) => {
  return (
    <>
      <Title
        text={`How do I know if these books are right for ${profileName}?`}
      />
      <Typography
        variant="body1"
        mt={2}
        style={{
          fontWeight: 400,
          fontSize: '1rem',
          lineHeight: '1.5rem',
        }}>
        <List sx={{listStyleType: 'disc', pl: 3}}>
          <ListItem sx={{display: 'list-item', mt: 2}}>
            They should need help with no more than one word every two pages.
          </ListItem>

          <ListItem sx={{display: 'list-item', mt: 2}}>
            This indicates they are working on fluency and comprehension at a
            comfortable pace.
          </ListItem>

          <ListItem sx={{display: 'list-item', mt: 2}}>
            <strong>If you are not sure, pick the easier option.</strong> After
            all, we want to keep {profileName} motivated and feeling confident!
          </ListItem>
        </List>
      </Typography>
    </>
  );
};

export default RequestAdjustmentBookInfoScreen;

import React from 'react';
import CreditCard from 'customerPortal/components/CreditCard/CreditCard';
import {
  BillingDetails,
  StripeSubscription,
} from 'shared/types/stripeSubscription';
import {CustomerSubscription} from 'shared/types/customer';
import {toUSCurrency} from 'shared/utils/currency';

const joinAddressDetails = (address: BillingDetails['address']) => {
  if (!address) return;

  return [
    address.line1,
    address.line2,
    address.city,
    address.state,
    address.postalCode,
    address.country,
  ]
    .filter(Boolean)
    .join(', ');
};

export const getShippingInfo = (stripeSub: StripeSubscription) => {
  const {paymentData} = stripeSub;

  if (!paymentData) {
    return {};
  }

  const primaryPaymentInfo = paymentData[0];
  const {billingDetails} = primaryPaymentInfo;

  return {
    ...(primaryPaymentInfo && {
      'Pay with': <CreditCard card={primaryPaymentInfo.card} />,
    }),
    ...(billingDetails && {
      'Ship to': (
        <>
          <div>{billingDetails?.name}</div>
          <div>{joinAddressDetails(billingDetails?.address)}</div>
        </>
      ),
    }),
  };
};

export const getCostBreakdown = (
  subscriptionPreview: CustomerSubscription | undefined,
  ignoreShipping = false,
): Record<string, string> => {
  if (!subscriptionPreview) {
    return {
      total: '---',
    };
  }

  const SHIPPING_COST = 500;

  const {title, price} = subscriptionPreview;

  if (ignoreShipping) {
    return {
      [title]: toUSCurrency(price),
      total: toUSCurrency(price),
    };
  }

  return {
    [title]: toUSCurrency(price - SHIPPING_COST),
    Shipping: toUSCurrency(SHIPPING_COST),
    total: toUSCurrency(price),
  };
};

import React, {useRef, useState} from 'react';
import Typography from '@mui/material/Typography';
import useStyles, {
  DESKTOP_IMAGE_DIMENSIONS,
  MOBILE_IMAGE_DIMENSIONS,
} from './ExampleBookPanel.style';
import {Card, CardContent, CircularProgress, Fade} from '@mui/material';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, {Pagination, Navigation} from 'swiper';
import Box from '@mui/material/Box';
import {Rating} from '@mui/material';
import {ArrowBackIosTwoTone, ArrowForwardIosTwoTone} from '@mui/icons-material';
import Fab from '@mui/material/Fab';
import {useSwipeable} from 'react-swipeable';
import {useWindowSize} from 'shared/utils/layoutHooks';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

SwiperCore.use([Pagination, Navigation]);

function BookPanels({books, isLoading}) {
  const classes = useStyles();
  const [activeBook, setActiveBook] = useState(null);
  const [transitioning, setTransitioning] = useState(false);

  // `any` conversion is a hack;
  // because ref cannot be assigned
  // nor does Swiper take the ref attribute
  const swiperRef = useRef(null);
  const prevRef = useRef(null);
  const nextRef = useRef(null);

  const swipeHandlers = useSwipeable({
    onSwipedLeft: () => {
      setTransitioning(true);
      if (!swiperRef.current) return;
      swiperRef.current.slideNext(300);
    },
    onSwipedRight: () => {
      setTransitioning(true);
      if (!swiperRef.current) return;
      swiperRef.current.slidePrev(300);
    },
  });

  return (
    <>
      {isLoading && <CircularProgress className={classes.progress} />}
      {books && books.length > 0 && (
        <>
          <Box>
            <Swiper
              onInit={swiper => {
                swiper.navigation.init();
                swiper.navigation.update();
              }}
              navigation={{
                prevEl: prevRef.current,
                nextEl: nextRef.current,
              }}
              loop={true}
              loopedSlides={books.length}
              loopAdditionalSlides={1}
              grabCursor={true}
              onSliderFirstMove={() => {
                setTransitioning(true);
              }}
              onTransitionEnd={swiper => {
                setActiveBook(books[swiper.realIndex]);
                setTransitioning(false);
              }}
              centeredSlides={true}
              slidesPerView={'auto'}
              pagination={true}
              onSwiper={swiper => {
                swiperRef.current = swiper;
              }}
              className={classes.swiper}>
              {books.map((item, index) => (
                <SwiperSlide key={index} className={classes.swiperSlide}>
                  <CardItem item={item} />
                </SwiperSlide>
              ))}

              <div ref={prevRef} className={classes.backButton}>
                <Fab
                  className={classes.swiperNavButtons}
                  color="secondary"
                  aria-label="back"
                  size="small">
                  <ArrowBackIosTwoTone />
                </Fab>
              </div>

              <div ref={nextRef} className={classes.forwardButton}>
                <Fab
                  className={classes.swiperNavButtons}
                  color="secondary"
                  aria-label="back"
                  size="small">
                  <ArrowForwardIosTwoTone />
                </Fab>
              </div>
            </Swiper>
          </Box>
          <Box className={classes.mobileReviewContent} {...swipeHandlers}>
            {activeBook && (
              <Fade in={!transitioning} timeout={{enter: 300, exit: 600}}>
                <Box className={classes.mobileReviewContentContainer}>
                  <Content item={activeBook} />
                </Box>
              </Fade>
            )}
          </Box>
        </>
      )}
    </>
  );
}

function getContent(starRatingSource) {
  const split = starRatingSource.split(/(from)/g);
  const source = split[split.length - 1];
  split.pop(); // remove source from array
  const supportText = split.join('');
  return {source, supportText};
}

function Content({item}) {
  const classes = useStyles();
  return (
    <Box display="flex" flexDirection="column" alignContent="flex-start">
      <Typography
        align="left"
        variant="subtitle1"
        className={classes.bookTitle}>
        {item.title}
      </Typography>
      <Typography align="left" className={classes.author}>
        by {item.author}
      </Typography>
      <Rating
        precision={0.1}
        size="small"
        className={classes.rating}
        value={Number(item.starRating)}
        readOnly
      />
      <Typography align="left" className={classes.ratingSource}>
        <span className={classes.starRatingValue}>{item.starRating}</span> (
        <span className={classes.ratingText}>
          {getContent(item.starRatingSource).supportText}
        </span>
        <span className={classes.starRatingSource}>
          {getContent(item.starRatingSource).source}
        </span>
        )
      </Typography>
      <Typography align="left" className={classes.reviewDescription}>
        {item.reviewDescription}
      </Typography>
    </Box>
  );
}

function CardItem({item}) {
  const [windowWidth] = useWindowSize();
  const defaultHeight =
    windowWidth > TABLET_BREAKPOINT
      ? DESKTOP_IMAGE_DIMENSIONS
      : MOBILE_IMAGE_DIMENSIONS;
  const [imageHeight, setImageHeight] = useState(defaultHeight);
  const [imageWidth, setImageWidth] = useState(defaultHeight); // assume equal width and height

  const classes = useStyles({imageWidth, imageHeight});
  const imageRef = useRef(null);
  return (
    <Card
      classes={{root: classes.card}}
      elevation={12}
      raised={true}
      className={classes.container}>
      <div className={classes.imageContainer}>
        <img
          ref={imageRef}
          onLoad={() => {
            if (imageRef.current) {
              setImageHeight(imageRef.current.naturalHeight);
              setImageWidth(imageRef.current.naturalWidth);
            }
          }}
          src={item.coverPhotoURL}
          className={classes.cardMediaPhoto}
          alt="Book"
        />
        <div className={classes.imageShadowOverlay} />
      </div>
      <CardContent className={classes.cardContent}>
        <Content item={item} />
      </CardContent>
    </Card>
  );
}

export default BookPanels;

import React from 'react';
import {Chip} from '@mui/material';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';

import {BookOrderStatus} from 'shared/types/purchases';
import styles from './StatusChip.styles';
import cN from 'classnames';

interface StatusChipProps extends WithStyles<typeof styles> {
  status: BookOrderStatus;
  isFilled?: boolean;
  containerClass?: string;
}

const bookOrdersStatuses: BookOrderStatus[] = [
  BookOrderStatus.Shipped,
  BookOrderStatus.Delivered,
  BookOrderStatus.Returned,
  BookOrderStatus.Created,
];

const BookOrderStatusChip = ({
  classes,
  status,
  containerClass,
}: StatusChipProps) => {
  return (
    <Chip
      size="small"
      className={cN(
        {
          [classes.chipKept]: status && status === BookOrderStatus.Kept,
          [classes.bookOrderDue]: status && status === BookOrderStatus.Due,
          [classes.bookOrderChip]:
            status && bookOrdersStatuses.includes(status),
        },
        containerClass,
      )}
      label={status}
    />
  );
};

export default withStyles(styles)(BookOrderStatusChip);

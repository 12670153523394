import {Book, CategoryName, ReadingList} from './Library.types';

const gradeCategoryMap: Record<string, CategoryName> = {
  kindergarten: CategoryName.kindergarten,
  '1st grade': CategoryName.firstGrade,
  firstgrade: CategoryName.firstGrade,
  '2nd grade': CategoryName.secondGrade,
  secondgrade: CategoryName.secondGrade,
  '3rd grade': CategoryName.thirdGrade,
  thirdgrade: CategoryName.thirdGrade,
};

function getCategoryNameForGrade(grade?: string): CategoryName {
  if (!grade) {
    return CategoryName.gradeLevel;
  }

  const cleanedGrade = grade.toLowerCase().trim();
  const categoryName = gradeCategoryMap[cleanedGrade];
  if (!categoryName) {
    return CategoryName.gradeLevel;
  }

  return categoryName;
}

function readingListsByCategory(
  acc: Record<string, ReadingList[]>,
  readingList: ReadingList,
): Record<string, ReadingList[]> {
  if (!readingList) {
    return acc;
  }

  const categoryIds =
    (readingList.categories
      ?.map(category => category.id)
      .filter(id => !!id) as string[]) || [];
  for (const categoryId of categoryIds) {
    if (!acc[categoryId]) {
      acc[categoryId] = [];
    }

    acc[categoryId].push(readingList);
  }

  const ancestorIds =
    (readingList.ancestors
      ?.map(category => category.id)
      .filter(id => !!id) as string[]) || [];
  for (const ancestorId of ancestorIds) {
    if (!acc[ancestorId]) {
      acc[ancestorId] = [];
    }

    acc[ancestorId].push(readingList);
  }

  return acc;
}

function uniqueBooks(books: Book[]): Book[] {
  const bookIds = books?.reduce((acc: Record<string, Book>, book) => {
    if (!book?.id) {
      return acc;
    }

    acc[book.id] = book;
    return acc;
  }, {});
  return Object.values(bookIds);
}

const LibraryService = {
  getCategoryNameForGrade,
  readingListsByCategory,
  uniqueBooks,
};

export default LibraryService;

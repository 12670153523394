import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      flex: '0 1 500px',
      alignItems: 'flex-start',
    },
    sideContent: {
      flexBasis: '400px',
    },
    form: {
      width: '100%',
      display: 'flex',
      alignItems: 'baseline',
      margin: `${theme.spacing(4)} 0`,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        margin: `${theme.spacing(3)} 0 ${theme.spacing(2)} 0`,
      },
    },
    text: {
      fontSize: `1.125rem`,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: 'inherit',
      },
    },
    dropdown: {
      flex: '0 1 400px',
    },
    button: {
      marginTop: theme.spacing(12),
      width: '50%',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        width: '100%',
        marginTop: 0,
      },
    },
  });

export default styles;

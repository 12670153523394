import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import withStyles from '@mui/styles/withStyles';
import LinearProgress from '@mui/material/LinearProgress';
import * as palette from 'shared/styles/palette';

const BorderLinearProgress = withStyles(theme => ({
  root: {
    height: 10,
    borderRadius: 5,
  },
  colorPrimary: {
    backgroundColor: palette.turquoise100,
  },
  bar: {
    borderRadius: 5,
    backgroundColor: palette.turquoise900,
  },
}))(LinearProgress);

const useStyles = makeStyles({
  root: {
    flexGrow: 1,
    borderRadius: 5,
    willChange: 'transform', // prevent border-radius breaking during transform animation
    overflow: 'hidden',
  },
});

export default function CustomizedProgressBars(props) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <BorderLinearProgress {...props} variant="determinate" />
    </div>
  );
}

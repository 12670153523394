import React, {useState} from 'react';
import {Typography, Grid, Box} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Section from 'customerPortal/components/Section';
import Button, {ButtonType} from 'shared/components/common/Button';
import TextField from '@mui/material/TextField';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import styles from './CancellationFeedback.styles';
import clsx from 'clsx';

interface Props extends WithStyles<typeof styles> {
  onConfirm: (input: string) => void;
  onSkip: () => void;
  onBack?: () => void;
  hideDesktopBackButton?: boolean;
}

const CancellationFeedback = ({
  classes,
  onConfirm,
  onSkip,
  onBack = () => {},
  hideDesktopBackButton = false,
}: Props) => {
  const isMobile = useMobileLayout();
  const [input, setInput] = useState('');

  const onContinue = () => {
    onConfirm(input);
  };

  return (
    <>
      <Grid container className={classes.content}>
        <Box className={classes.topContainer}>
          {isMobile && <Section title="Cancellation" />}
          <Typography variant={'h6'} align={'center'} className={classes.title}>
            Why are you cancelling?
          </Typography>
          <TextField
            className={classes.textField}
            placeholder={
              'We are always trying to improve. Please share any advice or feedback for us.'
            }
            variant="outlined"
            multiline
            rows={isMobile ? 10 : 6}
            onChange={({target: {value}}) => {
              setInput(value);
            }}
          />
        </Box>
        <Box>
          <Grid container className={classes.buttonContainer}>
            {isMobile ? (
              <>
                <Button
                  className={classes.button}
                  disabled={!input}
                  onClick={onContinue}>
                  Continue
                </Button>
                <Button
                  className={clsx(
                    classes.skipButton,
                    !!input && classes.hidden,
                  )}
                  onClick={onSkip}>
                  Skip
                </Button>
              </>
            ) : (
              <>
                <Grid container className={classes.leftContainer}>
                  <Grid item>
                    {!hideDesktopBackButton && (
                      <Button
                        className={classes.activeButton}
                        variant={ButtonType.Inline}
                        onClick={onBack}>
                        Back
                      </Button>
                    )}
                  </Grid>
                </Grid>
                <Grid item>
                  <Button
                    className={classes.button}
                    disabled={!input}
                    onClick={onContinue}>
                    Continue
                  </Button>
                </Grid>
                <Grid container className={classes.rightContainer}>
                  <Grid item>
                    {!input && (
                      <Button
                        className={classes.skipButton}
                        variant={ButtonType.Inline}
                        onClick={onSkip}>
                        Skip
                      </Button>
                    )}
                  </Grid>
                </Grid>
              </>
            )}
          </Grid>
        </Box>
      </Grid>
    </>
  );
};

export default withStyles(styles)(CancellationFeedback);

import React from 'react';
import {Container, Grid, Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import styles from './RemoveChildPreview.styles';
import {FlowLayout} from 'customerPortal/components/Flow';
import {toUSDateFormat} from 'shared/utils/dateUtils';
import {possessive} from 'shared/utils/stringUtils/stringUtils';
import {useHistory, useLocation} from 'react-router-dom';
import {useMutation, useQuery} from '@apollo/react-hooks';
import {
  MUTATE_REMOVE_CHILD,
  QUERY_REMOVE_CHILD_PREVIEW,
} from './RemoveChildPreview.query';
import {Profile} from 'shared/types/profile';
import {CustomerSubscription} from 'shared/types/customer';
import {getCostBreakdown} from 'customerPortal/utils/costBreakdown';
import Table from 'customerPortal/components/Table/Table/Table';
import Button, {ButtonType} from 'shared/components/common/Button';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import {RemoveChildFlow} from '../types';
import Alert from 'shared/components/common/Alert/Alert';
import {FlowState} from 'customerPortal/components/Flow/types';

interface QueryResult {
  profile: Profile;
  profileRemovalSubscriptionPreview: CustomerSubscription;
}

const RemoveChildPreview = ({
  classes,
  setFlowState,
}: RemoveChildFlow & WithStyles<typeof styles>) => {
  const location = useLocation();
  const history = useHistory();
  const searchParams = new URLSearchParams(location.search);

  const {loading, data, error} = useQuery<QueryResult>(
    QUERY_REMOVE_CHILD_PREVIEW,
    {
      variables: {
        profileId: searchParams.get('childId'),
      },
      onError: error => console.error('Remove child preview::', error),
    },
  );

  const [removeChild, {loading: isRemovingChild, error: removalError}] =
    useMutation(MUTATE_REMOVE_CHILD, {
      variables: {
        profileId: searchParams.get('childId'),
      },
      onCompleted: () => {
        setFlowState(FlowState.Success);
      },
      onError: error => console.error('Remove child::', error),
    });

  const {total, ...costBreakdown} = getCostBreakdown(
    data?.profileRemovalSubscriptionPreview,
  );

  return (
    <>
      <FlowLayout
        error={error ? 'Unable to load preview' : ''}
        isLoading={loading}
        title={`Are you sure to remove ${possessive(
          data?.profile.name ?? '',
        )} profile?`}
        subtitle={
          data &&
          `This will remove the child's current progress from Ello program and can't
          be undone.`
        }
        ctaContainerSize={'sm'}
        cta={
          <Grid spacing={2} container wrap="wrap-reverse">
            <Grid container item xs={12} sm={6} md={6}>
              <Button
                className={classes.button}
                variant={ButtonType.Secondary}
                disabled={isRemovingChild}
                onClick={() =>
                  history.push(PRIVATE_ROUTES.ACCOUNT_SUB_DETAILS.path)
                }>
                Cancel
              </Button>
            </Grid>

            <Grid item xs={12} sm={6} md={6}>
              <Button
                danger
                className={classes.button}
                isLoading={isRemovingChild}
                onClick={removeChild}>
                Remove Profile
              </Button>
            </Grid>
          </Grid>
        }>
        <Container maxWidth="md" className={classes.content}>
          <>
            {data?.profileRemovalSubscriptionPreview && (
              <>
                {data.profileRemovalSubscriptionPreview?.nextCycleDate && (
                  <Typography className={classes.description}>
                    Starting{' '}
                    <time>
                      <b>
                        {toUSDateFormat(
                          new Date(
                            data.profileRemovalSubscriptionPreview.nextCycleDate,
                          ).getTime(),
                        )}
                      </b>
                    </time>{' '}
                    your subscription will change to:
                  </Typography>
                )}

                {costBreakdown && (
                  <Table
                    tableClassName={classes.table}
                    entries={costBreakdown}
                    footerEntries={{Total: total}}
                    cellAlign="right"
                  />
                )}
              </>
            )}
          </>
        </Container>
      </FlowLayout>
      {removalError && (
        <Alert
          error={
            'Unable to remove child from subscription. Please text us at (415) 214-8119 for help.'
          }
        />
      )}
    </>
  );
};

export default withStyles(styles)(RemoveChildPreview);

import React from 'react';

import Typography from '@mui/material/Typography';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';

const Text = () => {
  return (
    <Typography
      variant="body1"
      mt={3}
      style={{
        fontWeight: 400,
        fontSize: '1rem',
        lineHeight: '1.5rem',
      }}>
      Here is how you would know if books are right:
      <br />
      <List sx={{listStyleType: 'disc', pl: 3}}>
        <ListItem sx={{display: 'list-item', mt: 2}}>
          Choose a book where your child can read independently. This indicates
          they are working on fluency and comprehension.
        </ListItem>

        <ListItem sx={{display: 'list-item', mt: 2}}>
          They should need help with no more than one word every two pages
        </ListItem>
      </List>
    </Typography>
  );
};

export default Text;

import {Profile} from '../../../../../shared/types/profile';

export const allGrades = [
  {
    grade: 'Not in school',
    value: 'not-in-school',
  },
  {
    grade: 'Pre-K',
    value: 'pre-k',
  },
  {
    grade: 'Kindergarten',
    value: 'k',
  },
  {
    grade: '1st grade',
    value: 'first',
  },
  {
    grade: '2nd grade',
    value: 'second',
  },
  {
    grade: '3rd grade',
    value: 'third',
  },
];

export const readingAbilities = [
  {
    ability: 'Far behind grade level',
    value: 'far-behind',
  },
  {
    ability: 'A little behind grade level',
    value: 'little-behind',
  },
  {
    ability: 'As expected for grade level',
    value: 'grade-level',
  },
  {
    ability: 'A little ahead of grade level',
    value: 'little-ahead',
  },
  {
    ability: 'Far ahead of grade level',
    value: 'far-ahead',
  },
];

export enum AddProfileStep {
  AddDetails = 'addDetails',
  SelectProfileCharacter = 'selectProfileCharacter',
  SuccessfullyAddedProfile = 'successfullyAddedProfile',
  ErrorWhenAddingProfile = 'errorWhenAddingProfile',
}

const handleProfileCharacterSelected = (successfullyAddedProfile: boolean) => {
  if (successfullyAddedProfile) {
    return AddProfileStep.SuccessfullyAddedProfile;
  }

  return AddProfileStep.ErrorWhenAddingProfile;
};

export const nextAddProfileStep = (
  currentStep: string,
  successfullyAddedProfile = true,
) => {
  switch (currentStep) {
    case AddProfileStep.AddDetails:
      return AddProfileStep.SelectProfileCharacter;
    case AddProfileStep.SelectProfileCharacter:
      return handleProfileCharacterSelected(successfullyAddedProfile);
    default:
      return AddProfileStep.AddDetails;
  }
};

export const previousAddProfileStep = (currentStep: string) => {
  if (currentStep === AddProfileStep.SelectProfileCharacter) {
    return AddProfileStep.AddDetails;
  }

  return AddProfileStep.SelectProfileCharacter;
};

export const isDuplicateProfileName = (
  customerProfiles?: Profile[],
  addedProfileName?: string,
) => {
  if (!addedProfileName || !customerProfiles?.length) {
    return false;
  }

  const profileNames = customerProfiles.map(profile =>
    profile.name.trim().toLowerCase(),
  );
  return profileNames.includes(addedProfileName.toLowerCase());
};

export const addProfileWithMutation = (
  customerAddProfileMutation: (options: any) => Promise<any>,
) => {
  return async (input: any) => {
    try {
      const {data} = await customerAddProfileMutation({
        variables: {
          input,
        },
      });

      return data;
    } catch (error) {
      console.log('customer add profile', error);
      throw error;
    }
  };
};

export const isLevellingError = (error: string) => {
  return error.includes('Cannot level a child in grade');
};

export const addProfileErrorMessage = (error: string) =>
  isLevellingError(error)
    ? `Sorry, we curerntly do not support the selected grade.
                   We only support Kindergarten, 1st, 2nd and thrid grades.
                   If you need help, please text us at (415) 214-8119.`
    : `Sorry, we're having a problem adding the profile to your
                account. You can try again later or if you need help, please
                text us at (415) 214-8119.`;

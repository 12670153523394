import makeStyles from '@mui/styles/makeStyles';
import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

export const DESKTOP_IMAGE_DIMENSIONS = 200;
export const MOBILE_IMAGE_DIMENSIONS = 180;

// Calculate dimensions of overlay used to place shadows
// over images
function calculateDimensions(dimensionSize, side, props) {
  // do nothing
  if (props.imageHeight === props.imageWidth) {
    return dimensionSize;
  }

  const calculateWidth = () => {
    if (props.imageHeight > props.imageWidth) {
      const scale = dimensionSize / props.imageHeight;
      return props.imageWidth * scale;
    } else {
      return dimensionSize;
    }
  };

  const calculateHeight = () => {
    if (props.imageHeight < props.imageWidth) {
      const scale = dimensionSize / props.imageWidth;
      return props.imageHeight * scale;
    } else {
      return dimensionSize;
    }
  };

  if (side === 'width') {
    return calculateWidth();
  } else {
    return calculateHeight();
  }
}

export default makeStyles(theme => ({
  container: {
    display: 'flex',
    padding: theme.spacing(3),
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      height: 230,
      paddingLeft: theme.spacing(4),
      paddingRight: theme.spacing(4),
    },
  },
  card: {
    borderRadius: 5,
    boxShadow: `10px 10px 20px ${palette.gray100}`,
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      boxShadow: '0px 0px 0px',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      marginBottom: theme.spacing(3),
    },
  },
  imageContainer: {
    display: 'flex',
    position: 'relative',
    justifyContent: 'center',
    alignItems: 'center',
    // overwrite webflow max width
    '& img': {
      maxWidth: props =>
        calculateDimensions(DESKTOP_IMAGE_DIMENSIONS, 'width', props),
    },
  },
  imageShadowOverlay: {
    position: 'absolute',
    boxShadow: `3px 3px 20px ${palette.gray100}`,
    width: props =>
      calculateDimensions(DESKTOP_IMAGE_DIMENSIONS, 'width', props),
    height: props =>
      calculateDimensions(DESKTOP_IMAGE_DIMENSIONS, 'height', props),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      width: props =>
        calculateDimensions(MOBILE_IMAGE_DIMENSIONS, 'width', props),
      height: props =>
        calculateDimensions(MOBILE_IMAGE_DIMENSIONS, 'height', props),
    },
  },
  cardMediaPhoto: {
    width: DESKTOP_IMAGE_DIMENSIONS,
    height: DESKTOP_IMAGE_DIMENSIONS,
    objectFit: 'contain',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      alignSelf: 'center',
      width: MOBILE_IMAGE_DIMENSIONS,
      height: MOBILE_IMAGE_DIMENSIONS,
    },
  },
  swiper: {
    alignSelf: 'center',
    flexDirection: 'column',
    '& .swiper-slide': {
      maxWidth: 600,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        maxWidth: 220,
      },
    },
    '& .swiper-pagination-bullet-active': {
      backgroundColor: theme.palette.primary.main,
    },
  },
  cardContent: {
    display: 'none', // hide view when in mobile
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      padding: 0,
      paddingLeft: theme.spacing(4),
      display: 'block',
      height: DESKTOP_IMAGE_DIMENSIONS,
    },
  },
  mobileReviewContent: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
  mobileReviewContentContainer: {
    padding: theme.spacing(4),
  },
  swiperSlide: {
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginLeft: 0,
      marginRight: 0,
      marginBottom: 0,
    },
  },
  backButton: {
    position: 'relative',
    right: 300,
    bottom: 158,
    marginTop: -40, // Remove white space left by button
    zIndex: 2,
    // remove button when in mobile view
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
  forwardButton: {
    position: 'relative',
    left: 300,
    bottom: 158,
    marginTop: -40, // Remove white space left by button
    zIndex: 2,
    // remove button when in mobile view
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      display: 'none',
    },
  },
  swiperNavButtons: {
    color: theme.palette.primary.main,
    backgroundColor: '#FFFFFF',
    border: `1px solid ${theme.palette.primary.main}`,
    '&:hover': {
      backgroundColor: '#F0FFFF',
    },
  },
  bookTitle: {
    fontWeight: 'bold',
  },
  author: {
    fontSize: 13,
    fontWeight: 400,
  },
  rating: {
    marginTop: theme.spacing(2),
  },
  ratingSource: {
    fontSize: '13px',
  },
  starRatingValue: {
    fontWeight: 'bold',
  },
  ratingText: {
    fontWeight: 400,
  },
  starRatingSource: {
    fontWeight: 'bold',
  },
  reviewDescription: {
    paddingTop: theme.spacing(1),
    fontSize: '13px',
    fontWeight: 400,
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      overflow: 'hidden',
      lineClamp: 5, // limit to five lines then show ellipsis (...)
      display: '-webkit-box',
      boxOrient: 'vertical',
    },
  },
  progress: {
    marginTop: theme.spacing(3),
  },
}));

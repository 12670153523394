import React from 'react';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {verticalBookListStyles} from './BookBrowseScreen.styles';
import {BookOrder, ProfileReadingLevel} from 'shared/types/purchases';
import BookOrderStatusChip from 'customerPortal/components/StatusChip/BookOrderStatusChip';
import BookOrdersProfileViewer from 'customerPortal/components/BookOrdersProfileViewer/BookOrdersProfileViewer';

interface Props extends WithStyles<typeof verticalBookListStyles> {
  books: BookOrder[];
  profileObjects: ProfileReadingLevel[];
}

const VerticalBookList = ({classes, books, profileObjects}: Props) => {
  return (
    <>
      {books.map((book: BookOrder) => (
        <div className={classes.itemContainer} key={book.isbn}>
          <div key={book.isbn} className={classes.itemRow}>
            <img src={book.coverPhotoURL} alt="" className={classes.rowImg} />
            <span>
              <Typography component="h1" variant="h6" className={classes.title}>
                {book.title}
              </Typography>
              <Typography component="p" className={classes.author}>
                By {book.author}
              </Typography>
              <BookOrderStatusChip status={book.status} />
            </span>
          </div>
          <BookOrdersProfileViewer
            profileObjects={profileObjects}
            showBackground
          />
        </div>
      ))}
    </>
  );
};

export default withStyles(verticalBookListStyles)(VerticalBookList);

import React from 'react';
import {Box, Link, Typography} from '@mui/material';
import {gray700, turquoise900} from 'shared/styles/palette';

const MobileFeedbackContact = () => {
  return (
    <Box py="24px">
      <Typography
        variant="body1"
        color={gray700}
        fontWeight="600"
        textAlign="center">
        💬 Have feedback or issues?
        <br />
        <Link
          href="sms:+14152148119"
          sx={{
            color: turquoise900,
            fontWeight: 'bold',
            textDecoration: 'none',
          }}>
          Text us at (415) 214 8119
        </Link>
      </Typography>
    </Box>
  );
};

export default MobileFeedbackContact;

import {FlowState} from 'customerPortal/components/Flow/types';
import {Profile} from 'shared/types/profile';
import {GradeState, Grade, ReadingLevel} from 'shared/types/readingLevels';

export interface AddChildFlow {
  formValues: Form;
  setFormValues: React.Dispatch<React.SetStateAction<Form>>;
  flowState: FlowState;
  setFlowState: (f: FlowState) => void;
  recommendation: Recommendation;
  setRecommendationState: React.Dispatch<React.SetStateAction<Recommendation>>;
  profiles: Profile[];
}

export interface Form {
  name: string;
  readingAbility: GradeState | null;
  grade: Grade | null;
  plan: Plan | null;
  readingLevel: ReadingLevel | null;
}

export interface Recommendation {
  recommendedPlan: Plan | null;
  selectedRecommendation: Plan | null;
  selectedReadingLevel: ReadingLevel | null;
}

export enum Plan {
  Profile = 'profile',
  Bundle = 'bundle',
}

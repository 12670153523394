import React from 'react';

import Typography from '@mui/material/Typography';

type Props = {
  header: string;
};

const Header = ({header}: Props) => {
  return (
    <Typography
      sx={{
        color: 'text',
        fontSize: '1.25rem',
        fontWeight: 700,
      }}>
      {header}
    </Typography>
  );
};

export default Header;

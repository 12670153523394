import {CustomerSubscription} from './customer';

export enum Bundle {
  'ello-default' = 'ello-default',
  'ello-single' = 'ello-single',
  'ello-double-shared' = 'ello-double-shared',
  'ello-double-individual' = 'ello-double-individual',
  'ello-triple-shared' = 'ello-triple-shared',
  'ello-triple-mixed' = 'ello-triple-mixed',
  'ello-triple-individual' = 'ello-triple-individual',
}

export interface SubscriptionPreview {
  currentSubscription: CustomerSubscription;
  description: string;
  name: Bundle;
  nextCycleDate?: string;
  bundles?: number;
  price?: number;
  title: string;
}

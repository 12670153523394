import {isDateInPast, toFriendlyDate} from '../../../../shared/utils/dateUtils';
import {isActiveSubscription} from '../../../../shared/utils/subscriptionStatus';

import {useState, useEffect} from 'react';
import {
  isDevelop,
  isProduction,
  isStagingOrWebflow,
} from '../../../../shared/utils/environments';

export function subscriptionStatusText(
  subscriptionStatus: string | undefined,
  subscriptionEndDate: number | undefined,
) {
  if (!subscriptionEndDate) {
    return;
  }

  const subscriptionEndDateFormatted =
    subscriptionEndDate && toFriendlyDate(subscriptionEndDate, false);

  if (isActiveSubscription(subscriptionStatus)) {
    return `Renews on ${subscriptionEndDateFormatted}`;
  }

  if (isDateInPast(subscriptionEndDate)) {
    return `Inactive`;
  }

  return `Expires on ${subscriptionEndDateFormatted}`;
}

export function isInactiveSubscription(
  subscriptionEndDate: number | undefined,
) {
  if (!subscriptionEndDate) {
    return;
  }

  return isDateInPast(subscriptionEndDate);
}

export function toTitleCase(str: string): string {
  return str.replace(/_/g, ' ').replace(/\b\w/g, char => char.toUpperCase());
}

export function useIsAppleDevice() {
  const [isAppleDevice, setIsAppleDevice] = useState(false);

  useEffect(() => {
    const userAgent = navigator.userAgent;

    const isAppleDevice = /iPad|iPhone|iPod|Macintosh/.test(userAgent);

    setIsAppleDevice(isAppleDevice);
  }, []);

  return isAppleDevice;
}

export function getResusbcriptionBaseUrl(): string {
  if (isProduction()) {
    return 'https://www.ello.com/start';
  }

  if (isStagingOrWebflow()) {
    return 'https://helloello.webflow.io/start';
  }

  if (isDevelop()) {
    return 'https://develop-portal.helloello.com/onboarding';
  }

  return 'http://localhost:3000/onboarding';
}

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      textAlign: 'center',
    },
    title: {
      fontSize: '1.625rem',
      fontWeight: 700,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1.375rem',
      },
    },
    img: {
      maxWidth: '200px',
      marginBottom: theme.spacing(6),
    },
    msg: {
      fontSize: '1.25rem',
      fontWeight: 400,
      margin: `${theme.spacing(4)} auto 0 auto`,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
      },
    },
    link: {
      marginTop: theme.spacing(18),
      display: 'inline-block',
      padding: `${theme.spacing(2)} ${theme.spacing(6)}`,
      fontWeight: 800,
      fontSize: '1.125rem',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
      },
    },
  });

export default styles;

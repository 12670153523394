const CustomerSubscriptionStatus = {
  canceled: 'canceled',
  active: 'active',
  trialing: 'trialing',
  unpaid: 'unpaid',
  expired: 'expired',
};

export function isCanceledSubscription(status: string | undefined) {
  return CustomerSubscriptionStatus.canceled === status;
}

export function isActiveSubscription(status: string | undefined) {
  return [
    CustomerSubscriptionStatus.active,
    CustomerSubscriptionStatus.trialing,
  ].includes(status || '');
}

export function isCancellationStatus(status: string | undefined) {
  return [
    CustomerSubscriptionStatus.canceled,
    CustomerSubscriptionStatus.unpaid,
    CustomerSubscriptionStatus.expired,
  ].includes(status || '');
}

export function isTrialing(status: string | undefined) {
  return CustomerSubscriptionStatus.trialing === status;
}

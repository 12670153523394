import Box from '@mui/material/Box';
import Link from '@mui/material/Link';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {turquoise900} from '../../../shared/styles/palette';
import {desktopBreakpoint} from './BreakPoints';

const TryEllo: FC = () => {
  return (
    <Box
      mt={4}
      sx={{
        textAlign: 'center',
        [desktopBreakpoint]: {
          textAlign: 'left',
        },
      }}>
      <Box>
        <Typography sx={{fontWeight: 700}}>
          Want to bring this book to life?
        </Typography>
      </Box>
      <Box>
        <Typography
          sx={{
            fontWeight: 700,
            color: turquoise900,
            mt: 1,
          }}>
          <Link href="https://www.ello.com/" sx={{textDecoration: 'none'}}>
            Try Ello for Free!
          </Link>
        </Typography>
      </Box>
    </Box>
  );
};

export default TryEllo;

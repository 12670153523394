import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import * as palette from 'shared/styles/palette';
import {OnboardingFlowType} from '../../types/onboardingFlow.type';

// HACK HACK HACK: check if we are in the example books panel
const isExampleBooksPanel = props => {
  try {
    const page = props.location.query.p;
    const activeStep = props.flowSpec.steps[page];
    return activeStep.questions
      .map(question => question.type)
      .includes(OnboardingFlowType.exampleBooks);
  } catch (e) {
    return false;
  }
};

const isReferralLinkComponent = props => {
  try {
    const page = props.location.query.p;
    const activeStep = props.flowSpec.steps[page];
    return activeStep.questions
      .map(question => question.type)
      .includes(OnboardingFlowType.referralLink);
  } catch (e) {
    return false;
  }
};

// Used to remove padding around flow container when we are in the
// example books section or referral link component
const getFlowContainerPadding = (props, theme) => {
  let padding;
  try {
    const removePadding =
      isExampleBooksPanel(props) || isReferralLinkComponent(props);

    padding = removePadding ? theme.spacing(0) : theme.spacing(3);
  } catch (e) {
    padding = theme.spacing(3);
  }
  return padding;
};

// Used to set desktop width to 100% when in example books flow
const getFlowContainerDesktopWidth = props => {
  let width;
  try {
    width = isExampleBooksPanel(props) ? '100%' : '1000px';
  } catch (e) {
    width = '1000px';
  }
  return width;
};

// Used to remove question container padding when in example_books
// flow
const getQuestionContainerPadding = (props, theme) => {
  let padding;
  try {
    padding = isExampleBooksPanel(props) ? 0 : theme.spacing(4);
  } catch (e) {
    padding = theme.spacing(4);
  }
  return padding;
};

const styles = theme => {
  return {
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: '#fff',
    },
    layout: {
      // Mobile config
      maxWidth: '100%',
      marginLeft: 0,
      marginRight: 0,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        // Desktop config
        width: '1028px',
        // minWidth: '1028px', // Forcing ourselves on top of 4px Webflow margins
        maxWidth: '90%',
        marginLeft: 'auto',
        marginRight: 'auto',
      },
      textAlign: 'center',
    },
    questionTitle: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        width: '70%',
      },
      margin: '0 auto',
      lineHeight: 1.3,
    },
    paymentPanelTitle: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        textAlign: 'left',
        width: '100%',
        paddingLeft: theme.spacing(2),
      },
      textAlign: 'center',
    },
    titleContainer: {
      marginBottom: theme.spacing(2),
      justifySelf: 'left',
    },
    paymentTitleContainer: {
      justifyContent: 'left',
    },
    gridContainer: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
    },
    paper: {
      backgroundColor: '#fff',

      // Mobile config
      borderRadius: 0,
      marginTop: 0,
      marginBottom: 0,
      height: '100%',

      display: 'flex',
      flexDirection: 'column',
      boxShadow: 'none',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        // Desktop config
        borderRadius: '13px',
        marginTop: theme.spacing(6),
        marginBottom: theme.spacing(6),
        height: 'fit-content',
        minWidth: '0',
        // Match webflow homepage style
        boxShadow: '0 0 30px 0 rgba(17, 73, 79, 0.1)',
      },
    },
    flowContainer: {
      paddingLeft: props => getFlowContainerPadding(props, theme),
      paddingRight: props => getFlowContainerPadding(props, theme),
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(3),
      // Mobile config: spread out
      flexGrow: 1,
      overflowY: 'none',

      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        // Desktop config
        overflowY: 'auto',
        width: '100%',
        maxWidth: props => getFlowContainerDesktopWidth(props),
        margin: '0 auto',
      },
    },
    backButtonContainer: {
      paddingLeft: props => (isExampleBooksPanel(props) ? 38 : 0),
      display: 'flex',
      justifyContent: 'left',
      position: 'relative',
      marginBottom: -46, // because we are using relative position add this to remove vertical space introduced by element
      top: 16,
    },
    questionContainer: {
      // Mobile config
      padding: 0,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        // Desktop config
        paddingLeft: props => getQuestionContainerPadding(props, theme),
        paddingRight: props => getQuestionContainerPadding(props, theme),
      },
    },
    stepper: {
      // Mobile config
      borderRadius: 0,
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        // Desktop Config
        borderRadius: '10px 10px 0 0',
      },
      // General config
      padding: 0,
      marginBottom: 0,
      overflowX: 'scroll',
      // Hide scrollbars on IE/Edge
      '-ms-overflow-style': 'none',
      // Hide scrollbars on Chrome, Safari, etc.
      '&::-webkit-scrollbar': {
        display: 'none',
      },
      maxWidth: 'inherit',
      whiteSpace: 'nowrap',
    },
    stepLabel: {
      textTransform: 'uppercase',
      fontSize: '0.85rem',
      fontWeight: 700,

      // Only show on mobile
      display: 'block',
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        display: 'none',
      },
    },
    ctaNextButton: {
      paddingLeft: 20,
      paddingRight: 20,
      fontSize: 16,
      borderRadius: 50,
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(3),
      minWidth: 150,
      maxWidth: 300,
      fontWeight: 700,
      textTransform: 'none',
      lineHeight: 1.25,
      paddingTop: 15,
      paddingBottom: 15,
    },
    backButton: {
      backgroundColor: '#fafafa',
      color: '#a3a3a3',
    },
    thankYouBox: {
      padding: theme.spacing(6),
      minHeight: 300,
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
    },
    markdownStyles: {
      '& h1, h2, h3, h4, h5, h6': {
        color: palette.blue500,
      },
    },
    stepTitle: {
      fontSize: '19px',
      color: palette.turquoise900,
    },
    mobileStepperContainer: {
      paddingLeft: theme.spacing(7),
      paddingRight: theme.spacing(7),
      marginBottom: theme.spacing(2),
    },
    mobileStepNumber: {
      backgroundColor: palette.turquoise900,
      height: '36px',
      width: '36px',
      marginRight: theme.spacing(1),
      fontSize: '19px',
    },
    stepDetailsContainer: {
      marginTop: theme.spacing(2),
      marginBottom: theme.spacing(1),
    },
  };
};

export default styles;

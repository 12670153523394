import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {BORDER_RADIUS} from 'shared/styles/breakpoints';
import * as palette from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    tabContainer: {
      maxWidth: '625px',
      margin: `${theme.spacing(3)} 0`,
    },
    tabItem: {
      minWidth: 'auto',
      border: `1px solid ${palette.turquoise900}`,
      color: palette.turquoise900,
      fontSize: '1.125rem',
      textTransform: 'none',
      fontWeight: 700,
      '&:first-of-type': {
        borderRadius: `${BORDER_RADIUS / 2}px 0 0 ${BORDER_RADIUS / 2}px`,
        borderRightColor: 'transparent',
      },
      '&:last-of-type': {
        borderRadius: `0 ${BORDER_RADIUS / 2}px ${BORDER_RADIUS / 2}px 0`,
        borderLeftColor: 'transparent',
      },
      '&:hover:not($selected)': {
        backgroundColor: palette.turquoise100,
      },
    },
    selected: {
      color: palette.white,
      backgroundColor: palette.turquoise900,
    },
  });

export default styles;

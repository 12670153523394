import TabPanel from '@mui/lab/TabPanel';
import {Box} from '@mui/material';
import React, {FC} from 'react';
import {useHistory} from 'react-router-dom';
import Alert from '../../../shared/components/common/Alert/Alert';
import Books from './Books';
import {useProfileBooks} from './ProfileBooks.service';
import useStyles from './ProfileBooks.styles';

type profileBooksProps = {
  profile: {id: string; name: string};
};

const ProfileBooks: FC<profileBooksProps> = ({profile}: profileBooksProps) => {
  const classes = useStyles();
  const {profileBooks, error} = useProfileBooks(profile.id);

  const history = useHistory();
  const handleClick = (bookId?: string) => {
    return () => history.push(`/account/reader/${profile.id}/books/${bookId}`);
  };

  return (
    <Box>
      <TabPanel
        key={profile.id}
        value={profile.id}
        className={classes.tabPanel}>
        <Books books={profileBooks} handleClick={handleClick} />
      </TabPanel>
      {error && (
        <Alert
          error={`Unable to retrieve books for ${profile.name}. Please check your internet connection and refresh the page.`}
          autoHide={false}
        />
      )}
    </Box>
  );
};

export default ProfileBooks;

import Box from '@mui/material/Box';
import React, {FC, useEffect} from 'react';
import {TrialChoice} from '../TrialSelection.types';
import Grid from '@mui/material/Grid';
import CTAOld from './CTAOld';
import ChoicesRadioGroup from './ChoicesRadioGroup';
import IOSSwitch from './IOSSwitch';
import {FormControlLabel, FormGroup} from '@mui/material';
import CTA from './CTA';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import useStyles from './Body.styles';
import {CTAVariant} from '../../ProductSelectionPanel/Product.types';
import {ClassNameMap} from '@mui/styles';
import featureFlags, {FEATURE_IDS} from '../../../utils/featureFlags';

type Props = {
  benefits: string[];
  choices: TrialChoice[];
  onSelected: (priceId: string) => void;
  body: string;
};

const Body: FC<Props> = ({choices, onSelected, body}) => {
  const classes = useStyles();
  const [choice, setChoice] = React.useState<TrialChoice>({
    ctaText: '',
    ctaVariant: CTAVariant.contained,
    priceId: '',
    selected: false,
  });
  const useUpdatedTrialPicker = featureFlags.isOn(
    FEATURE_IDS.USE_UPDATED_TRIAL_PICKER,
  );

  const handleValueChange = (choice: TrialChoice) => {
    setChoice(choice);
  };

  useEffect(() => {
    const selectedChoice = choices.find(choice => choice.selected);
    if (selectedChoice) {
      setChoice(selectedChoice);
    }
  }, [choices]);

  return (
    <Box
      sx={{
        mt: 2,
        height: '100%',
        position: 'relative',
        pb: 1,
      }}>
      {!useUpdatedTrialPicker && (
        <OriginalBodyLayout
          choices={choices}
          children={body}
          classes={classes}
          onSelected={onSelected}
        />
      )}

      {useUpdatedTrialPicker && (
        <>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <ChoicesRadioGroup
                choices={choices}
                onValueChange={handleValueChange}
              />
            </Grid>
          </Grid>

          <FormGroup
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}>
            <FormControlLabel
              labelPlacement="start"
              control={<IOSSwitch defaultChecked />}
              label="Remind me before my trial ends"
              sx={{
                margin: 1,
                gap: 2,
              }}
              componentsProps={{typography: {sx: {fontSize: '0.875rem'}}}}
            />
          </FormGroup>

          <ReactMarkdown
            rehypePlugins={[rehypeRaw as any]}
            children={body}
            className={classes.productBody}
          />

          <CTA
            onSelected={() => {
              onSelected(choice.priceId);
            }}
            ctaText={choice.ctaText}
          />
        </>
      )}
    </Box>
  );
};

function OriginalBodyLayout(props: {
  choices: TrialChoice[];
  children: string;
  classes: ClassNameMap;
  onSelected: (priceId: string) => void;
}) {
  return (
    <>
      <Grid container spacing={2}>
        {props.choices.map((choice, idx) => (
          <Grid key={idx} item xs={12} md={6}>
            <CTAOld
              onSelected={() => props.onSelected(choice.priceId)}
              ctaText={choice.ctaText}
              ctaVariant={choice.ctaVariant}
            />
          </Grid>
        ))}
      </Grid>

      <FormGroup
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}>
        <FormControlLabel
          labelPlacement="start"
          control={<IOSSwitch defaultChecked />}
          label="Remind me before my trial ends"
          sx={{
            margin: 1,
            gap: 2,
          }}
          componentsProps={{typography: {sx: {fontSize: '0.875rem'}}}}
        />
      </FormGroup>

      <ReactMarkdown
        rehypePlugins={[rehypeRaw as any]}
        children={props.children}
        className={props.classes.productBody}
      />
    </>
  );
}

export default Body;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';
import {white} from 'shared/styles/palette';

const styles = (theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      display: 'flex',
      justifyContent: 'space-evenly',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        display: 'block',
      },
    },
    content: {
      display: 'flex',
      flex: '1 1 500px',
      flexDirection: 'column',
      alignItems: 'center',
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
      },
    },
    title: {
      fontWeight: 700,
      fontSize: '1.625rem',
      marginBottom: theme.spacing(2),
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1.375rem',
        alignSelf: 'center',
      },
    },
    subtitle: {
      fontWeight: 400,
      fontSize: `1.125rem`,
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        fontSize: '1rem',
      },
    },
    progress: {
      margin: `${theme.spacing(5)} auto`,
    },
    sideContent: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: theme.spacing(2),
      flex: '0 1 670px',
    },
  });

export default styles;

export const useActionBarStyles = (theme: Theme) =>
  createStyles({
    actionBar: {
      backgroundColor: white,
      bottom: 0,
      top: 'auto',
      padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
    },
    buttonContainer: {
      [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
        maxWidth: '100%',
      },
    },
  });

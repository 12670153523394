import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';

const styles = (theme: Theme) =>
  createStyles({
    content: {
      flex: '0 1 580px',
      display: 'flex',
      alignItems: 'flex-start',
    },
    selectContainer: {
      width: '100%',
      display: 'flex',
      alignItems: 'baseline',
      margin: `${theme.spacing(2)} 0 ${theme.spacing(6)} 0`,
    },
    dropdown: {
      flex: '1 1 400px',
    },
    tooltip: {
      flex: '1 0 50px',
      cursor: 'pointer',
      marginLeft: theme.spacing(3),
    },
    button: {
      width: '100%',
    },
  });

export default styles;

import React, {useContext} from 'react';

export const Locales = {enUS: 'en-US', esES: 'es-ES', zhCN: 'zh-CN'};
export const DEFAULT_LOCALE = Locales.enUS;

/**
 * Identifies the unified locale based on the language passed in from
 * the query params and what we can get out of the browser, e.g. if they
 * switched their phone to Spanish. Defaults to US English.
 *
 * @param {String} queryLanguage: The language from the query params
 */
export function identifyLanguage(queryLanguage) {
  let locale = DEFAULT_LOCALE;
  let lang = null;

  // Order is
  // 1. Query param language passed in
  // 2. Subdomain
  // 3. Weglot integration
  // 4. Browser/OS language
  if (queryLanguage) lang = queryLanguage;
  else if (window.location.host.includes('es.')) lang = 'es';
  else if (window.location.host.includes('zh.')) lang = 'zh';
  else if (window.Weglot) {
    console.log('Fetching language from Weglot');
    lang = window.Weglot.getCurrentLang();
  } else {
    lang = navigator.language;
  }

  if (lang) {
    if (/^es\b/.test(lang)) {
      locale = Locales.esES;
    } else if (/^zh\b/.test(lang)) {
      locale = Locales.zhCN;
    }
  }

  return locale;
}

export const LocalizationContext = React.createContext(DEFAULT_LOCALE);

/**
 * Convenient hook wrapper
 */
export function useLocale() {
  const {locale, setLocale} = useContext(LocalizationContext);
  // Ensure that we always provide a defined locale (even in test env)
  if (!locale) return {locale: DEFAULT_LOCALE, setLocale: () => {}};
  return {locale, setLocale};
}

/**
 * Generic UI strings t
 */
export const STRINGS = {
  [Locales.enUS]: {
    next: 'Next',
    back: 'Back',
    confirm: 'Confirm',
    finish: 'Finish',
    name: 'Your Name',
    phoneNumber: 'Mobile Number',
    email: 'Email',
    invalidPhone: 'Invalid Phone Number',
    invalidEmail: 'Invalid Email',
    date: 'Date',
    fullNamePlease: 'Please Enter Full Name',
    other: 'Other',
    enterAddress: 'Street Address',
    thankYou: 'Thank you!',
    zipCode: 'ZIP Code',
    city: 'City',
    state: 'State',
    aptSuite: 'Address Line 2',
    addressFirstName: 'First Name',
    addressLastName: 'Last Name',
  },
  [Locales.esES]: {
    next: 'Adelante',
    back: 'Regresa',
    confirm: 'Confirmar',
    finish: 'Terminar',
    name: 'Su numbre',
    phoneNumber: 'Numero de Telefono',
    email: 'Correo electrónico',
    invalidPhone: 'Numero de telefono invalido',
    invalidEmail: 'Email inválido',
    date: 'Fecha',
    fullNamePlease: 'Nombre Completa por favor',
    other: 'Otro',
    enterAddress: 'Su dirección',
    thankYou: '¡Gracias!',
    zipCode: 'ZIP Code',
  },
  [Locales.zhCN]: {
    next: '下一步',
    back: '返回',
    confirm: '确定',
    finish: '完成',
    name: '名称',
    phoneNumber: '电话号码',
    email: '电子邮件',
    invalidPhone: '无效的电话号码',
    invalidEmail: '不合规电邮',
    date: '日期',
    fullNamePlease: '请输入全名',
    other: '其它',
    enterAddress: '你的地址',
    thankYou: '谢谢！',
    zipCode: 'ZIP Code',
  },
};

/**
 * Hook wrapper for getting localized strings
 */
export function useStrings() {
  const {locale} = useLocale();

  return STRINGS[locale];
}

import {gql} from '@apollo/client';

export const QUERY_PROFILES = gql`
  query Profiles {
    profiles {
      id
      name
    }
  }
`;

import React, {useState} from 'react';
import {FlowLayout} from 'customerPortal/components/Flow';
import {FormControl, FormHelperText, Typography} from '@mui/material';
import Button from 'shared/components/common/Button/Button';
import {ReactComponent as IconChevron} from 'shared/assets/icons/iconChevron.svg';
import FormRadioInput from 'customerPortal/components/Form/FormRadioInput/FormRadioInput';
import {containerStyles, optionStyles} from './SelectPlan.styles';
import {AddChildFlow, Form, Plan} from '../types';
import useMobileLayout from 'customerPortal/utils/useMobileLayout';
import {PlanOption, PLANS} from './SelectPlan.constants';
import {FlowState} from 'customerPortal/components/Flow/types';
interface OptionProps extends PlanOption {
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  isSelected: boolean;
}

const Option = ({
  value,
  Icon,
  title,
  price,
  description,
  onChange,
  isSelected,
}: OptionProps) => {
  const classes = optionStyles();

  return (
    <FormRadioInput
      className={classes.option}
      checkedClass={classes.selected}
      checked={isSelected}
      id={`select-plan-${value}`}
      name={`select-plan`}
      value={value}
      label={
        <>
          <div className={classes.header}>
            <div className={classes.headerContent}>
              <Icon className={classes.icon} aria-hidden />
              <div className={classes.label}>
                <Typography
                  data-testid="plan-label"
                  component="span"
                  variant="h6"
                  className={classes.title}>
                  {title}
                </Typography>
                <Typography
                  component="span"
                  variant="h6"
                  className={classes.price}>
                  {price}
                </Typography>
              </div>
            </div>
            <IconChevron className={classes.chevron} aria-hidden />
          </div>
          <Typography component="span" className={classes.desc}>
            {description}
          </Typography>
        </>
      }
      onChange={onChange}
    />
  );
};

const SelectPlan = ({
  formValues,
  setFlowState,
  setFormValues,
}: Pick<AddChildFlow, 'formValues' | 'setFlowState' | 'setFormValues'>) => {
  const classes = containerStyles();
  const [showError, setShowError] = useState<boolean>(false);
  const isMobile = useMobileLayout();

  const onSubmit = () => {
    setShowError(false);

    if (!formValues.plan) {
      setShowError(true);
      return;
    }

    setFlowState(FlowState.AddChild);
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setShowError(false);
    setFormValues((prev: Form) => ({
      ...prev,
      plan: e.target.value as Plan,
    }));

    if (isMobile) {
      setFlowState(FlowState.AddChild);
    }
  };

  return (
    <FlowLayout
      title={"Let's add a new reader"}
      cta={
        <>
          {!isMobile && (
            <Button type="button" onClick={onSubmit} className={classes.button}>
              Continue
            </Button>
          )}
        </>
      }>
      <form>
        <FormControl
          component="fieldset"
          error={showError}
          className={classes.fieldset}>
          <div className={classes.optionContainer}>
            {PLANS.map((option: PlanOption, i: number) => (
              <Option
                {...option}
                onChange={onChange}
                key={i}
                isSelected={option.value === formValues.plan}
              />
            ))}
          </div>
          {showError && (
            <FormHelperText error>Please select a plan</FormHelperText>
          )}
        </FormControl>
      </form>
    </FlowLayout>
  );
};

export default SelectPlan;

import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {gray700, turquoise700} from '../../../shared/styles/palette';

const useStyles = (theme: Theme) =>
  createStyles({
    container: {
      position: 'absolute',
      textAlign: 'center',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      padding: `0 ${theme.spacing(3)}`,
    },
    loginOptionButton: {
      color: turquoise700,
      width: '100%',
      marginTop: theme.spacing(2),
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    forgotPasswordButton: {
      color: turquoise700,
      '&:hover': {
        backgroundColor: 'transparent',
      },
      paddingLeft: 0,
    },
    title: {
      fontWeight: 700,
      marginBottom: theme.spacing(2),
    },
    subtitle: {
      fontWeight: 400,
      marginBottom: theme.spacing(5),
      color: gray700,
    },
    button: {
      marginTop: theme.spacing(5),
      width: '100%',
    },
  });

export default useStyles;

import createStyles from '@mui/styles/createStyles';
import makeStyles from '@mui/styles/makeStyles';
import {turquoise50} from '../../../styles/palette';
import {
  fullHorizontalBreakpoint,
  paddingBreakpoint,
  partialHorizontalBreakpoint,
} from '../BreakPoints';

function containerStyles(props: {isDigital: boolean}) {
  const styles = {
    backgroundColor: turquoise50,
    margin: '0 auto',
    padding: '16px',
    borderRadius: '16px',
    [paddingBreakpoint]: {
      padding: '24px',
    },
    [partialHorizontalBreakpoint]: {
      minWidth: '500px',
      maxWidth: '740px',
    },
  };

  if (props.isDigital) {
    return {
      ...styles,
      [fullHorizontalBreakpoint]: {
        margin: '0',
        marginLeft: '-16px',
        alignSelf: 'center',
        minWidth: '540px',
        minHeight: '340px',
      },
    };
  }

  return styles;
}

export default makeStyles(theme =>
  createStyles({
    container: containerStyles,
    qrlink: {
      [partialHorizontalBreakpoint]: {
        display: 'flex',
      },
    },
  }),
);

import {FormSelectItem} from 'customerPortal/components/Form/FormSelect/FormSelect';
import {Profile} from 'shared/types/profile';
import {ReadingLevel} from 'shared/types/readingLevels';

export const getReadingLevelOptions = (
  profiles: Profile[],
  recommendedLevel: ReadingLevel | null,
) => {
  const existingReadingLevels = profiles
    .map(p => p.readingLevel)
    .filter(Boolean);

  return Object.values(ReadingLevel).reduce((res, value) => {
    if (existingReadingLevels.includes(value)) {
      return res;
    }
    const isRecommendedLevel = recommendedLevel === value;
    res.push({
      value,
      label: `Level ${value} ${isRecommendedLevel ? '(Recommended)' : ''}`,
    });
    return res;
  }, [] as FormSelectItem[]);
};

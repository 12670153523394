import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import {useMutation} from '@apollo/client';
import CircularProgress from '@mui/material/CircularProgress';

import styles from './EditProfileScreen.styles';
import {useHistory, useParams} from 'react-router-dom';
import {
  removeProfileWithMutation,
  useProfile,
} from './EditProfileScreen.service';
import useMobileLayout from '../../../../utils/useMobileLayout';
import {CUSTOMER_REMOVE_PROFILE_MUTATION} from './EditProfileScreen.query';
import {PRIVATE_ROUTES} from '../../../../../shared/components/Route/routes';

interface props extends WithStyles<typeof styles> {
  onCloseModal?: () => void;
}

const RemoveProfileScreen = ({classes, onCloseModal}: props) => {
  const isMobile = useMobileLayout();
  const history = useHistory();
  const {profileId} = useParams<{profileId?: string}>();

  const {data} = useProfile(profileId);
  const profileName = data?.name;
  const headerProfileName = profileName ? `${profileName}'s` : 'This';

  const [customerRemoveProfileMutation, {loading}] = useMutation(
    CUSTOMER_REMOVE_PROFILE_MUTATION,
  );
  const removeProfile = removeProfileWithMutation(
    customerRemoveProfileMutation,
  );

  const closePageOrModal = () => {
    if (isMobile) {
      history.goBack();
      return;
    }
    onCloseModal && onCloseModal();
  };

  const onCancel = () => {
    closePageOrModal();
  };

  const onConfirm = async () => {
    profileId && (await removeProfile(profileId));
    onCloseModal && onCloseModal();
    history.push(PRIVATE_ROUTES.ACCOUNT_PROFILES.path);
  };

  return (
    <Box className={classes.removeProfileContainer}>
      <Box>
        <Box className={classes.removeProfileModalHeaderContainer}>
          <Typography className={classes.removeProfileHeader}>
            Remove {headerProfileName} profile?
          </Typography>
          {onCloseModal && (
            <IconButton>
              <CloseIcon onClick={onCloseModal} className={classes.closeIcon} />
            </IconButton>
          )}
        </Box>

        <Typography className={classes.removeProfileSubtitle}>
          Are you certain you want to remove {headerProfileName} profile? This
          action can’t be undone. All progress will be lost.
        </Typography>
      </Box>

      <Box className={classes.removeProfileActions}>
        <Button
          disabled={loading}
          className={classes.removeProfileConfirmButton}
          onClick={onConfirm}>
          Yes, remove
          {loading && (
            <CircularProgress
              size={20}
              style={{
                marginLeft: '1rem',
              }}
              color="inherit"
            />
          )}
        </Button>
        <Button
          className={classes.removeProfileCancelButton}
          onClick={onCancel}>
          Cancel
        </Button>
      </Box>
    </Box>
  );
};

export default withStyles(styles)(RemoveProfileScreen);

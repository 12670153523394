import React from 'react';

import Title from './AdjustReadingLevel.request-adjustment.title';
import {Typography} from '@mui/material';
import readingLevelAdjusted from 'shared/assets/readingLevelAdjusted.svg';

const RequestAdjustmentSuccessScreen = () => {
  return (
    <>
      <Title text="Reading level Adjusted" />
      <img src={readingLevelAdjusted} alt="" />
      <Typography
        variant="body1"
        mt={3}
        style={{
          fontWeight: 'bold',
          fontSize: '1rem',
          lineHeight: '1.5rem',
        }}>
        So excited to continue our reading adventures!
      </Typography>
    </>
  );
};

export default RequestAdjustmentSuccessScreen;

import EmailIcon from '../../../../shared/assets/email.svg';
import FacebookIcon from '../../../../shared/assets/facebook.svg';
import InstagramIcon from '../../../../shared/assets/instagram.svg';
import PinterestIcon from '../../../../shared/assets/pinterest.svg';
import TwitterIcon from '../../../../shared/assets/twitter.svg';
import CopyIcon from '../../../../shared/assets/Copy Link Icon.svg';
import {Destination, Icon} from './SocialMedia.types';
import {emailLink} from './Email';

function twitterLink(link: string) {
  return `https://twitter.com/intent/tweet?url=${encodeURIComponent(link)}`;
}

function pinterestLink(link: string) {
  return `https://pinterest.com/pin/create/button/?url=${encodeURIComponent(
    window.location.href,
  )}`;
}

function facebookLink(link: string) {
  const appId = '1209890710373553';
  const redirectUri = 'https://portal.helloello.com/books';
  return `https://www.facebook.com/dialog/share?app_id=${appId}&display=popup&href=${encodeURIComponent(
    link,
  )}&redirect_uri=${encodeURIComponent(redirectUri)}`;
}

function instagramLink(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      alert(
        'Link copied to clipboard! Open Instagram and paste the link to share.',
      );
    },
    err => {
      console.error('Could not copy text: ', err);
    },
  );
}

function copyToClipboard(text: string) {
  navigator.clipboard.writeText(text).then(
    () => {
      alert('Link copied to clipboard!');
    },
    err => {
      console.error('Could not copy text: ', err);
    },
  );
}

export function getSocialMediaIcons(
  link: string,
  destination: Destination,
): Icon[] {
  return [
    {
      src: EmailIcon,
      alt: 'Email this book',
      onClick: () => (window.location.href = emailLink(link, destination)),
    },
    {
      src: InstagramIcon,
      alt: 'Share on Instagram',
      onClick: () => instagramLink(link),
    },
    {
      src: FacebookIcon,
      alt: 'Share on Facebook',
      onClick: () =>
        window.open(
          facebookLink(link),
          'facebook-share-dialog',
          'width=800,height=600',
        ),
    },
    {
      src: TwitterIcon,
      alt: 'Tweet this book',
      onClick: () => window.open(twitterLink(link)),
    },
    {
      src: PinterestIcon,
      alt: 'Pin this book',
      onClick: () => window.open(pinterestLink(link)),
    },
    {
      src: CopyIcon,
      alt: 'Copy link to share',
      onClick: () => copyToClipboard(link),
    },
  ];
}

import {isProduction} from './environments';

const slackMakeHook =
  'https://hook.us1.make.com/xwfeqig3bq15j6ek7r1k081wxeowwwpt';

export enum SlackMessageTypes {
  OnboardingError = 'onboarding-error',
  DeleteAccount = 'delete-account',
  RenewSubscription = 'renew-subscription',
  GiftReceiverExists = 'gift-receiver-exists',
}

/**
 * Sends a message to Slack using a webhook.
 *
 * @param {string} type - The type of message to send that is configured on Make (e.g., 'onboarding-error', etc.).
 * @param {string} message - The message content to send.
 */
export function sendSlackMessage(type: string, message: string) {
  // Only send messages to Slack in production
  if (!isProduction()) {
    return;
  }

  // Construct payload object
  const payload = {
    type: type,
    data: {
      message,
    },
  };

  // Send payload to Slack webhook
  fetch(slackMakeHook, {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(payload),
  });
}

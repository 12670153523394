import createStyles from '@mui/styles/createStyles';
import {Theme} from '@mui/material/styles';
import {turquoise900, gray300, gray900, gray} from 'shared/styles/palette';
const styles = (theme: Theme) =>
  createStyles({
    profileIcon: {
      display: 'flex',
      alignItems: 'center',
    },
    profileName: {
      marginLeft: theme.spacing(1),
      color: turquoise900,
    },
    tooltipProfileName: {
      borderBottom: `1px solid ${gray300}`,
      '&:last-of-type': {
        borderBottom: 'transparent',
      },
      color: gray900,
      textAlign: 'center',
    },
    background: {
      backgroundColor: gray,
      width: 44,
      height: 44,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '16px',
    },
    boxShadow: {
      boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.1)',
    },
  });

export default styles;

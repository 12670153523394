import {Radio} from '@mui/material';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import clsx from 'clsx';
import React, {FC} from 'react';
import ReactMarkdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import {useWindowSize} from '../../../utils/layoutHooks';
import ExtraInfoTooltip from '../ExtraInfoTooltip';
import {Product} from '../Product.types';
import ProductDescription from '../ProductDescription';
import ProductImage from '../ProductImage';
import useStyles from '../ProductSelectionPanel.styles';
import {getPillColor} from './Choices.service';

type props = {
  choices: Product[];
  selectedProduct: string;
  onSelectProduct: (priceId: string) => void;
};

const InitialChoices: FC<props> = ({
  choices,
  selectedProduct,
  onSelectProduct,
}) => {
  const classes = useStyles();

  const [windowWidth] = useWindowSize();
  const isDesktop = windowWidth > 430;

  return (
    <Box>
      <Grid container spacing={3} justifyContent="center">
        {choices.map((product, idx) => (
          // Render Each product as a grid item with a radio button on the left and a pill at the top right
          <Grid key={idx} item xs={12} sm={6} md={4}>
            <Box
              onClick={() => onSelectProduct(product.priceId)}
              component="div"
              className={clsx(
                classes.productBox,
                selectedProduct === product.priceId &&
                  classes.productBoxSelected,
              )}>
              {/* Right-aligned product pill */}
              {product.pill ? (
                <Box
                  display="flex"
                  justifyContent="flex-end"
                  alignItems="center">
                  <Typography
                    variant="body2"
                    className={classes.productPillText}
                    style={{backgroundColor: getPillColor(product.pillColor)}}>
                    {product.pill}
                  </Typography>{' '}
                </Box>
              ) : (
                <Box className={classes.pillPlaceholder} />
              )}
              {product.productImage && isDesktop && (
                <ProductImage
                  productImage={product.productImage}
                  title={product.title}
                  height={80}
                />
              )}
              <Box display="flex" alignItems="center">
                <Radio
                  color="default"
                  key={idx}
                  checked={selectedProduct === product.priceId}
                  onChange={() => onSelectProduct(product.priceId)}
                  inputProps={{'aria-label': product.title}}
                />
                <Typography
                  variant="h5"
                  className={clsx(
                    classes.productTitle,
                    selectedProduct === product.priceId &&
                      classes.productTitleSelected,
                  )}>
                  {product.title}
                  {product.extrainfo && (
                    <ExtraInfoTooltip text={product.extrainfo} />
                  )}
                </Typography>
                {product.productImage && !isDesktop && (
                  <ProductImage
                    productImage={product.productImage}
                    title={product.title}
                    height={60}
                  />
                )}
              </Box>
              {product.description && (
                <ProductDescription description={product.description} />
              )}
              <ReactMarkdown
                rehypePlugins={[rehypeRaw as any]}
                children={product.body}
                className={classes.productBody}
              />
            </Box>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
export default InitialChoices;

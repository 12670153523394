import Box from '@mui/material/Box';
import React, {FC, useState} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import Book from './Book';
import {useExternalBook} from './Book.service';

const ExternalBook: FC = () => {
  const {bookId, page} = useParams<{bookId: string; page: string}>();
  const pageInt = isNaN(parseInt(page)) ? 0 : parseInt(page);

  const [pageNumber, setPageNumber] = useState(pageInt || 0);

  const {book, error} = useExternalBook(bookId);

  const history = useHistory();
  const goBack = () => {
    return history.push(`/books`);
  };

  const onFinish = () => {
    return history.push(`/books/${bookId}/finish`);
  };

  return (
    <Box mt={9}>
      <Book
        book={book}
        error={error}
        pageNumber={pageNumber}
        goBack={goBack}
        setPageNumber={setPageNumber}
        onFinish={onFinish}
      />
    </Box>
  );
};

export default ExternalBook;

import React from 'react';

import Title from './AdjustReadingLevel.request-adjustment.title';
import Text from './AdjustReadingLevel.request-adjustment.text';
import ButtonContainer from './AdjustReadingLevel.request-adjustment.button-container';
import {ScreenState} from './AdjustReadingLevel.types';

type Props = {
  profileName?: string;
  profileId?: string;
  updateScreenState: (screenState: ScreenState) => void;
};

const RequestAdjustmentScreen = ({
  profileName,
  profileId,
  updateScreenState,
}: Props) => {
  return (
    <>
      <Title
        text={`How would you like to adjust ${profileName}’s reading level?`}
      />
      <Text />
      <ButtonContainer
        updateScreenState={updateScreenState}
        profileId={profileId}
      />
    </>
  );
};

export default RequestAdjustmentScreen;

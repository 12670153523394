import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {useHistory, useParams} from 'react-router-dom';
import {turquoise900} from '../../../shared/styles/palette';
import {useExternalBook} from './Book.service';
import {desktopBreakpoint} from './BreakPoints';
import CoverPhoto from './CoverPhoto';
import ReturnToLibrary from './ReturnToLibrary';
import SocialMediaLinks from './SocialMedia/SocialMediaLinks';
import TitleAndAuthor from './TitleAndAuthor';
import TryEllo from './TryEllo';
import {Destination} from './SocialMedia/SocialMedia.types';

const ExternalStartBook: FC = () => {
  const {bookId} = useParams<{bookId: string}>();
  const {book} = useExternalBook(bookId);

  const history = useHistory();
  const readBook = () => {
    history.push(`/books/${bookId}`);
  };

  const shareLink = `https://portal.helloello.com/books/${bookId}/start`;

  return (
    <Box mt={12}>
      <Box
        mx="auto"
        sx={{
          width: '100%',
          maxWidth: '480px',
          [desktopBreakpoint]: {
            maxWidth: 'none',
          },
        }}>
        <ReturnToLibrary />
        <Box
          sx={{
            [desktopBreakpoint]: {
              display: 'flex',
            },
          }}>
          <Box
            sx={{
              [desktopBreakpoint]: {
                width: '45%',
                maxWidth: '480px',
              },
            }}>
            <CoverPhoto book={book} />
            <Box
              sx={{
                display: 'flex',
                alignItems: 'stretch',
                justifyContent: 'center',
              }}>
              <SocialMediaLinks
                destination={Destination.startBook}
                link={shareLink}
              />
            </Box>
          </Box>
          <Box
            sx={{
              [desktopBreakpoint]: {
                width: '55%',
                pl: 4,
              },
            }}>
            <TitleAndAuthor book={book} />
            <Box
              sx={{
                width: '280px',
                mt: 3,
                mx: 'auto',
                [desktopBreakpoint]: {
                  mx: 0,
                  width: '211px',
                },
              }}>
              <Button
                fullWidth
                variant="contained"
                onClick={readBook}
                sx={{
                  backgroundColor: turquoise900,
                  borderRadius: '50px',
                  py: '14px',
                }}>
                <Typography sx={{fontWeight: 800, fontSize: '1rem'}}>
                  Read Me
                </Typography>
              </Button>
            </Box>
            <TryEllo />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ExternalStartBook;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CheckIcon from '@mui/icons-material/Check';

const ElloAppSubscription = ({
  titleString,
  priceString,
  features,
  trialPeriodDays,
  chargeableTrialAmount,
  classes,
}) => {
  const trialAmount = chargeableTrialAmount
    ? `${chargeableTrialAmount}.00`
    : '0.00';
  const price = trialPeriodDays ? trialAmount : priceString;

  return (
    <Box>
      <Box display="flex" justifyContent="space-between">
        <Box>
          <Typography className={classes.pricingLabel} align="left">
            {titleString}
          </Typography>
        </Box>
        <Box>
          <Typography className={classes.pricing} align="right">
            ${price}
          </Typography>
        </Box>
      </Box>
      <Box>
        <Box className={classes.subscriptionDetailsList}>
          {features &&
            features.map(item => {
              return (
                <Box className={classes.subscriptionDetailItem}>
                  <CheckIcon size="small" className={classes.checkIcon} />
                  <Typography
                    component="p"
                    align="left"
                    className={classes.subscriptionDetails}>
                    {item}
                  </Typography>
                </Box>
              );
            })}
        </Box>
      </Box>
    </Box>
  );
};

export default ElloAppSubscription;

import React from 'react';
import {Box} from '@mui/material';

type Props = {children: React.ReactNode};

const ImageSection: React.FC<Props> = ({children}) => (
  <Box sx={{display: 'flex', justifyContent: 'center', height: '100%'}}>
    {children}
  </Box>
);

export default ImageSection;

import gql from 'graphql-tag';

export const PROFILE_QUERY = gql`
  query Profile($profileId: ID!) {
    profile(profileId: $profileId) {
      id
      name
      readingLevel
      profileCharacter
      requestedLevelAdjustmentAt
    }
  }
`;

export const CUSTOMER_UPDATE_PROFILE_MUTATION = gql`
  mutation CustomerUpdateProfile(
    $profileId: ID!
    $input: CustomerUpdateProfileInput!
  ) {
    customerUpdateProfile(profileId: $profileId, input: $input) {
      profiles {
        name
        profileCharacter
      }
    }
  }
`;

export const CUSTOMER_REMOVE_PROFILE_MUTATION = gql`
  mutation CustomerRemoveProfile($profileId: ID!) {
    customerRemoveProfile(profileId: $profileId) {
      profiles {
        deletedAt
      }
    }
  }
`;

import React from 'react';
import {WithStyles} from '@mui/styles';
import withStyles from '@mui/styles/withStyles';
import styles from './ResetPasswordScreen.styles';
import BasicForm from './ResetPasswordScreen.form';
import ReturnToLoginButton from './ResetPasswordScreen.login-button';
interface props extends WithStyles<typeof styles> {
  email: string;
}

const RequestConfirmation = ({email, classes}: props) => {
  return (
    <BasicForm
      titleText="Check your email!"
      subtitleText={
        <>
          We've sent an email to <b>{email}</b> with a link to create a new
          password. When you're done updating your password, you can return to
          the login page.
        </>
      }>
      <ReturnToLoginButton />
    </BasicForm>
  );
};

export default withStyles(styles)(RequestConfirmation);

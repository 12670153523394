import makeStyles from '@mui/styles/makeStyles';

export default makeStyles(theme => ({
  root: {
    marginBottom: theme.spacing(3),
  },
  languageButton: {
    width: 100,
  },
}));

import React from 'react';
import {Box, useTheme} from '@mui/material';
import Lottie from 'react-lottie-player';
import elloSuccess from 'shared/assets/lottie/elloSuccess.json';
import ImageSection from '../Sections/ImageSection';
import BodySection from '../Sections/BodySection';
import ActionsSection from '../Sections/ActionsSection';
import ContainerWrapper from '../Sections/ContainerWrapper';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

type Props = {
  onClose: () => void;
};

const ConfirmationScreen: React.FC<Props> = ({onClose}) => {
  const theme = useTheme();
  return (
    <ContainerWrapper
      sx={{
        [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
          padding: '40px 60px 40px 60px',
          minHeight: '580px',
        },
      }}
      showCancellationTitle={false}>
      <Box>
        <Box height="298px">
          <ImageSection>
            <Lottie loop animationData={elloSuccess} play />
          </ImageSection>
        </Box>
        <BodySection title="Great, you have a free month applied to your account!" />
      </Box>

      <ActionsSection
        acceptLabel="Done"
        cancelLabel="Continue Cancellation"
        onAccept={onClose}
      />
    </ContainerWrapper>
  );
};

export default ConfirmationScreen;

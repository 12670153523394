import {FunctionComponent, SVGProps} from 'react';
import {ReactComponent as IconAccount} from 'shared/assets/icons/iconAccount.svg';
import {ReactComponent as IconBook} from 'shared/assets/icons/iconBook.svg';
import {ReactComponent as IconProfiles} from 'shared/assets/icons/iconProfiles.svg';
import {ReactComponent as IconSubscription} from 'shared/assets/icons/iconSubscription.svg';
import {ReactComponent as IconBoxes} from 'shared/assets/icons/iconBoxes.svg';
import {PRIVATE_ROUTES} from 'shared/components/Route/routes';
import featureFlags, {FEATURE_IDS} from 'shared/utils/featureFlags';
import {
  isDigitalSubscription,
  isReturningSubscription,
  supportsDigital,
} from '../../shared/utils/productType';

export interface Navigation {
  to: string;
  title: string;
  subtitle: string;
  Icon: FunctionComponent<SVGProps<SVGSVGElement>>;
  isEnabled: () => boolean;
  hasChipContent?: boolean;
}

export function getNavigationItems(customerProductType?: string) {
  const NAVIGATION_ITEMS: Navigation[] = [
    {
      to: PRIVATE_ROUTES.ACCOUNT_DETAILS.path,
      title: 'Account Details',
      subtitle: 'View email and phone number',
      Icon: IconAccount,
      isEnabled: () => true,
    },
    {
      to: PRIVATE_ROUTES.ACCOUNT_SUB.path,
      title: 'Subscription',
      subtitle: 'Adjust your subscription and payment method',
      Icon: IconSubscription,
      isEnabled: () => featureFlags.isOn(FEATURE_IDS.CUSTOMER_PORTAL_SUB),
    },
    {
      to: PRIVATE_ROUTES.ACCOUNT_PROFILES.path,
      title: 'Profiles',
      subtitle: 'Edit or add child profiles and levels',
      Icon: IconProfiles,
      isEnabled: () =>
        featureFlags.isOn(FEATURE_IDS.PROFILES_SCREEN) &&
        isDigitalSubscription(customerProductType),
    },
    booksNavigation(customerProductType),
    {
      to: PRIVATE_ROUTES.ACCOUNT_BOOKS_CURRENT.path,
      title: 'Boxes',
      subtitle: 'Manage your monthly shipments',
      Icon: IconBoxes,
      isEnabled: () => supportsDigital(customerProductType),
      hasChipContent: isReturningSubscription(customerProductType),
    },
  ];

  return NAVIGATION_ITEMS;
}

function booksNavigation(customerProductType?: string) {
  if (supportsDigital(customerProductType)) {
    return {
      to: PRIVATE_ROUTES.ACCOUNT_READER.path,
      title: 'Books',
      subtitle: 'Read your books online',
      Icon: IconBook,
      isEnabled: () => true,
    };
  }
  return {
    to: PRIVATE_ROUTES.ACCOUNT_BOOKS_CURRENT.path,
    title: 'Books',
    subtitle: 'Manage your active and upcoming bundles, view reading history',
    Icon: IconBook,
    isEnabled: () => !supportsDigital(customerProductType),
    hasChipContent: isReturningSubscription(customerProductType),
  };
}

import Button from './Button';

export enum ButtonType {
  Primary = 'contained',
  Secondary = 'outlined',
  Inline = 'text',
  Icon = 'icon',
}

export default Button;

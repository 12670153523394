import * as palette from 'shared/styles/palette';
import {TABLET_BREAKPOINT, LAPTOP_BREAKPOINT} from 'shared/styles/breakpoints';

const styles = theme => ({
  couponField: {
    '& input': {
      textTransform: 'uppercase',
    },
    [`& fieldset`]: {
      borderRadius: 8,
      borderColor: palette.gray300,
    },
    '& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
      borderWidth: '1px',
    },
    '& .MuiOutlinedInput-adornedEnd': {
      paddingRight: 0,
    },
  },
  tagImage: {
    height: '100px',
    marginBottom: '20px',
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
  redeemCode: {
    cursor: 'pointer',
    marginTop: '10px',
    fontWeight: 700,
    color: palette.turquoise900,
    fontSize: '16px',
    lineHeight: '22.4px',
  },
  moneyBackBox: {
    marginTop: '16px',
  },
  terms: {
    marginTop: '10px',
    '& a': {
      fontWeight: 800,
      textDecoration: 'none',
      color: theme.palette.primary.dark,
    },
  },
  couponContainer: {
    margin: '0 auto',
    marginTop: '10px',
  },
  successCoupon: {
    color: palette.green,
    fontWeight: 550,
    fontSize: '13px',
    paddingLeft: '30px',
  },
  leftPaddingOff: {
    paddingLeft: 0,
  },
  successCouponDiscount: {
    color: palette.green,
    fontWeight: 550,
    fontSize: '18px',
    verticalAlign: 'center',
  },
  stripeSectionContainer: {
    [theme.breakpoints.down(LAPTOP_BREAKPOINT)]: {
      marginTop: '30px',
    },
  },
  root: {
    flexGrow: 1,
  },
  section: {
    flexGrow: 1,
  },
  sectionLeft: {
    [theme.breakpoints.up(950)]: {
      paddingRight: theme.spacing(5),
    },
  },
  container: {
    padding: theme.spacing(2),
    paddingTop: 0,
  },
  sectionRight: {
    [theme.breakpoints.up(950)]: {
      paddingLeft: theme.spacing(5),
    },
  },
  rightColumnInnerContainer: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
  },
  subscriptionContainer: {
    [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
      paddingTop: '50px',
    },
  },
  testimonialSection: {
    backgroundColor: palette.gray100,
    borderRadius: '20px',
    padding: 0,
    marginTop: '30px',
  },
  testimonial: {
    fontFamily: 'Muli, Regular',
    fontSize: '14px',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(3),
    paddingBottom: theme.spacing(1),
    textAlign: 'left',
  },
  quotationMark: {
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(3),
    maxWidth: theme.spacing(6),
  },
  rating: {
    paddingLeft: theme.spacing(1),
    paddingBottom: theme.spacing(3),
    display: 'flex',
    justifyContent: 'left',
    alignItems: 'center',
  },
  stars: {
    paddingRight: theme.spacing(2),
  },
  subscriptionDetails: {
    fontSize: '12.3px',
  },
  subscriptionDetailsList: {
    paddingLeft: '20px',
    margin: 0,
    marginBottom: '10px',
  },
  checkIcon: {
    fontSize: '15px',
    marginRight: theme.spacing(1),
  },
  subscriptionDetailItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  lineBreak: {
    width: '100%',
    backgroundColor: palette.gray100,
    height: '1px',
    borderWidth: 0,
  },
  total: {
    fontSize: '18px',
    fontWeight: '550',
  },
  pricing: {
    fontSize: '18px',
    fontWeight: '550',
  },
  pricingLabel: {
    fontWeight: '400',
    fontSize: '18px',
    textTransform: 'capitalize',
  },
  promoApplied: {
    fontWeight: '550',
    fontSize: '18px',
    textTransform: 'capitalize',
    color: palette.green,
  },
  paymentButton: {
    width: '100%',
    borderRadius: 30,
    paddingTop: '12px',
    marginTop: '20px',
    paddingBottom: '12px',
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      marginTop: '40px',
    },
  },
  stripeBadge: {
    width: '120px',
  },
  safeAndSecureText: {
    fontSize: '12px',
    paddingLeft: '8px',
  },
  stripeBadgeContainer: {
    marginTop: '30px',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
  },
  stripeBadgeInnerContainer: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
  },
  contactUs: {
    paddingTop: '30px',
    paddingLeft: '24px',
    paddingRight: '24px',
    fontSize: '12px',
    '& a': {
      fontWeight: 800,
      textDecoration: 'none',
      color: palette.gray900,
      whiteSpace: 'nowrap',
    },
    '& span': {
      fontWeight: 800,
      textDecoration: 'none',
      color: palette.gray900,
      whiteSpace: 'nowrap',
    },
    [theme.breakpoints.down(TABLET_BREAKPOINT)]: {
      fontSize: '14px',
    },
  },
  couponTextFieldIconButton: {
    padding: 5,
  },
  couponProgress: {
    width: 20,
    margin: 5,
  },
});

export default styles;

import React from 'react';
import cN from 'classnames';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/swiper-bundle.min.css';
import 'swiper/swiper.min.css';
import SwiperCore, {Scrollbar} from 'swiper';
import {LAPTOP_BREAKPOINT} from 'shared/styles/breakpoints';
import {Typography} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import {horizontalBookListStyles} from './BookBrowseScreen.styles';
import {BookOrder, ProfileReadingLevel} from 'shared/types/purchases';
import BookOrdersProfileViewer from 'customerPortal/components/BookOrdersProfileViewer/BookOrdersProfileViewer';
import BookOrderStatusChip from 'customerPortal/components/StatusChip/BookOrderStatusChip';
SwiperCore.use([Scrollbar]);

interface Props extends WithStyles<typeof horizontalBookListStyles> {
  books: BookOrder[];
  profileObjects: ProfileReadingLevel[];
}

const HorizontalBookList = ({classes, books, profileObjects}: Props) => {
  return (
    <Swiper
      grabCursor
      scrollbar
      slidesPerView={1}
      spaceBetween={5}
      breakpoints={{
        [LAPTOP_BREAKPOINT]: {
          slidesPerView: 3,
        },
      }}>
      {books.map((item: BookOrder) => (
        <SwiperSlide key={item.isbn} className={classes.slide}>
          <div key={item.isbn} className={classes.slideItem}>
            <div className={classes.divImg}>
              <img src={item.coverPhotoURL} alt="" className={classes.img} />
            </div>

            <Typography
              component="h1"
              variant="h6"
              className={cN(classes.text, classes.title)}>
              {item.title}
            </Typography>
            <Typography
              component="p"
              className={cN(classes.text, classes.author)}>
              By {item.author}
            </Typography>
            <BookOrderStatusChip
              status={item.status}
              containerClass={classes.statusContainer}
            />
            <BookOrdersProfileViewer
              showText
              profileObjects={profileObjects}
              containerClass={classes.profileViewer}
            />
          </div>
        </SwiperSlide>
      ))}
    </Swiper>
  );
};

export default withStyles(horizontalBookListStyles)(HorizontalBookList);

import Tooltip from '@mui/material/Tooltip';
import withStyles from '@mui/styles/withStyles';
import * as palette from 'shared/styles/palette';

export const LightTooltip = withStyles(theme => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: palette.gray900,
    fontSize: '0.875rem',
    boxShadow: theme.shadows[1],
    padding: '16px',
  },
}))(Tooltip);

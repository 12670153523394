import Box from '@mui/material/Box';
import React, {FC} from 'react';
import logoEllo from 'shared/assets/logoEllo.svg';

const ExternalLogo: FC = () => {
  return (
    <Box>
      <img src={logoEllo} alt="Ello logo" width="54px" />
    </Box>
  );
};

export default ExternalLogo;

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {blue500} from '../../../../shared/styles/palette';

const firstBreakPoint = '@media (min-width: 600px)';

const CategoriesBanner: FC = () => {
  return (
    <Box>
      <Box sx={{mt: 4}}>
        <Typography
          sx={{
            fontSize: '1.625rem',
            fontWeight: 800,
            textAlign: 'center',
            color: blue500,
            [firstBreakPoint]: {
              fontSize: '2.25rem',
            },
          }}>
          Explore Ello reading sets.
        </Typography>
      </Box>
    </Box>
  );
};

export default CategoriesBanner;

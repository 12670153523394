import React from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';

import styles from './AddProfileScreen.styles';
import ProfileCharacters from '../ProfilesScreen.characters';
import {ProfileCharacter} from '../EditProfile/EditProfileScreen.service';
import {addedProfileType} from '../ProfilesScreen.types';

interface props extends WithStyles<typeof styles> {
  selectedProfileCharacter: ProfileCharacter;
  setProfileBeingAdded: React.Dispatch<React.SetStateAction<addedProfileType>>;
}

const AddProfileCharacter = ({
  classes,
  selectedProfileCharacter,
  setProfileBeingAdded,
}: props) => {
  const setSelectedProfileCharacter = (character: ProfileCharacter) => {
    setProfileBeingAdded((profile: addedProfileType) => ({
      ...profile,
      profileCharacter: character,
    }));
  };

  return (
    <Box>
      <Typography className={classes.titleHeader}>
        Choose a character for your child profile
      </Typography>
      <ProfileCharacters
        selectedProfileCharacter={selectedProfileCharacter}
        setSelectedProfileCharacter={setSelectedProfileCharacter}
      />
    </Box>
  );
};

export default withStyles(styles)(AddProfileCharacter);

export type Product = {
  benefits: string[];
  body: string;
  ctaFinePrint: string;
  ctaText: string;
  ctaVariant: CTAVariant;
  description: string;
  extrainfo: string;
  pill: string;
  pillColor: string;
  previousBenefitsText: string;
  priceId: string;
  productImage: string;
  title: string;
  goToStep: number;
};

export enum CTAVariant {
  contained = 'contained',
  outlined = 'outlined',
}

const CTAVariantMap: Record<string, CTAVariant> = {
  contained: CTAVariant.contained,
  outlined: CTAVariant.outlined,
};

export function stringToCTAVariant(ctaVariant: string): CTAVariant {
  if (!ctaVariant) {
    return CTAVariant.contained;
  }

  return CTAVariantMap[ctaVariant] || CTAVariant.contained;
}

export function isCTAVariantContained(ctaVariant: CTAVariant): boolean {
  return ctaVariant === CTAVariant.contained;
}

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import React, {FC} from 'react';
import {blue500, gray500, turquoise50} from '../../../../shared/styles/palette';

type Props = {
  grade?: string;
  option: {label: string; value: string};
  handleGradeChange: (grade: string) => void;
};

const UnselectedGrade: FC<Props> = ({option, handleGradeChange}: Props) => {
  return (
    <Button
      variant="text"
      onClick={() => handleGradeChange(option.value)}
      sx={{textTransform: 'none', px: 3, py: 1}}>
      <Typography sx={{fontSize: '1.125rem', fontWeight: 700, color: gray500}}>
        {option.label}
      </Typography>
    </Button>
  );
};

const SelectedGrade: FC<Props> = ({option, handleGradeChange}: Props) => {
  return (
    <Button
      variant="contained"
      onClick={() => handleGradeChange(option.value)}
      sx={{
        textTransform: 'none',
        px: 3,
        py: 1,
        backgroundColor: turquoise50,
        borderRadius: '16px',
        boxShadow: 'none',
        '&:hover': {
          backgroundColor: turquoise50,
          boxShadow: 'none',
        },
      }}>
      <Typography sx={{fontSize: '1.125rem', fontWeight: 700, color: blue500}}>
        {option.label}
      </Typography>
    </Button>
  );
};

const FilterGrade: FC<Props> = ({option, grade, handleGradeChange}: Props) => {
  const gradeSelected = grade === option.value;
  const gradeExists = !!grade;

  const defaultGrade = option.value === 'all' && !gradeExists;
  const selected = gradeExists && gradeSelected;
  const unselected = !defaultGrade && !gradeSelected;

  return (
    <Box>
      {defaultGrade && (
        <SelectedGrade option={option} handleGradeChange={handleGradeChange} />
      )}
      {selected && (
        <SelectedGrade option={option} handleGradeChange={handleGradeChange} />
      )}
      {unselected && (
        <UnselectedGrade
          option={option}
          handleGradeChange={handleGradeChange}
        />
      )}
    </Box>
  );
};

export default FilterGrade;

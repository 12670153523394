import {FormSelectItem} from 'customerPortal/components/Form/FormSelect/FormSelect';
import {Profile} from 'shared/types/profile';
import {ReadingLevel} from 'shared/types/readingLevels';

export const isMatchingAnyExistingLevels = (
  existingProfiles: Profile[],
  newReaderLevel: ReadingLevel,
): boolean => {
  const validReadingLevels = existingProfiles
    .map(p => p.readingLevel)
    .filter(Boolean);
  return new Set(validReadingLevels).has(newReaderLevel);
};

export const getSelectInitialValue = (
  readingLevel: ReadingLevel | null,
  options: FormSelectItem[],
): string => {
  const matchingOption = options.filter(o => o.value === readingLevel)[0]
    ?.value;

  return matchingOption || '';
};

/**
 * APIValidationActionPanel is a panel that should be used to invoke
 * validation process that need to be performed as an onboarding step.
 * performAction() gets called when the next button is pressed on an
 * APIValidationActionPanel and will perform custom logic based on the
 * `action` prop before calling goToNextStep()
 */

import React from 'react';
import withStyles from '@mui/styles/withStyles';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import {withApollo} from '@apollo/client/react/hoc';
import {collectQueryParams} from 'shared/utils/queryParams';
import {LocalizationContext} from 'shared/utils/localization';
import {VALIDATE_COUPON} from './APIValidationActionPanel.query';
import styles from './APIValidationActionPanel.styles';

const Action = {
  VALIDATE_COUPON: 'validate-coupon',
};

class APIValidationActionPanel extends React.Component {
  state = {
    loading: false,
    error: null,
    errorVisible: false,
  };

  /**
   * Triggers API call
   */
  performAction = () => {
    this.setState({loading: true});

    // Capture all collected params
    const params = collectQueryParams();

    // Capture user UID
    // (bubble-wrap for ad blocker)
    let anonymousId;
    try {
      anonymousId = window.analytics.user().anonymousId();
    } catch (e) {
      console.warn(e);
    }
    params.uid = anonymousId;

    // Capture locale
    params.locale = this.locale;

    if (this.props.action === Action.VALIDATE_COUPON) {
      if (!params.coupon) {
        // Coupon unspecified
        this.setState({loading: false});

        this.setState({
          error: 'Coupon code must be specified.',
          errorVisible: true,
        });
      } else {
        this.props.client
          .query({
            query: VALIDATE_COUPON,
            variables: {coupon: params.coupon},
          })
          .then(({data}) => {
            console.log(data);
            this.setState({loading: false});
            this.props.goToNextStep();
          })
          .catch(error => {
            this.setState({loading: false});

            if (error.toString().includes('Coupon Expired')) {
              this.setState({
                error: 'Coupon has expired',
                errorVisible: true,
              });
            } else {
              this.setState({
                error:
                  'Cannot validate coupon. Text us at (415) 214-8119 if you think this is incorrect.',
                errorVisible: true,
              });
            }
          });
      }
    } else {
      // Action type not specified or invalid
      this.setState({
        error: 'Oops – something went wrong!',
        errorVisible: true,
      });
      console.log(`Error: '${this.props.action}' is not a valid submit action`);
    }
  };

  handleErrorClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.setState({errorVisible: false});
  };

  render() {
    const {classes} = this.props;

    return (
      <LocalizationContext.Consumer>
        {({locale}) => {
          // Keep track at the instance level
          this.locale = locale;
          return (
            <>
              <Backdrop className={classes.backdrop} open={this.state.loading}>
                <CircularProgress color="primary" />
              </Backdrop>

              <Snackbar
                open={this.state.errorVisible}
                onClose={this.handleErrorClose}
                autoHideDuration={3000}>
                <Alert onClose={this.handleErrorClose} severity="error">
                  {this.state.error}
                </Alert>
              </Snackbar>
            </>
          );
        }}
      </LocalizationContext.Consumer>
    );
  }
}

export default withStyles(styles)(
  withApollo(APIValidationActionPanel, {withRef: true}),
);

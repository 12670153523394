import React, {useState} from 'react';
import Section from '../../../../components/Section';
import Header from '../Components/Header';
import {useParams} from 'react-router-dom';
import {useProfile} from '../EditProfile/EditProfileScreen.service';
import {ScreenState} from './AdjustReadingLevel.types';
import RequestReceivedScreen from './AdjustReadingLevel.request-received.screen';
import RequestAdjustmentScreen from './AdjustReadingLevel.request-adjustment.screen';

const AdjustReadingLevel = () => {
  const {profileId} = useParams<{profileId?: string}>();
  const {loading, data, error} = useProfile(profileId);
  const profileName = data?.name;

  const [screenState, setScreenState] = useState<ScreenState>(
    ScreenState.RequestAdjustment,
  );
  const isRequestAdjustmentState =
    screenState === ScreenState.RequestAdjustment;
  const isRequestReceivedState = screenState === ScreenState.RequestReceived;

  return (
    <Section
      titleSection={<Header oldPath={profileName} currentPath="Adjust Level" />}
      loading={loading}
      error={error ? 'Error loading profile' : ''}>
      {isRequestAdjustmentState && (
        <RequestAdjustmentScreen
          profileName={profileName}
          updateScreenState={setScreenState}
          profileId={profileId}
        />
      )}

      {isRequestReceivedState && <RequestReceivedScreen />}
    </Section>
  );
};

export default AdjustReadingLevel;

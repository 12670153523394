/**
 * Back Navigation buttons for the onboarding flow.
 * Handle mobile and desktop layouting.
 *
 * Props:
 * - onBack: back button handler
 * - hideNextButton: optional to hide the next button if we want to render a different CTA
 *   or input isn't ready yet.
 */

import React, {useCallback, useEffect, useState} from 'react';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import {useWindowSize} from 'shared/utils/layoutHooks';
import useStyles from './OnboardingNavigator.styles';
import {Fade, IconButton} from '@mui/material';
import {TABLET_BREAKPOINT} from 'shared/styles/breakpoints';

function OnboardingBackButton({onBack}) {
  const classes = useStyles();
  const [windowWidth] = useWindowSize();
  const [isBackButtonVisible, setIsBackButtonVisible] = useState(true);

  const isDesktop = windowWidth > TABLET_BREAKPOINT;

  // Hide back button once we scroll past 30 pixels
  const listenToScroll = useCallback(() => {
    let heightToHideFrom = 30;
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop;

    if (winScroll > heightToHideFrom) {
      isBackButtonVisible && // to limit setting state only the first time
        setIsBackButtonVisible(false);
    } else {
      setIsBackButtonVisible(true);
    }
  }, [isBackButtonVisible]);

  useEffect(() => {
    window.addEventListener('scroll', listenToScroll);
    return () => window.removeEventListener('scroll', listenToScroll);
  }, [listenToScroll]);

  if (isDesktop) {
    // On Desktop, we use contained Next and Back buttons
    return (
      <>
        <IconButton
          onClick={onBack}
          className={classes.backButton}
          size="large">
          <ArrowBackIcon fontSize="large" />
        </IconButton>
      </>
    );
  }

  // On mobile, we use FABs
  return (
    <>
      {/* Add extra padding to the bottom of scrollable screen estate to make sure
        FAB doesn't cover content at the bottom. */}
      <div />
      <Fade in={isBackButtonVisible}>
        <IconButton onClick={onBack} className={classes.fabBack} size="large">
          <ArrowBackIcon fontSize="large" />
        </IconButton>
      </Fade>
    </>
  );
}

export default React.memo(OnboardingBackButton);

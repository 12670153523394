import React from 'react';
import {blockResubscriptionModalStyles as styles} from './AccountSubscription.styles';
import Modal from '@mui/material/Modal';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';
import BlockResubscriptionScreen from './AccountSubscription.block-resub.screen';

type props = {
  open: boolean;
  onCloseModal?: () => void;
};

const BlockResubscriptionModal = ({open, onCloseModal}: props) => {
  const classes = styles();
  return (
    <Modal open={open} onClose={onCloseModal}>
      <Box className={classes.box}>
        <Box className={classes.closeIconContainer}>
          <IconButton>
            <CloseIcon onClick={onCloseModal} />
          </IconButton>
        </Box>

        <BlockResubscriptionScreen onCloseModal={onCloseModal} />
      </Box>
    </Modal>
  );
};
export default BlockResubscriptionModal;

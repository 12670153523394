import gql from 'graphql-tag';

export const QUERY_CURRENT_SUBSCRIPTION = gql`
  query Subscription {
    subscription {
      description
      name
      price
      title
    }
    profiles {
      name
      id
    }
  }
`;

export const QUERY_STRIPE_PORTAL = gql`
  query CreateStripePortalSession($returnUrl: String) {
    createStripePortalSession(returnUrl: $returnUrl) {
      url
    }
  }
`;

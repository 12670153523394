import React from 'react';
import withStyles from '@mui/styles/withStyles';
import {WithStyles} from '@mui/styles';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import styles from './EditProfile/EditProfileScreen.styles';
import {
  ProfileCharacter,
  allProfileCharacters,
} from './EditProfile/EditProfileScreen.service';
import * as palette from 'shared/styles/palette';

interface props extends WithStyles<typeof styles> {
  selectedProfileCharacter: string;
  setSelectedProfileCharacter: (character: ProfileCharacter) => void;
}

const ProfileCharacters = ({
  classes,
  selectedProfileCharacter,
  setSelectedProfileCharacter,
}: props) => {
  return (
    <Box className={classes.profileCharactersContainer}>
      {allProfileCharacters.map(({image, name}, index) => (
        <IconButton
          key={index}
          className={classes.profilePicButton}
          onClick={() => {
            setSelectedProfileCharacter(name);
          }}>
          <img
            src={image}
            alt=""
            className={classes.profilePictureImage}
            style={{
              border:
                selectedProfileCharacter === name
                  ? `4px solid ${palette.turquoise900}`
                  : '',
            }}
          />
        </IconButton>
      ))}
    </Box>
  );
};

export default withStyles(styles)(ProfileCharacters);

import Box from '@mui/material/Box';
import React, {FC} from 'react';
import Benefit from './Benefit';

type Props = {
  benefits: string[];
};

const Benefits: FC<Props> = ({benefits}) => {
  return (
    <Box mt={2}>
      {benefits?.map((benefit, idx) => (
        <Box key={idx}>
          <Benefit benefit={benefit} />
        </Box>
      ))}
    </Box>
  );
};

export default Benefits;

import createStyles from '@mui/styles/createStyles';
import {makeStyles} from '@mui/styles';
import {TABLET_BREAKPOINT} from '../../styles/breakpoints';

export default makeStyles(theme => {
  return createStyles({
    container: {
      [theme.breakpoints.up(TABLET_BREAKPOINT)]: {
        padding: `0 5rem`,
      },
    },
  });
});

import {useQuery} from '@apollo/react-hooks';

import BullIcon from 'shared/assets/profileCharacters/bull.svg';
import CatIcon from 'shared/assets/profileCharacters/cat.svg';
import Bird1Icon from 'shared/assets/profileCharacters/bird1.svg';
import RobotIcon from 'shared/assets/profileCharacters/robot.svg';
import Bird2Icon from 'shared/assets/profileCharacters/bird2.svg';
import UnicornIcon from 'shared/assets/profileCharacters/unicorn.svg';
import {Profile} from '../../../../../shared/types/profile';
import {PROFILE_QUERY} from './EditProfileScreen.query';
import {CUSTOMER_PROFILES} from '../ProfilesScreen.query';

export enum ProfileCharacter {
  Bull = 'bull',
  Cat = 'cat',
  Robot = 'robot',
  DinoBird = 'dinoBird',
  Bird = 'bird',
  Unicorn = 'unicorn',
}

export const allProfileCharacters = [
  {
    image: BullIcon,
    name: ProfileCharacter.Bull,
  },
  {
    image: CatIcon,
    name: ProfileCharacter.Cat,
  },
  {
    image: RobotIcon,
    name: ProfileCharacter.Robot,
  },
  {
    image: Bird1Icon,
    name: ProfileCharacter.DinoBird,
  },
  {
    image: Bird2Icon,
    name: ProfileCharacter.Bird,
  },
  {
    image: UnicornIcon,
    name: ProfileCharacter.Unicorn,
  },
];

export const profileImageByName = (profileCharacter?: string) => {
  if (!profileCharacter) {
    return BullIcon;
  }
  const profilePicture = allProfileCharacters.find(
    ({name}) => name === profileCharacter,
  );

  if (!profilePicture) {
    return BullIcon;
  }
  return profilePicture?.image;
};

export const updateProfileWithMutation = (
  customerUpdateProfileMutation: (options: any) => Promise<any>,
) => {
  return async (profileId: string, input: any) => {
    try {
      const {data} = await customerUpdateProfileMutation({
        variables: {
          profileId,
          input,
        },
      });

      return data;
    } catch (error) {
      console.log('customer update profile', error);
      return;
    }
  };
};

export const removeProfileWithMutation = (
  customerRemoveProfileMutation: (options: any) => Promise<any>,
) => {
  return async (profileId: string) => {
    try {
      const {data} = await customerRemoveProfileMutation({
        variables: {
          profileId,
        },
      });

      return data;
    } catch (error) {
      console.log('customer remove profile', error);
      return;
    }
  };
};

export const useProfile = (profileId: string | undefined) => {
  const {loading, data, error, refetch} = useQuery<{profile: Profile}>(
    PROFILE_QUERY,
    {
      variables: {
        profileId,
      },
      fetchPolicy: 'network-only',
      onError: error => console.error('Customer profiles', error),
    },
  );

  const profileName = data?.profile?.name;
  const profileCharacter = data?.profile?.profileCharacter;
  const readingLevel = data?.profile.readingLevel;
  const requestedLevelAdjustmentAt = data?.profile.requestedLevelAdjustmentAt;

  return {
    data: {
      name: profileName,
      profileCharacter,
      readingLevel,
      requestedLevelAdjustmentAt,
    },
    loading,
    error,
    refetch,
  };
};

export const useProfiles = () => {
  const {loading, data, error, refetch} = useQuery<{profiles: Profile[]}>(
    CUSTOMER_PROFILES,
    {
      fetchPolicy: 'network-only',
      onError: error => console.error('Customer profiles', error),
    },
  );

  const profiles = data?.profiles;

  return {
    data: {
      profiles,
    },
    loading,
    error,
    refetch,
  };
};

import React, {useState} from 'react';

import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {useHistory} from 'react-router-dom';

import featureFlags, {
  FEATURE_IDS,
} from '../../../../../shared/utils/featureFlags';
import * as palette from 'shared/styles/palette';
import {EDIT_PROFILE_BASE_PATH} from '../../../../../shared/components/Route/routes';
import AnalyticsService from '../../../../../analytics/Analytics.service';
import AdjustReadingLevelService from './AdjustReadingLevel.service';
import {LightTooltip} from '../ProfileScreen.profile-card.reading-level';
import Typography from '@mui/material/Typography';
import withStyles, {WithStyles} from '@mui/styles/withStyles';
import styles from '../ProfilesScreen.styles';

interface props extends WithStyles<typeof styles> {
  profileId: string;
  requestedLevelAdjustmentAt?: Date | string;
}

const AdjustReadingLevelButton = ({
  classes,
  profileId,
  requestedLevelAdjustmentAt,
}: props) => {
  const showAdjustButton = featureFlags.isOn(FEATURE_IDS.ADJUST_READING_LEVEL);
  const allowSelfServiceLevelAdjust = featureFlags.isOn(
    FEATURE_IDS.ALLOW_SELF_SERVICE_LEVEL_ADJUST,
  );

  const [open, setOpen] = useState(false);
  const history = useHistory();
  const adjustReadingLevelHandler = () => {
    AnalyticsService.trackLevelAdjustmentStarted(profileId);

    const pushPath = allowSelfServiceLevelAdjust
      ? `${EDIT_PROFILE_BASE_PATH}/${profileId}/adjustReadingLevel`
      : `${EDIT_PROFILE_BASE_PATH}/${profileId}/readingLevel`;
    history.push(pushPath);
  };

  const disabled = allowSelfServiceLevelAdjust
    ? false
    : AdjustReadingLevelService.disabledFromRecentRequest(
        requestedLevelAdjustmentAt,
      );
  return (
    <Box>
      {showAdjustButton && (
        <LightTooltip
          placement="bottom"
          arrow
          open={disabled && open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          title={
            <Typography variant="subtitle1" className={classes.tooltipText}>
              Our curriculum specialist is going to reach out and help calibrate
              the perfect level for your child within the next 24-48 hours.
            </Typography>
          }>
          <span>
            <Button
              style={{
                fontWeight: 700,
                fontSize: '16px',
                color: disabled ? palette.gray300 : palette.turquoise900,
                textTransform: 'none',
              }}
              onClick={adjustReadingLevelHandler}
              disabled={disabled}>
              Adjust Level
            </Button>
          </span>
        </LightTooltip>
      )}
    </Box>
  );
};

export default withStyles(styles)(AdjustReadingLevelButton);

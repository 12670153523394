export function validateAddress(
  street?: string,
  city?: string,
  state?: string,
  zip?: string,
) {
  const VALID_STATE_REGEX = /^[A-Z]{2}$/;
  const VALID_ZIP_REGEX = /^\d{5}$/;

  return (
    !!street &&
    !!city &&
    !!state &&
    !!zip &&
    VALID_STATE_REGEX.test(state) &&
    VALID_ZIP_REGEX.test(zip)
  );
}

export function validateAddressNames(
  includeNames?: boolean,
  firstName?: string,
  lastName?: string,
) {
  if (!includeNames) {
    return true;
  }

  return !!firstName && !!lastName;
}

export const LocationPickerService = {
  validateAddress,
  validateAddressNames,
};
